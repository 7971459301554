interface PaymentMethodCardProps {
    label: string;
    card_brand: string;
    expiry_date: string;
    onClick?: () => void;
}

export default function PaymentMethodCard({ label, card_brand, expiry_date, onClick }: PaymentMethodCardProps) {

    const handleClick = () => {
        if (typeof onClick === "function") {
            onClick();
        }
    }

    return (
        <div aria-label="button" onClick={handleClick} className="flex gap-2 py-1 items-center">
            {card_brand === "visa" && (
                <img className="h-[25px]" src="/visa.png" alt="" />
            )}
            {card_brand === "mastercard" && (
                <img className="h-[25px]" src="/master_card.png" alt="" />
            )}
            <div className="flex flex-col justify-center">
                <p className="card-number m-0 text-[13px] font-semibold">
                    {label}
                </p>
                <p className="text-slate-500 text-[12px] m-0 text-sm">
                    Expires: {expiry_date}
                </p>
            </div>
        </div>
    );
}

export function PaymentMethodOptionLabel({ label, card_brand, expiry_date }) {
    return (
        <div className="flex gap-2 py-1 items-center">
            <img className="h-[25px]" src={`/${card_brand}.png`} alt="" />
            <div className="flex flex-col justify-center">
                <p className="card-number m-0 leading-[1] text-[13px] font-semibold">{label}</p>
                <p className="text-slate-500 leading-[1] text-[12px] m-0 text-sm">{expiry_date}</p>
            </div>
        </div>
    );
}