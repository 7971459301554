/*
======= FORMAT COLLABORATE FUNCTIONS =========
 */

import { getAuthUser } from "../auth.utils";

export function formatCollaborateMessage(message: string | string[]) {
    if (!message) {
        return <></>
    }

    if (Array.isArray(message)) {
        return message[0].split("\n").map((item, key) => {
            return (
                <span key={key}>
                    {item}
                    <br />
                </span>
            );
        });
    } else {
        return message.split("\n").map((item, key) => {
            return (
                <span key={key}>
                    {item}
                    <br />
                </span>
            );
        });
    }
}

function getDownloadLink(file = "") {
    const parts = file.split('.');
    const extension = parts.pop();
    const filename = parts.join('.');
    const { token } = getAuthUser();


    return process.env.REACT_APP_API_URL + 
        "/api/v1/tool/download-output-file/" + 
        filename + 
        "/" + 
        extension +
        "?access_token=" +
        token;
}

export function formatCollaborationAppFileLink(file?: string) {
    if (!file) {
        return <></>
    }


    return (
        <a target="_blank" href={getDownloadLink(file)} download={file} rel="noreferrer">
            {file}
            <br />
        </a>
    );
}
