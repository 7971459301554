import { SearchState } from "../../store/slices/searches.slice";
import { useSelector } from "react-redux";

export default function useSearches() {
    const searches: SearchState = useSelector(
        (state) => (state as any).searches,
    );

    function getFindChainHistory() {
        let history = "";
        searches.chain.forEach((response) => {
            history += "Question: " + response.question + "\n";
            history += "Answer: " + response.reply + "\n";
            history += "-------\n";
        });

        return history;
    }

    function getFolderChainHistory() {
        let history = "";
        searches.folderChain.forEach((response) => {
            history += "Question: " + response.question + "\n";
            history += "Answer: " + response.reply + "\n";
            history += "-------\n";
        });

        return history;
    }

    return {
        getFindChainHistory,
        getFolderChainHistory,
    };
}
