import React, { useEffect, useRef, useState } from "react";
import NeoSheetModal, {
    NeoSheetModalDescription,
    NeoSheetModalFooter,
    NeoSheetModalTitle,
} from "./NeoSheetModal";
import { Button, FormControl, Input, useToast } from "@chakra-ui/react";
import { useHttpClient } from "../../utils/http.utils";
import { AiFillCheckCircle } from "react-icons/ai";

interface NewsLetterModalProps {
    isOpen: boolean;
    onClose?: () => void;
}
export function NewsletterModal(props: NewsLetterModalProps) {
    const emailInput = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [joinNewsLetterStatus, setJoinNewsLetterStatus] = useState<
        "joined" | "loading" | "not_joined" | "joined_failed"
    >("not_joined");
    const toast = useToast();
    const { joinNewsLetterApi } = useHttpClient();

    useEffect(() => {
        setModalVisible(props.isOpen);
    }, [props.isOpen]);

    const close = () => {
        setModalVisible(false);
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    function joinNewsLetter() {
        const email = (emailInput.current as unknown as HTMLInputElement).value;

        if (!email) {
            toast({
                title: "Please enter your email",
                status: "warning",
            });
            return;
        }

        setJoinNewsLetterStatus("loading");

        joinNewsLetterApi({ email })
            .then(({ data }) => {
                console.log(data);
                if (data.success === false) {
                    console.error(data?.error[0]);
                    setJoinNewsLetterStatus("joined_failed");

                    if (data?.error[0].code === 5902) {
                        toast({
                            title: "You are already on the Newsletter list.",
                            status: "warning",
                        });
                    } else {
                        toast({
                            title: "Cannot add your email at this time. Please try again later",
                            status: "error",
                        });
                    }
                } else {
                    (emailInput.current as unknown as HTMLInputElement).value =
                        "";
                    setJoinNewsLetterStatus("joined");
                    toast({
                        title: "Thank you, you have been added to the list",
                        status: "success",
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                setJoinNewsLetterStatus("joined_failed");
                toast({
                    title: "Cannot add your email at this time. Please try again later",
                    status: "error",
                });
            });
    }

    return (
        <NeoSheetModal
            backgroundImageUrl={"/ChangeisNigh.png"}
            isOpen={modalVisible}
            onClose={close}
        >
            <div className="mb-10 md:mb-16">
                <img src="/NeoAI_text_anim.gif" alt="" className="neo-logo" />
            </div>
            <NeoSheetModalTitle>Change is nigh. Keep up.</NeoSheetModalTitle>
            <NeoSheetModalDescription>
                As an Everything App, Neo's potential is bound only by your
                creativity. Stay connected by subscribing to our newsletter for
                insightful tips, updates about innovative personas, new memory
                additions, tool enhancements, and more.
            </NeoSheetModalDescription>
            {joinNewsLetterStatus === "joined" ? (
                <div className="newsletter-success">
                    <AiFillCheckCircle className="check-icon text-green-500" />
                    <div className="">
                        <p className="title">
                            Thank you for joining NeoAI's Newsletter.
                        </p>
                        <p className="text">
                            You will receive Newsletter updates from NeoAI
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    <div className="pt-8 md:pt-12 flex pr-10">
                        <Input
                            ref={emailInput}
                            size={"lg"}
                            className="waitlist-input white"
                            placeholder="Email for Newsletter"
                        />
                    </div>
                    <FormControl mt={8}>
                        <Button
                            onClick={joinNewsLetter}
                            className="join-btn white"
                            background="white"
                            color="black"
                            isLoading={joinNewsLetterStatus === "loading"}
                            size={"lg"}
                        >
                            Subscribe
                        </Button>
                    </FormControl>
                </>
            )}

            <NeoSheetModalFooter>
                <ul>
                    <li>
                        <a target="_blank" href="https://neoworlder.com">
                            NeoWorlder.
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://projectxlabs.ai">
                            Project X.
                        </a>
                    </li>
                </ul>
            </NeoSheetModalFooter>
        </NeoSheetModal>
    );
}
