import { capitalizeWord } from "../utils/strings.utils";
import { ReactElement } from "react";

interface ActionCardsProps {
    title: string;
    allowHover?: boolean;
    subtitle?: string;
    text: string;
    actionEl: ReactElement;
    textOverflowLines?: number;
    onActionClick?: () => void;
    onClick?: () => void;
}
export default function ActionCard(props: ActionCardsProps) {
    const over_flow_lines = props.textOverflowLines || 2;

    const emitClick = () => {
        if (typeof props.onClick === "function") {
            props.onClick();
        }
    };

    const emitAction = () => {
        if (typeof props.onActionClick === "function") {
            props.onActionClick();
        }
    };

    // @ts-ignore
    return (
        <div className={`action-card ${props.allowHover ? "allow-hover" : ""}`}>
            <div className="action-card-title">
                <p
                    onClick={emitClick}
                    title={props.title}
                    className="title-text pr-4"
                >
                    {props.title}
                </p>
                <p className="action">
                    {props.actionEl && (
                        <button onClick={emitAction}>{props.actionEl}</button>
                    )}
                </p>
            </div>
            <p className="action-card-subtitle text-slate-500 text-sm">
                {capitalizeWord(props.subtitle || "")}
            </p>
            <div
                onClick={emitClick}
                title={props.text}
                className="action-card-text"
                style={{ WebkitLineClamp: over_flow_lines }}
            >
                {props.text}
            </div>
        </div>
    );
}
