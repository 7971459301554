import { Button } from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { useEffect, useState } from "react";
import { setMetaTags } from "../../../utils/seo.utils";
import PaymentMethodForm from "../../../components/accounts/PaymentMethodForm";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHttpClient } from "../../../utils/http.utils";
import { PaymentMethodModel } from "../../../models/payment.model";
import { DeleteConfirmation } from "../../../components/utils/ConfirmationModals";
import hotToast from "react-hot-toast";
import { useSelector } from "react-redux";
import PaymentMethodTile from "../../../components/accounts/PaymentMethodTile";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

export default function PaymentMethodsView() {
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const { updatePaymentMethodApi, deletePaymentMethodApi } = useHttpClient();
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;

    useEffect(() => {
        setMetaTags({ title: "Payment methods" });
    }, []);

    const closeFormModal = () => {
        setIsAddFormOpen(false);
    };

    const deletePaymentMethod = (paymentMethod: PaymentMethodModel) => {
        DeleteConfirmation({
            title: "Delete payment method",
            message: `Are you sure you want to remove the card ending with ${paymentMethod.card_extract} ?`,
        }).then((isConfirmed) => {
            if (isConfirmed) {
                // show loading
                hotToast.promise(deletePaymentMethodApi(paymentMethod.id), {
                    loading: "Deleting Payment method...",
                    success: "Payment method deleted",
                    error: "Cannot delete payment method",
                });
            }
        });
    };

    const setPaymentMethodAsDefault = (paymentMethod: PaymentMethodModel) => {
        // show loading
        hotToast.promise(updatePaymentMethodApi(paymentMethod.id, { is_default: "yes" }), {
            loading: "Setting to default...",
            success: "The Payment method has been set to default",
            error: "Cannot update payment method",
        });
    };

    return (
        <div className="accounts-subview account-payment-method w-full h-full">
            <div className="side-nav-sm with-action">
                <div>
                    <h4 className="title">Payment Methods</h4>
                    <p className="subtitle">Manage your payment methods</p>
                </div>
                <div className="actions">
                    <Button onClick={() => setIsAddFormOpen(true)} size="sm" leftIcon={<FiPlus />} colorScheme="brand">
                        Add
                    </Button>
                </div>
            </div>

            <div className="body pt-6 px-6">
                {paymentMethods.map((paymentMethod) => (
                    <PaymentMethodTile key={`pm_${paymentMethod.id}`} data={paymentMethod} onDelete={() => deletePaymentMethod(paymentMethod)} onSetAsDefault={() => setPaymentMethodAsDefault(paymentMethod)} />
                ))}
            </div>
            <PaymentMethodForm isOpen={isAddFormOpen} onClose={closeFormModal} />
        </div>
    );
}
