import "../styles/NeoModal.component.scss";
import { AiOutlineClose } from "react-icons/ai";
import { Button, FormControl, Input, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { BsArrowRightSquare } from "react-icons/bs";
import { useHttpClient } from "../utils/http.utils";

interface NeoModalProps {
    isOpen: boolean;
    title?: string;
    subtitle?: string;
    type: "banner" | "banner-image" | "sheet" | "regular" | "image";
    onClose?: () => void;
    backgroundImageUrl?: string;
}

export default function NeoModal(props: NeoModalProps) {
    const nodeRef = useRef(null);
    const emailInput = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [joinNewsLetterStatus, setJoinNewsLetterStatus] = useState<
        "joined" | "loading" | "not_joined" | "joined_failed"
    >("not_joined");
    const toast = useToast();
    const { joinNewsLetterApi } = useHttpClient();
    const coverImageRef = useRef(null);

    useEffect(() => {
        setModalVisible(props.isOpen);
    }, [props.isOpen]);

    const close = () => {
        setModalVisible(false);
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    function joinNewsLetter() {
        const email = (emailInput.current as unknown as HTMLInputElement).value;

        if (!email) {
            toast({
                title: "Please enter your email",
                status: "warning",
            });
            return;
        }

        joinNewsLetterApi({ email })
            .then(({ data }) => {
                console.log(data);
                if (data["success"] === false) {
                    console.error(data);
                    setJoinNewsLetterStatus("joined_failed");
                    toast({
                        title: "Cannot add your email at this time. Please try again later",
                        status: "error",
                    });
                } else {
                    (emailInput.current as unknown as HTMLInputElement).value =
                        "";
                    setJoinNewsLetterStatus("joined");
                    toast({
                        title: "Thank you, you have been added to the list",
                        status: "success",
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                setJoinNewsLetterStatus("joined_failed");
                toast({
                    title: "Cannot add your email at this time. Please try again later",
                    status: "error",
                });
            });
    }

    const onImageLoad = () => {
        const coverImage = coverImageRef.current as unknown as HTMLElement;
        // wait for image to complete loading before displaying
        coverImage?.classList.add("scale-up");
    };

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={modalVisible}
            timeout={100}
            classNames="neo-modal-node"
        >
            {modalVisible ? (
                <div ref={nodeRef} className="neo-modal-container">
                    {props.type === "banner" && (
                        <div className="overlay neo-regular">
                            <div className="neo-modal-body neo-modal-banner">
                                <div className="neo-modal-header">
                                    <button
                                        onClick={close}
                                        className="neo-modal-close"
                                    >
                                        <AiOutlineClose />
                                    </button>
                                    <h1 className="modal-title">
                                        {props.title}
                                    </h1>
                                    <h2 className="modal-description">
                                        {props.subtitle}
                                    </h2>
                                </div>
                                <div className="neo-modal-body">
                                    <div className="neo-modal-contents">
                                        <div className="neo-modal-contents-inner">
                                            <div className="content-section">
                                                <div className="icon-box">
                                                    <BsArrowRightSquare className="icon" />
                                                </div>
                                                <div className="">
                                                    <h2 className="title">
                                                        Our Upload Procedure
                                                    </h2>
                                                    <p className="">
                                                        Like how our brains
                                                        work, the first step in
                                                        creating your digital
                                                        twin with Neo involves
                                                        uploading your digital
                                                        memories. You get to
                                                        choose whether these
                                                        memories are classified
                                                        intel (private) or
                                                        general knowledge.
                                                        You're in control of the
                                                        section and location
                                                        within the 'brain' where
                                                        you want these memories
                                                        to be stored. And don't
                                                        worry about file
                                                        formats; we can handle
                                                        any type. Once
                                                        processed, your memories
                                                        are ready to be queried,
                                                        and the results will
                                                        astonish you.
                                                    </p>
                                                </div>
                                            </div>

                                            {}
                                            <div className="content-section">
                                                <div className="icon-box">
                                                    <BsArrowRightSquare className="icon" />
                                                </div>
                                                <div className="">
                                                    <h2 className="title">
                                                        A Note About privacy
                                                    </h2>
                                                    <p className="mb-3">
                                                        We take your privacy
                                                        seriously.
                                                    </p>
                                                    <p className="mb-3">
                                                        The information you
                                                        upload is used
                                                        exclusively for the
                                                        purpose of processing
                                                        and organizing it into
                                                        your own database. As
                                                        soon as the transfer
                                                        process is completed,
                                                        any files that were
                                                        handled in transit are
                                                        deleted, ensuring no
                                                        data residue on our
                                                        systems.
                                                    </p>
                                                    <p className="mb-3">
                                                        Access credentials to
                                                        your external
                                                        repositories and tools
                                                        are securely encrypted
                                                        and stored on your
                                                        device - we never have
                                                        direct access to them.
                                                        This local storage
                                                        approach fortifies your
                                                        data security by putting
                                                        you in control.
                                                    </p>
                                                    <p className="mb-3">
                                                        To improve our service,
                                                        we use advanced
                                                        techniques such as
                                                        training, alignment,
                                                        reinforcement learning
                                                        through human feedback,
                                                        and automated
                                                        introspection. By
                                                        choosing to use our
                                                        system, you are giving
                                                        us permission to use
                                                        these methods.
                                                    </p>
                                                    <p className="mb-3">
                                                        These strategies solely
                                                        aim to enhance the
                                                        precision and creativity
                                                        of your interactions.
                                                        It's important to note
                                                        that while we may need
                                                        to store some data on
                                                        our end during this
                                                        process, we ensure it's
                                                        encrypted. This means
                                                        the information isn't
                                                        usable by us or anyone
                                                        without the correct
                                                        decryption key.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {props.type === "sheet" && (
                        <div className="overlay neo-sheet">
                            <div className="neo-modal-body neo-modal-sheet">
                                <div className="modal-sheet-overlay">
                                    <button
                                        onClick={close}
                                        className="neo-modal-close"
                                    >
                                        <AiOutlineClose />
                                    </button>
                                    <div className="neo-modal-contents">
                                        <div className="mb-16">
                                            <img
                                                src="/NeoAI_text_anim.gif"
                                                alt=""
                                                className="neo-logo"
                                            />
                                        </div>
                                        <h1 className="neo-modal-title">
                                            Change is nigh. Keep up.
                                        </h1>
                                        <p className="neo-modal-description">
                                            As an Everything App, Neo's
                                            potential is bound only by your
                                            creativity. Stay connected by
                                            subscribing to our newsletter for
                                            insightful tips, updates about
                                            innovative personas, new memory
                                            additions, tool enhancements, and
                                            more.
                                        </p>
                                        <div className="pt-12 flex pr-10">
                                            <Input
                                                ref={emailInput}
                                                size={"lg"}
                                                className="waitlist-input white"
                                                placeholder="Email for Newsletter"
                                            />
                                        </div>
                                        <FormControl mt={8}>
                                            <Button
                                                onClick={joinNewsLetter}
                                                background="#fff"
                                                color="black"
                                                colorScheme="join-btn white"
                                                loadingText={
                                                    joinNewsLetterStatus ===
                                                    "loading"
                                                }
                                                size={"lg"}
                                            >
                                                Subscribe
                                            </Button>
                                        </FormControl>

                                        <div className="neo-contents-footer">
                                            <ul>
                                                <li>
                                                    <a
                                                        target="_blank"
                                                        href="https://neoworlder.com"
                                                    >
                                                        NeoWorlder.
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        target="_blank"
                                                        href="https://projectxlabs.ai"
                                                    >
                                                        Project X.
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/*<img src="/Luka_Image_PopUp.png" alt="" className="sheet-image"/>*/}
                            </div>
                        </div>
                    )}
                    {props.type === "regular" && (
                        <div className="overlay neo-regular">
                            <div className="neo-modal-body neo-modal-regular">
                                <img
                                    ref={coverImageRef}
                                    className="neo-modal-image-cover"
                                    onLoad={onImageLoad}
                                    src={props.backgroundImageUrl}
                                    alt=""
                                />
                                <div className="overlay-content darker">
                                    <button
                                        onClick={close}
                                        className="neo-modal-close"
                                    >
                                        <AiOutlineClose />
                                    </button>
                                    <div className="neo-modal-contents image-modal-small-text">
                                        <h1 className="neo-modal-title">
                                            Lootverse
                                        </h1>
                                        <p className="neo-modal-description">
                                            Our AI laboratory doubles as a
                                            virtual theme park, meticulously
                                            crafted from scratch. We have woven
                                            a backstory larger than the entire
                                            Harry Potter series combined, and
                                            developed applications to run an
                                            entire world, as if it was real. In
                                            this comprehensive creation, no
                                            stone has been left untouched.
                                        </p>

                                        <div className="neo-contents-footer">
                                            <ul>
                                                <li>
                                                    <a
                                                        target="_blank"
                                                        href="http://neoworlder.com/"
                                                    >
                                                        Origin Story.
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        target="_blank"
                                                        href="http://neoworlder.com/"
                                                    >
                                                        Learn More.
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        target="_blank"
                                                        href="http://lootverse.com/"
                                                    >
                                                        Visit.
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <img
                                        src="/lootverse.png"
                                        alt=""
                                        className="lootverse"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {props.type === "image" && (
                        <div className="overlay neo-regular">
                            <div className="neo-modal-body neo-modal-regular neo-modal-image">
                                <button
                                    onClick={close}
                                    className="neo-modal-close"
                                >
                                    <AiOutlineClose />
                                </button>
                                <div className="neo-modal-contents image-modal-small-text">
                                    <h1 className="neo-modal-title">
                                        Sparks of Silicon Life
                                    </h1>
                                    <p className="neo-modal-description mb-4">
                                        We’ve developed a unique process known
                                        as “Cellular Reasoning Chains,” where a
                                        persona creates cells within its brain
                                        that are interconnected to each other.
                                    </p>
                                    <p className="neo-modal-description mb-4">
                                        These cells are reasoning entities
                                        capable of executing highly complex
                                        tasks and have the unique ability to
                                        auto-generate additional cells in
                                        sequence.
                                    </p>
                                    <p className="neo-modal-description">
                                        You have the power to create any
                                        software application that can help you
                                        or your business thrive and evolve.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
        </CSSTransition>
    );
}
