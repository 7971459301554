import { AuthUserProfile } from "../../models/user.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateUserSession } from "../../utils/auth.utils";

export interface AuthState {
    isLoggedIn: boolean;
    user: AuthUserProfile | any;
}

const initialState: AuthState = {
    isLoggedIn: false,
    user: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthState(state, action: PayloadAction<boolean>) {
            state.isLoggedIn = action.payload;
        },
        setAuthUser(state, action: PayloadAction<AuthUserProfile>) {
            state.user = action.payload;
        },
        updateAuthUserProfile(state, action: PayloadAction<any>) {
            console.log(action.payload);
            const payloadData = {
                ...state.user,
                ...action.payload,
                ...(action.payload.name && { fullname: action.payload.name }),
            };
            delete payloadData.name;
            state.user = payloadData;
            // remove unnecessary fields
            // update cookies
            updateUserSession(payloadData);
        },
    },
});

export const { setAuthState, updateAuthUserProfile, setAuthUser } = authSlice.actions;

export default authSlice.reducer;
