import { createBrowserRouter, Navigate } from "react-router-dom";
import FindAnythingView from "../views/FindAnythingView";
import FolderView from "../views/FolderView";
import RefinementsView from "../views/RefinementsView";
import OAuthCallback from "../views/OAuthCallback";
import App from "../App";
import ProfileView from "../views/account/ProfileView";
import ProtectedRoute from "../components/auth/ProtectedRoute";
import StoreMemories from "../views/StoreMemories";
import NotFoundView from "../views/NotFoundView";
import PersonasView from "../views/PersonasView";
import OrganizationMembersView from "../views/account/organization/OrganizationMembersView";
import AccountRoot from "../views/account/AccountRoot";
import AgentsView from "../views/account/AgentsView";
import UsageView from "../views/account/UsageView";
import SubscriptionOrganizationView from "../views/account/organization/OrganizationView";
import PaymentMethodsView from "../views/account/organization/PaymentMethodsView";
import SubscriptionHistoryView from "../views/account/organization/SubscriptionHistoryView";
import NeuralPathways from "../views/account/NeuralPathways";
import SubcriptionView from "../views/account/SubcriptionView";
import ThreadView from "../views/ThreadView";
import RedirectAuth from "../components/auth/RedirectAuth";
import CollaborateView from "../views/Apply/CollaborateView";
import PromptEngineeringView from "../views/PromptEngineeringView";
import InviteesView from "../views/collaborate/InviteesView";
import IdeationView from "../views/collaborate/CollabIdeationView";
import LandingPage from "../views/LandingPage";
import SubscriptionCancelledView from "../views/payments/SubscriptionCancelledView";
import SubscriptionSuccessView from "../views/payments/SubscriptionSuccessView";
import CreditPurchase from "../views/payments/CreditPurchase";

export enum ROUTES {
    ASK_ANYTHING = "/ask-anything",
    ASK_ANYTHING_THREAD = "/ask-anything/thread/:id",
    FOLDERS = "/folders",
    FOLDERS_ID = "/folders/:id",
    REFINEMENTS = "/refinements",
    STORE_MEMORIES = "/store-memories",
    PERSONAS = "/personas",
    ACCOUNT = "/account",
    AGENTS = "/account/agents",
    USAGE = "/account/usage",
    SUBSCRIPTION = "/account/subscription",
    SUBSCRIPTION_ORGANIZATION = "/account/subscription/organization",
    SUBSCRIPTION_HISTORY = "/account/subscription/history",
    SUBSCRIPTION_PAYMENT_METHODS = "/account/subscription/payment-methods",
    BRAINS = "/account/brains",
    PROFILE = "/account/profile",
    MEMBERS = "/account/members",
    APPLY = "/apply",
    APPLY_APPLICATIONS = "/apply/applications",
    APPLY_INVITEES = "/apply/applications/invitees",
    APPLY_IDEATION = "/apply/applications/ideation",
    PROMPT_ENGINEERING = "/apply/prompt-engineering",
    THREAD = "/ask-anything/thread/:id",
    LANDING_PAGE = "/landing-page",
    OAUTH_CALLBACK = "/oauth_callback",
    PAYMENTS_SUBSCRIPTION_SUCCESS = "/payments/callback/subscription-success",
    PAYMENTS_SUBSCRIPTION_CANCELLED = "/payments/callback/subscription-cancelled",
    CREDIT_PURCHASE = "/payments/callback/credit-purchase",
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: (
                    <RedirectAuth>
                        <LandingPage />
                    </RedirectAuth>
                ),
            },
            {
                path: ROUTES.LANDING_PAGE,
                element: <LandingPage />,
            },
            {
                path: ROUTES.OAUTH_CALLBACK,
                element: <OAuthCallback />,
            },
            {
                path: ROUTES.ASK_ANYTHING,
                element: (
                    <ProtectedRoute>
                        <FindAnythingView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.ASK_ANYTHING_THREAD,
                element: <ThreadView />,
            },
            {
                path: ROUTES.FOLDERS,
                element: (
                    <ProtectedRoute>
                        <FolderView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.FOLDERS_ID,
                element: (
                    <ProtectedRoute>
                        <FolderView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.REFINEMENTS,
                element: (
                    <ProtectedRoute>
                        <RefinementsView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.STORE_MEMORIES,
                element: (
                    <ProtectedRoute>
                        <StoreMemories />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.PERSONAS,
                element: <PersonasView />,
            },
            {
                path: ROUTES.APPLY_APPLICATIONS,
                element: <CollaborateView />,
                children: [
                    {
                        path: ROUTES.APPLY_INVITEES,
                        element: <InviteesView />,
                    },
                    {
                        path: ROUTES.APPLY_IDEATION,
                        element: <IdeationView />,
                    },
                ],
            },
            {
                path: ROUTES.PROMPT_ENGINEERING,
                element: <PromptEngineeringView />,
            },
            {
                path: ROUTES.ACCOUNT,
                element: (
                    <ProtectedRoute>
                        <AccountRoot />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: "/account/",
                        element: <Navigate to={ROUTES.PROFILE} replace />,
                    },
                    {
                        path: ROUTES.MEMBERS,
                        element: (
                            <ProtectedRoute>
                                <OrganizationMembersView />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: ROUTES.PROFILE,
                        element: (
                            <ProtectedRoute>
                                <ProfileView />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: ROUTES.AGENTS,
                        element: (
                            <ProtectedRoute>
                                <AgentsView />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: ROUTES.USAGE,
                        element: (
                            <ProtectedRoute>
                                <UsageView />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: ROUTES.SUBSCRIPTION,
                        element: (
                            <ProtectedRoute>
                                <SubcriptionView />
                            </ProtectedRoute>
                        ),
                        children: [
                            {
                                path: ROUTES.SUBSCRIPTION,
                                element: (
                                    <Navigate
                                        to={ROUTES.SUBSCRIPTION_ORGANIZATION}
                                        replace
                                    />
                                ),
                            },
                            {
                                path: ROUTES.SUBSCRIPTION_ORGANIZATION,
                                element: (
                                    <ProtectedRoute>
                                        <SubscriptionOrganizationView />
                                    </ProtectedRoute>
                                ),
                            },
                            {
                                path: ROUTES.SUBSCRIPTION_HISTORY,
                                element: (
                                    <ProtectedRoute>
                                        <SubscriptionHistoryView />
                                    </ProtectedRoute>
                                ),
                            },
                            {
                                path: ROUTES.SUBSCRIPTION_PAYMENT_METHODS,
                                element: (
                                    <ProtectedRoute>
                                        <PaymentMethodsView />
                                    </ProtectedRoute>
                                ),
                            },
                        ],
                    },
                    {
                        path: ROUTES.BRAINS,
                        element: (
                            <ProtectedRoute>
                                <NeuralPathways />
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
            {
                path: ROUTES.PAYMENTS_SUBSCRIPTION_SUCCESS,
                element: <SubscriptionSuccessView />,
            },
            {
                path: ROUTES.PAYMENTS_SUBSCRIPTION_CANCELLED,
                element: <SubscriptionCancelledView />,
            },
            {
                path: ROUTES.CREDIT_PURCHASE,
                element: <CreditPurchase />,
            },
        ],
    },
    {
        path: "*",
        element: <NotFoundView />,
    },
]);

export default router;
