import {
    Checkbox,
    Switch,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    TagCloseButton,
    TagLabel,
} from "@chakra-ui/react";
import { HiLockClosed } from "react-icons/hi";
import "../../styles/MemoriesPanel.component.scss";
import { useDispatch, useSelector } from "react-redux";
import { UploadDataState } from "../../store/slices/memories.slice";
import { useEffect, useState } from "react";
import {
    MemoriesModelQuery,
    MemoryBrainType,
} from "../../models/memories.model";
import { removeDuplicatesByProperty } from "../../utils/strings.utils";
import { BsFillStarFill } from "react-icons/bs";
import {
    checkAllSegregatedMemories,
    checkThirdPartyMemory,
    checkSegregatedMemory,
    SearchState,
    setThirdPartyMemories,
    setIsAllMemoriesSelected,
    setSegregatedMemories,
    setShouldSearchNotes,
    setUseRLHFSearch,
    uncheckAllSegregatedMemories,
} from "../../store/slices/searches.slice";

export default function MemoriesPanel() {
    const memories_state: UploadDataState = useSelector(
        (state: any) => state.uploadedData,
    );
    const isAllMemoriesSelected: boolean = useSelector(
        (state: { searches: SearchState }) =>
            state.searches.isAllMemoriesSelected,
    );
    const segregatedMemories: MemoriesModelQuery[] = useSelector(
        (state: { searches: SearchState }) => state.searches.segregatedMemories,
    );
    const thirdPartyMemories: MemoriesModelQuery[] = useSelector(
        (state: { searches: SearchState }) => state.searches.thirdPartyMemories,
    );
    const useRLHFSearch: boolean = useSelector(
        (state: { searches: SearchState }) => state.searches.useRLHFSearch,
    );
    const shouldSearchNotes: boolean = useSelector(
        (state: { searches: SearchState }) => state.searches.shouldSearchNotes,
    );

    const [searchThirdPartyMemoriesList, setSearchThirdPartyMemoriesList] =
        useState<MemoriesModelQuery[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (memories_state.data.length > 0) {
            const segregatedMemoriesTemp = memories_state.data
                .filter(
                    (memory) =>
                        memory.brain_type === MemoryBrainType.SEGREGATED,
                )
                .map((memory) => ({ ...memory, isSelected: true }));

            dispatch(
                setSegregatedMemories(
                    removeDuplicatesByProperty(
                        segregatedMemoriesTemp,
                        "sector",
                    ),
                ),
            );

            setThirdPartyGeneralMemories();
        } else {
            dispatch(setSegregatedMemories([]));
        }
    }, [memories_state.data]);

    const checkShouldSearchNotes = (checked: boolean) => {
        dispatch(setShouldSearchNotes(checked));
    };

    const isAllSegregatedMemoriesSelected = () =>
        segregatedMemories.every((memory) => memory.isSelected);

    const setThirdPartyGeneralMemories = () => {
        if (thirdPartyMemories.length === 0) {
            const generalMemoriesTemp = memories_state.data
                .filter(
                    (memory) =>
                        memory.brain_type === MemoryBrainType.THIRD_PARTY,
                )
                .map((memory) => ({ ...memory, isSelected: false }));

            dispatch(setThirdPartyMemories(generalMemoriesTemp));
        }
    };

    // Write a function that sets all memories to selected
    const selectAllSegregated = (e) => {
        const checked = e.target.checked;

        if (checked) {
            dispatch(checkAllSegregatedMemories());
        } else {
            dispatch(uncheckAllSegregatedMemories());
        }
    };

    const unCheckAllSegregatedSectors = () => {
        dispatch(uncheckAllSegregatedMemories());
    };

    const checkSegregatedSector = (memoryId: string, checked: boolean) => {
        dispatch(checkSegregatedMemory({ id: memoryId, checked }));
        // If checked is false, unchecked all segregated
        if (!checked && isAllMemoriesSelected) {
            dispatch(setIsAllMemoriesSelected(false));
        }
    };

    const checkThirdParySector = (memoryId: string, checked: boolean) => {
        dispatch(checkThirdPartyMemory({ id: memoryId, checked }));
    };

    const onSelectAllMemories = (e) => {
        const checked = e.target.checked;
        // dispatch(setMemoriesChecklist(checked ? "All" : "Segregated"));
        dispatch(setIsAllMemoriesSelected(checked));
        if (checked) {
            dispatch(checkAllSegregatedMemories());
        } else {
            dispatch(uncheckAllSegregatedMemories());
        }
    };

    // This function is fired the "x" icon on the all memories is clicked
    function onDeselectAllMemories() {
        dispatch(setIsAllMemoriesSelected(false));
        dispatch(uncheckAllSegregatedMemories());
    }

    // a function that searches through general memories by sector property
    const searchGeneralMemories = (e: any) => {
        const query: string = e.target.value;
        if (query.length > 0) {
            const memories = thirdPartyMemories.filter((memory) =>
                memory.sector.toLowerCase().includes(query.toLowerCase()),
            );
            setSearchThirdPartyMemoriesList(memories);
        } else {
            setSearchThirdPartyMemoriesList([]);
        }
    };

    const onRLHFSearchChange = (e) => {
        dispatch(setUseRLHFSearch(e.target.checked));
    };

    const onSearchNotesChange = (e) => {
        checkShouldSearchNotes(e.target.checked);
    };

    return (
        <div className="memories-library-container sidebar-sm">
            <div className="memories-library-header">
                <div className="side-nav-sm">
                    <h2 className="title">Memories</h2>
                    <p className="subtitle">
                        {" "}
                        Select the memories to be queried.
                    </p>
                </div>
            </div>
            <div className="memories-library-body sidebar-body">
                <div className="settings mb-6">
                    <div className="flex justify-between items-center mb-3">
                        <p className="font-medium text-slate-700">
                            Use RLHF Search
                        </p>
                        <Switch
                            isChecked={useRLHFSearch}
                            onChange={onRLHFSearchChange}
                            id="rlhfSwitch"
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        <p className="font-medium text-slate-700">
                            Search Notes
                        </p>
                        <Switch
                            isChecked={shouldSearchNotes}
                            onChange={onSearchNotesChange}
                            id="searchNotes"
                        />
                    </div>
                </div>
                <div className="selected-container mt-4 mb-6">
                    <p className="font-semibold form-subtitle mb-6">
                        Selected sectors
                    </p>
                    <div className="">
                        {isAllMemoriesSelected ? (
                            <Tag
                                size={"md"}
                                className="mr-2 mb-1"
                                borderRadius="full"
                                variant="subtle"
                                colorScheme="blue"
                            >
                                <TagLabel>All</TagLabel>
                                <TagCloseButton
                                    onClick={onDeselectAllMemories}
                                />
                            </Tag>
                        ) : (
                            <>
                                {isAllSegregatedMemoriesSelected() ? (
                                    <Tag
                                        size={"md"}
                                        className="mr-2 mb-1"
                                        borderRadius="full"
                                        variant="subtle"
                                        colorScheme="blue"
                                    >
                                        <TagLabel>All (Segregated)</TagLabel>
                                        <TagCloseButton
                                            onClick={
                                                unCheckAllSegregatedSectors
                                            }
                                        />
                                    </Tag>
                                ) : (
                                    segregatedMemories.map((sector, index) => {
                                        if (sector.isSelected) {
                                            return (
                                                <Tag
                                                    size={"md"}
                                                    key={`seg-sector-tag-${index}`}
                                                    className="mr-2 mb-1"
                                                    borderRadius="full"
                                                    variant="subtle"
                                                    colorScheme="blue"
                                                >
                                                    <TagLabel>
                                                        {sector.sector_display}
                                                    </TagLabel>
                                                    <TagCloseButton
                                                        onClick={() =>
                                                            checkSegregatedSector(
                                                                sector.id,
                                                                false,
                                                            )
                                                        }
                                                    />
                                                </Tag>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                )}
                            </>
                        )}
                        {thirdPartyMemories.map((sector, index) => {
                            if (sector.isSelected) {
                                return (
                                    <Tag
                                        size={"md"}
                                        key={`seg-sector-tag-${index}`}
                                        className="mr-2 mb-1"
                                        borderRadius="full"
                                        variant="subtle"
                                        colorScheme="blue"
                                    >
                                        <TagLabel>
                                            {sector.sector_display}
                                        </TagLabel>
                                        <TagCloseButton
                                            onClick={() =>
                                                checkThirdParySector(
                                                    sector.id,
                                                    false,
                                                )
                                            }
                                        />
                                    </Tag>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
                <div className="mb-2 mt-2">
                    <Checkbox
                        isChecked={isAllMemoriesSelected}
                        onChange={onSelectAllMemories}
                    >
                        All Memories
                    </Checkbox>
                </div>
                <Tabs>
                    <TabList>
                        <Tab>
                            <HiLockClosed />
                            &nbsp;<span className="text-sm">Segregated</span>
                        </Tab>
                        <Tab>
                            &nbsp;<span className="text-sm">3rd Party</span>
                        </Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel pl={0} pr={0}>
                            <div>
                                <Checkbox
                                    isChecked={isAllSegregatedMemoriesSelected()}
                                    onChange={selectAllSegregated}
                                >
                                    All
                                </Checkbox>
                            </div>
                            <div className="pl-2 pt-1">
                                {segregatedMemories.map((sector, index) => {
                                    return (
                                        <SegregatedSectorCard
                                            key={`seg-sector-${index}`}
                                            title={sector.sector_display}
                                            description={
                                                sector.sector_description
                                            }
                                            isSelected={sector.isSelected}
                                            onChanged={(checked) =>
                                                checkSegregatedSector(
                                                    sector.id,
                                                    checked,
                                                )
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </TabPanel>
                        <TabPanel pl={0} pr={0}>
                            <div className="sector-memory-card-search-input-group mb-3">
                                <input
                                    type="text"
                                    placeholder="Search 3rd party prompts"
                                    onChange={searchGeneralMemories}
                                />
                            </div>
                            {searchThirdPartyMemoriesList.map(
                                (sector, index) => {
                                    return (
                                        <GeneralSectorCard
                                            key={`gen-sector-${index}`}
                                            title={sector.sector_display}
                                            description={
                                                sector.sector_description
                                            }
                                            isSelected={sector.isSelected}
                                            onChanged={(checked) =>
                                                checkThirdParySector(
                                                    sector.id,
                                                    checked,
                                                )
                                            }
                                        />
                                    );
                                },
                            )}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </div>
    );
}

interface SegregatedSectorCardProps {
    title: string;
    description: string;
    hideCheckBox?: boolean;
    onChanged?: (checked: boolean) => void;
    onClick?: () => void;
    isSelected?: boolean;
}
function SegregatedSectorCard(props: SegregatedSectorCardProps) {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(() => {
        setIsChecked(props.isSelected || false);
    }, [props.isSelected]);

    const onChange = (checked: boolean) => {
        setIsChecked(checked);
        if (props.onChanged) props.onChanged(checked); // Call the onChanged function if it exists
    };

    const clickEvent = () => {
        if (typeof props.onClick === "function") {
            props.onClick();
        }
    };

    return (
        <div onClick={clickEvent} className="sector-memory-card">
            {!props.hideCheckBox && (
                <div className="pt-2">
                    <Checkbox
                        isChecked={isChecked}
                        onChange={(e) => onChange(e.target.checked)}
                    />
                </div>
            )}
            <div className="">
                <p className="sector-title truncate mb-1" title={props.title}>
                    {props.title}
                </p>
                <p
                    title={props.description}
                    className="sector-description text-slate-500 text-sm"
                >
                    {props.description}
                </p>
            </div>
        </div>
    );
}

function GeneralSectorCard(props: SegregatedSectorCardProps) {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(() => {
        setIsChecked(props.isSelected || false);
    }, [props.isSelected]);

    const onChange = (checked: boolean) => {
        setIsChecked(checked);
        if (props.onChanged) props.onChanged(checked); // Call the onChanged function if it exists
    };
    return (
        <div className="sector-memory-card">
            <div className="pt-1">
                <Checkbox
                    isChecked={isChecked}
                    onChange={(e) => onChange(e.target.checked)}
                />
            </div>
            <div className="">
                <div
                    className="sector-title general-title mb-1"
                    title={props.title}
                >
                    <p className="truncate">{props.title}</p>
                </div>
                <p
                    title={props.description}
                    className="sector-description text-slate-500 mb-2 text-sm"
                >
                    {props.description}
                </p>
                <div className="sector-card-footer flex justify-between items-center py-2">
                    <p className="flex items-center">
                        <span className="text-xs text-slate-700">4.5</span>
                        &nbsp;
                        <BsFillStarFill className="rating-icon" />
                    </p>
                    <p
                        title={props.description}
                        className="sector-description text-slate-500 text-xs text-right"
                    >
                        +$0.001/Word
                    </p>
                </div>
            </div>
        </div>
    );
}
