import { Button, ButtonGroup, Switch } from "@chakra-ui/react";
import { CiWarning } from "react-icons/ci";
import { useHttpClient } from "../../utils/http.utils";
import { useEffect, useState } from "react";
import { PersonaModel } from "../../models/persona.model";
import { config } from "../../config";
import PersonaDonateModal from "./PersonaDonateModal";

export default function PersonaAutonomy({persona}: {persona: PersonaModel | undefined}) {
    const {
        updatePersonaApi,
        getPersonaHealthPointApi,
        initiateDeepenLifeApi,
        getPersonaBalanceApi
    } = useHttpClient();

    const {
        dailyHealthPointUsage,
        maxHealthPointRefill
    } = config;

    const [personaLifeExpectancy, setPersonaLifeExpectancy] = useState(0);
    const [personaHealthPoints, setPersonaHealthPoints] = useState(0);
    const [personaCredits, setPersonaCredits] = useState(0);
    const [personaLTT, setPersonaLTT] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showDonateModal, setShowDonateModal] = useState(false);
    const [donateMode, setDonateMode] = useState<"health" | "ltt" | "credit">("credit");

    useEffect(() => {
        const fetchLifeExpectancy = async () => {
            const healthPointsObject = await getPersonaHealthPointApi(persona?.id!);

            if (typeof healthPointsObject?.health_points === "number") {
                setPersonaHealthPoints(healthPointsObject.health_points)
                setPersonaLifeExpectancy(Math.floor(healthPointsObject.health_points / dailyHealthPointUsage));
            } else {
                setPersonaLifeExpectancy(0);
            }
        }

        const fetchPersonaBalance = async () => {
            const balanceObject = await getPersonaBalanceApi(persona?.id!);

            if (balanceObject.credit_balance) {
                setPersonaCredits(balanceObject.credit_balance)
            }

            if (balanceObject.ltt_balance) {
                setPersonaLTT(balanceObject.ltt_balance)
            }
        }

        if (persona?.id) {
            fetchLifeExpectancy();

            fetchPersonaBalance();
        } else {
            setPersonaLifeExpectancy(0);
        }
    }, [persona]);

    const updatePersona = async (data: PersonaModel) => {
        if (persona?.id) {
            setIsLoading(true);
            await updatePersonaApi(data);
            setIsLoading(false);
        }
    };

    const initiateDeepenLife = async (e) => {
        if (e.target.checked && persona?.id) {
            setIsLoading(true);
            await initiateDeepenLifeApi(persona?.id!)
            setIsLoading(false);
        }
    };

    const openDonateModal = (mode: "health" | "ltt" | "credit") => {
        setDonateMode(mode);
        setShowDonateModal(true);
    }

    return (
        <div className="pt-3 pb-8">
            <h1 className="mb-[7px] leading-[1] font-medium text-[16px]">
                Opt-in to Deepen Life
            </h1>
            <p className="text-slate-500 text-[14px]">
                NeoWorlder has developed a parallel universe filled with
                history, interactions, and values to infuse AI entities with a
                semblance of life. In this unpredictable environment, these
                entities operate based on their primary directives, interacting
                with each other, earning money for necessities like food and
                healthcare, engaging in conflicts, producing offspring, and even
                experiencing death. It's essential to regularly check usage and
                establish spending caps to avoid excessive costs.
            </p>
            <p className="text-slate-500 text-[14px] my-3">
                Once you opt-in, it is permanent and irreversible. Do not turn
                on if you are unsure.
            </p>

            <div className="switch-form border-b flex items-center pb-8 mb-6 pt-2 gap-[45px]">
                <div className="switch flex gap-2">
                    <Switch
                        id="email-alerts"
                        isChecked={persona?.deepen_life === "yes"}
                        isDisabled={isLoading || persona?.deepen_life === "yes"}
                        onChange={initiateDeepenLife}
                    />
                    <p className="text-slate-500">
                        You choose to turn this on.
                    </p>
                </div>
                <div className="switch flex gap-2">
                    <Switch
                        id="email-alerts"
                        isChecked={persona?.laboratory_participation === "yes"}
                        isDisabled={isLoading}
                        onChange={() => updatePersona({
                            id: persona!.id,
                            laboratory_participation: persona?.laboratory_participation === "yes" ? "no" : "yes"
                        })}
                    />
                    <p className="text-slate-500">Engage in the laboratory</p>
                </div>
            </div>

            <div className="balance">
                <div className="balance-card border rounded-[8px] p-4 mb-4">
                    <h2 className="title font-medium pl-[10px] text-[14px]">
                        Money Account
                    </h2>
                    <div className="section border-b py-3">
                        <h1 className="text-xl pl-[10px] ">{personaLTT} TIX</h1>
                        <div className="btn pt-4">
                            <ButtonGroup size="sm" isAttached variant="outline">
                                {/* <Button colorScheme="blue" variant="ghost">
                                    Buy more
                                </Button> */}
                                <Button colorScheme="blue" variant="ghost" onClick={() => openDonateModal("ltt")}>
                                    Donate
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="section pt-3">
                        <h1 className="text-xl pl-[10px] ">{personaCredits} T$</h1>
                        <div className="btn pt-4">
                            <ButtonGroup size="sm" isAttached variant="outline">
                                {/* <Button colorScheme="blue" variant="ghost">
                                    Buy more
                                </Button> */}
                                <Button colorScheme="blue" variant="ghost" onClick={() => openDonateModal("credit")}>
                                    Donate
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>

                <div className="health-balance-card border rounded-[8px] p-4">
                    <h2 className="title font-medium pl-[10px] text-[14px]">
                        Healthcare
                    </h2>
                    <div className="section pt-3">
                        <h1 className="text-xl pl-[10px] ">{personaHealthPoints} Health</h1>
                        <div className="btn pt-4">
                            <ButtonGroup size="sm" isAttached variant="outline">
                                {/* <Button colorScheme="blue" variant="ghost">
                                    Buy Health
                                </Button> */}
                                <Button colorScheme="blue" variant="ghost" onClick={() => openDonateModal("health")}>
                                    Donate
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <p className="text-slate-500 text-[15px] my-6">
                    {"" + persona?.first_name + " " + persona?.last_name}'s life expectancy is{" "}
                    <strong>
                        {personaLifeExpectancy}&nbsp;
                        {personaLifeExpectancy === 1 ? "day" : "days"}
                    </strong>.
                </p>
                <div className="bg-gray-50 p-4 mb-8 flex items-center gap-3 rounded-[8px]">
                    <p className="">
                        <CiWarning fontSize="34px" />
                    </p>
                    <p className="text-[13px] text-slate-500">
                        To simulate life, health points decrease by {dailyHealthPointUsage} every day.
                        Other than in potions, you can only top up by {maxHealthPointRefill} health
                        points at a time. If your Al persona depletes its health
                        points, it becomes 'dead' and can't be revived. While
                        you can use it to create offspring, the child may have
                        different personality traits. The child will inherit the
                        parent's history, which may evolve over time.
                    </p>
                </div>
            </div>
            {/* modals */}
            {persona && <PersonaDonateModal 
                isOpen={showDonateModal} 
                mode={donateMode} 
                persona={persona as PersonaModel}
                onClose={() => setShowDonateModal(false)} 
            />}
        </div>
    );
}
