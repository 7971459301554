import "../styles/ChainingCard.component.scss";
import { Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { SearchModel } from "../models/search.model";
import { formatDate } from "../utils/date.utils";
import { useEffect, useState } from "react";
import {
    clearHistory,
    removeFromChain,
    clearChain,
    removeFromFolderChain,
    clearFolderChain,
    clearFolderHistory,
} from "../store/slices/searches.slice";
import { useHttpClient } from "../utils/http.utils";
import { VscClearAll } from "react-icons/vsc";

interface ChainCardProps {
    mode?: "folder" | "find";
}

export default function ChainingCard(props: ChainCardProps) {
    const chainMode = props.mode || "find";
    const chainedItems: SearchModel[] = useSelector(
        (state) => (state as any).searches.chain,
    );
    const folderChainedItems: SearchModel[] = useSelector(
        (state) => (state as any).searches.folderChain,
    );
    const [isChainMode, setIsChainMode] = useState<boolean>(false);
    const [displayData, setDisplayData] = useState<SearchModel[]>([]);
    const { fetchHistoryApi } = useHttpClient();
    const dispatch = useDispatch();

    useEffect(() => {
        setIsChainMode(false);
        switch (chainMode) {
            case "find":
                setIsChainMode(chainedItems.length > 0);
                setDisplayData(chainedItems);
                break;
            case "folder":
                setIsChainMode(folderChainedItems.length > 0);
                setDisplayData(folderChainedItems);
                break;
        }
    }, [chainMode, chainedItems, folderChainedItems]);

    const unChain = (id: number) => {
        switch (chainMode) {
            case "find":
                dispatch(removeFromChain(id));
                break;
            case "folder":
                dispatch(removeFromFolderChain(id));
                break;
        }
        // fetch new history of items
        // Using set time out to wait for the state to update variables
        setTimeout(() => {
            switch (chainMode) {
                case "find":
                    if (chainedItems.length > 0) {
                        // get new history of chained items
                        fetchHistoryApi(
                            chainedItems.map((item) =>
                                (item.id as number).toString(),
                            ),
                        );
                    }
                    break;
                case "folder":
                    if (chainedItems.length > 0) {
                        // get new history of chained items
                        fetchHistoryApi(
                            folderChainedItems.map((item) =>
                                (item.id as number).toString(),
                            ),
                            "folder",
                        );
                    }
                    break;
            }
        });
    };

    const clearChainItems = () => {
        switch (chainMode) {
            case "find":
                dispatch(clearChain());
                dispatch(clearHistory());
                break;
            case "folder":
                dispatch(clearFolderChain());
                dispatch(clearFolderHistory());
                break;
        }
    };

    return (
        <div className={`chaining-box ${isChainMode ? "show" : ""}`}>
            <div className="side-nav-sm">
                <h2 className="title">Chain</h2>
                <p className="subtitle">
                    Chain past conversations for better context.
                </p>
                {/*<Progress value={20} size="xs" />*/}
            </div>
            <div className="chain-card-body px-6 pt-4">
                {displayData.map((item: SearchModel, index: number) => {
                    return (
                        <div key={index} className="chain mt-3 pl-4 ml-1 pr-1">
                            <div className="heading flex items-center gap-3 pb-1">
                                <p className="date text-slate-500 text-sm">
                                    {formatDate(item.date_added as string)}
                                </p>
                                <div className="action">
                                    <button
                                        onClick={() =>
                                            unChain(item.id as number)
                                        }
                                        className="text-red-500 text-sm font-semibold"
                                    >
                                        Unchain
                                    </button>
                                </div>
                            </div>
                            <h1 className="question font-medium">
                                {item.question}
                            </h1>
                        </div>
                    );
                })}
            </div>
            <div className="chain-footer px-6 pt-2 border-t">
                <Button
                    leftIcon={<VscClearAll />}
                    onClick={clearChainItems}
                    colorScheme="red"
                    size="sm"
                    variant="ghost"
                >
                    Clear chain
                </Button>
            </div>
        </div>
    );
}
