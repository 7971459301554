import { ReactElement, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowRightSquare } from "react-icons/bs";

interface NeoModalProps {
    title: string;
    subtitle: string;
    isOpen: boolean;
    children: JSX.Element | JSX.Element[];
    onClose?: () => void;
}

export function NeoBannerModalContent({
    title,
    children,
}: {
    title?: string;
    children: JSX.Element | JSX.Element[];
}) {
    return (
        <div className="content-section">
            {title && (
                <div className="icon-box">
                    <BsArrowRightSquare className="icon" />
                </div>
            )}
            <div className="">
                {title && <h2 className="title">{title}</h2>}
                {children}
            </div>
        </div>
    );
}

export function NeoBannerModalParagraph({ children }) {
    return <p className="mb-3 neo-modal-paragraph">{children}</p>;
}

export default function NeoBannerModal(props: NeoModalProps) {
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        setModalVisible(props.isOpen);
    }, [props.isOpen]);

    const close = () => {
        setModalVisible(false);
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    return modalVisible ? (
        <div className="neo-modal-container">
            <div className="overlay neo-regular">
                <div className="neo-modal-banner">
                    <div className="neo-modal-header">
                        <button onClick={close} className="neo-modal-close">
                            <AiOutlineClose />
                        </button>
                        <h1 className="modal-title">{props.title}</h1>
                        <h2 className="modal-description">{props.subtitle}</h2>
                    </div>
                    <div className="neo-modal-body">
                        <div className="neo-modal-contents">
                            <div className="neo-modal-contents-inner">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
}
