import { Skeleton, Stack } from "@chakra-ui/react";
import React from "react";

export default function SkeletonLoader() {
    return (
        <div className="mb-8">
            <div className="bg-white rounded-xl px-8 mb-4 py-8">
                <Stack>
                    <Skeleton height="20px" width="200px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                </Stack>
            </div>

            <div className="bg-white rounded-xl px-8 mb-4 py-8">
                <Stack>
                    <Skeleton height="20px" width="200px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                </Stack>
            </div>

            <div className="bg-white rounded-xl px-8 mb-4 py-8">
                <Stack>
                    <Skeleton height="20px" width="200px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                </Stack>
            </div>
        </div>
    );
}
