import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    IconButton,
    Table,
    Thead,
    Th,
    Tr,
    Tbody,
    Td,
    HStack,
} from "@chakra-ui/react";
import { FaEdit, FaTrash, FaCopy, FaCheck } from "react-icons/fa";
import { useHttpClient } from "../../utils/http.utils";
import { PersonaState } from "../../store/slices/persona.slice";
import { useSelector } from "react-redux";
import hideMiddleChars from "../../utils/collaborate/hideMiddleChars";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { formatDate } from "../../utils/date.utils";
import hotToast from "react-hot-toast";

type SecretKey = {
    id: string;
    name: string;
    key: string;
    createdAt: Date;
    last_used: Date;
};

enum ModalType {
    None,
    SecretKeyGenerated,
    ConfirmDelete,
}

export const SecretKeysTable: React.FC = () => {
    const {
        createPersonaApiKeyApi,
        getPersonaApiKeysApi,
        deletePersonaApiKeyApi,
    } = useHttpClient();

    const [secretKeys, setSecretKeys] = useState<SecretKey[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newlyCreatedKey, setNewlyCreatedKey] = useState<string | null>(null);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const [keyToDelete, setKeyToDelete] = useState<string | null>(null);
    const [modalType, setModalType] = useState<ModalType>(ModalType.None);

    const personaState: PersonaState = useSelector(
        (state: any) => state.persona,
    );
    const { id: personaId, first_name: personaName } =
        personaState.personaAttributesForm;

    const openModal = (type: ModalType) => setModalType(type);
    const closeModal = () => {
        setModalType(ModalType.None);
        setNewlyCreatedKey(null);
        setIsCopied(false);
        setKeyToDelete(null);
    };

    useEffect(() => {
        const fetchSecretKeys = async () => {
            try {
                const secretKeys = await getPersonaApiKeysApi(personaId);
                setSecretKeys(secretKeys);
            } catch (err) {
                hotToast.error("Cannot fetch persona secret keys");
            }
        };

        fetchSecretKeys();
    }, [personaId]);

    const createNewSecret = async () => {
        try {
            if (!personaId || !personaName) {
                throw new Error("persona id and name is not defined");
            }
            setIsLoading(true);
            const newSecretKey = await createPersonaApiKeyApi(
                personaId,
                `API Key ${secretKeys.length + 1}`,
            );
            if (newSecretKey) {
                setIsLoading(false);
                const modified: SecretKey = {
                    id: newSecretKey.persona_api_key.id,
                    name: newSecretKey.persona_api_key.name,
                    key: newSecretKey.persona_api_key.key,
                    createdAt: newSecretKey.persona_api_key.created_at,
                    last_used: newSecretKey.persona_api_key.last_used,
                };
                setSecretKeys((prevKeys) => [...prevKeys, modified]);
                setNewlyCreatedKey(modified.key);
                openModal(ModalType.SecretKeyGenerated);
            }
        } catch (error) {
            console.log("Error in createNewSecret:", error);
            setIsLoading(false);
        }
    };

    const handleDelete = (keyId) => {
        setKeyToDelete(keyId);
        openModal(ModalType.ConfirmDelete);
    };

    const confirmDelete = async () => {
        if (!keyToDelete) return;

        await deletePersonaApiKeyApi(keyToDelete);
        setSecretKeys((prevKeys) =>
            prevKeys.filter((key) => key.id !== keyToDelete),
        );
        setKeyToDelete(null);
        closeModal();
    };

    return (
        <Box width="100%" height="100%">
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Key</Th>
                        <Th>Created</Th>
                        <Th>Last Used</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {secretKeys.map((personaApiKey) => (
                        <Tr key={personaApiKey.id}>
                            <Td>{personaApiKey.name}</Td>
                            <Td>{hideMiddleChars(personaApiKey.key)}</Td>
                            <Td>
                                {formatDate(
                                    personaApiKey.createdAt?.toString(),
                                )}
                            </Td>
                            <Td>
                                {formatDate(
                                    personaApiKey.last_used?.toString(),
                                )}
                            </Td>
                            <Td>
                                <IconButton
                                    aria-label="Delete"
                                    icon={<FaTrash />}
                                    size={"xs"}
                                    onClick={() =>
                                        handleDelete(personaApiKey.id)
                                    }
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Button
                onClick={createNewSecret}
                size={"sm"}
                className="my-4 text-sm"
                isLoading={isLoading}
                loadingText="Creating..."
            >
                Create New Secret Key
            </Button>

            <Modal
                isOpen={modalType !== ModalType.None}
                onClose={closeModal}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    {modalType === ModalType.ConfirmDelete && (
                        <>
                            <ModalHeader>Confirmation</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                Are you sure you want to delete this secret key?
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="ghost" onClick={closeModal}>
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme="red"
                                    ml={3}
                                    onClick={confirmDelete}
                                >
                                    Delete
                                </Button>
                            </ModalFooter>
                        </>
                    )}

                    {modalType === ModalType.SecretKeyGenerated && (
                        <>
                            <ModalHeader>Secret Key Generated</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <p className=" pb-5">
                                    Please save this secret key somewhere safe
                                    and accessible. For security reasons,
                                    <b>
                                        you won't be able to view it again
                                    </b>{" "}
                                    through your OpenAI account. If you lose
                                    this secret key, you'll need to generate a
                                    new one.
                                </p>
                                <HStack>
                                    <input
                                        type="text"
                                        readOnly
                                        value={newlyCreatedKey || ""}
                                        className="h-[40px] bg-gray-100 px-2 outline-none rounded-[8px] border w-[90%]"
                                    />
                                    <CopyToClipboard
                                        text={newlyCreatedKey || ""}
                                        onCopy={() => setIsCopied(true)}
                                    >
                                        <IconButton
                                            icon={
                                                isCopied ? (
                                                    <FaCheck />
                                                ) : (
                                                    <FaCopy />
                                                )
                                            }
                                            h={"40px"}
                                            w={"40px"}
                                            aria-label="Copy"
                                        />
                                    </CopyToClipboard>
                                </HStack>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    mt={4}
                                    type="submit"
                                    style={{
                                        color: "#fff",
                                        background: "#000",
                                        width: "150px",
                                    }}
                                    onClick={() => {
                                        closeModal();
                                        setNewlyCreatedKey(null);
                                        setIsCopied(false);
                                    }}
                                >
                                    Okay
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </Box>
    );
};
