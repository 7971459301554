import { AiOutlineClose } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";

interface NeoModalProps {
    isOpen: boolean;
    textLength?: string;
    textContainerWidth?: string;
    children: JSX.Element | JSX.Element[];
    imageUrl: string;
    onClose?: () => void;
}

export function NeoImageModalTitle({ children }) {
    return <h1 className="neo-modal-title">{children}</h1>;
}

export function NeoImageModalParagraph({ children }) {
    return <p className="neo-modal-description mb-4">{children}</p>;
}

export default function NeoImageModal(props: NeoModalProps) {
    const nodeRef = useRef(null);
    const coverImageRef = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        setModalVisible(props.isOpen);
    }, [props.isOpen]);

    const close = () => {
        setModalVisible(false);
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const onImageLoad = () => {
        const coverImage = coverImageRef.current as unknown as HTMLElement;
        // wait for image to complete loading before displaying
        coverImage?.classList.add("scale-up");
    };

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={modalVisible}
            timeout={200}
            classNames="neo-modal-node"
        >
            {modalVisible ? (
                <div ref={nodeRef} className="neo-modal-container">
                    <div className="overlay neo-regular">
                        <div className="neo-modal-body neo-modal-image">
                            <img
                                ref={coverImageRef}
                                className="neo-modal-image-cover"
                                onLoad={onImageLoad}
                                src={props.imageUrl}
                                alt=""
                            />
                            <div className="overlay-content darker">
                                <button
                                    onClick={close}
                                    className="neo-modal-close"
                                >
                                    <AiOutlineClose />
                                </button>
                                <div
                                    style={
                                        props.textContainerWidth
                                            ? {
                                                  width: props.textContainerWidth,
                                              }
                                            : {}
                                    }
                                    className={`neo-modal-contents ${
                                        props.textLength === "short"
                                            ? "image-modal-small-text"
                                            : ""
                                    }`}
                                >
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </CSSTransition>
    );
}
