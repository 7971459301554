export interface QueryFile {
    filename: string;
    preview: {
        header_list: string[];
        data_list: string[][];
    };
}

export interface SearchModel {
    id?: number;
    RLHF_id?: string;
    is_approved: boolean;
    is_training: boolean;
    type?: "search" | "notes";
    is_edited: boolean;
    isFinishedTyping: boolean;
    isExpanded?: boolean;
    isLoading: boolean;
    Sentiment?: string;
    Suggestion?: string;
    persona_id?: string;
    character: string;
    character_message: string;
    check_list?: string;
    custom_prompt?: string;
    date_added?: string;
    email?: string;
    folderName?: string;
    name?: string;
    prompt_used?: string;
    question?: string;
    reply?: string;
    rlhf_switch?: boolean;
    segregated_destribution?: string[];
    sub_query?: boolean;
    struct_files?: QueryFile[];
}

export interface ThreadModel {
    search_id?: number;
    thread_id?: number;
    RLHF_id?: string;
    is_approved: boolean;
    is_training: boolean;
    type?: "search" | "notes";
    is_edited: boolean;
    isFinishedTyping: boolean;
    isExpanded?: boolean;
    isLoading: boolean;
    Sentiment?: string;
    Suggestion?: string;
    character: string;
    character_message: string;
    check_list?: string;
    custom_prompt?: string;
    date_added?: string;
    email?: string;
    folderName?: string;
    name?: string;
    prompt_used?: string;
    question?: string;
    reply?: string;
    rlhf_switch?: boolean;
    segregated_destribution?: string[];
    sub_query?: boolean;
    struct_files?: QueryFile[];
}

export enum AI_MODEL {
    PUBLIC = "O",
    PRIVATE = "L",
}

export interface SearchConfig {
    temp: number;
    check_list?: string;
    custom_prompt: string;
    prompt_used: string;
    relation: string;
    alpha: number;
    is_structured: boolean;
    max_tokens: number;
    top_p: number;
    frequency_penalty: number;
    presence_penalty: number;
    top_k: number;
    num_beam: number;
    model: string;
    data_upload_id?: string;
}

export interface NotesModel {
    notes: string;
    isFinishedTyping: boolean;
}
