import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    AutoInteractionMessage,
    CollaborationApp,
    CollaborationGroup,
} from "../../models/collaboration.model";
import { PersonaModel } from "../../models/persona.model";

export interface CollaborateState {
    viewMode: "start" | "initialize";
    isCreatingApp: boolean;
    isInitializing: boolean;
    isInitialized: boolean;
    isExecuting: boolean;
    isExecuted: boolean;
    enableInput: boolean;
    isLoading: boolean;
    autoInteractionId: string;
    socketRoom: string;
    persona: PersonaModel;
    socketConnected: boolean;
    collaborationAppId: string;
    initializationMessage: string;
    autoInteractionMessages: AutoInteractionMessage[];
    lastDecisionMessageId: string;
    brainActivityMessages: AutoInteractionMessage[];
    collaborationGroups: CollaborationGroup[];
    selectedCollaborationApp: CollaborationApp | null;
    appMode: "view" | "interactive";
}

const initialState: CollaborateState = {
    isInitializing: false,
    viewMode: "start", // "start" | "initialize"
    isCreatingApp: false,
    isInitialized: false,
    isExecuting: false,
    isExecuted: false,
    enableInput: true,
    isLoading: false,
    autoInteractionId: "",
    socketRoom: "",
    socketConnected: false,
    collaborationAppId: "",
    initializationMessage: "",
    persona: {},
    autoInteractionMessages: [],
    lastDecisionMessageId: "",
    brainActivityMessages: [],
    collaborationGroups: [],
    selectedCollaborationApp: null,
    appMode: "interactive",
};

const collaborateSlice = createSlice({
    name: "collaborate",
    initialState,
    reducers: {
        setIsCreatingCollaborateApp(state, action: PayloadAction<boolean>) {
            state.isCreatingApp = action.payload;
        },
        setIsExecuting(state, action: PayloadAction<boolean>) {
            state.isExecuting = action.payload;
        },
        setIsExecuted(state, action: PayloadAction<boolean>) {
            state.isExecuted = action.payload;
        },
        setCollaborateViewMode(
            state,
            action: PayloadAction<"start" | "initialize">,
        ) {
            state.viewMode = action.payload;
        },
        setIsCollaborateLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setLastDecisionMessageId(state, action: PayloadAction<string>) {
            state.lastDecisionMessageId = action.payload;
        },
        setEnableCollaborationInput(state, action: PayloadAction<boolean>) {
            console.log("action.payload: ", action.payload); //debug

            state.enableInput = action.payload;
        },
        setIsAutoInteractionInitializing(
            state,
            action: PayloadAction<boolean>,
        ) {
            state.isInitializing = action.payload;
        },
        setIsAutoInteractionInitialized(state, action: PayloadAction<boolean>) {
            state.isInitialized = action.payload;
        },
        setSocketConnected(state, action: PayloadAction<boolean>) {
            state.socketConnected = action.payload;
        },
        setSocketRoom(state, action: PayloadAction<string>) {
            state.socketRoom = action.payload;
        },
        setCollaborationAppId(state, action: PayloadAction<string>) {
            state.collaborationAppId = action.payload;
        },
        setAutoInteractionId(state, action: PayloadAction<string>) {
            state.autoInteractionId = action.payload;
        },
        setAutoInteractionMessage(
            state,
            action: PayloadAction<AutoInteractionMessage[]>,
        ) {
            state.autoInteractionMessages = action.payload;

            if (
                action.payload.at(-1)?.type === "execute_next_cell" ||
                action.payload.at(-1)?.type === "execute_tool"
            ) {
                state.lastDecisionMessageId = action.payload.at(-1)?.id || "";
            } else {
                state.lastDecisionMessageId = "";
            }
        },
        setCollaborateInitializationMessage(
            state,
            action: PayloadAction<string>,
        ) {
            state.initializationMessage = action.payload;
        },
        setCollaborationPersona(state, action: PayloadAction<PersonaModel>) {
            state.persona = action.payload;
        },
        addAutoInteractionMessage(
            state,
            action: PayloadAction<AutoInteractionMessage>,
        ) {
            const idExists = state.autoInteractionMessages.some(
                (object) => object.id === action.payload.id,
            );

            if (!idExists) {
                state.autoInteractionMessages = [
                    ...state.autoInteractionMessages,
                    action.payload,
                ];

                if (
                    action.payload.type === "execute_next_cell" ||
                    action.payload.type === "execute_tool"
                ) {
                    state.lastDecisionMessageId = action.payload.id || "";
                } else {
                    state.lastDecisionMessageId = "";
                }
            }
        },
        addBrainActivityMessage(
            state,
            action: PayloadAction<AutoInteractionMessage>,
        ) {
            const idExists = state.brainActivityMessages.some(
                (object) => object.id === action.payload.id,
            );

            if (!idExists) {
                state.brainActivityMessages = [
                    ...state.brainActivityMessages,
                    action.payload,
                ];
            }
        },
        setSelectCollaborationApp(
            state,
            action: PayloadAction<CollaborationApp | null>,
        ) {
            state.selectedCollaborationApp = action.payload;
        },
        setAppMode(state, action: PayloadAction<"view" | "interactive">) {
            state.appMode = action.payload;
        },
        setCollaborationAppGroups(
            state,
            action: PayloadAction<CollaborationGroup[]>,
        ) {
            state.collaborationGroups = action.payload;
        },
        prependCollaborationGroup(
            state,
            action: PayloadAction<CollaborationGroup>,
        ) {
            state.collaborationGroups = [
                action.payload,
                ...state.collaborationGroups,
            ];
        },
        appendCollaborationGroup(
            state,
            action: PayloadAction<CollaborationGroup>,
        ) {
            state.collaborationGroups = [
                ...state.collaborationGroups,
                action.payload,
            ];
        },
        addCollaborationGroupApp(
            state,
            action: PayloadAction<{ groupId: string; app: CollaborationApp }>,
        ) {
            const { groupId, app } = action.payload;
            const groupIndex = state.collaborationGroups.findIndex(
                (group) => group.id === groupId,
            );

            if (groupIndex > -1) {
                state.collaborationGroups = state.collaborationGroups.map(
                    (group) => {
                        if (group.id === groupId) {
                            return {
                                ...group,
                                collaboration_app_list: [
                                    app,
                                    ...group.collaboration_app_list,
                                ],
                            };
                        } else {
                            return group;
                        }
                    },
                );
            }
        },
        clearAutoInteractionMessages(state) {
            state.autoInteractionMessages = [];

            state.lastDecisionMessageId = "";
        },
        clearBrainActivityMessages(state) {
            state.brainActivityMessages = [];
        },
        removeCollaborationGroup(state, action: PayloadAction<string>) {
            state.collaborationGroups = state.collaborationGroups.filter(
                (group) => group.id !== action.payload,
            );
        },
        removeCollaborationGroupApp(
            state,
            action: PayloadAction<{ groupId: string; appId: string }>,
        ) {
            const { groupId, appId } = action.payload;
            const groupIndex = state.collaborationGroups.findIndex(
                (group) => group.id === groupId,
            );

            if (groupIndex > -1) {
                state.collaborationGroups[groupIndex].collaboration_app_list =
                    state.collaborationGroups[
                        groupIndex
                    ].collaboration_app_list.filter((app) => app.id !== appId);
            }
        },
    },
});

export const {
    setIsCreatingCollaborateApp,
    setIsAutoInteractionInitializing,
    setIsAutoInteractionInitialized,
    setSocketConnected,
    setAutoInteractionId,
    setSocketRoom,
    setEnableCollaborationInput,
    setIsCollaborateLoading,
    setIsExecuted,
    setIsExecuting,
    setAutoInteractionMessage,
    addAutoInteractionMessage,
    setCollaborationAppId,
    setCollaborateInitializationMessage,
    setCollaborationPersona,
    setCollaborateViewMode,
    addCollaborationGroupApp,
    appendCollaborationGroup,
    prependCollaborationGroup,
    addBrainActivityMessage,
    setSelectCollaborationApp,
    setCollaborationAppGroups,
    clearAutoInteractionMessages,
    clearBrainActivityMessages,
    removeCollaborationGroup,
    removeCollaborationGroupApp,
    setAppMode,
    setLastDecisionMessageId
} = collaborateSlice.actions;

export default collaborateSlice.reducer;
