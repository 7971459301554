import { getAuthUser } from "../../utils/auth.utils";
import "../../styles/ProfileView.component.scss";
import { capitalizeWord } from "../../utils/strings.utils";
import { useEffect, useRef, useState } from "react";
import { setMetaTags } from "../../utils/seo.utils";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useHttpClient } from "../../utils/http.utils";
import { MdOutlineAddAPhoto } from "react-icons/md";
import { useSelector } from "react-redux";
import { PaymentMethodModel } from "../../models/payment.model";
import hotToast from "react-hot-toast";
import { FiCheck } from "react-icons/fi";
import { AccountState } from "../../store/slices/account.slice";
import { formatDate } from "../../utils/date.utils";
import { DeleteConfirmation } from "../../components/utils/ConfirmationModals";
import { useNavigate } from "react-router-dom";

export default function ProfileView() {
    const user = getAuthUser();
    const [hasImageLoaded, setHasImageLoaded] = useState(false);
    const profileImageRef = useRef<HTMLImageElement>(null);
    const photoInputRef = useRef<HTMLInputElement>(null);
    const { updatePasswordApi, updateUserProfilePictureApi, listSubscriptionPricesApi, createSubscriptionSessionApi, cancelUserSubscriptionApi } = useHttpClient();
    const [subscriptionPrices, setSubscriptionPrices] = useState<any[]>([]);
    const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;
    const navigate = useNavigate();

    useEffect(() => {
        setMetaTags({ title: "Profile" });
        const fetchSubscriptionPrices = async () => {
            const data = await listSubscriptionPricesApi();
            if (Array.isArray(data)) {
                setSubscriptionPrices(data);
            }
        };

        if (subscriptionPrices.length === 0) {
            fetchSubscriptionPrices();
        }
    }, []);

    /**
     * Form validators
     */

    function validateRequired(key, value) {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    }

    const updatePassword = async (values: any, actions) => {
        updatePasswordApi(values).finally(() => {
            actions.setSubmitting(false);
        });
    };

    const openFilePicker = () => {
        (photoInputRef.current as any).click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (!["image/jpeg", "image/png"].includes(file.type)) {
                // Inform user about invalid file type
                return;
            }
            // update  in file
            (profileImageRef.current as any).src = URL.createObjectURL(file);
            // update in api
            updateUserProfilePictureApi(file);
        }
    };

    const createSubscriptionSession = () => {
        /**
         * If the user has not configured his organization, tell them to do that
         * If the user has not configured payment method,
         * return and ask them to add a payment method
         */
        if (!accountState.activeOrganization?.id) {
            hotToast.error("Please set up your organization first");
            navigate("/account/subscription/organization");
            return;
        }

        if (paymentMethods.length < 1) {
            hotToast.error("Please add a payment method first");
            navigate("/account/subscription/payment-methods");
            return;
        }

        if (subscriptionPrices.length > 0) {
            setIsLoadingSubscription(true);

            createSubscriptionSessionApi({
                organization: accountState.activeOrganization?.id,
                subscription_price: subscriptionPrices[0].id,
            })
                .then((res) => {
                    // console.log(res);
                    if (res?.session_url) {
                        window.location.href = res.session_url;
                    }
                })
                .catch(() => {
                    setIsLoadingSubscription(false);
                });
        }
    };

    const cancelSubscription = () => {
        DeleteConfirmation({
            title: "Confirm Cancellation",
            message: `Are you sure you want to cancel your subscription?`,
            keyword: "Cancel",
        }).then((isConfirmed) => {
            if (isConfirmed) {
                // show loading
                hotToast.promise(cancelUserSubscriptionApi(accountState.subscription?.id as string), {
                    loading: "Cancelling subscription...",
                    success: "Subscription cancelled",
                    error: "Cannot cancel subscription",
                });
            }
        });
    };

    return (
        <div className="profile-container relative mx-auto px-8 pt-6">
            <div className="profile-gradient h-[125px]"></div>
            <div className="profile-card relative bg-white rounded-lg pl-16 pt-4">
                <div className="profile-section">
                    <div className="image-container">
                        <input style={{ display: "none" }} type="file" ref={photoInputRef} onChange={handleFileChange} accept="image/*" />
                        <img
                            ref={profileImageRef}
                            src={user?.profile_picture || "/user_avatar.png"}
                            className={`object-cover ${hasImageLoaded ? "block photo" : "hidden"}`}
                            onLoad={() => setHasImageLoaded(true)}
                            onError={() => {
                                profileImageRef.current?.setAttribute("src", "/user_avatar.png");
                            }}
                            alt=""
                        />
                        {!hasImageLoaded && <SkeletonCircle size="85" />}
                        <div onClick={openFilePicker} className="upload-trigger text-white">
                            <MdOutlineAddAPhoto size="20px" />
                        </div>
                    </div>
                </div>
                <div className="details">
                    <h2 className="name text-xl font-semibold">{capitalizeWord(user.fullname || user.username || "")}</h2>
                    <h4 className="email text-slate-500">{user.email}</h4>
                </div>
            </div>
            <div className="subscription px-16 mb-6">
                {accountState.accountData?.backend_subscription_status === "active" ? (
                    accountState.subscription && (<div className="mb-6">
                        <div className="bg-gray-100 px-4 py-4 min-h-[70px] rounded-[12px] border border-gray-200">
                            <div className="">
                                <h2 className="mb-[4px]">Your plan</h2>
                                <div className="flex justify-between">
                                    <div className="">
                                        <p className="price text-[20px] text-blue-600">
                                            <span className="">${(Number(accountState.subscription?.plan.amount) * Number(accountState.subscription?.quantity)) / 100 || "10"}</span>
                                            <span className="">
                                                /{accountState.subscription.plan.interval}
                                            </span>
                                        </p>
                                        <p className="text-[15px] text-blue-900 mb-2 mt-1">Plan: ${Number(accountState.subscription?.plan.amount) / 100 || "10"}/user/{accountState.subscription.plan.interval}</p>
                                        <p className="text-[13px] text-slate-500">Subscribed on: {formatDate(Number(accountState.subscription?.current_period_start) * 1000, "LL")}</p>
                                        <p className="text-[13px] text-slate-500">Next subscription date: {formatDate(Number(accountState.subscription?.current_period_end) * 1000, "LL")}</p>
                                    </div>

                                    <div className="flex items-center">
                                        <Button onClick={cancelSubscription} variant={"ghost"} colorScheme="red" size="sm">
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                ) : (
                    <div className="pt-4 mb-6">
                        <div className="bg-gradient-to-r from-indigo-900 via-blue-900 to-blue-800 px-6 py-6 min-h-[70px] rounded-[12px] text-white border border-gray-300">
                            <div className="">
                                <h2 className="mb-[5px]">Upgrade to a paid plan</h2>
                                {subscriptionPrices.length > 0 ? (
                                    <div className="flex justify-between">
                                        <p className="price mb-2 font-thin">
                                            <span className="text-[26px]">${subscriptionPrices[0]?.unit_amount / 100 || "10"}</span>
                                            <span className="text-gray-200">
                                                /user/{subscriptionPrices[0]?.recurring.interval}
                                            </span>
                                        </p>
                                        <div className="pr-2 flex items-center">
                                            <Button isDisabled={subscriptionPrices.length < 1} backgroundColor={"#fff"} color="#1e1e1e" isLoading={isLoadingSubscription} onClick={createSubscriptionSession}>
                                                Subscribe
                                            </Button>
                                        </div>
                                    </div>
                                ): (<div className="flex justify-between"><Skeleton height="35px" width="155px" borderRadius={4} /></div>)}
                                <div className="flex gap-2 items-center mb-2">
                                    <FiCheck size="16px" />
                                    <p className="text-[12px]">Interact with all synthetic life forms or arins without invitation</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <FiCheck size="16px" />
                                    <p className="text-[12px]">Create and license Personas and other synthetic life forms</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="header pl-[64px] mb-[10px]">
                    <div className="border-b pb-[10px]">
                    <h2 className="title text-[15px] mb-[4px] font-semibold leading-[1]">Update Password</h2>
                    <p className="text-slate-500 text-[13px]">Update your account's password</p>
                    </div>
                </div>
            <div className="form w-[60%] pl-[64px]">
                <Formik
                    initialValues={{
                        current_password: "",
                        new_password: "",
                        confirm_new_password: "",
                    }}
                    onSubmit={updatePassword}
                >
                    {(props) => (
                        <Form>
                            <Field name="current_password" validate={(e) => validateRequired("Password", e)}>
                                {({ field, form }) => (
                                    <FormControl mb={3} isInvalid={form.errors.current_password && form.touched.current_password}>
                                        <FormLabel>Current Password</FormLabel>
                                        <Input {...field} type="password" placeholder="Current password" />
                                        <FormErrorMessage>{form.errors.current_password}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="new_password" validate={(e) => validateRequired("Password", e)}>
                                {({ field, form }) => (
                                    <FormControl mb={3} isInvalid={form.errors.new_password && form.touched.new_password}>
                                        <FormLabel>New Password</FormLabel>
                                        <Input {...field} type="password" placeholder="New password" />
                                        <FormErrorMessage>{form.errors.new_password}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="confirm_new_password" validate={(e) => validateRequired("Password", e)}>
                                {({ field, form }) => (
                                    <FormControl mb={3} isInvalid={form.errors.confirm_new_password && form.touched.confirm_new_password}>
                                        <FormLabel>Confirm new Password</FormLabel>
                                        <Input {...field} type="password" placeholder="Confirm password" />
                                        <FormErrorMessage>{form.errors.confirm_new_password}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <div className="actions pt-6 mb-8">
                                <Button isLoading={props.isSubmitting} type="submit" colorScheme="brand">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
