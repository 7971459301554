import Select from "react-select";
import { Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { getAuthUser } from "../../utils/auth.utils";
import "../../styles/InviteesView.component.scss";
import { setMetaTags } from "../../utils/seo.utils";

export default function InviteesView() {
    const authUser = getAuthUser();

    useEffect(() => {
        setMetaTags({ title: "Applications | Invitees" });
    }, []);

    const options = [
        { value: "Marketing", label: "Marketing" },
        { value: "Sales", label: "Sales" },
        { value: "Engineering", label: "Engineering" },
    ];

    return (
        <div className="invitees-container h-full">
            <div className="side-nav-sm">
                <h1 className="title">Invitees</h1>
            </div>
            <div className="body px-6">
                <div className="invitees-card">
                    <div className="user-info">
                        <img
                            src={
                                authUser?.profile_picture || "/user_avatar.png"
                            }
                        />
                        <div className="info">
                            <p className="name">
                                {authUser.fullname || authUser.username}
                            </p>
                            <p className="email">{authUser.email}</p>
                        </div>
                    </div>
                    <div className="select">
                        <Select
                            isSearchable={false}
                            options={options}
                            className="arin-react-select-container"
                            classNamePrefix="arin-react-select"
                        />
                    </div>
                    <div className="actions">
                        <Button>Remove</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
