import { Button, FormControl } from "@chakra-ui/react";
import "../../styles/PersonasSideBar.component.scss";
import Select from "react-select";
import { TbRobot } from "react-icons/tb";
import { AiFillCheckCircle } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import {useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAuthUser } from "../../utils/auth.utils";
import { PersonaModel } from "../../models/persona.model";
import { PersonaState } from "../../store/slices/persona.slice";
import PersonaListCard from "./PersonaListCard";

const agents = [
    { label: "Montague", value: "montague" },
    { label: "Katy", value: "katy" },
    { label: "Luka", value: "luka" },
];

interface PersonaSideBarProps {
    onPersonaSelect: (persona: PersonaModel) => void;
    onCreatePersona?: () => void;
}

export default function PersonaSideBar({
    onPersonaSelect,
    onCreatePersona,
}: PersonaSideBarProps) {
    const authUser = getAuthUser();

    const personaState = useSelector(
        (state: any) => state.persona,
    ) as PersonaState;

    const [selectedArins, setSelectedArins] = useState([agents[0]]);
    const [selectedPersonaId, setSelectedPersonaId] = useState<string>("");
    const [isAiEntityActive, setIsAiEntityActive] = useState<boolean>(false);
    const [selectedPersonas, setSelectedPersonas] = useState<PersonaModel[]>(
        [],
    );
    const [personaSelectOptions, setPersonaSelectOptions] = useState<
        { label: string; value: string }[]
    >([]);

    useEffect(() => {
        const options = personaState.personas.map((persona) => ({
            value: persona.id as string,
            label:
                (persona.first_name as string) +
                " " +
                (persona.last_name as string),
        }));
        setPersonaSelectOptions(options);
    }, [personaState.personas]);

    const onSelectArins = (val) => {
        setSelectedArins(val);
    };

    const onSelectPersonas = (values: any) => {
        const personas: PersonaModel[] = values.map((v) => {
            return personaState.personas.find(
                (p) => p.id === v.value,
            ) as PersonaModel;
        });
        setSelectedPersonas(personas);
    };

    const createPersona = () => {
        setSelectedPersonaId("");
        setIsAiEntityActive(false);
        if (typeof onCreatePersona === "function") {
            onCreatePersona();
        }
    };

    const personaSelect = (persona: PersonaModel) => {
        setSelectedPersonaId(persona.id as string);
        setIsAiEntityActive(persona.is_general_entity === "yes");

        if (typeof onPersonaSelect === "function") {
            onPersonaSelect(persona);
        }
    };

    return (
        <div className="sidebar h-full">
            <div className="side-nav-sm">
                <h2 className="title">AI Entities</h2>
                <p className="subtitle">Create and manage a new species.</p>
            </div>
            <div className="sidebar-body px-6">
                {/*<Input placeholder="Search Personas" />*/}

                <div className="agents mt-4">
                    {/* <div className='title flex py-3 justify-between'>
            <h2 className='font-semibold'>Arins</h2>
            <Button colorScheme='blue' variant='ghost' size='xs'>
              About
            </Button>
          </div> */}
                    {/* <FormControl mb={3}>
            <Select
              defaultValue={selectedArins}
              isMulti
              isClearable={false}
              name='colors'
              options={agents}
              onChange={onSelectArins}
              className='arin-react-select-container'
              classNamePrefix='arin-react-select'
            />
          </FormControl> */}
                    <div
                        onClick={() =>
                            personaSelect(personaState.generalAIEntity)
                        }
                        className={`${
                            isAiEntityActive
                                ? "border-blue-500 bg-blue-50"
                                : "border-gray-200"
                        } font-medium cursor-pointer border mb-3 text-sm p-3 flex justify-between items-center rounded-lg`}
                    >
                        <p className="flex gap-2 items-center">
                            <TbRobot className="text-lg" />
                            <span>General AI Entity</span>
                        </p>
                        <span className="text-green-500 text-lg">
                            <AiFillCheckCircle />
                        </span>
                    </div>
                </div>

                <div className="personas mt-4">
                    <div className="title flex py-3 justify-between">
                        <h2 className="font-semibold">Personas</h2>
                        <Button
                            onClick={createPersona}
                            colorScheme="blue"
                            variant="ghost"
                            size="xs"
                            leftIcon={<FiPlus />}
                        >
                            Create
                        </Button>
                    </div>

                    <FormControl mb={3}>
                        <Select
                            isMulti
                            isClearable={false}
                            name="colors"
                            options={personaSelectOptions}
                            onChange={onSelectPersonas}
                            className="arin-react-select-container"
                            classNamePrefix="arin-react-select"
                        />
                    </FormControl>

                    <div className="mt-1">
                        {selectedPersonas.map((persona, index) => (
                            <PersonaListCard
                                key={"persona-list-card-" + index}
                                data={persona}
                                isActive={selectedPersonaId === persona.id}
                                onClick={() => personaSelect(persona)}
                            />
                            // <div
                            //     key={index}
                            //     className={`avatar-card ${
                            //         selectedPersonaId === persona.id
                            //             ? "active"
                            //             : ""
                            //     } mb-4 px-4 py-2 rounded-lg`}
                            //     onClick={() => personaSelect(persona)}
                            // >
                            //     <div className="left flex gap-3 items-center">
                            //         <ProfileAvatar size="30px" />
                            //         <h4 className="clone-text font-semibold">
                            //             {persona.first_name +
                            //                 " " +
                            //                 persona.last_name}
                            //         </h4>
                            //     </div>
                            // </div>
                        ))}
                    </div>

                    {/*<div>
                        {personasList && personasList.length > 0 ? (personasList.map((persona, index) => (
                            <div key={index} className="avatar-card mb-4 px-4 py-2 rounded-lg">
                                <div className="left flex gap-3 items-center">
                                    <img src={"/user_avatar.png"} alt="avatar" className="profile-image" />
                                    <h4 className="clone-text font-semibold">{persona.name}</h4>
                                </div>
                            </div>)) ) : (<p>No personas yet</p>)}
                    </div>*/}
                </div>
            </div>
        </div>
    );
}
