import ArinLayout from "../../components/ArinLayout";
import React, { useEffect } from "react";
import "../../styles/ApplicationsView.component.scss";
import { Input } from "@chakra-ui/react";
import { BiChevronRight } from "react-icons/bi";
import { Outlet, useNavigate } from "react-router-dom";
import { CollaborateState } from "../../store/slices/collaborate.slice";
import { useSelector } from "react-redux";
import { useHttpClient } from "../../utils/http.utils";
import BrainActivityPanel from "../../components/collaborate/BrainActivityPanel";
import CollaborationGroupCard from "../../components/collaborate/CollaborationGroupCard";

export default function CollaborateView() {
    const navigate = useNavigate();
    const collaborate_state: CollaborateState = useSelector(
        (state) => (state as any).collaborate,
    );
    const { fetchCollaborationAppGroupApi } = useHttpClient();

    useEffect(() => {
        fetchCollaborationAppGroupApi().then();
    }, []);

    return (
        <ArinLayout>
            <div className="search-layout-wrapper">
                <div className="grid-column pl-2 pb-3">
                    <div className="sidebar-sm">
                        <div className="side-nav-sm">
                            <p className="title">Collaborations</p>
                            <p className="subtitle">
                                Choose application to initialize
                            </p>
                        </div>
                        <div className="sidebar-body">
                            <Input placeholder="Search Application" />
                            {/*<div onClick={() => navigate("/apply/applications/invitees")}*/}
                            {/*     className="invites flex justify-between py-1 my-2">*/}
                            {/*    <button className="text-blue-500">Invites(3)</button>*/}
                            {/*    <button className="text-slate-500 text-lg"><BiChevronRight/></button>*/}
                            {/*</div>*/}
                            <div className="collaborations pt-3">
                                <div className="">
                                    {collaborate_state.collaborationGroups?.map(
                                        (collaborationAppGroup, index) => {
                                            return (
                                                <CollaborationGroupCard
                                                    key={index}
                                                    data={collaborationAppGroup}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-column">
                    <Outlet />
                </div>
                <div className="grid-column pr-3 pb-3">
                    {collaborate_state.viewMode === "initialize" && (
                        <BrainActivityPanel />
                    )}
                </div>
            </div>
        </ArinLayout>
    );
}
