import { Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CreditTopUpForm from "../../components/accounts/CreditTopUpForm";
import { useHttpClient } from "../../utils/http.utils";
import UsageChart from "../../components/UsageChart";
import UserUsageChart from "../../components/UserUsageChart";

export default function UsageView() {
    const { listSubscriptionPricesApi } = useHttpClient();
    const [isPurchaseFormOpen, setIsPurchaseFormOpen] = useState(false);
    const [subscriptionPrices, setSubscriptionPrices] = useState<any[]>([]);

    const closeFormModal = () => {
        setIsPurchaseFormOpen(false);
    };

    useEffect(() => {
        const fetchSubscriptionPrices = async () => {
            const data = await listSubscriptionPricesApi();
            if (Array.isArray(data)) {
                setSubscriptionPrices(data);
            }
        };

        if (subscriptionPrices.length === 0) {
            fetchSubscriptionPrices();
        }
    }, []);

    return (
        <div className="accounts-subview account-usage w-full h-full">
            <div className="side-nav-sm">
                <h4 className="title">Usage</h4>
                <p className="subtitle">
                    Below you'll find a summary of usage for your
                    organization. All dates and times are UTC.
                </p>
            </div>

            <div className="body px-6 pt-2">
                <div className="usage-progress">
                    {/* <UsageProgressBar /> */}
                    <div className="mt-3">
                        <Button
                            onClick={() => setIsPurchaseFormOpen(true)}
                            colorScheme="brand"
                        >
                            Top Up
                        </Button>
                    </div>
                </div>
                <UserUsageChart />
            </div>
            {/* modals */}
            <CreditTopUpForm
                isOpen={isPurchaseFormOpen}
                onClose={closeFormModal}
            />
        </div>
    );
}
