// ModalContext.js
import React, { createContext, useContext, useState } from "react";

interface IGenericModalContext {
    openPaywallModal: () => void;
    closePaywallModal: () => void;
    isPaywallOpen: boolean;
}

// @ts-ignore
const GenericModalContext = createContext<IGenericModalContext>();

export const useGenericModal = () => useContext(GenericModalContext);

export const GenericModalProvider = ({ children }) => {
    const [isPaywallOpen, setIsPaywallOpen] = useState(false);

    const openPaywallModal = () => {
        setIsPaywallOpen(true);
    };

    const closePaywallModal = () => {
        setIsPaywallOpen(false);
    };

    return (
        <GenericModalContext.Provider
            value={{
                openPaywallModal,
                closePaywallModal,
                isPaywallOpen,
            }}
        >
            {children}
        </GenericModalContext.Provider>
    );
};
