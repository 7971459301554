import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersonaModel } from "../../models/persona.model";
import { MemoriesModel } from "../../models/memories.model";

export interface PersonaFormState extends PersonaModel {}

export interface PersonaPersonalityFormState {
    personalityKnowledge?: string;
    personalityWritingSampling?: string;
    personalityTrait?: string;
    personalitySpecificKnowledge?: string;
}

export interface PersonaState {
    loading: boolean;
    loadingError: string;
    // generalEntities: PersonaModel[];
    personas: PersonaModel[];
    generalAIEntity: PersonaModel;
    activePersona: PersonaModel | null;
    personaAttributesForm: PersonaFormState;
    personaPersonalityForm: PersonaPersonalityFormState;
    personalityTraitQuestions: {
        question: string;
        "question-no": string;
        choices: object;
    }[];
    selectedPersonaMemories: MemoriesModel[];
}

const initialState: PersonaState = {
    loading: false,
    loadingError: "",
    personas: [],
    // generalEntities: [],
    generalAIEntity: {
        first_name: "General",
        last_name: "AI Entity",
        is_general_entity: "yes",
        description: "",
        specificities: ""
    },
    activePersona: null,
    personaAttributesForm: {},
    personaPersonalityForm: {
        personalityKnowledge: "",
        personalityWritingSampling: "",
        personalityTrait: "",
        personalitySpecificKnowledge: "",
    },
    personalityTraitQuestions: [],
    selectedPersonaMemories: [],
};

const personaSlice = createSlice({
    name: "persona",
    initialState,
    reducers: {
        setPersonaLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setPersonaLoadingError: (state, action: PayloadAction<string>) => {
            state.loadingError = action.payload;
        },
        setActivePersona: (state, action: PayloadAction<PersonaModel>) => {
            state.activePersona = action.payload;
        },
        populatePersonas: (state, action: PayloadAction<PersonaModel[]>) => {
            // TODO: FETCH GENERAL AI ENTITY
            // const generalAiEntity = { ...action.payload[0] };
            // generalAiEntity.id = "ai_entity";
            // generalAiEntity.first_name = "General";
            // generalAiEntity.last_name = "AI Entity";
            // generalAiEntity.type = "entity";
            // set Generate entity
            // state.generalAIEntity = generalAiEntity;

            // Remove personas with empty names or the general entity
            const personas = action.payload.filter((persona: PersonaModel) =>
                (persona.first_name || persona.last_name) && persona.is_general_entity !== "yes",
            );

            // Populate General Entity if available
            const generalEntity = action.payload.find((persona: PersonaModel) => 
                persona.is_general_entity === "yes"
            );

            state.personas = [...personas];
            if (generalEntity) {
                state.generalAIEntity = generalEntity;
            }
        },
        addPersona: (state, action: PayloadAction<PersonaModel>) => {
            if (action.payload.is_general_entity === "yes") {
                state.generalAIEntity = action.payload
            } else {
                state.personas.push(action.payload);
            }
        },
        updatePersona: (state, action: PayloadAction<PersonaModel>) => {
            const index = state.personas.findIndex(
                (persona) => persona.id === action.payload.id,
            );

            if (index > -1) {
                state.personas[index] = {
                    ...state.personas[index],
                    ...action.payload,
                };
                // update the current attribute form if present
                if (
                    state.personas[index].id === state.personaAttributesForm.id
                ) {
                    state.personaAttributesForm = {
                        ...state.personaAttributesForm,
                        ...action.payload,
                    };
                }
            } 

            // Update General Entity if available
            const generalEntity = action.payload.is_general_entity === "yes"
                ? action.payload
                : undefined;

            if (generalEntity) {
                state.generalAIEntity =  {
                    ...state.generalAIEntity,
                    ...action.payload
                }

                // update the current attribute form if present
                if (state.personaAttributesForm.is_general_entity === "yes") {
                    state.personaAttributesForm = {
                        ...state.personaAttributesForm,
                        ...action.payload,
                    };
                }
            }
        },
        removePersona: (state, action: PayloadAction<string>) => {
            const index = state.personas.findIndex(
                (persona) => persona.id === action.payload,
            );
            if (index > -1) {
                state.personas.splice(index, 1);
            }
        },
        setPersonaForm: (state, action: PayloadAction<PersonaFormState>) => {
            state.personaAttributesForm = action.payload;
        },
        setPersonaPersonalityForm(
            state,
            action: PayloadAction<PersonaPersonalityFormState>,
        ) {
            state.personaPersonalityForm = {
                ...state.personaPersonalityForm,
                ...action.payload,
            };
        },
        setPersonalityTraitQuestions(state, action: PayloadAction<any>) {
            state.personalityTraitQuestions = action.payload;
        },
        setSelectedPersonaMemories( state, action: PayloadAction<MemoriesModel[]>) {
            state.selectedPersonaMemories = action.payload;
        },
        // setGeneralEntities(state, actions: PayloadAction<PersonaModel[]>) {
        //     state.generalEntities = actions.payload;
        // }
    },
});

// Export actions
export const {
    setPersonaLoading,
    setPersonaLoadingError,
    setActivePersona,
    populatePersonas,
    addPersona,
    updatePersona,
    removePersona,
    setPersonaForm,
    setPersonaPersonalityForm,
    setPersonalityTraitQuestions,
    setSelectedPersonaMemories,
    // setGeneralEntities
} = personaSlice.actions;

export default personaSlice.reducer;
