import "../styles/ArinChat.component.scss";
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Skeleton,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { addChatQuery, updateChatQuery } from "../store/slices/chat.slice";
import { useHttpClient } from "../utils/http.utils";
import { scrollToBottom } from "../utils/dom.utils";
import { ChatModel } from "../models/chat.model";

interface ArinChat {
    isOpen: boolean;
    onClose: () => void;
}

export default function ArinChat(props: ArinChat) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const character = useSelector((state) => (state as any).searches.character);
    const chatState = useSelector((state) => (state as any).chat);
    const chatInputRef = useRef(null);
    const containerRef = useRef(null);
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const { fetchChatReplyApi } = useHttpClient();

    useEffect(() => {
        if (props.isOpen) {
            onOpen();
        } else {
            onClose();
        }
    }, [props]);

    const onModalClose = () => {
        onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const onInputKeyUP = (e) => {
        const value = e.target.value; // input value

        if (!value) {
            return; // exit function is user entered nothing
        }
        // If enter keyis pressed, continue to chant
        if (e.keyCode === 13) {
            queryChat();
        }
    };

    const queryChat = () => {
        const inputEl = chatInputRef.current as unknown as HTMLInputElement;
        // Get input value
        const inputValue = inputEl.value;
        // Reset input value
        inputEl.value = "";
        // add to state
        dispatch(
            addChatQuery({
                id: "",
                type: "user",
                isTyping: false,
                isLoading: false,
                message: inputValue,
            }),
        );

        scrollToBottom(containerRef);

        // Get chat replies
        fetchChatReplyApi({
            search: inputValue,
        }).finally(() => {
            scrollToBottom(containerRef);
        });
    };

    const onAvatarLoaded = () => {
        setIsLoaded(true);
    };

    return (
        <div className="arin-chat-modal">
            <Modal isOpen={isOpen} onClose={onModalClose}>
                <ModalOverlay />
                <ModalContent
                    className="chat-modal-content"
                    style={{ padding: 0 }}
                >
                    <ModalBody
                        style={{ padding: 0, background: "transparent" }}
                    >
                        <div className="chat-box" id="chat">
                            <div className="chat-header">
                                <div
                                    className={`intro flex gap-2 ${
                                        isLoaded ? "hidden" : "block"
                                    } items-center`}
                                >
                                    <div className="photo">
                                        <Skeleton
                                            style={{ borderRadius: "50%" }}
                                            height="40px"
                                            width="40px"
                                        />
                                    </div>
                                    <div className="info">
                                        <Skeleton
                                            width="150px"
                                            style={{ marginBottom: "10px" }}
                                            height="15px"
                                        />
                                        <Skeleton width="200px" height="15px" />
                                    </div>
                                </div>
                                <div
                                    className={`intro flex ${
                                        isLoaded ? "block" : "hidden"
                                    } gap-2 items-center`}
                                >
                                    <div className="photo">
                                        <img
                                            src="/montague.png"
                                            onLoad={onAvatarLoaded}
                                            alt="Montague Adameve"
                                        />
                                    </div>
                                    <div className="info">
                                        <p className="name">Montague Adameve</p>
                                        <p className="title">
                                            Arin, works for NeoWorlder, Inc.
                                        </p>
                                    </div>
                                </div>
                                <div className="actions">
                                    <button
                                        onClick={onModalClose}
                                        className="neo-modal-close"
                                    >
                                        <AiOutlineClose className="icon" />
                                    </button>
                                </div>
                            </div>
                            <div
                                ref={containerRef}
                                className="chat__conversation-board"
                            >
                                {chatState.replies.map((chat, index) => {
                                    return (
                                        <MessageBubble
                                            key={index}
                                            data={chat}
                                            scrollToBottomHandler={() =>
                                                scrollToBottom(containerRef)
                                            }
                                        />
                                    );
                                })}
                            </div>
                            <div className="chat__conversation-panel">
                                <div className="chat__conversation-panel__container">
                                    <input
                                        ref={chatInputRef}
                                        disabled={chatState.isChatLoading}
                                        className="chat__conversation-panel__input panel-item"
                                        placeholder="Type a message..."
                                        onKeyUp={onInputKeyUP}
                                    />
                                    <button
                                        onClick={queryChat}
                                        className="chat__conversation-panel__button panel-item btn-icon send-message-button"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            aria-hidden="true"
                                            data-reactid="1036"
                                        >
                                            <line
                                                x1="22"
                                                y1="2"
                                                x2="11"
                                                y2="13"
                                            ></line>
                                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}

interface MessageBubbleProps {
    data: ChatModel;
    scrollToBottomHandler?: () => void;
}

function MessageBubble(props: MessageBubbleProps) {
    // state variables
    const [displayResponse, setDisplayResponse] = useState("");
    const dispatch = useDispatch();

    function scrollToBottomOfParent() {
        // scroll to bottom of page after answering
        if (typeof props.scrollToBottomHandler === "function") {
            props.scrollToBottomHandler();
        }
    }

    useEffect(() => {
        if (props.data.message.length === 0) {
            return;
        }
        // The index of the type-writer cursor, 0 means it will start typing form the beginning
        let cursorIndex: number = 0;
        // In case of expanded results, start typing from extended reply

        const stringResponse = props.data.message || "";

        if (!props.data.isTyping || props.data.type !== "ai") {
            setDisplayResponse(stringResponse);
            return;
        }

        console.log(props);

        const typewriterIntervalRef = setInterval(() => {
            setDisplayResponse(stringResponse.slice(0, cursorIndex));

            cursorIndex++;
            // While typing, stay at the bottom of the parent
            scrollToBottomOfParent();
            // When typing is complete
            if (cursorIndex > stringResponse.length) {
                clearInterval(typewriterIntervalRef);
                // update state of answer in db
                if (stringResponse.length > 0) {
                    dispatch(
                        updateChatQuery({
                            id: props.data.id,
                            type: props.data.type as string,
                            isLoading: false,
                            message: props.data.message,
                            isTyping: false,
                        }),
                    );
                    scrollToBottomOfParent();
                }
                //
            }
        }, 20);

        return () => clearInterval(typewriterIntervalRef);
    }, [props.data.message]);
    return (
        <div
            className={`chat__conversation-board__message-container ${
                props.data.type === "user" ? "reversed" : ""
            }`}
        >
            <div className="chat__conversation-board__message__person">
                {/*<div className="chat__conversation-board__message__person__avatar">*/}
                {/*    <img*/}
                {/*        src="/images/avatar.png" alt="Montague Adameve"/></div>*/}
                {/*<span*/}
                {/*    className="chat__conversation-board__message__person__nickname">Montague Adameve</span>*/}
            </div>
            <div className="chat__conversation-board__message__context">
                <div className="chat__conversation-board__message__bubble">
                    {props.data.isLoading ? (
                        <p
                            className="message_contents"
                            style={{
                                background: "transparent",
                                border: "none",
                                padding: 0,
                            }}
                        >
                            <span className="typing">
                                <span className="circle scaling"></span>
                                <span className="circle scaling"></span>
                                <span className="circle scaling"></span>
                            </span>
                        </p>
                    ) : (
                        <p className="message_contents">{displayResponse}</p>
                    )}
                </div>
            </div>
        </div>
    );
}
