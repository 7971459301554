import { BsDownload, BsFolder } from "react-icons/bs";
import { useSelector } from "react-redux";
import "../../styles/FoldersSidePanel.component.scss";
import { useHttpClient } from "../../utils/http.utils";
import React, { useState } from "react";
import SideMenuCard from "../SideMenuCard";
import { Tab, TabList, Tabs } from "@chakra-ui/react";
import PromptLibrary from "../PromptLibrary";
import MemoriesPanel from "../memories/MemoriesPanel";
import { useMemoriesConfig } from "../../utils/memories-config.utils";

interface FoldersSidePanelProps {
    onPromptSelected?: (prompt: string) => void;
}

export default function FoldersSidePanel(props: FoldersSidePanelProps) {
    const { fetchFolderByName } = useHttpClient();
    const folders = useSelector((state) => (state as any).folders.folders);
    const activeFolderName = useSelector(
        (state) => (state as any).folders.activeFolderName,
    );
    const [tabIndex, setTabIndex] = useState(0);
    const memoriesConfig = useMemoriesConfig();

    const loadActiveFolder = (name: string) => {
        if (name === activeFolderName) {
            return;
        }
        // Fetch active folder
        fetchFolderByName(name);
    };

    function onPromptSelect(prompt: string) {
        if (typeof props.onPromptSelected == "function") {
            props.onPromptSelected(prompt);
        }
    }

    const downloadFolder = () => {
        console.log("downloaded");
    };

    return (
        <div id="left-panel" className="left-panel grid-column pl-2 pb-3">
            <div className="switch-header">
                <Tabs
                    variant="solid-rounded"
                    size="sm"
                    colorScheme="brand"
                    onChange={(index) => setTabIndex(index)}
                >
                    <TabList>
                        <Tab>Folders</Tab>
                        {activeFolderName && <Tab>Memories</Tab>}
                        {activeFolderName && <Tab>Prompt</Tab>}
                    </TabList>
                </Tabs>
            </div>
            <div className="switch-body">
                {tabIndex === 0 && (
                    <div className="h-full">
                        <div className="sidebar-sm">
                            <div className="side-nav-sm">
                                <h4 className="title">Folders</h4>
                                <p className="subtitle">
                                    Information you saved.
                                </p>
                            </div>

                            <div className="folder-list-body sidebar-body">
                                {folders.map((folder: any, index: any) => {
                                    return (
                                        <SideMenuCard
                                            key={index}
                                            title={folder.name}
                                            leftIcon={<BsFolder />}
                                            rightIcon={<BsDownload />}
                                            isActive={
                                                folder.name === activeFolderName
                                            }
                                            onClick={() =>
                                                loadActiveFolder(folder.name)
                                            }
                                            onRightActionClick={downloadFolder}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
                {tabIndex === 1 && (
                    <div className="h-full">
                        <MemoriesPanel />
                    </div>
                )}
                {tabIndex === 2 && (
                    <div className="h-full">
                        <PromptLibrary onSelect={onPromptSelect} />
                    </div>
                )}
                {/*<TransitionGroup>*/}
                {/*    <CSSTransition*/}
                {/*        nodeRef={promptContainerRef}*/}
                {/*        in={tabIndex === 0}*/}
                {/*        timeout={200}*/}
                {/*        classNames="left-switch-node"*/}
                {/*    >*/}
                {/*        */}
                {/*    </CSSTransition>*/}

                {/*    <CSSTransition nodeRef={memoriesContainerRef} in={tabIndex === 0} timeout={200} classNames="left-switch-node">*/}
                {/*       */}
                {/*    </CSSTransition>*/}
                {/*</TransitionGroup>*/}
            </div>
        </div>
    );
}
