import { ProfileAvatar } from "../Avatar";
import { useCallback, useEffect, useState } from "react";
import { useHttpClient } from "../../utils/http.utils";
import { sortListByProperty } from "../../utils/array.utils";
import { useSelector } from "react-redux";
import hotToast from "react-hot-toast";

export default function PersonalPortal() {
    const { listPersonaPortalsApi, deleteRoomApi } = useHttpClient();
    const [portals, setPortals] = useState<any[]>([]);
    const { accountData: loggedInUser } = useSelector(
        (state: any) => state.account,
    );

    useEffect(() => {
        if (loggedInUser && portals.length === 0) {
            fetchRooms();
        }
    }, [loggedInUser]);

    const fetchRooms = useCallback(() => {
        if (portals.length === 0 && loggedInUser) {
            listPersonaPortalsApi().then((response) => {
                if (Array.isArray(response)) {
                    for (const room of response) {
                        let personaParticipants: any[] = [],
                            userParticipants: any[] = [];
    
                        if (room) {
                            if (room.persona_member_list) {
                                personaParticipants = room.persona_member_list.map(
                                    (personaMember) => ({
                                        id: personaMember.persona?.id as string,
                                        member_id: personaMember.id,
                                        name:
                                            personaMember.persona?.first_name +
                                            " " +
                                            personaMember?.persona.last_name,
                                        profile_picture:
                                            personaMember.persona?.profile_picture,
                                        type: "persona",
                                        persona_type: personaMember.persona?.type,
                                    }),
                                );
                            }
    
                            if (room.user_member_list) {
                                // remove the current user form the participant array
                                userParticipants = room.user_member_list
                                    .filter(
                                        (member) =>
                                            member.user.id !== loggedInUser.id,
                                    )
                                    .map((userMember) => ({
                                        id: userMember.user?.id as string,
                                        member_id: userMember.id,
                                        name:
                                            userMember?.user.name ||
                                            userMember?.user.email,
                                        profile_picture:
                                            userMember?.user.profile_picture,
                                        type: "user",
                                    }));
                            }
                        }
                        room.participants = sortListByProperty(
                            [...(personaParticipants || []), ...userParticipants],
                            "name",
                        );
                    }
                    setPortals(response);
                }
            });
        }
    }, [loggedInUser]);

    const deletePortal = (portalId: string) => {
        hotToast.promise(deleteRoomApi(portalId), {
            loading: "Deleting portal...",
            success: (data) => {
                // remove portal with id equal to partalId
                const newPortals = portals.filter(
                    (portal) => portal.id !== portalId,
                );
                setPortals(newPortals);
                return "Portal deleted";
            },
            error: "Cannot delete portal",
        });
    };

    return (
        <div className="column-contents grid grid-rows-[55px_auto] h-full">
            <div className="side-nav-sm">
                <h2 className="title">Active Portals</h2>
                <p className="subtitle">
                    Get your personas to connect to the world.
                </p>
            </div>
            <div className="h-full">
                <div className="h-full overflow-y px-4 pt-4">
                    {portals.map((portal) => (
                        <PersonaPortalCard
                            key={`portal_${portal.id}`}
                            data={portal}
                            onDelete={deletePortal}
                        />
                    ))}
                    {/* <PersonaPortalCard
                        title={"Persona N   ame"}
                        subtitle={"with Janice, Jeremy and 235 others"}
                        actions={["Drop In", "End Portal"]}
                    /> */}
                    {/* <PersonaPortalCard
                        title={"Persona Name"}
                        subtitle={"With Jeremy"}
                        actions={["End Portal"]}
                    />
                    <PersonaPortalCard
                        title={"Persona Name"}
                        subtitle={"Anonymous User"}
                        actions={["Drop In", "End Portal"]}
                    /> */}
                </div>
            </div>
        </div>
    );
}

function PersonaPortalCard({
    data,
    onDelete,
}: {
    data: any;
    onDelete?: (id: string) => void;
}) {
    const navigateToPortal = () => {
        window.open(
            `${process.env.REACT_APP_NEO_UI_URL}/room/${data.id}`,
            "_blank",
        );
    };

    const killPortal = () => {
        if (typeof onDelete === "function") {
            onDelete(data.id);
        }
    };

    return (
        <div
            className={`persona-avatar-card min-h-[48px] mb-4 px-4 py-3 rounded-lg`}
        >
            {/* <div className="flex items-center">
                <ProfileAvatar size="30px" />
            </div> */}
            <div className="">
                <div className="w-full flex justify-between items-center">
                    <div className="">
                        <h4 className="clone-text font-semibold w-full truncate">
                            {data.title}
                        </h4>
                    </div>
                    <div className="text-right actions-container">
                        <button
                            className={`text-[12px] inline-block text-blue-500 mr-2`}
                            onClick={navigateToPortal}
                        >
                            Drop In
                        </button>
                        <button
                            className={`text-[12px] inline-block text-red-500 mr-2`}
                            onClick={killPortal}
                        >
                            End Portal
                        </button>
                    </div>
                </div>
                <p className="text-slate-500 text-[12px]">
                    with
                    {(data.participants as any[]).slice(0, 2).map((p, i) => {
                        return (
                            <span key={"p_" + i}>
                                {i > 0 ? ", " : " "}
                                {p.name}
                            </span>
                        );
                    })}
                    {data.participants.length > 2 &&
                        `and ${data.participants.length - 2} others`}
                </p>
            </div>
        </div>
    );
}
