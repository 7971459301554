import "../styles/UploadLinksForm.component.scss";
import React, {
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
} from "react";
import {
    Button,
    Input,
    Progress,
    FormControl,
    FormLabel,
    useToast,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { IoDocumentAttachOutline, IoRefresh } from "react-icons/io5";
import { formatFileSize } from "../utils/number.utils";
import { AiOutlineDelete } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";

interface UploadLinksProps {
    type?: "video" | "web";
    progress: number;
    onChange?: (file: File | undefined) => void;
}

const UploadLInksForm = forwardRef((props: UploadLinksProps, ref: any) => {
    const [formFields, setFormFields] = useState([{ id: 1, value: "" }]);
    const [columnName, setColumnName] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<File>();
    const [isUploading, setIsUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const inputFileRef = useRef(null);
    const toast = useToast();

    useEffect(() => {
        setIsUploading(
            Number(props.progress) > 0 && Number(props.progress) < 100,
        );
        if (Math.ceil(Number(props.progress)) === 100) {
            setUploaded(true);
        }
    }, [props]);

    useImperativeHandle(ref, () => ({
        resetForm() {
            setFormFields([{ id: 1, value: "" }]);
            setSelectedFile(undefined);
            setColumnName("");
            // @ts-ignore
            inputFileRef.current.value = "";
        },

        getValues() {
            return {
                file: selectedFile,
                links: formFields,
                columnName: columnName,
            };
        },

        validateForm(): boolean {
            /**
             * 1. FIRST: IF FILE IS DEFINED, MAKE SURE COLUMN NAME IS DEFINED
             *
             */
            if (
                !selectedFile &&
                formFields.some((formControl) => !formControl.value)
            ) {
                toast({
                    status: "warning",
                    title: "Please upload file containing data or links, or specify links",
                    duration: 2000,
                });
                return false;
            }

            if (selectedFile) {
                if (!columnName) {
                    toast({
                        status: "warning",
                        title: "Please specify the column name in the file",
                        duration: 2000,
                    });
                    return false;
                }
            } else if (formFields.some((formControl) => !formControl.value)) {
                toast({
                    status: "warning",
                    title: "Please specify links",
                    duration: 2000,
                });
                return false;
            }

            return true;
        },
    }));

    const openFileSelector = () => {
        // @ts-ignore
        inputFileRef.current?.click();
    };

    const removeSelectedFile = () => {
        // @ts-ignore
        inputFileRef.current.value = null;
        setSelectedFile(undefined);
        // Emit changes to parent
        emitChanges(undefined);
    };

    const handleInputChange = (index: number, event: any) => {
        const updatedFormFields = [...formFields];
        updatedFormFields[index].value = event.target.value;
        setFormFields(updatedFormFields);
    };

    const handleAddField = () => {
        const newField = {
            id: formFields.length + 1,
            value: "",
        };
        setFormFields([...formFields, newField]);
    };

    const emitChanges = (file: File | undefined) => {
        // Emit data to parent
        if (typeof props.onChange === "function") {
            props.onChange(file);
        }
    };

    const handleRemoveField = (index: number) => {
        const updatedFormFields = formFields.filter((_, i) => i !== index);
        setFormFields(updatedFormFields);
    };

    const selectFile = () => {
        // @ts-ignore
        inputFileRef.current.click();
    };

    const onFileSelect = (e: any) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    function onColumnNameInput(e: any) {
        setColumnName(e.target.value);
    }

    return (
        <div className="links">
            <input
                type="file"
                style={{ display: "none" }}
                ref={inputFileRef}
                onChange={onFileSelect}
                accept="cvs,xls,xlsx"
                name=""
                id=""
            />
            <div className="links-form mt-3">
                <div className="header pb-6">
                    <span className="text-slate-500">
                        Upload multiple links? Use Excel or CSV and select the
                        column with the links.
                    </span>
                    <button
                        onClick={selectFile}
                        className="text-blue-600 ml-2 font-medium hover:underline"
                    >
                        Upload file
                    </button>
                </div>
                {selectedFile ? (
                    <div className="file-section w-3/4">
                        <div className="selected-file pl-4 pr-2 py-3">
                            <div className="info">
                                <div className="icon-section">
                                    <IoDocumentAttachOutline className="icon" />
                                </div>
                                <div className="meta-data">
                                    <div className="file-name">
                                        <span>{selectedFile?.name}</span>
                                    </div>
                                    <p className="text-slate-500">
                                        {formatFileSize(
                                            selectedFile?.size as number,
                                            0,
                                        )}
                                    </p>
                                </div>
                            </div>
                            {!isUploading ? (
                                <div className="actions flex items-center">
                                    <Button
                                        onClick={openFileSelector}
                                        pl={0}
                                        pr={0}
                                        variant="ghost"
                                    >
                                        <IoRefresh className="icons" />
                                    </Button>
                                    {!uploaded ? (
                                        <Button
                                            onClick={removeSelectedFile}
                                            pl={0}
                                            pr={0}
                                            variant="ghost"
                                            colorScheme="red"
                                        >
                                            <AiOutlineDelete className="icons" />
                                        </Button>
                                    ) : (
                                        <div className="px-2">
                                            <BsCheckCircleFill
                                                className="icons"
                                                color="#3caf50"
                                            />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="progress-section">
                                    <Progress
                                        value={props.progress}
                                        size="xs"
                                        colorScheme="blue"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="input-column-name pt-6">
                            <FormControl>
                                <FormLabel>Enter column name</FormLabel>
                                <Input
                                    value={columnName}
                                    onChange={onColumnNameInput}
                                    placeholder="Enter column name"
                                />
                            </FormControl>
                        </div>
                    </div>
                ) : (
                    <div>
                        {formFields.map((field, index) => (
                            <div key={field.id} className="links-form-row mb-3">
                                <div className="input-form">
                                    <Input
                                        value={field.value}
                                        placeholder={"Link " + (index + 1)}
                                        prefix="https://"
                                        onChange={(event) =>
                                            handleInputChange(index, event)
                                        }
                                    />
                                </div>
                                <div className="icon-action">
                                    <Button
                                        colorScheme="red"
                                        variant="ghost"
                                        onClick={() => handleRemoveField(index)}
                                    >
                                        <MdOutlineDelete className="icon" />
                                    </Button>
                                </div>
                            </div>
                        ))}
                        <div className="">
                            <Button
                                onClick={handleAddField}
                                variant="ghost"
                                colorScheme="blue"
                                leftIcon={<FiPlus />}
                            >
                                Add Link
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default UploadLInksForm;
