import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Button,
    ButtonGroup,
    FormControl,
    FormErrorMessage,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    updateMemoryLocationName,
    updateMemorySectorName,
    UploadDataState,
} from "../../store/slices/memories.slice";
import "../../styles/UploadedDataPanel.component.scss";
import { capitalizeWord } from "../../utils/strings.utils";
import { MdDeleteOutline } from "react-icons/md";
import { useHttpClient } from "../../utils/http.utils";
import { Field, Form, Formik } from "formik";
import { MemoryBrainType, MemoryFile } from "../../models/memories.model";
import { BsFileEarmarkLock2, BsFileEarmarkMedical } from "react-icons/bs";
import { DeleteConfirmation } from "../utils/ConfirmationModals";
import { HiLockClosed } from "react-icons/hi";

interface SectorLocationHeaderProps {
    id: string;
    sectorId?: string;
    brainType?: MemoryBrainType;
    title: string;
    type?: "sector" | "location";
    onDelete?: (id: string) => void;
}

export default function StoreMemoriesPanel() {
    const uploadedData = useSelector(
        (state) => (state as any).uploadedData as UploadDataState,
    );
    const { deleteLocationApi, deleteSectorApi, deleteAllMemoriesApi } =
        useHttpClient();
    const deleteDisclosure = useDisclosure();
    const [selectedMemory, setSelectedMemory] =
        useState<{
            sectorId: string;
            locationId: string;
            sector: string;
            location?: string;
            brainType?: MemoryBrainType;
        }>();
    const [deleteAction, setDeleteAction] = useState<"sector" | "location">();
    const [isDeletingAllMemories, setIsDeletingAllMemories] = useState(false);

    const validateDelete = (value) => {
        let error;
        if (!value) {
            error = "Please confirm this action";
        } else if (value.toLowerCase() !== "delete") {
            error = "Please input DELETE to confirm this action";
        }
        return error;
    };

    const setDeleteMode = (
        sectorId: string,
        locationId: string,
        action: "sector" | "location",
        sector: string,
        location?: string,
        brainType?: MemoryBrainType,
    ) => {
        setSelectedMemory({
            sectorId,
            locationId,
            sector,
            location,
            brainType,
        });
        setDeleteAction(action);
        deleteDisclosure.onOpen();
    };

    /**
     * On Delete
     */
    const onDelete = (_, actions) => {
        switch (deleteAction) {
            case "sector":
                deleteSectorApi(selectedMemory?.sectorId as string, {
                    sectorId: selectedMemory?.sectorId as string,
                    sector: selectedMemory?.sector as string,
                    brainType: selectedMemory?.brainType as MemoryBrainType, // TODO: Add brain type
                }).finally(() => {
                    actions.setSubmitting(false);
                    deleteDisclosure.onClose();
                });
                break;
            case "location":
                deleteLocationApi(
                    selectedMemory?.sectorId as string,
                    selectedMemory?.locationId as string,
                    selectedMemory?.sector as string,
                    selectedMemory?.location as string,
                    selectedMemory?.brainType as MemoryBrainType,
                ).finally(() => {
                    actions.setSubmitting(false);
                    deleteDisclosure.onClose();
                });
                break;
        }
    };

    /**
     *  Delete all data
     *
     */
    const onDeleteAllData = () => {
        DeleteConfirmation({ title: "Delete all memories" }).then((confirm) => {
            if (confirm) {
                console.log("delete");
                setIsDeletingAllMemories(true);
                deleteAllMemoriesApi().finally(() => {
                    setIsDeletingAllMemories(false);
                });
            }
        });
    };

    return (
        <div className="upload-data-panel">
            {/*<Tree*/}
            {/*    initialData={data}*/}
            {/*    openByDefault={true}*/}
            {/*/>*/}
            <Accordion defaultIndex={[0]} allowMultiple>
                {uploadedData.memoriesTree.map((tree, index) => {
                    return (
                        <AccordionItem
                            key={"tree-" + index}
                            style={{ borderTop: "none" }}
                        >
                            <div className="accordion-header py-2">
                                <SectorLocationHeader
                                    onDelete={() =>
                                        setDeleteMode(
                                            tree.sector_id,
                                            "",
                                            "sector",
                                            tree.sector,
                                            "",
                                            tree.brain_type,
                                        )
                                    }
                                    id={tree.sector_id as string}
                                    type="sector"
                                    title={tree.sector_display}
                                    brainType={
                                        tree.brain_type as MemoryBrainType
                                    }
                                />
                                <div className="action">
                                    <AccordionButton>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </div>
                            </div>
                            <AccordionPanel
                                className="accordion-inner-panel"
                                pb={4}
                                pr={0}
                            >
                                {tree.locations.map((location, index) => {
                                    return (
                                        <AccordionItem
                                            className="location-accordion"
                                            key={"loc-" + index}
                                        >
                                            <div className="accordion-header py-2">
                                                <SectorLocationHeader
                                                    id={
                                                        location.location_id as string
                                                    }
                                                    type="location"
                                                    title={
                                                        location.location_display
                                                    }
                                                    sectorId={tree.sector_id}
                                                    brainType={
                                                        tree.brain_type as MemoryBrainType
                                                    }
                                                    onDelete={() =>
                                                        setDeleteMode(
                                                            tree.sector_id,
                                                            location.location_id,
                                                            "location",
                                                            tree.sector,
                                                            location.location,
                                                            tree.brain_type,
                                                        )
                                                    }
                                                />
                                                <AccordionButton>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </div>
                                            <AccordionPanel
                                                pl={0}
                                                pb={4}
                                                pr={0}
                                            >
                                                <div className="pl-2">
                                                    <span className="font-bold text-sm">
                                                        Files
                                                    </span>
                                                    {location.files.map(
                                                        (file, index) => {
                                                            return (
                                                                <MemoryFileCard
                                                                    file={file}
                                                                    key={`file-${index}`}
                                                                    params={{
                                                                        sector: tree.sector,
                                                                        sectorId:
                                                                            tree.sector_id,
                                                                        location:
                                                                            location.location,
                                                                        locationId:
                                                                            location.location_id,
                                                                    }}
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    );
                                })}
                            </AccordionPanel>
                        </AccordionItem>
                    );
                })}
                {uploadedData.memoriesTree.length === 0 && (
                    <div className="empty-state">
                        <div className="empty-content px-10">
                            <img
                                src="/no_memories.png"
                                className={"mx-auto h-16 mb-6"}
                                alt=""
                            />
                            <h1 className="title font-bold">Nothing to show</h1>
                            <p className="text-sm text-slate-500">
                                All the files (memories) you create shall
                                display here.
                            </p>
                        </div>
                    </div>
                )}
            </Accordion>

            <div className="memories-action-footer">
                <Button
                    onClick={onDeleteAllData}
                    variant="ghost"
                    size="sm"
                    colorScheme="red"
                    isLoading={isDeletingAllMemories}
                    loadingText="Deleting.."
                    isDisabled={uploadedData.memoriesTree.length === 0}
                >
                    Delete All
                </Button>
            </div>

            <Modal
                isOpen={deleteDisclosure.isOpen}
                onClose={deleteDisclosure.onClose}
                closeOnOverlayClick={false}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <Formik initialValues={{ action: "" }} onSubmit={onDelete}>
                        {(props) => (
                            <Form>
                                <ModalHeader>
                                    <span className="text-lg">
                                        Are you sure you want to delete this
                                        data?{" "}
                                    </span>
                                </ModalHeader>
                                <ModalBody>
                                    <div className="text-slate-500 mb-2 text-sm">
                                        <p className="mb-1">
                                            To confirm this action, type{" "}
                                            <strong>DELETE</strong> in the input
                                            below
                                        </p>
                                    </div>
                                    <Field
                                        name="action"
                                        validate={validateDelete}
                                    >
                                        {({ field, form }) => (
                                            <FormControl
                                                isInvalid={
                                                    form.errors.action &&
                                                    form.touched.action
                                                }
                                            >
                                                <Input
                                                    {...field}
                                                    placeholder="Confirm Action"
                                                />
                                                <FormErrorMessage>
                                                    {form.errors.action}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </ModalBody>
                                <ModalFooter>
                                    <ButtonGroup spacing="2">
                                        <Button
                                            colorScheme="red"
                                            type="submit"
                                            isLoading={props.isSubmitting}
                                            loadingText="Deleting"
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            variant="outline"
                                            onClick={deleteDisclosure.onClose}
                                            colorScheme="brand"
                                        >
                                            Cancel
                                        </Button>
                                    </ButtonGroup>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </div>
    );
}

function SectorLocationHeader(props: SectorLocationHeaderProps) {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [titleForm, setTitleForm] = useState<string>("");
    const dispatch = useDispatch();
    const { updateSectorApi, updateLocationApi } = useHttpClient();
    const type = props.type || "sector";
    const toast = useToast();

    const openEditForm = () => {
        setTitleForm(props.title);
        setEditMode(true);
    };

    const onSave = () => {
        if (!titleForm) {
            toast({
                title: "Please enter a title",
                status: "warning",
            });
            return;
        }

        if (type === "sector") {
            dispatch(
                updateMemorySectorName({
                    id: props.id,
                    name: titleForm.trim(),
                }),
            );
            // Save new info in db
            updateSectorApi(props.id, props.title, titleForm);
        } else {
            dispatch(
                updateMemoryLocationName({
                    id: props.id,
                    sector_id: props.sectorId as string,
                    name: titleForm.trim(),
                }),
            );
            // save info into db
            updateLocationApi(
                props.sectorId as string,
                props.id,
                props.title,
                titleForm,
            );
        }
        setEditMode(false);
    };

    const onDelete = () => {
        if (typeof props.onDelete === "function") {
            props.onDelete(props.id);
        }
    };

    return editMode ? (
        <div className="accordion-edit-form">
            <div>
                <input
                    value={titleForm}
                    onChange={(e) => setTitleForm(e.target.value)}
                    type="text"
                />
            </div>
            <div className="actions pl-2">
                <button className="save" onClick={onSave}>
                    <AiOutlineCheck className="icon" />
                </button>
                <button className="text-red-500">
                    <AiOutlineClose
                        onClick={() => setEditMode(false)}
                        className="icon"
                    />
                </button>
            </div>
        </div>
    ) : (
        <div className="accordion-sector">
            <p
                title={capitalizeWord(props.title)}
                className="accordion-sector-title"
            >
                {capitalizeWord(props.title)}
                {props.type === "sector" &&
                    props.brainType === "Segregated" && (
                        <HiLockClosed className="icon" />
                    )}
            </p>
            <div className="memory-actions">
                <button onClick={openEditForm}>
                    <AiOutlineEdit className="icon" />
                </button>
                <button onClick={onDelete} className="text-red-500">
                    <MdDeleteOutline className="icon" />
                </button>
            </div>
        </div>
    );
}

function MemoryFileCard({
    file,
    params,
}: {
    file: MemoryFile;
    params: {
        sector: string;
        location: string;
        sectorId: string;
        locationId: string;
    };
}) {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>();
    const { updateSectorFileNameApi, deleteSectorFileNameApi } =
        useHttpClient();
    const toast = useToast();
    const [isDeleting, setIsDeleting] = useState(false);

    const openEditForm = () => {
        setFileName(file.name);
        setEditMode(true);
    };

    async function onDelete() {
        // Ask user to confirm if the want to delete the file
        const shouldDelete = await DeleteConfirmation({
            title: "Delete File",
            message: "Are you sure you want to delete this file?",
        });

        if (shouldDelete) {
            setIsDeleting(true);
            deleteSectorFileNameApi({
                id: file.upload_id as string,
                sector: params.sector,
                location: params.location,
                sectorId: params.sectorId, // used by redux to update file
                locationId: params.locationId, // used by redux to update file
                filename: file.name,
                brainType: file.brain_type,
            }).finally(() => {
                setIsDeleting(false);
            });
        }
    }

    function onSave() {
        if (!fileName) {
            toast({
                title: "Please enter a file name",
                status: "warning",
            });
            return;
        }
        updateSectorFileNameApi({
            id: file.upload_id as string,
            sectorId: params.sectorId, // used by redux to update file
            locationId: params.locationId, // used by redux to update file
            oldFileName: file.name,
            newFileName: fileName as string,
        });
        setEditMode(false);
    }

    return (
        <>
            {editMode ? (
                <div className="file-edit-form">
                    <div>
                        {file.brain_type === MemoryBrainType.SEGREGATED ? (
                            <BsFileEarmarkLock2 className="icon" />
                        ) : (
                            <BsFileEarmarkMedical className="icon" />
                        )}
                    </div>
                    <div>
                        <input
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value)}
                            type="text"
                        />
                    </div>
                    <div className="actions pl-2">
                        <button className="save" onClick={onSave}>
                            <AiOutlineCheck className="icon" />
                        </button>
                        <button
                            onClick={() => setEditMode(false)}
                            className="text-red-500"
                        >
                            <AiOutlineClose className="icon" />
                        </button>
                    </div>
                </div>
            ) : (
                <div
                    className={`tree-file-item text-slate-700 py-2 pl-2 ${
                        isDeleting ? "opacity-50" : ""
                    }`}
                >
                    {file.brain_type === MemoryBrainType.SEGREGATED ? (
                        <BsFileEarmarkLock2 className="icon" />
                    ) : (
                        <BsFileEarmarkMedical className="icon" />
                    )}
                    <span title={file.name} className="file-name">
                        {file.name}
                    </span>
                    <div className="memory-actions">
                        <button onClick={openEditForm}>
                            <AiOutlineEdit className="icon" />
                        </button>
                        <button onClick={onDelete} className="text-red-500">
                            <MdDeleteOutline className="icon" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
