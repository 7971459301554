export enum MemoryBrainType {
    SEGREGATED = "Segregated",
    GENERAL = "General",
    THIRD_PARTY = "third-party",
}
export interface MemoryFile {
    upload_id: string; // this the id of the upload data
    name: string;
    brain_type: MemoryBrainType;
}

export interface MemoryLocation {
    location: string;
    namespace?: string;
    location_id: string;
    location_display: string;
    location_description?: string;
    files: MemoryFile[];
}

export interface MemoriesTreeModel {
    id?: string;
    sector_id: string;
    sector: string;
    namespace?: string;
    brain_type?: MemoryBrainType;
    is_structured?: boolean;
    relation?: string;
    sector_display: string;
    sector_description?: string;
    locations: MemoryLocation[];
}

export interface MemoriesModelQuery extends MemoriesModel {
    isSelected: boolean;
}

export interface MemoriesModel {
    createdAt: number;
    updatedAt: number;
    id: string;
    filename: string;
    filename_display: string;
    brain_type: MemoryBrainType;
    is_structured: false;
    relation: [
        {
            file_name: string;
            sheet_name: string;
            relation: string;
        },
    ];
    namespace: string;
    status: string;
    user: string;
    data_sector: string;
    data_location: string;
    sector: string;
    sector_description: string;
    sector_display: string;
    name_display?: string;
    location: string;
    location_description: string;
    location_display: string;
}
