import { ResponsiveLine } from "@nivo/line";
import { useEffect, useMemo, useState } from "react";
import ReactSelect from "react-select";
import "../../styles/UsageChart.component.scss";
import { PersonaModel, PersonaTransaction } from "../../models/persona.model";
import { getPersonaFullName } from "../../utils/personas.utils";
import dayjs from "dayjs";

interface FinanceChartProps {
    data: PersonaTransaction[];
    // chartType: "revenue" | "expenses";
    persona?: PersonaModel;
}

interface GraphOption {
    value: "revenue" | "expenses";
    label: "Revenue" | "Expenses";
}

const chartTypeOptions: GraphOption[] = [
    { value: "revenue", label: "Revenue" },
    { value: "expenses", label: "Expenses" },
];

export default function PersonaFinanceChart({
    data,
    persona,
}: FinanceChartProps) {
    const [segmentValue, setSegmentValue] = useState("daily");
    const [selectedGraphOption, setSelectedGraphOption] = useState<GraphOption>(
        { value: "revenue", label: "Revenue" }
    );
    // const [personaOptions, setPersonaOptions] = useState<any[]>([]);

    // useEffect(() => {
    //     console.log(data);
    //     setPersonaOptions([
    //         { label: "All", value: "all" },
    //         ...personas.map((persona) => ({
    //             value: persona.id as string,
    //             label: getPersonaFullName(persona),
    //         })),
    //     ]);
    // }, [personas]);

    const graphData = () => {
        const dayJsDayList = Array.from(
            { length: 7 },
            (_, i) => dayjs().subtract(i, "days").startOf("day")
        );

        const sevenDaysGraphData = dayJsDayList.map(dayJsDay => ({
            x: dayJsDay.format("MMM DD"),
            y: 0
        }));

        let filteredData: PersonaTransaction[];
        switch (selectedGraphOption.value) {
            case "expenses":
                filteredData = data.filter(transaction => transaction.ledger_entry === "debit");
                break;
            case "revenue": 
            default:
                filteredData = data.filter(transaction => transaction.ledger_entry === "credit");
        }

        // Fill values in graphData
        filteredData.forEach(transaction => {
            const transactionDayStart = dayjs(transaction.created_at).startOf("day");
            const todayStart = dayjs().startOf("day");
            const diffDays = todayStart.diff(transactionDayStart, "days");

            let graphAmount = transaction.amount;

            if (diffDays < 7 && diffDays >= 0) {
                sevenDaysGraphData[diffDays].y += graphAmount;
            }
        });

        return [
            {
                id: "Finance",
                color: "hsl(300, 70%, 50%)",
                data: sevenDaysGraphData.reverse()
            },
        ];
    };

    const memoizedGraphData = useMemo(graphData, [data, selectedGraphOption]);

    const onGraphOptionChange = (value) => {
        setSelectedGraphOption(value)
    };

    return (
        <div className="chart-container">
            <div className="flex justify-between">
                <div className="header">
                    <div className="header-tile pl-[24px]">
                        <h1 className="title">Finance</h1>
                    </div>
                </div>
                <div className={"flex gap-2 items-center"}>
                    <div className="w-32">
                        <ReactSelect
                            placeholder="Persona"
                            className="arin-react-select-container"
                            classNamePrefix="arin-react-select"
                            value={selectedGraphOption}
                            options={chartTypeOptions}
                            onChange={onGraphOptionChange}
                        />
                    </div>
                    {/* <div className="w-[200px]">
                        <ReactSelect
                            placeholder="Persona"
                            className="arin-react-select-container"
                            classNamePrefix="arin-react-select"
                            value={personaOptions[0]}
                            options={personaOptions}
                        />
                    </div> */}
                </div>
            </div>
            <div className="chart" style={{ height: "40vh", width: "100%" }}>
                <ResponsiveLine
                    data={memoizedGraphData}
                    margin={{ top: 50, right: 30, bottom: 60, left: 60 }}
                    xScale={{ type: "point" }}
                    yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: false,
                        reverse: false,
                    }}
                    curve="cardinal"
                    enableArea={true}
                    areaBaselineValue={60}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Credits",
                        legendOffset: -40,
                        legendPosition: "middle",
                    }}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 15,
                    }}
                    pointSize={10}
                    pointColor={{ theme: "background" }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: "serieColor" }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                />
            </div>
        </div>
    );
}
