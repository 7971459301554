import "../styles/PromptEngineering.component.scss";
import ArinLayout from "../components/ArinLayout";
import { useSearchParams } from "react-router-dom";

export default function PromptEngineeringView() {
    const [searchParams] = useSearchParams();
    const liteGraphURL = process.env.REACT_APP_LITEGRAPH_URL;

    return (
        <ArinLayout>
            <div className="prompt-engineering">
                <iframe
                    title="brain"
                    src={`${liteGraphURL}?application_code=${searchParams.get(
                        "collaboration_app_group_id",
                    )}&token=${searchParams.get(
                        "token",
                    )}`}
                    className="prompt-engineer"
                ></iframe>
            </div>
        </ArinLayout>
    );
}
