import ArinLayout from "../components/ArinLayout";
import "../styles/FolderView.component.scss";
import FoldersSidePanel from "../components/sidenav/FoldersSidePanel";
import React, { useEffect, useRef, useState } from "react";
import { setMetaTags } from "../utils/seo.utils";
import { useDispatch, useSelector } from "react-redux";
import { SearchConfig, SearchModel } from "../models/search.model";
import SkeletonLoader from "../components/SkeletonLoader";
import SearchBox from "../components/SearchBox";
import ResponseCard from "../components/ResponseCard";
import { useHttpClient } from "../utils/http.utils";
import { scrollToBottom } from "../utils/dom.utils";
import ChainingCard from "../components/ChainingCard";
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { removeLastQuery } from "../store/slices/searches.slice";
import {
    addActiveFolderQuery,
    removeLastActiveFolderQuery,
} from "../store/slices/folder.slice";
import { useMemoriesConfig } from "../utils/memories-config.utils";
import useSearches from "../utils/search/searches.utils";

export default function FolderView() {
    const searches = useSelector((state) => (state as any).searches);
    const isLoading = useSelector((state) => (state as any).folders.loading);
    const activeFolderFiles = useSelector(
        (state) => (state as any).folders.activeFolderFiles,
    );
    const activeFolderName = useSelector(
        (state) => (state as any).folders.activeFolderName,
    );
    const {
        fetchSearchReplyInFolderApi,
        fetchNotesReplyInFolderApi,
        getAgentNamespace,
    } = useHttpClient();
    const containerRef = useRef(null);
    const [isControlsFormVisible, setIsControlsFormVisible] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();
    const noDatabaseDis = useDisclosure();
    const [selectedPrompt, setSelectedPrompt] = useState<string>();
    const memoriesConfig = useMemoriesConfig();
    const { getFolderChainHistory } = useSearches();

    useEffect(() => {
        // Set meta tags...
        setMetaTags({ title: "Folders" });
    }, []);

    function onPromptSelect(prompt: string) {
        setSelectedPrompt(prompt);
    }

    const onSearch = async (query: string, params: SearchConfig) => {
        // Clean search query, remove white spaces and trailing new line characters if exist
        const search_query = query.trim();
        if (!search_query) {
            return;
        }
        /**
         * User's can only ask question at the time, in case the user is trying to ask a question, while another
         * is pending, reject
         */
        if (searches.isSearchLoading) {
            toast({
                title: "Please wait for first query to complete",
                position: "top",
                status: "info",
                duration: 2300,
            });
            return;
        }

        // add active folder query to show loading state on screen
        dispatch(
            addActiveFolderQuery({
                isFinishedTyping: false,
                isLoading: true,
                isExpanded: false,
                question: search_query,
                character: searches.character.character,
                character_message: searches.character.character_message,
                reply: "",
            }),
        );

        if (params.check_list === "all") {
            const { data } = await getAgentNamespace();

            if (data["message"] === "No database matched") {
                // TODO: SHOW CHOOSE OTHER MEMORY MODAL
                dispatch(removeLastActiveFolderQuery());
                noDatabaseDis.onOpen();
                return;
            } else {
                params.check_list = data["message"];
            }
        }
        // SEarch Api
        fetchSearchReplyInFolderApi(activeFolderName, {
            search: search_query,
            character: searches.character.character,
            character_message: searches.character.character_message,
            history: getFolderChainHistory(),
            ...params,
            ...memoriesConfig,
            relation: "",
        });
        // Scroll to bottom
        scrollToBottom(containerRef);
    };

    function onNotes(notes: string, params: any) {
        // Clean search query, remove white spaces and trailing new line characters if exist
        const search_query = notes.trim();
        if (!search_query) {
            return;
        }
        /**
         * User's can only ask question at the time, in case the user is trying to ask a question, while another
         * is pending, reject
         */
        if (searches.isSearchLoading) {
            toast({
                title: "Please wait for first query to complete",
                position: "top",
                status: "info",
                duration: 2300,
            });
            return;
        }

        // Scroll to bottom
        scrollToBottom(containerRef);

        fetchNotesReplyInFolderApi(activeFolderName, {
            notes: notes,
            character: searches.character.character,
            character_message: searches.character.character_message,
            ...params,
        });
    }

    return (
        <div id="view">
            <ArinLayout>
                <div className="search-layout-wrapper">
                    <div className="left-pane">
                        <FoldersSidePanel onPromptSelected={onPromptSelect} />
                    </div>
                    <div className="main grid-column">
                        <div
                            className={`search-view ${
                                !isControlsFormVisible && "expand"
                            }`}
                        >
                            <div ref={containerRef} className="scroll-view">
                                {!isLoading && (
                                    <div className="items">
                                        {activeFolderFiles.map(
                                            (
                                                file: SearchModel,
                                                index: number,
                                            ) => {
                                                return (
                                                    <ResponseCard
                                                        data={file}
                                                        key={index}
                                                        mode={"folder"}
                                                        hideSaveAction={true}
                                                        scrollToBottomHandler={() =>
                                                            scrollToBottom(
                                                                containerRef,
                                                            )
                                                        }
                                                    />
                                                );
                                            },
                                        )}
                                    </div>
                                )}
                                {isLoading && <SkeletonLoader />}
                                {!isLoading && activeFolderFiles.length === 0 && (
                                    <div className="empty-state">
                                        <div className="empty-content">
                                            <img
                                                src="/empty_folder.png"
                                                className="icon"
                                            />
                                            <h1 className="title text-2xl font-bold">
                                                Choose folder
                                            </h1>
                                            <p className="text-lg text-slate-500">
                                                Choose a folder on the left pane
                                                to display here
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            className={`search-box-form ${
                                !isControlsFormVisible && "shrink"
                            } ${activeFolderName ? "visible" : "invisible"}`}
                        >
                            <SearchBox
                                onControlsFormToggle={(val) =>
                                    setIsControlsFormVisible(val)
                                }
                                defaultInputValue={selectedPrompt}
                                onSearch={onSearch}
                                onNotes={onNotes}
                            />
                        </div>
                    </div>
                    <div className="chaining-col grid-column">
                        <ChainingCard mode="folder" />
                    </div>
                </div>

                <Modal
                    size="sm"
                    isCentered={true}
                    isOpen={noDatabaseDis.isOpen}
                    onClose={noDatabaseDis.onClose}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <span className="text-lg">No Database Matched</span>
                        </ModalHeader>
                        <ModalBody>
                            <p className="text-sm">
                                Your agent could not find any database for your
                                search query. Please select a memory from the
                                search box below
                            </p>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                onClick={noDatabaseDis.onClose}
                                colorScheme="brand"
                                variant="outline"
                                size="sm"
                            >
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ArinLayout>
        </div>
    );
}
