import { useEffect, useState } from "react";
import "../../styles/personas/PersonalityTraitMCQ.component.scss";
import { Button, Spinner, useToast } from "@chakra-ui/react";
import { useHttpClient } from "../../utils/http.utils";
import { PersonaState } from "../../store/slices/persona.slice";
import { useSelector } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import hotToast from "react-hot-toast";

export default function PersonalityTraitMCQ({ onSaved }) {
    const {
        getPersonaPersonalityTraitQuestionsApi,
        savePersonalityTraitTestApi,
    } = useHttpClient();
    const toast = useToast();
    const personaState: PersonaState = useSelector(
        (state: any) => state.persona,
    );

    const [answeredQuestions, setAnsweredQuestions] = useState<
        { question: string; answer: string }[]
    >([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoadingQuestions, setIsLoadingQuestions] =
        useState<boolean>(false);

    const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);

    useEffect(() => {
        // if test has been done
        if (
            personaState.personaAttributesForm.personality_trait_status ===
            "completed"
        ) {
            setIsFormCompleted(true);
        } else if (personaState.personalityTraitQuestions.length === 0) {
            setIsLoadingQuestions(true);
            setIsFormCompleted(false);
            getPersonaPersonalityTraitQuestionsApi(
                personaState.personaAttributesForm.id as string,
            ).finally(() => setIsLoadingQuestions(false));
        }
    }, [personaState.personaAttributesForm, personaState.personalityTraitQuestions.length]);

    function getQuestionChoices(choices): string[] {
        return Object.keys(choices);
    }

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState<string>();

    const handleAnswerOptionClick = (question, answer, answerKey) => {
        setSelectedAnswer(answerKey);
        // If the question is already answered, replace it in the list
        const questionIndex = answeredQuestions.findIndex((q) => q.question === question);

        if (questionIndex > -1) {
            const newAnsweredQuestions = [...answeredQuestions];
            newAnsweredQuestions[questionIndex] = { question, answer };
            setAnsweredQuestions(newAnsweredQuestions);
            return;
        }
        setAnsweredQuestions([...answeredQuestions, { question, answer }]);
    };

    const navigateQuestions = (direction: string) => {
        // check if user answer the question and show warning message asking user to answer the questions
        if (!selectedAnswer && direction === "next") {
            hotToast.error("Please answer the question.");
            return;
        }

        if (direction === "next") {
            const nextQuestion = currentQuestion + 1;
            if (nextQuestion < personaState.personalityTraitQuestions.length) {
                setCurrentQuestion(nextQuestion);
                // reset answer
                setSelectedAnswer("");
            } else {
                // TODO: SUBMITE ACTION
                saveAnswers();
            }
        } else if (direction === "previous") {
            const previousQuestion = currentQuestion - 1;
            if (previousQuestion >= 0) {
                setCurrentQuestion(previousQuestion);
                /* Find the key of the answer from personaState.personalityTraitQuestions[previousQuestion].choices 
                that with the value that corresponds to the answer of the answeredQuestion 
                */
                const answeredQuestion = answeredQuestions.find((q) => q.question === personaState.personalityTraitQuestions[previousQuestion].question);
                if (answeredQuestion) {
                    const answerKey = Object.keys(personaState.personalityTraitQuestions[previousQuestion].choices).find(key => personaState.personalityTraitQuestions[previousQuestion].choices[key] === answeredQuestion.answer);
                    setSelectedAnswer(answerKey);
                }
                // reset answer
                // setSelectedAnswer("");
            }
        }
    };

    const saveAnswers = async () => {
        try {
            setIsSaving(true);

            await savePersonalityTraitTestApi(
                answeredQuestions,
                personaState.personaAttributesForm.id as string,
            );

            setIsFormCompleted(true);

            // emit saved functions
            if (typeof onSaved === "function") {
                onSaved();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsSaving(false);
        }
    };

    return isFormCompleted ? (
        <div className="h-[200px] flex flex-col gap-[6px] items-center justify-center">
            <BsFillCheckCircleFill className="text-green-500 text-5xl" />
            <p className="text-[15px] mt-4 text-gray-500">
                You have completed the Personality triat test
            </p>
        </div>
    ) : (
        <div>
            {personaState.personalityTraitQuestions.length > 0 ? (
                <div id="persona-mcq" className="app">
                    <div className="question-section">
                        <div className="question-count">
                            <span>Question {currentQuestion + 1}</span>/
                            {personaState.personalityTraitQuestions.length}
                        </div>
                        <div className="question-text">
                            {
                                personaState.personalityTraitQuestions[
                                    currentQuestion
                                ]?.question
                            }
                        </div>
                    </div>
                    <div className="answer-section">
                        {getQuestionChoices(
                            personaState.personalityTraitQuestions[
                                currentQuestion
                            ].choices,
                        ).map((key, index) => {
                            const answer =
                                personaState.personalityTraitQuestions[
                                    currentQuestion
                                ].choices[key];
                            return (
                                <button
                                    className={`answer-btn ${
                                        key === selectedAnswer ? "selected" : ""
                                    }`}
                                    key={`q-${index}`}
                                    onClick={() =>
                                        handleAnswerOptionClick(
                                            personaState
                                                .personalityTraitQuestions[
                                                currentQuestion
                                            ].question,
                                            answer,
                                            key,
                                        )
                                    }
                                >
                                    {key}.&nbsp;{answer}
                                </button>
                            );
                        })}
                    </div>
                    <div className="navigation pt-8 flex justify-between items-center">
                        <div className="">
                            {currentQuestion > 0 && (
                                <Button
                                    onClick={() =>
                                        navigateQuestions("previous")
                                    }
                                    variant="outline"
                                    isDisabled={isSaving}
                                >
                                    Previous
                                </Button>
                            )}
                        </div>
                        <div className="">
                            <Button
                                onClick={() => navigateQuestions("next")}
                                variant="outline"
                                colorScheme="blue"
                                isLoading={isSaving}
                            >
                                {currentQuestion ===
                                personaState.personalityTraitQuestions.length -
                                    1
                                    ? "Commit"
                                    : "Next"}
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {isLoadingQuestions ? (
                        <div className="h-[200px] flex flex-col gap-[6px] items-center justify-center">
                            <Spinner size="lg" />
                            <p className="text-[14px] text-slate-500">
                                Loading questions
                            </p>
                        </div>
                    ) : (
                        <div className="h-[200px] flex flex-col gap-[6px] items-center justify-center">
                            <p className="text-[15px] font-semibold text-gray-500">
                                No questions to display
                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
