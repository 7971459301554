import {
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import "../styles/ApprovedResponseCard.component.scss";
import { MdContentCopy } from "react-icons/md";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { BsCheckCircle } from "react-icons/bs";
import { SearchModel } from "../models/search.model";
import { formatDate } from "../utils/date.utils";
import { getAuthUser } from "../utils/auth.utils";
import React, { useRef, useState } from "react";
import { useHttpClient } from "../utils/http.utils";

interface ResponseCardProps {
    onSaveResponse?: () => void;
    data: SearchModel;
    hideSaveAction?: boolean;
    onApproveForTraining?: (search: SearchModel) => void;
}

export default function ApprovedResponseCard(props: ResponseCardProps) {
    const authUser = getAuthUser();
    const toast = useToast();
    const { editSearchResponseApi } = useHttpClient();
    const [suggestedAnswer, setSuggestedAnswer] = useState<string>();
    const [isEditing, setIsEditing] = useState(false);
    const [isSavingEdit, setIsSavingEdit] = useState(false);
    const suggestEditInputRef = useRef(null);
    /**
     * Emit a response to the parent container in the case thea user
     * tries to save the response..
     */
    const emitSaveResponse = () => {
        if (typeof props.onSaveResponse === "function") {
            props.onSaveResponse();
        }
    };
    /**
     * When the user clicks on approve
     */
    const emitApproval = () => {
        if (typeof props.onApproveForTraining === "function") {
            props.onApproveForTraining(props.data);
        }
    };

    /**
     *
     * Copy the reply to clipboard
     */
    const copyToClipBoard = (data: any) => {
        navigator.clipboard
            .writeText(data)
            .then(() => {
                toast({
                    title: "Copied",
                    duration: 1000,
                    status: "info",
                });
            })
            .catch(() => {
                toast({
                    title: "Can not copy to clipboard",
                    duration: 1000,
                    status: "error",
                });
            });
    };

    /**
     * ====================================================================================
     *          SUGGESTING EDIT FUNCTIONS
     * ====================================================================================
     *
     */
    const setEditMode = () => {
        setSuggestedAnswer(props.data.reply);
        setIsEditing(true);
        setTimeout(() => {
            // @ts-ignore
            suggestEditInputRef.current.focus();
        });
    };

    const clearEditMode = () => {
        setSuggestedAnswer("");
        setIsEditing(false);
    };

    const suggestEditApi = () => {
        if (!suggestedAnswer) {
            toast({
                title: "You cannot suggest an empty edit",
                status: "warning",
                duration: 45000,
            });
            return;
        }
        setIsSavingEdit(true);
        editSearchResponseApi(props.data, suggestedAnswer).finally(() => {
            // Close editor
            setIsEditing(false);
            setIsSavingEdit(false);
        });
    };

    return (
        <div className="approved-card px-6 py-6 mb-6">
            <div className="card-meta mb-4 flex justify-between">
                <div className="user">
                    {/*<img*/}
                    {/*    src={authUser.profile_picture || "/user_avatar.png"}*/}
                    {/*    alt="avatar"*/}
                    {/*    className="profile-image"*/}
                    {/*/>*/}
                    <p className="text-slate-500">
                        {formatDate(props.data.date_added as string)}
                    </p>
                </div>
                {!isEditing && (
                    <div className="actions">
                        <Button onClick={setEditMode} variant="ghost">
                            Suggest an Edit?
                        </Button>
                        {!props.hideSaveAction && (
                            <Button
                                onClick={emitApproval}
                                colorScheme="blue"
                                variant="ghost"
                            >
                                <BsCheckCircle className="icon" />
                            </Button>
                        )}
                        <Button
                            onClick={() => copyToClipBoard(props.data.reply)}
                            colorScheme="gray"
                            variant="ghost"
                        >
                            <MdContentCopy className="icon" />
                        </Button>
                    </div>
                )}
            </div>
            <div className="card-question flex justify-between px-4 py-3 mb-6">
                <div className="leading flex gap-2 items-center">
                    <img
                        src={authUser.profile_picture || "/user_avatar.png"}
                        alt="avatar"
                        className="profile-image"
                    />
                    <p className="name">{props.data.question}</p>
                </div>
                {!isEditing && (
                    <div className="text-slate-500">
                        {props.data.Sentiment === "1" && (
                            <p className="sentiment-message flex items-center gap-1.5 pr-4 text-slate-500">
                                <FiThumbsUp className="icon positive" />
                                <span>You rated "Amazing"</span>
                            </p>
                        )}
                        {props.data.Sentiment === "0" && (
                            <p className="sentiment-message flex items-center gap-1.5 pr-4 text-slate-500">
                                <FiThumbsDown className="icon negative" />
                                <span>You rated "Could be better"</span>
                            </p>
                        )}
                    </div>
                )}
            </div>
            {isEditing ? (
                <div className="edit-box">
                    <FormControl>
                        <FormLabel>
                            <strong>Edit Answer</strong>
                        </FormLabel>
                        <Textarea
                            ref={suggestEditInputRef}
                            className="text-box"
                            value={suggestedAnswer}
                            placeholder="Edit Answer"
                            onChange={(e) => setSuggestedAnswer(e.target.value)}
                        />
                    </FormControl>
                </div>
            ) : (
                <div className="card-response">{props.data.reply}</div>
            )}
            {/*    if  isEditing*/}
            {isEditing && (
                <div className="flex justify-end mt-3">
                    <div className="card-footer-actions">
                        <ButtonGroup>
                            <Button
                                isDisabled={isSavingEdit}
                                onClick={clearEditMode}
                                colorScheme="gray"
                                size="sm"
                                variant="outline"
                            >
                                Cancel
                            </Button>
                            <Button
                                isLoading={isSavingEdit}
                                loadingText="Saving.."
                                onClick={suggestEditApi}
                                colorScheme="green"
                                size="sm"
                                variant="solid"
                            >
                                Save
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            )}

            {/*<div className="card-expand text-right pt-4 px-8">*/}
            {/*    <Button colorScheme="blue" size="sm" variant="link">*/}
            {/*        Expand...*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    );
}
