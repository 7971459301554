import ArinLayout from "../components/ArinLayout";
import ResponseCard, { ArinCharacter } from "../components/ResponseCard";
import SearchBox from "../components/SearchBox";
import "../styles/FindAnything.component.scss";
import React, { useEffect, useRef, useState } from "react";
import SaveResponseForm from "../components/SaveResponseForm";
import { useHttpClient } from "../utils/http.utils";
import { SearchModel } from "../models/search.model";
import { useDispatch, useSelector } from "react-redux";
import { addSearchQuery, SearchState, setFindPersona, setSearchLoadingStatus } from "../store/slices/searches.slice";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Tabs, TabList, Tab, useToast } from "@chakra-ui/react";
import { setMetaTags } from "../utils/seo.utils";
import { RxReload } from "react-icons/rx";
import ChainingCard from "../components/ChainingCard";
import { scrollToBottom } from "../utils/dom.utils";
import { getAuthUser } from "../utils/auth.utils";
import PromptLibrary from "../components/PromptLibrary";
import { useNavigate } from "react-router-dom";
import { AccountState } from "../store/slices/account.slice";
import { newUTCDate } from "../utils/date.utils";
import MemoriesPanel from "../components/memories/MemoriesPanel";
import { useMemoriesConfig } from "../utils/memories-config.utils";
import useSearches from "../utils/search/searches.utils";
import { ProfileAvatar } from "../components/Avatar";
import { PersonaState } from "../store/slices/persona.slice";
import Select from "react-select";
import { useGenericModal } from "../components/modals/GenericModalContext";
import { PersonaModel } from "../models/persona.model";

export default function FindAnythingView() {
    // Redux selectors
    const searches: SearchState = useSelector((state) => (state as any).searches);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const memoriesConfig = useMemoriesConfig();

    const authUser = getAuthUser();
    const dispatch = useDispatch();
    const toast = useToast();
    const { fetchCharacterApi, fetchSearchReplyApi, fetchNotesReplyApi } = useHttpClient();
    const { getFindChainHistory } = useSearches();

    // local state
    const noDatabaseDis = useDisclosure();
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const containerRef = useRef(null);
    const [selectedSearch, setSelectedSearch] = useState<SearchModel>();
    const [selectedPrompt, setSelectedPrompt] = useState<string>();
    const apiKeysDisclosure = useDisclosure();
    const navigate = useNavigate();
    const [isControlsFormVisible, setIsControlsFormVisible] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const promptContainerRef = useRef(null);
    const memoriesContainerRef = useRef(null);
    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const [personaOptions, setPersonaOptions] = useState<{ label: string; value: string }[]>([]);

    useEffect(() => {
        // Set meta tags
        setMetaTags({ title: "Ask Anything" });
    }, []);

    useEffect(() => {
        if (authUser.token && !accountState.isAccountLoading && !accountState.apiKeys?.openAi?.apiKey && !accountState?.apiKeys?.pinecone?.key) {
            apiKeysDisclosure.onOpen();
        } else {
            apiKeysDisclosure.onClose();
        }
    }, [accountState.isAccountLoading, accountState.accountExists]);

    /*useEffect(() => {
        if (!searches.character.character) {
            // check for character in session storage
            // const character = window.sessionStorage.getItem('character');
            // if (!!character) {
            //   setCharacter(JSON.parse(character))
            //   setIsLoadingCharacter(false);
            //   return;
            // }
            // In case user has not yet fetched a character, fetch a character from API
            fetchCharacterApi();
        }
    }, [searches.character.character]);*/

    /**
     * When the user attempts to save a response...
     */
    const onResponseSave = (data: SearchModel) => {
        setSelectedSearch(data);
        setIsSaveModalOpen(true);
    };

    // clear suggest modal open state
    const clearSaveModalOpenState = () => {
        setIsSaveModalOpen(false);
    };
    /**
     *  This happens when the user hits refresh on character loading error
     */
    const onCharacterRefresh = () => {
        fetchCharacterApi();
    };

    // on Search
    const onSearch = async (query: string, params: any) => {
        // Clean search query, remove white spaces and trailing new line characters if exist
        const search_query = query.trim();
        if (!search_query) {
            return;
        }
        /**
         * User's can only ask question at the time, in case the user is trying to ask a question, while another is pending, reject
         */
        if (searches.isSearchLoading) {
            toast({
                title: "Please wait for first query to complete",
                position: "top",
                status: "info",
                duration: 2300,
            });
            return;
        }
        // Clear promt
        setSelectedPrompt("");
        // set loading status...
        dispatch(setSearchLoadingStatus(true));

        // set state to typing and add search to state
        dispatch(
            addSearchQuery({
                isFinishedTyping: false,
                isLoading: true,
                isExpanded: false,
                question: search_query,
                persona_id: searches.persona?.id,
                character: searches.persona?.first_name + " " + searches.persona?.last_name,
                character_message: "",
                reply: "",
            }),
        );

        // Scroll to bottom
        scrollToBottom(containerRef);
        /**
         *  Send search Query
         */
        fetchSearchReplyApi({
            search: search_query,
            persona_id: searches.persona?.id,
            character: searches.persona?.first_name + " " + searches.persona?.last_name,
            character_message: "",
            history: getFindChainHistory(),
            ...params,
            ...memoriesConfig,
        });
    };

    function onPromptSelect(prompt: string) {
        setSelectedPrompt(prompt);
    }

    function onNotes(notes: string, params: any) {
        // Clean search query, remove white spaces and trailing new line characters if exist
        const search_query = notes.trim();
        if (!search_query) {
            return;
        }
        /**
         * User's can only ask question at the time, in case the user is trying to ask a question, while another
         * is pending, reject
         */
        if (searches.isSearchLoading) {
            toast({
                title: "Please wait for first query to complete",
                position: "top",
                status: "info",
                duration: 2300,
            });
            return;
        }
        // Clear prompt
        setSelectedPrompt("");
        // set loading status...
        dispatch(setSearchLoadingStatus(true));

        // set state to typing and add search to state
        dispatch(
            addSearchQuery({
                isFinishedTyping: false,
                isLoading: true,
                question: "Thoughts",
                type: "notes",
                date_added: newUTCDate(),
                persona_id: searches.persona?.id,
                character: searches.persona?.first_name + " " + searches.persona?.last_name,
                character_message: "",
                reply: "",
            }),
        );

        // Scroll to bottom
        scrollToBottom(containerRef);

        fetchNotesReplyApi({
            notes: notes,
            ...params,
        });
    }

    const onClearSearchInput = () => {
        setSelectedPrompt("");
    };
    /**
     * ===  PERSONA FUNCTIONS ====
     */
    useEffect(() => {
        const generalAIEntity: PersonaModel = personaState.generalAIEntity;
        const generalEntityOptions: any = [];

        if (generalAIEntity.id) {
            generalEntityOptions.push({
                label: generalAIEntity.first_name + " " + generalAIEntity.last_name,
                value: generalAIEntity.id
            });
            dispatch(setFindPersona(generalAIEntity));
        }
        setPersonaOptions(generalEntityOptions);
    }, [personaState.personas.length]);

    const onPersonaSelect = (option) => {
        const persona = personaState.personas.find((p) => p.id === option["value"]);
        if (persona) {
            dispatch(setFindPersona(persona));
        }
    };

    return (
        <div id="view">
            <ArinLayout>
                <div className="search-layout-wrapper">
                    <div id="left-panel" className="left-panel grid-column pl-2 pb-3">
                        <div className="switch-header">
                            <Tabs variant="solid-rounded" size="sm" colorScheme="brand" onChange={(index) => setTabIndex(index)}>
                                <TabList>
                                    <Tab>Memories</Tab>
                                    <Tab>Prompt</Tab>
                                </TabList>
                            </Tabs>
                        </div>
                        <div className="switch-body">
                            {tabIndex === 0 && (
                                <div ref={memoriesContainerRef} className="h-full">
                                    <MemoriesPanel />
                                </div>
                            )}
                            {tabIndex === 1 && (
                                <div ref={promptContainerRef} className="h-full">
                                    <PromptLibrary onSelect={onPromptSelect} />
                                </div>
                            )}
                            {/*<TransitionGroup>*/}
                            {/*    <CSSTransition*/}
                            {/*        nodeRef={promptContainerRef}*/}
                            {/*        in={tabIndex === 0}*/}
                            {/*        timeout={200}*/}
                            {/*        classNames="left-switch-node"*/}
                            {/*    >*/}
                            {/*        */}
                            {/*    </CSSTransition>*/}

                            {/*    <CSSTransition nodeRef={memoriesContainerRef} in={tabIndex === 0} timeout={200} classNames="left-switch-node">*/}
                            {/*       */}
                            {/*    </CSSTransition>*/}
                            {/*</TransitionGroup>*/}
                        </div>
                    </div>
                    <div className="main grid-column">
                        <div className="toolbar px-8 mb-0 flex gap-2 items-center">
                            <div className="flex">
                                <div className="twin-avatars" style={{ gap: "" }}>
                                    <img src={"/user_avatar.png"} alt="avatar" className="profile-image" />
                                    <ProfileAvatar size="35px" />
                                </div>
                            </div>
                            {/* <p className="arin-name text">
                                    {searches.character.character} &{" "}
                                    {authUser.fullname || authUser.username}
                                </p> */}
                            <div className="flex gap-2 items-center h-full">
                                <Select placeholder="Select Persona" options={personaOptions} isDisabled={false} className="arin-react-select-container persona-select" classNamePrefix="arin-react-select" onChange={onPersonaSelect} value={personaOptions[0]} />
                                {searches.persona?.id && <p className="text-slate-500 text-sm">with {authUser.fullname || authUser.username}</p>}
                            </div>
                        </div>
                        <div className={`search-view with-toolbar ${!isControlsFormVisible && "expand"}`}>
                            <div ref={containerRef} className="scroll-view" id="scrollElement">
                                <div className="responses">
                                    {!personaState.loading && personaState.loadingError && <CharacterErrorCard onRefresh={onCharacterRefresh} />}
                                    {searches.persona?.id && <ArinCharacter loading={personaState.loading} name={searches.persona?.first_name as string} message={`Greetings! My name is ${searches.persona?.first_name + " " + searches.persona?.last_name}. I am an ${searches.persona?.type}, an artificially intelligent being.`} />}
                                    {searches.replies.map((item: SearchModel, index: number) => {
                                        return <ResponseCard key={index} data={item} onSaveResponse={onResponseSave} scrollToBottomHandler={() => scrollToBottom(containerRef)} />;
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={`search-box-form ${!isControlsFormVisible && "shrink"}`}>
                            <SearchBox onControlsFormToggle={(val) => setIsControlsFormVisible(val)} defaultInputValue={selectedPrompt} onClearInput={onClearSearchInput} onSearch={onSearch} onNotes={onNotes} />
                        </div>
                    </div>
                    <div className="chaining-col grid-column">
                        <ChainingCard />
                    </div>
                </div>
            </ArinLayout>

            {/* Save modals */}
            <SaveResponseForm isOpen={isSaveModalOpen} data={selectedSearch as SearchModel} onClose={clearSaveModalOpenState} />

            <Modal size="sm" closeOnOverlayClick={false} isCentered={true} isOpen={apiKeysDisclosure.isOpen} onClose={apiKeysDisclosure.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <span className="text-lg">Set Your Account</span>
                    </ModalHeader>
                    <ModalBody>
                        <p className="text-sm">To get started, you need to configure your OpenAI and Pinecone keys. These keys allow you to access the powerful features of NeoAI.</p>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={() => navigate("/account/brains")} colorScheme="brand" size="sm">
                            Set up account
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/*No database matched modal*/}
            <Modal size="sm" isCentered={true} isOpen={noDatabaseDis.isOpen} onClose={noDatabaseDis.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <span className="text-lg">No Database Matched</span>
                    </ModalHeader>
                    <ModalBody>
                        <p className="text-sm">Your agent could not find any database for your search query. Please select a memory from the search box below</p>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={noDatabaseDis.onClose} colorScheme="brand" variant="outline" size="sm">
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}

export function CharacterErrorCard({ onRefresh }: { onRefresh: () => void }) {
    return (
        <div className="error-card p-4 bg-white">
            <img src="/chat_error.png" />
            <h2 className="mt-6 text-xl font-bold">Error unable to load your AI character</h2>
            <p className="text-slate-500 mb-4">We are unable to fetch your characters, If the problem persist:</p>
            <ul className="tips-list text-slate-500 mb-6">
                <li>Refresh this page</li>
                <li>Check your network connection</li>
            </ul>
            <Button onClick={onRefresh} variant="outline" colorScheme="blue">
                <RxReload />
                &nbsp;Reload
            </Button>
        </div>
    );
}
