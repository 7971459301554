import { IoDocumentAttachOutline, IoRefresh } from "react-icons/io5";
import { formatFileSize } from "../utils/number.utils";
import {
    Button,
    Progress,
    Tab,
    TabList,
    Tabs,
    useToast,
} from "@chakra-ui/react";
import {
    AiOutlineCloudUpload,
    AiOutlineDelete,
    AiOutlineFileAdd,
} from "react-icons/ai";
import React, {
    ChangeEvent,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { UploadFileMeta } from "../models/upload.model";
import "../styles/FilePickerUpload.component.scss";
import { BsCheckCircleFill, BsGlobe } from "react-icons/bs";
import { HiOutlineVideoCamera } from "react-icons/hi";
import UploadLInksForm from "./UploadLInksForm";

interface FilePickerProps {
    linksUploadProgress: number;
    onChange?: (
        file: File | undefined,
        meta: UploadFileMeta | undefined,
    ) => void;
    onFileNameChange?: (fileName: string) => void;
    progress?: number;
}

const UploadDataForm = forwardRef((props: FilePickerProps, ref) => {
    const inputFileRef = useRef(null);
    const toast = useToast();
    const [selectedFile, setSelectedFile] = useState<UploadFileMeta>();
    const [file, setFile] = useState<File>();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [isUploading, setIsUploading] = useState(false);
    const fileNameInput = useRef(null);
    const linksInputRef = useRef(null);
    const [uploaded, setUploaded] = useState(false);

    useEffect(() => {
        setIsUploading(
            Number(props.progress) > 0 && Number(props.progress) < 100,
        );
        if (Math.ceil(Number(props.progress)) === 100) {
            setUploaded(true);
        } else {
            setUploaded(false);
        }
    }, [props]);

    const openFileSelector = () => {
        // @ts-ignore
        inputFileRef.current?.click();
    };

    const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        let _file = (event.target.files as FileList)[0];
        if (!_file) {
            return;
        }
        // Validate file extensions
        const lastIndex = _file.name.lastIndexOf("."),
            extension = _file.name.substring(lastIndex + 1);
        let allowedTypes = [
            "pdf",
            "docx",
            "csv",
            "xls",
            "xlsx",
            "txt",
            "jpg",
            "mp3",
            "mp4",
        ];

        if (!allowedTypes.includes(extension)) {
            toast({
                status: "error",
                title: "Invalid file type",
                description:
                    "Please upload a valid file type: " +
                    allowedTypes.join(","),
                duration: 2000,
            });
            // @ts-ignore
            inputFileRef.current.value = "";
            return;
        }

        setFile(_file);

        const fileMeta: UploadFileMeta = {
            lastModified: _file.lastModified,
            webkitRelativePath: _file.webkitRelativePath,
            size: _file.size,
            type: _file.type,
        };

        let fileName = _file.name.substring(0, lastIndex);
        fileMeta.name = fileName;
        fileMeta.extensionName = extension;
        fileMeta.isStructured = ["xls", "xlsx", "csv"].includes(extension);
        // save file meta data
        setSelectedFile(fileMeta);
        // Emit changes to parent
        emitChanges(_file, fileMeta);
        // set file name input initial value, use  setTimeout to wait for it to initialize
        setTimeout(() => {
            // @ts-ignore
            fileNameInput.current.innerText = fileName;
        }, 200);
    };

    const onFileNameChange = (e: any) => {
        const _selectedFile = { ...selectedFile };
        (_selectedFile as UploadFileMeta).name = e.currentTarget.textContent;
        setSelectedFile(_selectedFile);
        // Emit changes to parent
        if (typeof props.onFileNameChange === "function") {
            props.onFileNameChange(e.currentTarget.textContent);
        }
    };

    const removeSelectedFile = () => {
        // @ts-ignore
        inputFileRef.current.value = null;
        setSelectedFile(undefined);
        // Emit changes to parent
        emitChanges(undefined, undefined);
    };

    const emitChanges = (
        file: File | undefined,
        meta: UploadFileMeta | undefined,
    ) => {
        // Emit data to parent
        if (typeof props.onChange === "function") {
            props.onChange(file, meta);
        }
    };

    useImperativeHandle(ref, () => ({
        clearFileInputValue() {
            // @ts-ignore
            inputFileRef.current.value = null;
            setSelectedFile(undefined);
            setUploaded(false);
        },
        validateLinksForm() {
            if (!linksInputRef.current) {
                toast({
                    status: "warning",
                    title: "Please input your data",
                    description:
                        "Please upload file containing data or links, or specify links",
                    duration: 2000,
                });
                return false;
            }
            // @ts-ignore
            return linksInputRef.current.validateForm();
        },
        resetLinksForm() {
            // @ts-ignore
            return linksInputRef.current.resetForm();
        },
        getLinksFormValues() {
            // @ts-ignore
            const linksFormVal = linksInputRef.current.getValues();
            let type;
            switch (tabIndex) {
                case 0:
                    type = "file";
                    break;
                case 1:
                    type = "video";
                    break;
                case 2:
                    type = "web";
                    break;
            }
            return { ...linksFormVal, type };
        },
    }));

    return (
        <div className="mt-8">
            <Tabs
                variant="line"
                colorScheme="blue"
                onChange={(index) => setTabIndex(index)}
            >
                <TabList>
                    <Tab>
                        <AiOutlineFileAdd className="text-2xl" />
                        &nbsp;File
                    </Tab>
                    <Tab>
                        <HiOutlineVideoCamera className="text-2xl" />
                        &nbsp;Video
                    </Tab>
                    <Tab>
                        <BsGlobe className="text-lg" />
                        &nbsp;Web
                    </Tab>
                </TabList>
            </Tabs>
            {tabIndex === 0 ? (
                <div className="file-picker pt-6">
                    {selectedFile ? (
                        <div className="file-section">
                            <div className="selected-file pl-4 pr-2 py-3">
                                <div className="info">
                                    <div className="icon-section">
                                        <IoDocumentAttachOutline className="icon" />
                                    </div>
                                    <div className="meta-data">
                                        <div className="file-name">
                                            <span
                                                ref={fileNameInput}
                                                role="textbox"
                                                contentEditable={true}
                                                className="name"
                                                onInput={onFileNameChange}
                                            />
                                            <span>
                                                .{selectedFile?.extensionName}
                                            </span>
                                        </div>
                                        <p className="text-slate-500">
                                            {formatFileSize(
                                                selectedFile?.size as number,
                                                0,
                                            )}
                                        </p>
                                    </div>
                                </div>
                                {!isUploading ? (
                                    <div className="actions flex items-center">
                                        <Button
                                            onClick={openFileSelector}
                                            pl={0}
                                            pr={0}
                                            variant="ghost"
                                        >
                                            <IoRefresh className="icons" />
                                        </Button>
                                        {!uploaded ? (
                                            <Button
                                                onClick={removeSelectedFile}
                                                pl={0}
                                                pr={0}
                                                variant="ghost"
                                                colorScheme="red"
                                            >
                                                <AiOutlineDelete className="icons" />
                                            </Button>
                                        ) : (
                                            <div className="px-2">
                                                <BsCheckCircleFill
                                                    className="icons"
                                                    color="#3caf50"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="progress-section">
                                        <Progress
                                            value={props.progress}
                                            size="xs"
                                            colorScheme="blue"
                                        />
                                    </div>
                                )}
                            </div>
                            {!uploaded && (
                                <p className="text-slate-600 text-sm font-medium mt-2 px-2">
                                    You can change the file name by typing the
                                    field above
                                </p>
                            )}
                        </div>
                    ) : (
                        <div
                            onClick={openFileSelector}
                            className="upload-form py-2"
                        >
                            <p className="text-xl mb-2">
                                <AiOutlineCloudUpload className="icon" />
                            </p>
                            <p className="label font-semibold text-sm">
                                Click to select file (.pdf, .docx, .csv, .xls,
                                .xlsx, .txt, .jpg, .mp3, .mp4)
                            </p>
                        </div>
                    )}
                </div>
            ) : (
                <UploadLInksForm
                    ref={linksInputRef}
                    progress={props.linksUploadProgress}
                    type={tabIndex === 1 ? "video" : "web"}
                />
            )}
            {/*    input file*/}
            <input
                onChange={onFileSelect}
                ref={inputFileRef}
                type="file"
                accept=".pdf,.docx,.csv,.xls,.xlsx,.txt,.jpg,.mp3,.mp4"
                style={{ display: "none" }}
            />
        </div>
    );
});

export default UploadDataForm;
