import { AiOutlineClose } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";

interface NeoModalProps {
    isOpen: boolean;
    textLength?: "long" | "short";
    textContainerWidth?: string;
    children: JSX.Element | JSX.Element[];
    backgroundImageUrl?: string;
    rightImageUrl?: string;
    onClose?: () => void;
}

export function NeoSheetModalTitle({ children }) {
    return <h1 className="neo-modal-title">{children}</h1>;
}

export function NeoSheetModalDescription({ children }) {
    return <p className="neo-modal-description">{children}</p>;
}

export function NeoSheetModalFooter({ children }) {
    return <div className="neo-contents-footer">{children}</div>;
}

export default function NeoSheetModal(props: NeoModalProps) {
    const nodeRef = useRef(null);
    const coverImageRef = useRef(null);
    const rightImageRef = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        setModalVisible(props.isOpen);
    }, [props.isOpen]);

    const close = () => {
        setModalVisible(false);
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const onImageLoad = () => {
        const coverImage = coverImageRef.current as unknown as HTMLElement;
        // wait for image to complete loading before displaying
        coverImage?.classList.add("scale-up");
    };

    const onRightImageLoad = () => {
        const rightImage = rightImageRef.current as unknown as HTMLElement;
        // wait for image to complete loading before displaying
        rightImage?.classList.add("scale-up");
    };

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={modalVisible}
            timeout={200}
            classNames="neo-modal-node"
        >
            {modalVisible ? (
                <div ref={nodeRef} className="neo-modal-container">
                    <div className="overlay neo-sheet">
                        <div className="neo-modal-body neo-modal-sheet">
                            <img
                                ref={coverImageRef}
                                className="neo-modal-image-cover"
                                onLoad={onImageLoad}
                                src={props.backgroundImageUrl}
                                alt=""
                            />

                            <div className="modal-sheet-overlay">
                                <button
                                    onClick={close}
                                    className="neo-modal-close"
                                >
                                    <AiOutlineClose />
                                </button>
                                <div className="neo-modal-contents">
                                    {props.children}
                                </div>
                            </div>
                            {props.rightImageUrl && (
                                <img
                                    ref={rightImageRef}
                                    src={props.rightImageUrl}
                                    onLoad={onRightImageLoad}
                                    alt=""
                                    className="sheet-image"
                                />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </CSSTransition>
    );
}
