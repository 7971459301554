import { getAuthUser } from "../utils/auth.utils";

interface ProfileAvatarProps {
    src?: string;
    size?: string;
}

export function ProfileAvatar({ src, size = "35px" }: ProfileAvatarProps) {

    const onImageLoadError = (e) => {
        e.target.src = "/user_avatar.png";
    };
    return (
        <img
            src={src || "/user_avatar.png"}
            alt="avatar"
            style={{ height: size, width: size }}
            className="rounded-full"
            onError={onImageLoadError}
        />
    );
}
