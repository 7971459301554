import React, { useEffect } from "react";
import { Button, ButtonGroup, useToast } from "@chakra-ui/react";
import { ProfileAvatar } from "../Avatar";
import { useSelector } from "react-redux";
import { PersonaState, PersonaFormState } from "../../store/slices/persona.slice";
import { PersonaModel } from "../../models/persona.model";
import { useHttpClient } from "../../utils/http.utils";
import { DeleteConfirmation } from "../utils/ConfirmationModals";

interface PersonaHeaderProps {
    authUser: any;
    onEdit?: () => void;
    onPortalClick?: (id: string) => void;
    onKill?: (id: string) => void;
}

const PersonaHeader = ({ authUser, onPortalClick, onKill }: PersonaHeaderProps) => {
    const { updatePersonaApi, deletePersonaApi } = useHttpClient();
    const toast = useToast();
    const [isChangingStatus, setIsChangingStatus] = React.useState(false);
    const [isKillingPersona, setIsKillingPersona] = React.useState(false);
    // selected persona
    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const personaFormState: PersonaFormState = personaState.personaAttributesForm;

    const handleKillPersona = (id: string) => {
        DeleteConfirmation({
            title: "Kill Persona",
            message: "Are you sure you want to kill this persona? Confirm this action by typing 'DELETE' in the input below.",
        }).then((isConfirmed) => {
            if (isConfirmed) {
                deletePersonaApi(id).then(() => {
                    if (typeof onKill === "function") {
                        onKill(id);
                    }
                });
            }
        });
    };

    const handleClickPortal = (id: string) => {
        if (typeof onPortalClick === "function") {
            onPortalClick(id);
        }
    };

    // useEffect(() => {
    //     console.log(personaFormState.status);
    // }, [personaFormState]);

    const changePersonaStatus = () => {
        setIsChangingStatus(true);
        updatePersonaApi({
            id: personaFormState.id,
            status: personaFormState.status === "active" ? "draft" : "active",
        })
            .then(() => {
                toast({
                    title: "Persona status updated",
                });
            })
            .finally(() => {
                setIsChangingStatus(false);
            });
    };

    return (
        <div style={{ margin: 0 }} className="toolbar px-8 mb-0 flex gap-2 justify-between items-center">
            <div className="flex items-center">
                <div className="flex">
                    <div className="avatar">
                        <ProfileAvatar size="35px" />
                    </div>
                </div>
                <div className=" pl-3">
                    <p className="text  text-lg capitalize ">
                        {personaFormState.first_name + " " + personaFormState.last_name}
                        &nbsp;
                        <span className=" text-xs font-light">{personaFormState.type}</span>
                    </p>
                </div>
            </div>
            <div className="actions">
                {personaFormState.is_general_entity !== "yes" && (
                    <ButtonGroup>
                        <Button onClick={() => handleClickPortal(personaFormState.id as string)} size="sm" variant="outline">
                            Portal
                        </Button>

                        <Button size="sm" isLoading={isChangingStatus} loadingText="Changing status.." colorScheme={personaFormState.status === "active" ? "brand" : "gray"} variant={personaFormState.status === "active" ? "solid" : "outline"} onClick={changePersonaStatus}>
                            {personaFormState.status === "active" ? "Deactivate" : "Activate"}
                        </Button>

                        <Button onClick={() => handleKillPersona(personaFormState.id as string)} size="sm" colorScheme="red" variant="outline" isLoading={isKillingPersona} loadingText="Killing persona..">
                            Kill
                        </Button>
                    </ButtonGroup>
                )}
            </div>
        </div>
    );
};

export default PersonaHeader;
