import NeoBannerModal, {
    NeoBannerModalContent,
    NeoBannerModalParagraph,
} from "./NeoBannerModal";
import { useEffect, useState } from "react";

interface PrivacyModalProps {
    isOpen: boolean;
    onClose?: () => void;
}

export default function PrivacyPolicyModal(props: PrivacyModalProps) {
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        setModalVisible(props.isOpen);
    }, [props.isOpen]);

    const close = () => {
        setModalVisible(false);
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    return (
        <NeoBannerModal
            isOpen={modalVisible}
            title="Terms of Use"
            subtitle="Neo AI’s terms in plain English. Last updated June 2023."
            onClose={close}
        >
            <NeoBannerModalContent title="1. Acceptance of Terms:">
                <NeoBannerModalParagraph>
                    1.1 This Agreement presents the complete Terms and
                    Conditions (the “Terms”) that apply to your use of services
                    available at “neoworlder.ai” and "Neo AI" (collectively, the
                    "Services") operated by NeoWorlder, LLC (referred to as
                    "we," "us," or "our" herein). By accessing or using our
                    Services, you confirm that you have read, understood, and
                    agreed to be bound by these Terms, including any future
                    modifications.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    1.2 If you are entering into this agreement on behalf of a
                    company or other legal entity, you represent that you are
                    authorized to bind such entity to these Terms, in which case
                    “you” or “your” will refer to such entity. If you do not
                    agree to be bound by these Terms, you are not authorized to
                    use the Services.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="2. Definition of Services:">
                <NeoBannerModalParagraph>
                    The Services offered by our platform encompass a broad
                    spectrum of functionalities centered on the construction and
                    interaction with artificially intelligent entities, digital
                    doppelgangers, and personas. These functionalities assist in
                    diverse tasks and augment capabilities across various
                    domains. Our offerings are not restricted to, but
                    prominently feature, the utilization of cutting-edge
                    artificial intelligence technology to aid in memory storage,
                    efficient information retrieval, development of AI-based
                    apps, and the fostering of business collaborations with AI
                    systems. Additional services also include the option to
                    copyright your creative works, handle APIs to unveil
                    personas, engage in an active marketplace, participate in
                    immersive gaming experiences, and the opportunity to earn
                    valuable rewards among a myriad of other exciting features.
                    Please note, all interactions and use of our services are
                    subject to the terms and conditions outlined in our user
                    agreement.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="3. Registration and Account Security:">
                <NeoBannerModalParagraph>
                    As a user of our Services, you commit to furnishing precise
                    and comprehensive details during the registration process
                    and throughout the duration of your use of our Services. The
                    onus is solely on you to ensure the accuracy of these
                    details. Please note, we employ a 'Single Sign-On' feature
                    provided by NeoWorlder Inc., which implies that upon
                    registering on this platform, you are concurrently signing
                    up for all products under the NeoWorlder Inc.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="4. Acceptance of Terms:">
                <NeoBannerModalParagraph>
                    In using our Services, you expressly consent to refrain from
                    the following actions:
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (a) Utilizing the Services for any unlawful objectives or
                    illicit activities. This includes, but is not limited to,
                    the transmission, dissemination, or uploading of material
                    that infringes on the rights of others or solicitation of
                    illegal activities.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (b) Undertaking any actions with the intent to illicitly
                    gain access to the Service, its servers, or any associated
                    systems or networks. This includes, but is not limited to,
                    attempts to breach security, authentication measures, or any
                    other protection system in place without proper
                    authorization.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (c) Propagating or distributing malicious computer code,
                    viruses, or any other potentially harmful software. This
                    involves, but is not limited to, uploading, posting, or
                    otherwise transmitting any material that contains software
                    viruses or any other computer code, files, or programs
                    designed to interrupt, destroy, or limit the functionality
                    of any computer software or hardware or telecommunications
                    equipment.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (d) Interfering with, disrupting, or inhibiting other users'
                    enjoyment of the Services. This comprises, but is not
                    limited to, any activity that may hinder or disrupt the
                    Services or the servers or networks connected to the
                    Services, or disobey any requirements, procedures, policies,
                    or regulations of networks connected to the Services.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (e) Engaging in any reverse engineering, decompiling, or
                    disassembling of the Services, its source codes, underlying
                    ideas, or algorithms. This includes any attempts to
                    duplicate, replicate, modify, or create derivative works
                    from the Services or any portion thereof.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (f) Conducting any data scraping, harvesting, data mining,
                    or similar data gathering and extraction methods on the
                    Services or any part of it, or using bots or other automated
                    methods to access, collect data, and interact with the
                    Services.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (g) Using any unauthorized automated systems or software to
                    extract data from the Services for commercial purposes
                    ('screen scraping') is strictly prohibited unless you have
                    entered into a written license agreement with us that
                    expressly permits such activity.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (h) Infringe or misappropriate the rights of any third
                    party, including without limitation, copyright, trademark,
                    trade secret, confidentiality, contract, patent, rights of
                    privacy or publicity, or any other proprietary or legal
                    right.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (i) Use the Services to defame, harass, abuse, threaten or
                    defraud other users, or collect or attempt to collect
                    personal information about other users or third parties
                    without their consent.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (j) Use the Services for any commercial purpose other than
                    as expressly allowed under these Terms without our express
                    prior written consent.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (k) Access or attempt to access the Services by any means
                    other than the interface we provided or authorized.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (l) Use the Services in a way that could overburden,
                    disable, damage, or impair the Services or interfere with
                    any other party’s use and enjoyment of the Services.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (m)Impersonate any person or entity, falsely claim an
                    affiliation with any person or entity, or access the
                    accounts of others without permission.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (n) Share or transfer any user identification, password, or
                    any other piece of information used as part of our security
                    procedures.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (o) Solicit login information or access an account belonging
                    to someone else, or otherwise advocate or induce
                    infringement of intellectual property rights.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="5. Breach and Termination ">
                <NeoBannerModalParagraph>
                    5.1 We reserve the right to suspend or terminate your access
                    and use of the Neo AI platform by any user, without notice,
                    in case of a material breach of the terms and conditions
                    stipulated herein, including the actions mentioned in clause
                    4.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    5.2 Additional material breaches includes, but is not
                    limited to: Any unauthorized use of the platform or services
                    offered through the platform; Providing false or misleading
                    information during the sign-up process or during the use of
                    services; Breaching any applicable laws or regulations;
                    Disrupting or attempting to disrupt any of our services,
                    including but not limited to using or providing software
                    intended to damage or interfere with the proper working of
                    the platform; Ignoring notices regarding copyright
                    infringement or intellectual property rights; Violating our
                    privacy policy, including but not limited to any
                    unauthorized use, sharing, or dissemination of personal
                    information of any user; and Non-payment of amounts due to
                    us for our services.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    5.3 In the event of such a termination, all content related
                    to the user may be immediately deleted from our servers.
                    However, we are not obligated to return any content upon
                    such termination.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    5.4 Upon termination of your access and use to the Neo AI
                    platform, your right to use such services will immediately
                    cease. You will not be entitled to any refund of any amounts
                    billed or paid prior to such termination.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    5.5 Nothing herein shall limit other remedies NeoWorlder,
                    LLC may have at law or in equity. NeoWorlder, LLC reserves
                    the right to take any legal action it deems necessary or
                    appropriate against any user who breaches these terms or any
                    laws, and you consent to personal jurisdiction and venue in
                    Wyoming for the purpose of addressing any disputes relating
                    to such breach.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    5.6 This termination clause is without prejudice to any
                    contractual rights or obligations which are either expressly
                    stated to survive termination or by their nature are
                    intended to survive termination.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="6. Intellectual Property Rights: ">
                <NeoBannerModalParagraph>
                    6.1 All elements of the Services, encompassing but not
                    limited to all forms of text, graphics, logos, designs,
                    photographs, button icons, images, audio/video clips,
                    digital downloads, data compilations, software, and their
                    arrangement (collectively known as the "Content"), fall
                    under our ownership, control, or are licensed to us. They
                    enjoy protection under the legal framework of domestic and
                    international intellectual property laws, extending to
                    include copyright, trademark, and patent laws.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    6.2 Any actions encompassing unauthorized use, replication,
                    modification, or distribution of the Content are serious
                    offenses. Such actions are liable to invoke stringent civil
                    and criminal penalties under the respective intellectual
                    property laws that apply. These penalties can include
                    substantial monetary damages, and in certain situations, may
                    result in criminal prosecution.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    6.3 The agreement in these Terms does not afford you any
                    explicit or implicit rights concerning our intellectual
                    property or propriety rights. All rights attached to
                    Services and Content, that are not expressly granted in this
                    agreement, are our sole prerogatives. Usage of the Services
                    or the Content, that doesn't fulfill the explicit
                    authorization outlined in this document, and devoid of our
                    prior written consent, is prohibited.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="7. User Generated Content ">
                <NeoBannerModalParagraph>
                    7.1 When you post, upload, publish, submit, or transmit any
                    video, text, information, data, or any other content that
                    can be made publicly available (collectively, "Content"), on
                    or through the Service, you understand and acknowledge that
                    you are making that Content available for use by us and
                    other users of the Service.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.2. You retain ownership rights for all Content provided,
                    except for pre-designated components such as Prompts,
                    Imprints, Pathways, and the like as mentioned in these
                    terms. Beyond this, we do not claim ownership rights on your
                    Content.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.3 By making your Content available, you hereby grant us an
                    irrevocable, non-exclusive, worldwide, royalty-free,
                    transferable license, with the right to grant sub-licenses,
                    to use, copy, adapt, distribute, reference, store, cache,
                    license, sell, transfer, publicly display, publicly perform,
                    transmit, stream, broadcast, access, view, and otherwise
                    exploit such Content on, through, or by means of the
                    Service.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.4 You are solely responsible for all your Content. You
                    represent and warrant that you own all your Content or you
                    have all rights that are necessary to grant us the license
                    rights in your Content under these Terms. You also represent
                    and warrant that neither your Content, nor your use and
                    provision of your Content to be made available through the
                    Service, nor any use of your Content by us on or through the
                    Service will infringe, misappropriate, or violate a third
                    party's intellectual property rights, or rights of publicity
                    or privacy, or result in the violation of any applicable law
                    or regulation.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.5 As indicated in these terms, all content in transit are
                    purged from our systems periodically after post-processing.
                    However, you can also remove your Content made available
                    through our site, by specifically deleting it. However, in
                    certain instances, the metadata of some of your Content
                    (such as posts or comments you make) may not be completely
                    removed, and copies of your Content may continue to exist on
                    the Service.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.6 We are not responsible for any public display or misuse
                    of your Content. We have the right to refuse or remove any
                    Content you post on the Service for any or no reason. This
                    includes Content that in our own discretion violates these
                    Terms.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.7 We take no responsibility and assume no liability for
                    Content you or any third party posts on or through the
                    Service. We are not in control, nor do we have the ability
                    to control, the Content posted on the Service and, as such,
                    do not guarantee the accuracy, integrity, or quality of such
                    Content.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.8 We do not condone copyright infringing activities. If
                    you believe any Content on the Service infringes your
                    copyright, you may request removal of those materials from
                    the Service by contacting us with the following information:
                    (a) identification of the copyrighted work that you believe
                    to be infringed; (b) identification of the Content,
                    including its location, that you believe to be infringing
                    your copyright; (c) your name, address, telephone number,
                    and email address; (d) a statement that you have a good
                    faith belief that the disputed use is infringing; (e) a
                    statement that the information you have provided is accurate
                    and that under penalty of perjury you are the owner of the
                    copyright involved or are authorized to act on behalf of
                    that owner; and (f) your physical or electronic signature.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="8. Licensing:">
                <NeoBannerModalParagraph>
                    By using our Services, you acknowledge and agree that all
                    prompts and pathways developed within the Prompt Engineering
                    section of the platform constitute exclusive property of
                    NeoWorlder Inc. This includes, but is not limited to,
                    titles, descriptions, prompts, and other metadata associated
                    with these pathways. It's crucial to understand that these
                    assets cannot be used, replicated, or distributed without
                    our explicit written consent. Moreover, you're advised to
                    handle all sensitive data in a confidential and secure
                    manner, ensuring that its integrity, privacy, and
                    confidentiality are maintained at all times. Unauthorized
                    sharing, dissemination, or misuse of such data may lead to
                    severe legal penalties under applicable laws and
                    regulations.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="9. Third Party Vendors: ">
                <NeoBannerModalParagraph>
                    The Services we provide may incorporate links to third-party
                    websites or enable you to install and utilize certain
                    third-party applications or software. Please note that your
                    access and use of these third-party resources will be
                    governed by their respective terms and conditions, privacy
                    policies, and other contractual stipulations. We are not
                    responsible for the content, functionality, legality, or any
                    other aspect of these third-party resources, and you engage
                    with them at your own risk. Any grievances, claims, or
                    disputes arising out of or in connection with such
                    third-party resources should be directed to the respective
                    third-party.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="10. Pricing">
                <NeoBannerModalParagraph>
                    10.1 You agree to pay the subscription fees according to our
                    pricing model, currently set at $10 per user per month
                    ("Subscription Fee"). The subscription allows a usage quota
                    of 250,000 words for upload or download per month ("Usage
                    Quota").
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    10.2 Should you exceed the Usage Quota within a specified
                    billing period, you will be subjected to an additional
                    charge of $0.00003 per word ("Additional Charge”).
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    10.3 In instances where you pay for extra words beyond the
                    Usage Quota in a given month, and if they remain unused,
                    these words will be carried forward and added to your Usage
                    Quota for the following month.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    10.4 Our Subscription Fee operates on a month-to-month
                    basis. Therefore, we will not bind you to a long-term
                    contract.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    10.5 You may decide to end your subscription at any time.
                    Upon such a decision, you will not be billed for the
                    subsequent month, and you are still permitted to use the
                    platform until the end of the currently paid month.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    10.6 Kindly note that billing on Neo AI is based on word
                    count. Consequently, when your persona interacts with tools
                    to perform a task, charges will be accrued based on the word
                    count utilized in that conversation with the tool (as
                    quantified by the number of compute cycles required in the
                    use of the tool).
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    10.7 Unless otherwise specifically stated in these terms,
                    all Subscription Fees, Additional Charges, and other fees
                    collected under these terms are non-refundable. You will
                    receive no refund, prorated or otherwise, for the remainder
                    of any billing period upon cancellation of your
                    subscription.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    10.8 We reserve the right to change the Subscription Fees,
                    Additional Charges, and any other fees associated with the
                    use of our Services at any time. We will provide reasonable
                    notice of any such changes by posting the updated fees on
                    our Site or sending you an email notification. Your
                    continued use of the Services after the effective date of
                    the price change constitutes your acceptance of the adjusted
                    fees. If you do not agree to the price change, you have the
                    right to reject it by cancelling your subscription prior to
                    the price change going into effect. Please, therefore, make
                    sure you read any such notice carefully.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="11. Payment Methods">
                <NeoBannerModalParagraph>
                    11.1 We accept payment through credit cards. By providing a
                    credit card or other payment method that we accept, you
                    represent and warrant that you are authorized to use the
                    designated payment method, and you authorize us (or our
                    third-party payment processor) to charge your payment method
                    for the total fees you owe for the use of our Services
                    (including any applicable taxes and other charges).
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    11.2 In certain instances, we may accept payments in the
                    form of T$ (in-world currency) or Tickets (reward points),
                    which are the virtual currencies in NeoWorlder's Lootverse
                    gated world platform. This mode of payment is subject to the
                    rules and restrictions of NeoWorlder's Lootverse platform
                    and may be altered, suspended, or cancelled at any time in
                    our sole discretion. The conversion and exchange rate of T$
                    or Tickets to real-world currencies, if applicable, may also
                    be subject to fluctuations and changes based on market
                    dynamics, and we reserve the right to determine and change
                    such conversion and exchange rates.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    11.3 By using a designated payment method, you are
                    authorizing us to charge such payment method for the total
                    amount of your order (including any applicable taxes and
                    other charges). If the payment method cannot be verified, is
                    invalid, or is otherwise not acceptable, your access to the
                    Services may be suspended or cancelled until we receive a
                    valid payment method.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    11.4 We use a third-party payment processor (the "Payment
                    Processor") to bill you through a payment account for your
                    use of the Services. The processing of payments will be
                    subject to the terms, conditions, and privacy policies of
                    the Payment Processor in addition to this Agreement. We are
                    not responsible for errors by the Payment Processor. By
                    choosing to use the Services, you agree to pay us, through
                    the Payment Processor, all charges at the prices then in
                    effect in accordance with the applicable payment terms, and
                    you authorize us, through the Payment Processor, to charge
                    your chosen payment provider. We reserve the right to
                    correct any errors or mistakes that the Payment Processor
                    makes even if it has already requested or received payment.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="12. Privacy and Data Security">
                <NeoBannerModalParagraph>
                    We highly value the privacy and security of our users. Our
                    system architecture utilizes individual application
                    containers, like Docker, for each user's compute cycles and
                    data storage. These containers are managed by us but the
                    contents remain inaccessible, with our capacity limited to
                    potential deletion based on data retention policies or user
                    requests. Interactions with your chosen databases and
                    service providers occur via encrypted APIs. All information
                    and files associated with these interactions are deleted
                    from our systems post-processing and transit. We store an
                    encrypted version of your data to facilitate our services,
                    but this remains inaccessible to us and serves no additional
                    utility. We urge you to review our Privacy Policy for a
                    complete understanding of our data handling, usage, and
                    protection measures, which adhere to industry best practices
                    and legal standards.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="13. Indemnification">
                <NeoBannerModalParagraph>
                    You agree to indemnify, defend, and hold harmless
                    NeoWorlder, LLC, and its directors, officers, employees,
                    agents, licensors, and suppliers (collectively, the
                    "Indemnified Parties") from and against all claims, actions,
                    demands, liabilities, damages, losses, costs, and expenses,
                    including reasonable attorneys' fees and other legal costs,
                    arising out of or in connection with your use of the
                    Services, your breach of these Terms, or your infringement,
                    or infringement by any other user of your account, of any
                    intellectual property or other right of any person or
                    entity. You will cooperate as fully required by the
                    Indemnified Parties in the defense or settlement of any
                    claim. The Indemnified Parties reserve the right, at your
                    expense, to assume the exclusive defense and control of any
                    matter subject to indemnification by you, and you shall not
                    in any event settle any such claim or matter without the
                    written consent of the relevant Indemnified Parties. This
                    indemnification provision shall apply to third-party claims
                    as well as claims between you and NeoWorlder, LLC.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="14. Dispute Resolution">
                <NeoBannerModalParagraph>
                    14.1 In the event of a dispute arising out of or in
                    connection with these Terms or the Services provided by
                    NeoWorlder, LLC, both parties agree to settle such disputes
                    through binding arbitration, in accordance with the rules of
                    an agreed-upon arbitration body. This means that instead of
                    going to court, both parties agree to resolve the dispute by
                    appointing an independent third-party (the arbitrator) to
                    make a final and binding decision after hearing both
                    parties' arguments.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    14.2 It is important to note that by agreeing to this
                    clause, you waive your right to a trial by jury or to
                    participate in a class action lawsuit. All arbitration
                    procedures, including the selection of the arbitration body
                    and the location of the arbitration, will be determined in
                    accordance with the applicable laws of the great state of
                    Wyoming, USA.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    14.3 The award rendered by the arbitrator shall include
                    costs of arbitration, reasonable attorneys' fees, and
                    reasonable costs for experts and other witnesses, and any
                    judgment on such award may be entered in any court of
                    competent jurisdiction. Both you and NeoWorlder, LLC agree
                    to honor the arbitrator's decision and not seek to dispute
                    or overturn it unless there are significant legal grounds to
                    do so.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    14.4 This arbitration clause does not apply to any claims
                    that cannot be subject to arbitration as a matter of law,
                    and does not prevent you from making a complaint to any
                    relevant federal, state, or local agencies that can, if the
                    law allows, seek relief on your behalf.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="15. Changes to Terms">
                <NeoBannerModalParagraph>
                    15.1 We reserve the right, at our sole discretion, to
                    modify, add or delete any portion of these Terms at any time
                    without prior notice. Changes to these Terms will become
                    effective immediately upon their posting on our platform
                    unless otherwise specified.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    15.2 We will notify you of substantial changes to these
                    Terms by posting a prominent notice on our platform or by
                    sending an email to the email address you provided us during
                    registration. It is your responsibility to regularly check
                    for any updates or changes to these Terms.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    15.3 Your continued use of our Services following the
                    posting of changes represents your acceptance of such
                    changes. If you do not agree to any changes to these Terms,
                    you must immediately cease the use of our Services and
                    deactivate your account.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    15.4 It is your responsibility to regularly review these
                    Terms. We bear no responsibility if you miss any updates to
                    these Terms. Your continued use of the Services signifies
                    your agreement to our revisions. If you disagree with any
                    modifications to these Terms, your sole remedy is to stop
                    using the Services.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    15.5 Any changes will be effective upon the posting of the
                    revisions on the platform. Your continued use of the
                    Services following the posting of any changes will
                    constitute your acceptance of such changes. Please review
                    these Terms periodically for changes.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    Do not use the Services if you disagree with any of these
                    Terms and Conditions.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <h3 className="text-3xl font-semibold mt-6 mb-2">Privacy Policy</h3>
            <NeoBannerModalContent>
                <NeoBannerModalParagraph>
                    NeoWorlder, LLC (“we,” “us” or “our”) is committed to
                    transparency and ensuring that your privacy is protected.
                    This Privacy Policy is designed to inform you about how we
                    collect, use, store, and disclose your information when you
                    access or use our platform, Neo AI and associated
                    domains/services, collectively referred to as our
                    “Services.” By accessing or using our Services, you agree to
                    this Privacy Policy and our Terms and Conditions.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="1. Information Collection">
                <NeoBannerModalParagraph>
                    When you use our Services, we may collect and store the
                    following types of information:
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (a) Personal Information: We collect personal information
                    that you voluntarily provide to us when creating an account
                    or subscribing to our Services. This can include your name,
                    email address, billing information, and other identifiers
                    that enable us to contact you or provide our Services
                    effectively.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (b) Service Data: We collect information about how you
                    access and use our Services, including your actions on our
                    platform, the timestamps of your actions, the browser you
                    use, and your IP address.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (c) Uploaded Data: Data and content that you upload or
                    provide for the processing by our Services are collected and
                    stored temporarily for the duration of processing.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (d) Cookies: Like many online services, we use cookies,
                    which are small pieces of data stored on your device that
                    help us enhance your experience, understand user activity,
                    and improve the quality of our Services.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="2. Use of Information">
                <NeoBannerModalParagraph>
                    We may use the information we collect from you for a variety
                    of purposes, including to:
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (a) Provide, operate, maintain and improve our Services;
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (b) Enable you to access and use our Services, including
                    uploading, downloading, collaborating on, and sharing
                    content;
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (c) Send you service, technical, and administrative email
                    notifications;
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (d) For compliance purposes, including enforcing our Terms
                    and Conditions;
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (e) For protection against fraudulent, deceptive, and
                    illegal activity;
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="3. Data Security">
                <NeoBannerModalParagraph>
                    3.1 As a young and growing company, we are deeply committed
                    to ensuring the security of your information. We have
                    implemented suitable physical, electronic, and managerial
                    procedures to safeguard and secure the information we
                    collect online. However, it's important to note that no
                    method of online transmission or electronic storage is 100%
                    secure. While we utilize state-of-the-art tools and
                    protocols to maintain the safety of your data, we cannot
                    offer an absolute security guarantee.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    3.2 Furthermore, by using our Services, you acknowledge and
                    accept the inherent security implications of dealing online
                    and will not hold us responsible for any data breach, loss,
                    or unauthorized access. You understand that you provide your
                    information and use our Services at your own risk. We are
                    continuously working to improve our Services, enhance our
                    security measures and ensure the highest degree of safety
                    for your data.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    3.3 By agreeing to this Privacy Policy, you acknowledge an
                    understanding of the realistic scope of data security
                    measures on the internet, and willingly accept the risks
                    involved in the participation and usage of our Service. We
                    appreciate your support and understanding as we strive for
                    excellence and growth in this dynamic and evolving field.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="4. Data Retention">
                <NeoBannerModalParagraph>
                    We understand the importance of your personal data and aim
                    to be considerate, open, and compliant in how we handle and
                    protect it. Consequently, we practice a comprehensive data
                    retention and deletion policy as outlined below:
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (a) Retention Periods: We retain your Personal Information
                    as long as your account remains active or as needed to
                    provide you with our services. An active account is defined
                    as one where interaction with our services has been tracked
                    in recent years or where a subscription to periodic
                    communications exists. We also retain and use your
                    information as necessary to comply with our business
                    requirements, legal obligations, resolving disputes,
                    enforcing our agreements, including provisions that may
                    require us to retain your Personal Information.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (b) Inactive Accounts: If your account becomes inactive, we
                    will continue to store certain personal information to allow
                    for account reactivation and to maintain the security and
                    integrity of our site. The information retained during this
                    period can include your contact details and public
                    information, including any interactions you may have had
                    with our services.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (c) Deletion Upon Request: You may submit a request to
                    delete your personal account and Personal Information at any
                    time. We will evaluate such requests on a case-by-case basis
                    and act in compliance with applicable data protection laws.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (d) Post Deletion: Once your data is deleted, it is purged
                    from our servers and no longer accessible to you or us;
                    provided, however, that there may be a delay from the time
                    you delete your data until the time it is removed from our
                    active servers and from our backup systems.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (e) Beyond Deletion: Please note that even after deletion of
                    your account, we may be compelled to retain some information
                    for a period of time due to legal and regulatory
                    obligations. This can include information related to
                    financial transactions (for tax purposes), identification
                    verification, and record of agreement to our terms and
                    privacy policy.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (d) Periodic Reviews: We also conduct periodic reviews of
                    our data retention practices to ensure that we are complying
                    with our obligations under data protection laws and
                    regulations. Upon conclusion of the retention period and the
                    conditions requiring the retention are no longer relevant or
                    legally required, we will either anonymize the data or
                    securely delete it.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (e) Please note that the above does not apply to the extent
                    we need to retain and use your Personal Information in order
                    to comply with our legal obligations, resolve disputes, or
                    enforce our agreements.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="5. Sharing and Disclosure">
                <NeoBannerModalParagraph>
                    We value your privacy and are committed to keeping your
                    information private. We do not sell, rent, or otherwise
                    disclose personal information that we collect, except as
                    described in this Privacy Policy.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (a) With Service Providers: In the course of providing our
                    Services, there may be instances where we need to share or
                    give limited access to your information to our third-party
                    service providers. These providers perform various tasks for
                    us including, but not limited to, website and application
                    development, hosting, maintenance, backup, storage, payment
                    processing, analysis and other services for us, which may
                    require them to access or use information about you. These
                    service providers are granted access to your information
                    only to perform these tasks on our behalf, based on our
                    instructions and in compliance with this Privacy Policy and
                    appropriate confidentiality and security measures. We
                    endeavor to vet these service providers for their data
                    protection practices and aim to work with providers who
                    treat your data with the same level of importance as we do.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (b) Legal Compliance: There may be legal requirements which
                    necessitate the disclosure of your personal information. In
                    such cases, we may disclose your personal information if we
                    believe in good faith that such action is necessary to:
                    <ol className="roman-type sub">
                        <li>
                            Comply with any applicable law, regulation, legal
                            process, or enforceable government request;
                        </li>
                        <li>
                            Enforce our Terms and Conditions, including
                            investigating potential violations;
                        </li>
                        <li>
                            Detect, prevent, or otherwise address fraud,
                            security, or technical issues;
                        </li>
                        <li>
                            Protect against harm to our rights, property or
                            safety, our users or the public as required or
                            permitted by law.
                        </li>
                    </ol>
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (c) event involving our business, personal information may
                    be part of the transferred assets. You will be notified via
                    email and/or a prominent notice on our website of any change
                    in ownership or uses of your personal information, as well
                    as any choices you may have regarding your personal
                    information.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    (d) With Your Consent: In situations other than those
                    described above, you will receive notice when personal
                    information about you might be shared with third parties,
                    and you will have the opportunity to choose not to allow us
                    to share the information. Any information transferred
                    outside these circumstances would be based on your explicit
                    consent.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    It's important to note that NeoWorlder's commitment to
                    protecting your privacy does not mean that we can ensure or
                    warrant the security of any information you transmit to us,
                    and you do so at your own risk. In cases where we have
                    given, or you have chosen a password to access certain parts
                    of our Services, you have the responsibility of keeping this
                    password confidential, and you should not share it with
                    anyone.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="6. Your Rights">
                <NeoBannerModalParagraph>
                    As a user of our Services, you maintain several important
                    rights in relation to your personal information and we honor
                    them fully. These rights vary depending upon the applicable
                    legal framework, but under the General Data Protection
                    Regulation (GDPR), they typically include the following:
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    <ol className="letter-type">
                        <li>
                            Right to Access: You have the right to request
                            details of the personal data we hold about you and
                            how we process it. This includes obtaining a copy of
                            the personal data we maintain about you.
                        </li>
                        <li>
                            Right to Rectification: If you believe that any
                            personal data we hold about you is incomplete or
                            inaccurate, you have the right to request changes or
                            updates to such information.
                        </li>
                        <li>
                            Right to Data Portability: You may request that we
                            transfer your personal data that you provided to us
                            to another organization, or directly to you, if
                            technically feasible.
                        </li>
                        <li>
                            Right to Be Forgotten: In certain circumstances, you
                            can request us to delete or remove your personal
                            data where there is no compelling reason for us to
                            continue processing it.
                        </li>
                        <li>
                            Right to Object: If we collect or process your
                            personal data on the grounds of legitimate interests
                            or public interest, you have the right to object to
                            this processing. You can also object if we process
                            your data for direct marketing purposes.
                        </li>
                        <li>
                            Right to Withdraw Consent: If we are processing your
                            personal data based on your consent, you have the
                            right to withdraw this consent at any time.
                        </li>
                        <li>
                            Right to Lodge a Complaint: If you feel that we have
                            not handled your personal data correctly, you have a
                            right to lodge a complaint with the data protection
                            authority in the country where you live or work, or
                            where you believe a breach has occurred
                        </li>
                    </ol>
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    To exercise any of these rights, you can contact us by any
                    of the methods listed in the Contact Us section of this
                    Privacy Policy. Please note that we can ask you to verify
                    your identity before responding to such requests to ensure
                    the security of your data.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="7. Third-Party Websites and Services">
                <NeoBannerModalParagraph>
                    7.1 Our Services, including our platform, may contain links
                    to third-party websites, services, or applications that are
                    not owned or controlled by NeoWorlder, LLC. These could
                    include third- party smart assistants, partner vendors,
                    content providers, and other service integrations for
                    enhancing user experience.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.2 In some instances, these may involve collaboration with
                    our partners through a single sign-on mechanism, allowing
                    their customers access to our Services via a white-labeled
                    site, without any price change. These third-party sites may
                    also offer their own standalone products and services.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.3 Our Privacy Policy does not extend to these external
                    sites or third parties. Such links are provided purely for
                    your convenience and reference. They do not signify our
                    endorsement of these third-party websites, their content,
                    their owners, or their practices.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.4 Before engaging with any third parties found through our
                    Services, whether you click on the links provided or use
                    their services or applications, we strongly advise you to
                    read their terms and conditions and privacy policies
                    independently.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.5 This is to ensure that you understand how they will
                    handle your personal data, as these third parties may
                    collect, store, use and distribute your personal information
                    under different guidelines than NeoWorlder, LLC. We bear no
                    responsibility or liability for any information you provide
                    to these third parties, or for any interactions or issues
                    you may have with these third parties.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.6 It is your personal responsibility to review the privacy
                    policies of these third-party sites to understand how your
                    personal data will be treated before using their services
                    and to inform us promptly if you notice any concerns upon
                    interaction.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    7.7 Please contact the relevant third-party entity directly
                    with respect to any disputes or issues arising from their
                    website or operations.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="8. Children Information">
                <NeoBannerModalParagraph>
                    8.1 Our Services are designed for use by individuals aged 16
                    and above. NeoWorlder, LLC is firmly committed to the
                    safeguarding of children's online privacy, and we do not
                    intentionally collect, solicit, or maintain Personal
                    Information from anyone we know to be under the age of 16
                    without verifiable parental consent. If a user under the age
                    of 16 (or a parent or guardian) becomes aware that they have
                    shared personal information with us without parental
                    consent, we encourage them to notify us promptly.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    8.2 In line with the protections laid out in the Children’s
                    Online Privacy Protection Act (COPPA), if we discover or are
                    alerted that we have inadvertently received Personal
                    Information directly from a child under age 16, we will
                    endeavor to delete the information from our systems as
                    quickly as practically possible. Additionally, we will not
                    knowingly disclose this inadvertently gathered information
                    to any third parties.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    8.3 We strongly urge parents and guardians to take an active
                    role in their children's online activities and interests, as
                    nothing is as effective at safeguarding children's privacy
                    online as proper parent and guardian involvement. We request
                    that individuals under the age of 16 not provide us with any
                    personal information without consent and supervision from a
                    responsible parent or guardian.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    8.4 For further information, including how to review or
                    request deletion of your child's Personal Information, or to
                    specify and limit what data of your child’s can be
                    collected, please contact us directly at
                    info@neoworlder.com.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="9. Changes to this Privacy Policy">
                <NeoBannerModalParagraph>
                    9.1 We may periodically update or modify this Privacy
                    Policy. We will notify you of any significant changes to
                    this Privacy Policy by sending a notification to the primary
                    email address specified in your account, by prominent
                    posting on our platforms, and/or by updating the "Last
                    Updated" date at the top of this Privacy Policy. All
                    modifications or updates will become effective upon being
                    signaled to our users.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    9.2 We encourage you to frequently review this Privacy
                    Policy to ensure your understanding of the information we
                    collect, how we use it, and under what circumstances, if
                    any, we disclose it. Your continued use of the Neo AI
                    platform and our services following the alert of
                    modifications to this Privacy Policy serves as an
                    acknowledgement of the updates and your consent to abide and
                    be bound by the modified Privacy Policy.
                </NeoBannerModalParagraph>
                <NeoBannerModalParagraph>
                    9.3 If you disagree with any changes to this Privacy Policy,
                    you should discontinue your use of our services, deactivate
                    your account, and let us know of your disagreement by
                    emailing us at contact@neoworlder.ai or similar means.
                    Unless stated otherwise, our most current Privacy Policy
                    applies to all information that we have about you and your
                    account.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="10. Contact Us">
                <NeoBannerModalParagraph>
                    If you have any questions or concerns about this Privacy
                    Policy, your use of the Services, or your dealings with our
                    website, please contact us at NeoWorlder, LLC, 30 N. Gould
                    Suite R, Sheridan WY 82801 or info@neoworlder.com.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>
            <h3 className="text-3xl font-semibold mt-6 mb-8">GDPR Policy</h3>
            <NeoBannerModalContent title="1. Information Collection">
                <NeoBannerModalParagraph>
                    We only collect personal data when you choose to share it
                    with us, such as when you register for Neo AI, fill out a
                    form or survey, purchase our products or services, or
                    contact us directly. The categories of personal data we
                    collect include identification and contact information
                    (name, email), billing details, and information about your
                    interactions with Neo AI.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="2. Legal Basis for Processing">
                <NeoBannerModalParagraph>
                    We process your personal data only when we have a lawful
                    basis for doing so. The legal basis depends on the reason we
                    collected and need to use your data. We process your data on
                    the following legal bases: consent, contract, legal
                    obligation, vital interests, public task, and legitimate
                    interests.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="3. Consent">
                <NeoBannerModalParagraph>
                    If you have given us your consent to use personal data, we
                    will only process your data for the specific purpose stated
                    at the time of collection. You may withdraw your consent at
                    any time by contacting us.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="4. Processing for Legitimate Interests">
                <NeoBannerModalParagraph>
                    We may process your data when it is in our legitimate
                    interests to do this and when these interests are not
                    overridden by your data protection rights.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="5. Data Portability">
                <NeoBannerModalParagraph>
                    You have the right to get a copy of your data in a format
                    that can be easily re-used. You can also request we provide
                    your data directly to another company. This right only
                    applies when processing is based on your consent or when we
                    are carrying out a contract with you, and we are carrying
                    out the processing by automated means.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="6. Right to Rectification and Erasure">
                <NeoBannerModalParagraph>
                    You have the right to ask us to update inaccurate or
                    incomplete personal data we hold about you. You also have
                    the right to ask us to delete your personal data when it is
                    no longer necessary for us to retain such data.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="7. Right to Object">
                <NeoBannerModalParagraph>
                    You can object to any processing of your personal data which
                    has our legitimate interests as its legal basis, if you
                    believe your fundamental rights and freedoms outweigh our
                    legitimate interests.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="8. Data Retention">
                <NeoBannerModalParagraph>
                    Notwithstanding our privacy policy, we retain personal
                    information we collect from you where we have an ongoing
                    legitimate business need to do so or to comply with
                    applicable legal, tax or accounting requirements.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="9. International Data Transfers">
                <NeoBannerModalParagraph>
                    Personal data collected by Neo AI may be stored or processed
                    in your region, or in any other country where we or our
                    affiliates, subsidiaries, or service providers are located
                    or maintain facilities. We have taken appropriate safeguards
                    to require that your personal data will remain protected and
                    require our service providers to have appropriate safeguards
                    as well.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="10. Data Protection Officer">
                <NeoBannerModalParagraph>
                    We have appointed a Data Protection Officer (DPO) who is
                    responsible for overseeing questions in relation to our GDPR
                    compliance. If you have any questions about this privacy
                    notice, including any requests to exercise your legal
                    rights, please contact the DPO at{" "}
                    <a className="link" href="mailto:info@neoworlder.com">
                        info@neoworlder.com
                    </a>
                    .
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>

            <NeoBannerModalContent title="11. Breach Notification:">
                <NeoBannerModalParagraph>
                    In the case of a personal data breach that is likely to
                    result in a high risk to the rights and freedoms of
                    individuals, we will notify them without undue delay. We
                    will describe to the data subject in clear and plain
                    language the nature of the personal data breach and provide
                    the necessary information and recommendations to mitigate
                    potential adverse effects. Finally, we confirm that our
                    organization has implemented technical and organizational
                    security measures to ensure the privacy and protection of
                    all personal data that we process in line with GDPR.
                </NeoBannerModalParagraph>
            </NeoBannerModalContent>
        </NeoBannerModal>
    );
}
