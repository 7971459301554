import { FormControl, Spinner } from "@chakra-ui/react";
import "../styles/PromptLibrary.component.scss";
import { BsArrowRightShort } from "react-icons/bs";
import { useEffect, useState } from "react";
import Select from "react-select";
import { capitalizeWord } from "../utils/strings.utils";
import { useHttpClient } from "../utils/http.utils";
import { PromptModel } from "../models/prompt.model";
import { useSelector } from "react-redux";

interface PromptLibraryProps {
    onSelect: (prompt: string) => void;
}

export default function PromptLibrary(props: PromptLibraryProps) {
    const { fetchPromptsApi, fetchPromptCategoriesApi } = useHttpClient();
    const promptsState: PromptModel[] = useSelector(
        (state: any) => state.searches.prompts,
    );
    const promptsLoaded: boolean = useSelector(
        (state: any) => state.searches.hasPromptsLoaded,
    );
    const [prompts, setPrompts] = useState<PromptModel[]>([]);
    const [promptCategories, setPromptCategories] = useState<string[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!promptsLoaded) {
            setIsSearchLoading(true);
            fetchPromptsApi({}).finally(() => {
                setIsSearchLoading(false);
            });

            fetchPromptCategoriesApi({})
                .then(({ data }) => {
                    let categories = [
                        { label: "All", value: "all" },
                        ...data.map((p) => ({
                            label: capitalizeWord(p.title),
                            value: p.title,
                        })),
                    ];
                    setPromptCategories(categories);
                })
                .catch((err) => {});
        }
    }, [promptsState.length]);

    function searchPrompts(e: any) {
        const query: string = e.target.value;
        if (!query) {
            // setPrompts(example_prompts);
            if (selectedCategory && selectedCategory !== "all") {
                setPrompts(
                    promptsState.filter((p) =>
                        p.category.includes(selectedCategory),
                    ),
                );
            } else {
                setPrompts(promptsState);
            }
            return;
        }
        const results = promptsState.filter((p) => {
            const regex = new RegExp(query, "i");
            if (selectedCategory && selectedCategory !== "all") {
                return (
                    regex.test(p.title) && p.category.includes(selectedCategory)
                );
            } else {
                return regex.test(p.title);
            }
        });

        setPrompts(results);
    }

    // const debouncedResults = useMemo(() => {
    //     return debouce(searchPrompts, 300);
    // }, [searchPrompts]);

    function onEmitPrompt(prompt: any) {
        if (typeof props.onSelect === "function") {
            props.onSelect(prompt.prompt_text);
        }
    }

    function onPromptCategorySelect(e: any) {
        const value = e.value;
        setSelectedCategory(value);
        if (value === "all") {
            setPrompts(promptsState);
        } else {
            setPrompts(promptsState.filter((p) => p.category.includes(value)));
        }
    }

    return (
        <div className="prompt-library-container sidebar-sm">
            <div className="prompt-header">
                <div className="side-nav-sm">
                    <h2 className="title">Prompt Library</h2>
                    <p className="subtitle"> Choose a prompt to add.</p>
                </div>
                <div className="px-6 pt-3">
                    <div className="search-input-group">
                        <input
                            disabled={isSearchLoading}
                            type="text"
                            placeholder="Search Prompts"
                            onChange={searchPrompts}
                        />
                        {isSearchLoading && (
                            <div className="loader">
                                <Spinner size="sm" />
                            </div>
                        )}
                    </div>
                    <FormControl mt={2}>
                        <Select
                            placeholder="Choose Category"
                            options={promptCategories}
                            isSearchable={false}
                            className="arin-react-select-container"
                            classNamePrefix="arin-react-select"
                            isDisabled={isSearchLoading}
                            onChange={onPromptCategorySelect}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    height: 45,
                                    minHeight: 45,
                                }),
                            }}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="prompt-body sidebar-body">
                {prompts.map((p, index) => {
                    return (
                        <div
                            onDoubleClick={() => onEmitPrompt(p)}
                            key={index}
                            className="prompt-library-card"
                        >
                            <div className="prompt-library-card-title">
                                <p title={p.title} className="title-text pr-4">
                                    {p.title}
                                </p>
                                <p className="action">
                                    <button onClick={() => onEmitPrompt(p)}>
                                        Use Prompt&nbsp;
                                        <BsArrowRightShort className="icon" />
                                    </button>
                                </p>
                            </div>
                            <p className="prompt-category-text text-slate-500 text-sm">
                                {capitalizeWord(p.category[0] || "")}
                            </p>
                            <div
                                title={p.prompt_text}
                                className="prompt-library-card-text"
                            >
                                {p.prompt_text}
                            </div>
                        </div>
                    );
                })}
                {isSearchLoading && (
                    <div className="no-results">
                        <div className="content">
                            <img src="/prompts.png" alt="" />
                            <p className="text mt-3 text-slate-500">
                                Loading Prompts
                            </p>
                        </div>
                    </div>
                )}
                {!isSearchLoading && prompts.length === 0 && (
                    <div className="no-results">
                        <div className="content">
                            <img src="/prompts.png" alt="" />
                            <p className="text mt-3 text-slate-500">
                                No results to display
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
