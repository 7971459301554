import "../styles/LandingPage.component.scss";
import {
    Button,
    FormControl,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Skeleton,
    Spinner,
    Stack,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { TbArrowRightCircle, TbArrowDownCircle } from "react-icons/tb";
import React, { useEffect, useRef, useState } from "react";
import { AiFillInfoCircle, AiFillStar } from "react-icons/ai";
import ArinChat from "../components/ArinChat";
import { CgArrowsExpandUpRight } from "react-icons/cg";
import {
    BsArrowRightSquare,
    BsChevronDown,
    BsChevronLeft,
    BsChevronRight,
    BsFillVolumeMuteFill,
    BsFillVolumeUpFill,
} from "react-icons/bs";
import NeoModal from "../components/NeoModal";
import { requestLogin } from "../utils/auth.utils";
import { setMetaTags } from "../utils/seo.utils";
import { AiFillCheckCircle } from "react-icons/ai";
import { useHttpClient } from "../utils/http.utils";
import NeoImageModal, {
    NeoImageModalParagraph,
    NeoImageModalTitle,
} from "../components/modals/NeoImageModal";
import PrivacyPolicyModal from "../components/modals/PrivacyPolicyModal";
import { NewsletterModal } from "../components/modals/NewsletterModal";
import NeoSheetModal, {
    NeoSheetModalDescription,
    NeoSheetModalTitle,
} from "../components/modals/NeoSheetModal";
import { MdOutlineInstallDesktop } from "react-icons/md";
import { FiMinus, FiMoreVertical } from "react-icons/fi";
import { FaChevronLeft, FaChevronUp } from "react-icons/fa";
import { formatReadableNumber } from "../utils/number.utils";
import { Validators } from "../utils/validators.utils";
import { useSwipeable } from "react-swipeable";
import { LordIcon } from "../components/icons/LordIcon";
import { isDesktop, isTablet } from "react-device-detect";

export default function LandingPage() {
    const aboutCardsContainerRef = useRef(null);
    const { joinWaitListApi, getWaitListCountApi } = useHttpClient();
    const aboutVideoRef = useRef(null);
    const infinityVideoRef = useRef(null);
    const loadingSkeletonRef = useRef(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const [videoHasFinished, setVideoHasFinished] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [waitListEmail, setWaitListEmail] = useState("");
    const [waitListCount, setWaitListCount] = useState<number>(0);
    const [joinWaitListStatus, setJoinWaitListStatus] = useState<
        "joined" | "loading" | "not_joined" | "joined_failed"
    >("not_joined");
    // Scroll direcion for about containers, -1 for up, 1 for down
    const [aboutContainerScrollPosition, setAboutContainerScrollPostion] =
        useState<"top" | "bottom">("top");
    const [openChat, setOpenChat] = useState(false);
    const [showIntelligencePopUP, setShowIntelligencePopUP] = useState(false);
    const [showBannerPopUp, setShowBannerPopUp] = useState(false);
    const [showRegularPopUp, setShowRegularPopUp] = useState(false);
    const [showNewsLetterModal, setShowNewsLetterModal] = useState(false);
    const [showWhiteRabbitModal, setShowWhiteRabbitModal] = useState(false);
    const [showArchitectPopUp, setShowArchitectModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    // Prompt pop up
    const [promptPopUp, setPromptPopUp] = useState<
        "not_opened" | "opened" | "visible"
    >("not_opened");
    const [showAccessInformationModal, setShowAccessInformationModal] =
        useState(false);
    // Small nav toggle
    const [showSmSideNav, setShowSmSideNav] = useState(false);
    // Join waitlist input display state in small screens
    const [joinInputDisplayState, setJoinInputDisplayState] = useState(false);
    const [viewWidth, setViewWidth] = useState<number>(window.innerWidth);

    const redirectDisclosure = useDisclosure();
    const toast = useToast();
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.dir === "Left") {
                changeTab();
            } else if (eventData.dir === "Right") {
                changeTab(-1, -1);
            }
        },
    });

    useEffect(() => {
        setMetaTags({
            title: "Neo AI",
            description: "The Everything AI Platform",
            disableTrailAppName: true,
        });

        getWaitListCountApi().then(({ data }) => {
            console.log(data["data"].count);
            setWaitListCount(data["data"].count);
        });

        window.addEventListener("resize", () =>
            setViewWidth(window.innerWidth),
        );

        document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                // @ts-ignore
                document
                    .querySelector((e.target as any).getAttribute("href"))
                    ?.scrollIntoView({
                        behavior: "smooth",
                    });
            });
        });
        // show poster at end of video
        let video = document.querySelector("#about-video") as HTMLVideoElement;
        video.addEventListener(
            "ended",
            function () {
                setVideoHasFinished(true);
                video.load();
            },
            false,
        );
        /**
         * About cards small
         */

        /**
         * Listen to scroll changes in the about container cards and display the top or bottom arrow keys
         */
        const aboutContainer =
            aboutCardsContainerRef.current as unknown as HTMLDivElement;
        aboutContainer.addEventListener("scroll", (e) => {
            const scroll_bottom_index =
                aboutContainer.scrollHeight - aboutContainer.offsetHeight;

            if (aboutContainer.scrollTop === 0) {
                setAboutContainerScrollPostion("top");
            } else if (aboutContainer.scrollTop === scroll_bottom_index) {
                setAboutContainerScrollPostion("bottom");
            }

            // hide last action on mobile
            const last_action_btn = document.getElementById(
                "last-about-action-btn",
            ) as HTMLElement;

            if (
                aboutContainer.scrollLeft / aboutContainer.scrollWidth >=
                0.8876064852981589
            ) {
                last_action_btn.style.display = "none";
            } else {
                last_action_btn.style.display = "block";
            }
        });

        return () => {
            window.removeEventListener("resize", () =>
                setViewWidth(window.innerWidth),
            );
        };
    }, []);

    const isMobile = viewWidth <= 500;

    const isPWASupported = () => {
        return "serviceWorker" in navigator && "PushManager" in window;
    };

    const changeTab = (pageIndex: number = -1, direction = 1) => {
        // direction can either be 1 or -1
        let index: number;

        if (pageIndex > -1) {
            index = pageIndex;
        } else {
            if (activeTab === 0 && direction === -1) {
                index = 0;
            } else {
                index = activeTab + direction;
            }
        }

        const anchors = document.querySelectorAll(".landing-nav-link");

        anchors.forEach((a) => a.classList.remove("active"));
        // Stop videos
        if (infinityVideoRef.current) {
            (infinityVideoRef.current as unknown as HTMLVideoElement)?.pause();
            (
                infinityVideoRef.current as unknown as HTMLVideoElement
            ).currentTime = 0;
        }
        // end
        if (aboutVideoRef.current) {
            (aboutVideoRef.current as unknown as HTMLVideoElement).pause();
            (
                aboutVideoRef.current as unknown as HTMLVideoElement
            ).currentTime = 0;
        }
        setVideoHasFinished(false);

        const navbar = document.getElementById("navbar");
        // remove white bg
        navbar?.classList.remove("white-bg");

        if (activeTab >= 4 && index < 4) {
            document.querySelector("#banner")?.scrollIntoView({
                behavior: "smooth",
            });
        }

        switch (index) {
            case 0:
                break;
            case 1:
                break;
            case 3:
                // @ts-ignore
                infinityVideoRef.current.play();
                setIsVideoPlaying(true);
                break;
            case 4:
                // scroll to collaboration page
                document.querySelector("#collaboration")?.scrollIntoView({
                    behavior: "smooth",
                });
                // show black neo-logo by adding the white bg variant of the navbar
                setTimeout(() => {
                    navbar?.classList.add("white-bg");
                }, 500);

                break;
            case 5:
                document.getElementById("about-link")?.classList.add("active");

                document.querySelector("#about")?.scrollIntoView({
                    behavior: "smooth",
                });

                setTimeout(() => {
                    // Play luka video
                    // @ts-ignore
                    aboutVideoRef.current.play();
                    setIsVideoPlaying(true);
                }, 5000);
                break;
            case 6:
                // scroll to pricing page
                document
                    .getElementById("pricing-link")
                    ?.classList.add("active");

                document.querySelector("#pricing")?.scrollIntoView({
                    behavior: "smooth",
                });
                // show black neo-logo by adding the white bg variant of the navbar
                setTimeout(() => {
                    navbar?.classList.add("white-bg");
                }, 500);
                break;
        }

        setActiveTab(index);
        // hide sidenav => this will wark in mobile device
        toggleSideNav(false);
    };

    const scrollVerticalInAboutContainer = (direction: -1 | 1) => {
        const container =
            aboutCardsContainerRef.current as unknown as HTMLElement;
        let scroll_offset = 300;

        switch (direction) {
            case 1:
            default:
                /**
                 * This value hold the: {element}.scrollTop value for the bottom.
                 */
                const scroll_bottom_index =
                    container.scrollHeight - container.offsetHeight;
                /**
                 *  While the user scrolls down the page, we want to detect when he is the bottom at least close to the bottom,
                 *  In that case we just scroll the user to the bottom of the container.
                 */
                if (
                    container.scrollTop + scroll_offset >=
                    scroll_bottom_index
                ) {
                    container.scroll({
                        top: scroll_bottom_index,
                        left: 0,
                        behavior: "smooth",
                    });
                    setAboutContainerScrollPostion("bottom");
                } else {
                    container.scroll({
                        top: container.scrollTop + scroll_offset,
                        left: 0,
                        behavior: "smooth",
                    });
                }
                break;
            case -1:
                if (container.scrollTop <= scroll_offset) {
                    setAboutContainerScrollPostion("top");
                    container.scroll({ top: 0, left: 0, behavior: "smooth" });
                } else {
                    container.scroll({
                        top: container.scrollTop - scroll_offset,
                        left: 0,
                        behavior: "smooth",
                    });
                }
                break;
        }

        // setAboutCardsTranslateValue(scroll_index)
    };

    const scrollHorizontalInAboutContainer = (direction: -1 | 1) => {
        const container =
            aboutCardsContainerRef.current as unknown as HTMLElement;
        let scroll_offset = document.body.clientWidth - 30;

        switch (direction) {
            case 1:
            default:
                /**
                 * This value hold the: {element}.scrollTop value for the bottom.
                 */
                const scroll_right_index =
                    container.scrollWidth - container.offsetWidth;
                // last action btn on mobile

                /**
                 *  While the user scrolls down the page, we want to detect when he is the bottom at least close to the bottom,
                 *  In that case we just scroll the user to the bottom of the container.
                 */
                if (
                    container.scrollLeft + scroll_offset + 10 >=
                    scroll_right_index
                ) {
                    container.scroll({
                        top: 0,
                        left: scroll_right_index,
                        behavior: "smooth",
                    });
                    setAboutContainerScrollPostion("bottom");
                } else {
                    container.scroll({
                        top: 0,
                        left: container.scrollLeft + scroll_offset,
                        behavior: "smooth",
                    });
                }

                break;
            case -1:
                if (container.scrollTop <= scroll_offset) {
                    setAboutContainerScrollPostion("top");
                    container.scroll({ top: 0, left: 0, behavior: "smooth" });
                } else {
                    container.scroll({
                        top: 0,
                        left: container.scrollTop - scroll_offset,
                        behavior: "smooth",
                    });
                }
                break;
        }
    };

    const changeMuteStatus = () => {
        let video: HTMLVideoElement =
            aboutVideoRef.current as unknown as HTMLVideoElement;

        if (isVideoMuted) {
            video.muted = false;
            setIsVideoMuted(false);
        } else {
            video.muted = true;
            setIsVideoMuted(true);
        }
    };

    const authenticateUser = () => {
        // Quit if login is not enabled
        if (process.env.REACT_APP_ENABLE_LOGIN !== "yes") {
            return;
        }

        redirectDisclosure.onOpen();
        requestLogin()
            .then(({ url }) => {
                // redirect user to oauth website...
                window.open(url, "_self");
            })
            .catch((error) => {
                console.error(error);
                redirectDisclosure.onClose();
                toast({
                    title: "We are unable to sign you in, Please try again later",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    const joinWaitList = () => {
        const email = waitListEmail.trim();

        if (!Validators.validateEmail(email)) {
            toast({
                title: "Please provide a valid email",
                status: "warning",
            });
            return;
        }

        setJoinWaitListStatus("loading");
        // Add to database
        joinWaitListApi({ email: email })
            .then(({ data }) => {
                console.log(data);
                if (data["success"] === false) {
                    setJoinWaitListStatus("joined_failed");
                    // If the user is already in the waitlist
                    if (data?.error[0].code === 5902) {
                        toast({
                            title: "You are already on the waitlist. We will notify you when it's your turn to sign up.",
                            status: "warning",
                        });
                    } else {
                        toast({
                            title: "Cannot add your email at this time. Please try again later",
                            status: "error",
                        });
                    }
                } else {
                    setJoinWaitListStatus("joined");
                }
            })
            .catch((err) => {
                console.error(err?.error);
                setJoinWaitListStatus("joined_failed");
                toast({
                    title: "Cannot add your email at this time. Please try again later",
                    status: "error",
                });
            });
    };

    const showJoinWaitlistInput = () => {
        /**
         * This was handled for mobile
         * When the user first clicks on the join waitlist button, first we show the input's in the view
         * The second time the click, we process the request
         *
         */
        if (!joinInputDisplayState) {
            const inputs = document.getElementsByClassName(
                "waitlist-input-container",
            ) as HTMLCollectionOf<HTMLElement>;
            const container = document.getElementsByClassName(
                "input-text-contents",
            ) as HTMLCollectionOf<HTMLElement>;
            for (let i = 0; i < inputs.length; i++) {
                // inputs[i].style.display = "inline-flex";
                inputs[i].classList.add("show");
                container[i].classList.add("slide-up");
            }
            setJoinInputDisplayState(true);
            return;
        }
        joinWaitList();
    };

    function toggleSideNav(state: boolean) {
        setShowSmSideNav(state);
    }

    /**
     * This function fires when the first image loads
     */
    const onFirstImageLoad = () => {
        const loadingSkeleton =
            loadingSkeletonRef.current as unknown as HTMLElement;
        // wait for image to complete loading before displaying
        loadingSkeleton.remove();
    };

    /**
     *
     * Flip card
     */
    const flipCard = (e) => {
        (e.currentTarget as HTMLElement).classList.toggle("flip-card");
    };

    return (
        <>
            <main id="landing-page-view">
                <nav id="navbar" className="landing-nav">
                    <ul className="landing-nav-links">
                        <li>
                            <button onClick={() => changeTab(0)}>
                                <img
                                    className="logo white"
                                    src="/neo_ai_logo_white.png"
                                    alt=""
                                />
                                <img
                                    className="logo black"
                                    src="/neo_ai_logo_black.png"
                                    alt=""
                                />
                            </button>
                        </li>
                    </ul>
                    <ul className="landing-nav-links sm right">
                        <li>
                            {!showSmSideNav && (
                                <button
                                    onClick={() => toggleSideNav(true)}
                                    className="sm-toggle-sidenav"
                                >
                                    <FiMinus className="landing-nav-links-icon" />
                                </button>
                            )}
                        </li>
                    </ul>
                    <ul className="landing-nav-links lg right">
                        <li>
                            <button
                                id="about-link"
                                onClick={() => changeTab(5)}
                                className="landing-nav-link"
                            >
                                about
                            </button>
                        </li>
                        <li>
                            <button
                                id="pricing-link"
                                onClick={() => changeTab(6)}
                                className="landing-nav-link"
                            >
                                pricing
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={authenticateUser}
                                className="landing-nav-link"
                                disabled={
                                    process.env.REACT_APP_ENABLE_LOGIN !== "yes"
                                }
                                style={{
                                    cursor:
                                        process.env.REACT_APP_ENABLE_LOGIN ===
                                        "yes"
                                            ? "pointer"
                                            : "not-allowed",
                                }}
                            >
                                login
                            </button>
                        </li>
                    </ul>
                </nav>

                <div
                    className={`landing-page-wrapper ${
                        showSmSideNav ? "side-open" : ""
                    }`}
                >
                    <div
                        ref={loadingSkeletonRef}
                        className="skeleton-loader overview-panel"
                    >
                        <div className="relative h-full">
                            <Skeleton className="w-full h-full" />
                        </div>
                        <div className="h-full">
                            <div className="text-container-skeleton">
                                <Stack className="items-center">
                                    <Skeleton width="420px" height="20px" />
                                    <Skeleton width="420px" height="25px" />
                                    <Skeleton width="420px" height="50px" />
                                    <Skeleton width="420px" height="45px" />
                                </Stack>
                            </div>
                        </div>
                    </div>

                    <section
                        id="banner"
                        {...handlers}
                        className={`overview-panel active-state-${
                            activeTab + 1
                        }`}
                    >
                        <div className="img-container">
                            {/*<Skeleton style={{zIndex: 9999 }} className="w-full h-full" />*/}
                            <img
                                src="/FrontPage_NeoAI_WomanAsset.png"
                                onLoad={onFirstImageLoad}
                                alt="landing_image"
                            />
                            <img
                                src="/Superhumans_Image_Page.png"
                                alt="landing_image"
                            />
                            <img
                                src="/IntelligenceAsAService_Sepia.png"
                                alt="landing_image"
                            />
                            <div className="video-container">
                                <video
                                    ref={infinityVideoRef}
                                    id="bg-video"
                                    playsInline={true}
                                    poster="/Infinity_Page_Image.png"
                                    autoPlay={false}
                                    controls={false}
                                    preload="metadata"
                                    loop={false}
                                >
                                    <source
                                        src="/NewInfinity.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                {!videoHasFinished && (
                                    <div className="actions">
                                        <button
                                            onClick={changeMuteStatus}
                                            className="action-btn"
                                        >
                                            {isVideoMuted ? (
                                                <BsFillVolumeMuteFill className="icon" />
                                            ) : (
                                                <BsFillVolumeUpFill className="icon" />
                                            )}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="text-container">
                            <div className="digital-twin input-text-contents text-contents">
                                <div className="feature-btn-section">
                                    <button
                                        className="btn"
                                        onClick={() => changeTab()}
                                    >
                                        <BsChevronRight className="icon" />
                                    </button>
                                </div>
                                <div className="feature-btn-container">
                                    <button
                                        className="feature-btn"
                                        onClick={() => changeTab()}
                                    >
                                        Next
                                        <TbArrowRightCircle className="icon" />
                                    </button>
                                </div>

                                <div className="banner-header">
                                    <h1 className="title">
                                        Meet Your Digital Twin
                                    </h1>
                                    <h2 className="subtitle">
                                        Clone your memories and behaviors to
                                        create a second you.
                                    </h2>
                                </div>
                                {joinWaitListStatus === "joined" ? (
                                    <FormControl mt={6}>
                                        <div className="waitlist-success">
                                            <AiFillCheckCircle className="check-icon text-green-500" />
                                            <div className="">
                                                <p className="title">
                                                    Thank you for joining the
                                                    waitlist.
                                                </p>
                                                <p className="text">
                                                    You will be notified when
                                                    it's your turn to signup.
                                                </p>
                                            </div>
                                        </div>
                                    </FormControl>
                                ) : (
                                    <>
                                        <FormControl className="waitlist-input-container">
                                            <input
                                                type="email"
                                                value={waitListEmail}
                                                onChange={(e) =>
                                                    setWaitListEmail(
                                                        e.target.value,
                                                    )
                                                }
                                                className="input-waitlist mx-auto"
                                                placeholder={
                                                    waitListCount > 0
                                                        ? `Email (${formatReadableNumber(
                                                              waitListCount,
                                                          )} in Waitlist)`
                                                        : "Email"
                                                }
                                                disabled={
                                                    joinWaitListStatus ===
                                                    "loading"
                                                }
                                            />
                                        </FormControl>
                                        <FormControl mt={6}>
                                            <Button
                                                isLoading={
                                                    joinWaitListStatus ===
                                                    "loading"
                                                }
                                                loadingText="Joining.."
                                                onClick={showJoinWaitlistInput}
                                                size="lg"
                                                className="join-btn md:hidden sm"
                                            >
                                                Join Waitlist
                                            </Button>

                                            <Button
                                                isLoading={
                                                    joinWaitListStatus ===
                                                    "loading"
                                                }
                                                loadingText="Joining.."
                                                onClick={() => joinWaitList()}
                                                size="lg"
                                                className="join-btn sm:hidden lg"
                                            >
                                                Join Waitlist
                                            </Button>
                                        </FormControl>
                                    </>
                                )}
                            </div>

                            <div className="grow-superhumans input-text-contents text-contents">
                                <div className="feature-btn-section">
                                    <button
                                        className="btn"
                                        onClick={() => changeTab(0)}
                                    >
                                        <BsChevronLeft className="icon" />
                                    </button>
                                    <button
                                        className="btn"
                                        onClick={() => changeTab()}
                                    >
                                        <BsChevronRight className="icon" />
                                    </button>
                                </div>

                                <div className="feature-btn-container">
                                    <button
                                        className="feature-btn prev"
                                        onClick={() => changeTab(0)}
                                    >
                                        <FaChevronLeft className="icon" />
                                        {/*<TbArrowLeftCircle className="icon"/>*/}
                                    </button>
                                    <button
                                        className="feature-btn"
                                        onClick={() => changeTab()}
                                    >
                                        Next
                                        <TbArrowRightCircle className="icon" />
                                    </button>
                                </div>

                                <div className="banner-header">
                                    <h1 className="title">Grow Superhumans</h1>
                                    <h2 className="subtitle">
                                        Shape your personas, bring them to life,
                                        and copyright them.
                                    </h2>
                                </div>
                                {joinWaitListStatus === "joined" ? (
                                    <FormControl mt={6}>
                                        <div className="waitlist-success">
                                            <AiFillCheckCircle className="check-icon text-green-500" />
                                            <div className="">
                                                <p className="title">
                                                    Thank you for joining the
                                                    waitlist.
                                                </p>
                                                <p className="text">
                                                    You will be notified when
                                                    it's your turn to signup.
                                                </p>
                                            </div>
                                        </div>
                                    </FormControl>
                                ) : (
                                    <>
                                        <FormControl className="waitlist-input-container">
                                            <input
                                                type="email"
                                                value={waitListEmail}
                                                onChange={(e) =>
                                                    setWaitListEmail(
                                                        e.target.value,
                                                    )
                                                }
                                                className="input-waitlist mx-auto"
                                                placeholder={
                                                    waitListCount > 0
                                                        ? `Email (${formatReadableNumber(
                                                              waitListCount,
                                                          )} in Waitlist)`
                                                        : "Email"
                                                }
                                                disabled={
                                                    joinWaitListStatus ===
                                                    "loading"
                                                }
                                            />
                                        </FormControl>
                                        <FormControl mt={6}>
                                            <Button
                                                isLoading={
                                                    joinWaitListStatus ===
                                                    "loading"
                                                }
                                                loadingText="Joining.."
                                                onClick={showJoinWaitlistInput}
                                                size="lg"
                                                className="join-btn md:hidden sm"
                                            >
                                                Join Waitlist
                                            </Button>

                                            <Button
                                                isLoading={
                                                    joinWaitListStatus ===
                                                    "loading"
                                                }
                                                loadingText="Joining.."
                                                onClick={() => joinWaitList()}
                                                size="lg"
                                                className="join-btn sm:hidden lg"
                                            >
                                                Join Waitlist
                                            </Button>
                                        </FormControl>
                                    </>
                                )}
                            </div>

                            <div
                                data-index="2"
                                className="intelligence-as-service text-contents text-context-no-inputs"
                            >
                                <div className="feature-btn-section">
                                    <button
                                        className="btn"
                                        onClick={() => changeTab(1)}
                                    >
                                        <BsChevronLeft className="icon" />
                                    </button>
                                    <button
                                        className="btn"
                                        onClick={() =>
                                            setShowIntelligencePopUP(true)
                                        }
                                    >
                                        <CgArrowsExpandUpRight className="icon" />
                                    </button>
                                    <button
                                        className="btn"
                                        onClick={() => changeTab()}
                                    >
                                        <BsChevronRight className="icon" />
                                    </button>
                                </div>
                                <div className="banner-header">
                                    <div className="expand-section text-center mb-6">
                                        <button
                                            className="feature-btn expand"
                                            onClick={() =>
                                                setShowIntelligencePopUP(true)
                                            }
                                        >
                                            <CgArrowsExpandUpRight className="text-4xl inline" />
                                        </button>
                                    </div>
                                    <h1 className="title">
                                        Intelligence As A Service
                                    </h1>
                                    <h2 className="subtitle lg hidden md:block">
                                        Collaborate with your personas and give
                                        them autonomy <br />
                                        to think for themselves while they
                                        complete your tasks.
                                    </h2>
                                    <h2 className="subtitle subtitle-larger sm block md:hidden">
                                        Collaborate with your personas and give
                                        them autonomy to think for themselves
                                        while they complete your tasks.
                                    </h2>
                                </div>
                                <FormControl mt={4}>
                                    <div className="feature-btn-container">
                                        <button
                                            className="feature-btn prev"
                                            onClick={() => changeTab(1)}
                                        >
                                            <FaChevronLeft className="icon" />
                                            {/*<TbArrowLeftCircle className="icon"/>*/}
                                        </button>
                                        <button
                                            className="feature-btn"
                                            onClick={() => changeTab()}
                                        >
                                            Next
                                            <TbArrowRightCircle className="icon" />
                                        </button>
                                    </div>
                                </FormControl>
                            </div>

                            <div className="embrace-infinity text-contents text-context-no-inputs">
                                {promptPopUp === "visible" && (
                                    <div className="pwa-prompt">
                                        <div className="pwa-prompt-header">
                                            <MdOutlineInstallDesktop className="icon" />
                                            <div className="title">
                                                <h2 className="title">
                                                    Download App
                                                </h2>
                                            </div>
                                        </div>
                                        <p className="description hidden md:block">
                                            Click the download icon on the
                                            browser bar to download the Neo AI
                                            App
                                        </p>
                                        <p className="description block md:hidden">
                                            Click the{" "}
                                            <FiMoreVertical className="icon" />{" "}
                                            menu on the browser bar and select
                                            Install App for a native experience.
                                        </p>
                                        <div className="actions">
                                            <button
                                                onClick={() =>
                                                    setPromptPopUp("opened")
                                                }
                                            >
                                                Done
                                            </button>
                                            <button
                                                onClick={() =>
                                                    setPromptPopUp("opened")
                                                }
                                            >
                                                Go Away
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="feature-btn-section">
                                    <button
                                        className="btn"
                                        onClick={() => changeTab(2)}
                                    >
                                        <BsChevronLeft className="icon" />
                                    </button>
                                    {isPWASupported() && (
                                        <button
                                            className={`btn hidden ${
                                                promptPopUp === "opened"
                                                    ? "hide"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setPromptPopUp("visible")
                                            }
                                        >
                                            <CgArrowsExpandUpRight className="icon" />
                                        </button>
                                    )}
                                    <button
                                        className="btn"
                                        onClick={() => changeTab()}
                                    >
                                        <BsChevronRight className="icon" />
                                    </button>
                                </div>
                                <div className="banner-header no-input-header">
                                    {promptPopUp !== "opened" &&
                                        isPWASupported() && (
                                            <div
                                                className={`expand-section text-center mb-6`}
                                            >
                                                <button
                                                    className={`feature-btn expand`}
                                                    onClick={() =>
                                                        setPromptPopUp(
                                                            "visible",
                                                        )
                                                    }
                                                >
                                                    <CgArrowsExpandUpRight className="text-4xl inline" />
                                                </button>
                                            </div>
                                        )}
                                    <h1 className="title">Embrace Infinity</h1>
                                    <div className="hidden md:block">
                                        <h2 className="subtitle ">
                                            Stay forever close to your loved
                                            ones and create lasting <br />
                                            memories beyond the boundaries of
                                            time.
                                        </h2>
                                        <h2 className="subtitle mt-4 mb-2">
                                            Your virtual legacy awaits.
                                        </h2>
                                    </div>
                                    <div className="block md:hidden">
                                        <h2 className="subtitle subtitle-larger">
                                            Stay forever close to your loved
                                            ones and create lasting memories
                                            beyond the boundaries of time. Your
                                            virtual legacy awaits.
                                        </h2>
                                    </div>
                                </div>
                                <FormControl mt={4}>
                                    <div className="feature-btn-container">
                                        <button
                                            className="feature-btn prev"
                                            onClick={() => changeTab(2)}
                                        >
                                            <FaChevronLeft className="icon" />
                                            {/*<TbArrowLeftCircle className="icon"/>*/}
                                        </button>
                                        <button
                                            className="feature-btn"
                                            onClick={() => changeTab()}
                                        >
                                            Next
                                            <TbArrowRightCircle className="icon" />
                                        </button>
                                    </div>
                                </FormControl>
                            </div>
                        </div>
                    </section>

                    <section id="collaboration" className="four-cards-panel">
                        <div className="nav-back-action">
                            <button
                                className="btn rotate-180"
                                onClick={() => changeTab(3)}
                            >
                                <BsChevronDown className="icon" />
                            </button>
                        </div>

                        <h1 className="title">
                            Partake in an Unusual <br /> Collaboration
                        </h1>
                        <h2 className="subtitle lg hidden md:block">
                            Pick your app, invite your colleagues and personas
                            to <br />
                            collaborate and initialize the process. Humans and
                            AI <br />
                            interactions have never been this powerful.
                        </h2>
                        <h2 className="subtitle sm block md:hidden">
                            Pick your app, invite your colleagues and personas
                            to collaborate and initialize the process. Humans
                            and AI interactions have never been this powerful.
                        </h2>

                        <img
                            src="/collaboration.png"
                            alt=""
                            className="collaboration-image"
                        />

                        <div className="right-feature-btn">
                            <button
                                className="feature-btn prev"
                                onClick={() => changeTab(3)}
                            >
                                <FaChevronUp className="icon previous-icon vertical-icon" />
                            </button>

                            <button
                                className="feature-btn "
                                onClick={() => changeTab()}
                            >
                                Next
                                <TbArrowDownCircle className="icon vertical-icon" />
                            </button>
                        </div>

                        <div className="right-feature-sm-btn flex-col gap-8 items-center">
                            <button className="btn" onClick={() => changeTab()}>
                                <BsChevronDown className="icon" />
                            </button>
                        </div>
                    </section>

                    <section id="about" className="overview-panel shared-panel">
                        <div className="img-container video-container">
                            {isMobile ? (
                                <video
                                    ref={aboutVideoRef}
                                    id="about-video"
                                    poster="/Luka-New-Image.png"
                                    autoPlay={false}
                                    controls={false}
                                    playsInline={true}
                                    preload="metadata"
                                    loop={false}
                                    onEnded={() => setIsVideoPlaying(false)}
                                >
                                    <source
                                        src="/Luka_sm.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                            ) : (
                                <video
                                    ref={aboutVideoRef}
                                    id="about-video"
                                    poster="/Luka-New-Image.png"
                                    autoPlay={false}
                                    controls={false}
                                    playsInline={true}
                                    preload="metadata"
                                    loop={false}
                                    onEnded={() => setIsVideoPlaying(false)}
                                >
                                    <source
                                        src="/Luka_NewVideo.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                            )}
                            {!videoHasFinished && (
                                <div className="actions">
                                    <button
                                        onClick={changeMuteStatus}
                                        className="action-btn"
                                    >
                                        {isVideoMuted ? (
                                            <BsFillVolumeMuteFill className="icon" />
                                        ) : (
                                            <BsFillVolumeUpFill className="icon" />
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className="text-container flex-col">
                            <div className="text-contents about">
                                <div className="header">
                                    <h1 className="title">
                                        The Everything App.
                                    </h1>
                                    <h2 className="subtitle">
                                        Upload digital memories to your second
                                        brain, create intelligent friends,
                                        advisors, and workers, collaborate with
                                        personas and humans, build and deploy AI
                                        apps tailored to you.
                                    </h2>
                                </div>
                            </div>
                            <div className="container-action">
                                {aboutContainerScrollPosition === "bottom" && (
                                    <button
                                        onClick={() =>
                                            scrollVerticalInAboutContainer(-1)
                                        }
                                        className="container-action-btn"
                                    >
                                        <TbArrowDownCircle className="icon up-icon" />
                                        {/*<span className="text">next</span>*/}
                                    </button>
                                )}

                                {aboutContainerScrollPosition === "top" && (
                                    <button
                                        onClick={() =>
                                            scrollVerticalInAboutContainer(1)
                                        }
                                        className="container-action-btn"
                                    >
                                        <TbArrowDownCircle className="icon" />
                                        {/*<span className="text">next</span>*/}
                                    </button>
                                )}
                            </div>

                            <div
                                ref={aboutCardsContainerRef}
                                className="container-cards"
                            >
                                <div className="card-item-row">
                                    <div className="left-col">
                                        <button
                                            onClick={() =>
                                                setShowBannerPopUp(true)
                                            }
                                        >
                                            <CgArrowsExpandUpRight className="icon" />
                                        </button>
                                    </div>
                                    <div className="w-full">
                                        <div className="card-item">
                                            <h1 className="title">
                                                Tailored To You
                                            </h1>
                                            <p className="description">
                                                Interact with your digital
                                                brain, store only the memories
                                                you want, and sharpen it.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right-col">
                                        <div className="feature-btn-section">
                                            <button
                                                className="btn"
                                                onClick={() =>
                                                    scrollHorizontalInAboutContainer(
                                                        1,
                                                    )
                                                }
                                            >
                                                <BsChevronRight className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-item-row">
                                    <div className="left-col">
                                        <button
                                            onClick={() =>
                                                setShowAccessInformationModal(
                                                    true,
                                                )
                                            }
                                        >
                                            <CgArrowsExpandUpRight className="icon" />
                                        </button>
                                    </div>
                                    <div className="w-full">
                                        <div className="card-item black-card">
                                            <h1 className="title">
                                                Access Information
                                            </h1>
                                            <p className="description">
                                                Find the needle in your data
                                                haystacks, and make them
                                                actionable instantly.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right-col">
                                        <div className="feature-btn-section">
                                            <button
                                                className="btn"
                                                onClick={() =>
                                                    scrollHorizontalInAboutContainer(
                                                        1,
                                                    )
                                                }
                                            >
                                                <BsChevronRight className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-item-row">
                                    <div className="left-col no-icon">
                                        {/*<button onClick={() => setShowBannerPopUp(true)}>*/}
                                        {/*    <CgArrowsExpandUpRight className="icon"/>*/}
                                        {/*</button>*/}
                                    </div>
                                    <div className="w-full">
                                        <div className="card-item">
                                            <h1 className="title">
                                                It’s Your Data
                                            </h1>
                                            <p className="description">
                                                Maintain control with your
                                                personal keys. We don't have
                                                access to your data, except for
                                                information that is encrypted.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right-col">
                                        <div className="feature-btn-section">
                                            <button
                                                className="btn"
                                                onClick={() =>
                                                    scrollHorizontalInAboutContainer(
                                                        1,
                                                    )
                                                }
                                            >
                                                <BsChevronRight className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-item-row">
                                    <div className="left-col">
                                        <button
                                            onClick={() =>
                                                setShowNewsLetterModal(true)
                                            }
                                        >
                                            <CgArrowsExpandUpRight className="icon" />
                                        </button>
                                    </div>
                                    <div className="w-full">
                                        <div className="card-item black-card">
                                            <h1 className="title">Personas</h1>
                                            <p className="description">
                                                Clone yourself and establish
                                                copyrightable personas with
                                                authentic characteristics, such
                                                as voice and appearance. They
                                                are capable of original thought
                                                and can be monetized.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right-col">
                                        <div className="feature-btn-section">
                                            <button
                                                className="btn"
                                                onClick={() =>
                                                    scrollHorizontalInAboutContainer(
                                                        1,
                                                    )
                                                }
                                            >
                                                <BsChevronRight className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-item-row">
                                    <div className="left-col no-icon"></div>
                                    <div className="w-full">
                                        <div className="card-item">
                                            <h1 className="title">
                                                User Roles
                                            </h1>
                                            <p className="description">
                                                Create workspaces and invite
                                                your colleagues, family members
                                                or friends to collaborate, and
                                                control their access levels.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right-col">
                                        <div className="feature-btn-section">
                                            <button
                                                className="btn"
                                                onClick={() =>
                                                    scrollHorizontalInAboutContainer(
                                                        1,
                                                    )
                                                }
                                            >
                                                <BsChevronRight className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-item-row">
                                    <div className="left-col">
                                        <button
                                            onClick={() =>
                                                setShowArchitectModal(true)
                                            }
                                        >
                                            <CgArrowsExpandUpRight className="icon" />
                                        </button>
                                    </div>
                                    <div className="w-full">
                                        <div className="card-item black-card">
                                            <h1 className="title">
                                                You are the Architect
                                            </h1>
                                            <p className="description">
                                                Create apps with our cellular
                                                reasoning chain mechanism for
                                                your personas to execute.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right-col">
                                        <div className="feature-btn-section">
                                            <button
                                                className="btn"
                                                onClick={() =>
                                                    scrollHorizontalInAboutContainer(
                                                        1,
                                                    )
                                                }
                                            >
                                                <BsChevronRight className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-item-row">
                                    <div className="left-col">
                                        <button
                                            onClick={() =>
                                                setShowRegularPopUp(true)
                                            }
                                        >
                                            <CgArrowsExpandUpRight className="icon" />
                                        </button>
                                    </div>
                                    <div className="w-full">
                                        <div className="card-item">
                                            <h1 className="title">
                                                The Next Matrix
                                            </h1>
                                            <p className="description">
                                                We built a virtual world to be
                                                the home of AI beings. There,
                                                they live and get smarter. Your
                                                AI personas can live there too.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right-col">
                                        <div className="feature-btn-section">
                                            <button
                                                className="btn"
                                                onClick={() =>
                                                    scrollHorizontalInAboutContainer(
                                                        1,
                                                    )
                                                }
                                            >
                                                <BsChevronRight className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-item-row">
                                    <div className="left-col">
                                        <button
                                            onClick={() =>
                                                setShowWhiteRabbitModal(true)
                                            }
                                        >
                                            <CgArrowsExpandUpRight className="icon" />
                                        </button>
                                    </div>
                                    <div className="w-full">
                                        <div className="card-item black-card">
                                            <h1 className="title">
                                                Deployment
                                            </h1>
                                            <p className="description">
                                                Deploy your AI personas for
                                                collaboration, give them a job,
                                                or immerse them in the metaverse
                                                to experience digital
                                                immortality.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right-col">
                                        <div className="feature-btn-section">
                                            <button
                                                className="btn"
                                                onClick={() =>
                                                    scrollHorizontalInAboutContainer(
                                                        1,
                                                    )
                                                }
                                            >
                                                <BsChevronRight className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-item-row">
                                    <div className="left-col no-icon"></div>
                                    <div className="w-full">
                                        <div className="card-item">
                                            <div className="rating-container">
                                                <div className="icon-row">
                                                    <AiFillStar className="icon" />
                                                    <AiFillStar className="icon" />
                                                    <AiFillStar className="icon" />
                                                    <AiFillStar className="icon" />
                                                    <AiFillStar className="icon" />
                                                </div>
                                                <h2 className="rating-name">
                                                    Mark, ATS
                                                </h2>
                                            </div>
                                            <p className="">
                                                "I attempted to describe Neo AI
                                                to the team as though my brain
                                                now had a thumb drive in which I
                                                could store whatever I wanted."
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right-col">
                                        <div className="feature-btn-section">
                                            <button
                                                id="last-about-action-btn"
                                                className="btn"
                                                onClick={() =>
                                                    scrollHorizontalInAboutContainer(
                                                        1,
                                                    )
                                                }
                                            >
                                                <BsChevronRight className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="learn-more card-item-row mt-10">
                                    <div className="left-col no-icon"></div>
                                    <div className="learn-more-card text-center">
                                        <button
                                            onClick={() => setOpenChat(true)}
                                            className="action-btn"
                                        >
                                            Learn More.
                                        </button>
                                        <div className="feature-btn-section">
                                            <button
                                                className="btn"
                                                onClick={() => changeTab()}
                                            >
                                                <BsChevronDown className="icon" />
                                            </button>
                                        </div>

                                        <div className="mt-3">
                                            <button
                                                className="feature-btn"
                                                onClick={() => changeTab()}
                                            >
                                                Next
                                                <TbArrowRightCircle className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="right-col"></div>
                                </div>
                                <div className="overview-footer mt-8">
                                    <ul>
                                        <li>
                                            <a
                                                target="_blank"
                                                href="https://neoworlder.com"
                                            >
                                                NeoWorlder Inc.
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                href="https://lootverse.com"
                                            >
                                                Lootverse.
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                href="https://worldercore.com"
                                            >
                                                WorlderCore.
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                href="https://projectxlabs.ai"
                                            >
                                                Project X.
                                            </a>
                                        </li>
                                        <li
                                            onClick={() =>
                                                setShowTermsModal(true)
                                            }
                                        >
                                            <button>Terms.</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="pricing" className="four-cards-panel">
                        <div className="nav-back-action">
                            <button
                                className="btn rotate-180"
                                onClick={() => changeTab(5)}
                            >
                                <BsChevronDown className="icon" />
                            </button>
                        </div>
                        <h1 className="title">One Plan for All.</h1>
                        <h2 className="subtitle">
                            We have a simple payment plan. You pay $10 per user
                            per month which gives you 250,000 words to upload or
                            download monthly.
                        </h2>

                        <div className="pricing-cards-row">
                            <div className="pricing-cards black">
                                <div className="card-inner">
                                    <div className="card-front">
                                        <p className="text">$10/Month/User</p>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-cards" onClick={flipCard}>
                                <div className="card-inner card-inner-flip">
                                    <div className="card-front">
                                        <button className="action-icon">
                                            <AiFillInfoCircle />
                                        </button>
                                        <p className="text">
                                            $0.00003/word after your standard
                                            monthly allocation of 250,000 words
                                            has been consumed.
                                        </p>
                                    </div>
                                    <div className="card-back">
                                        <div className="pt-4">
                                            <h2 className="card-title">
                                                Excess Carries Over
                                            </h2>
                                            <p className="text">
                                                Pay a flat rate of $10 for the
                                                first 250k words, and $10 for
                                                every additional 250k words. Any
                                                additional words purchased and
                                                not used will carry forward.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-cards" onClick={flipCard}>
                                <div className="card-inner card-inner-flip">
                                    <div className="card-front">
                                        <button className="action-icon">
                                            <AiFillInfoCircle />
                                        </button>
                                        <p className="text">
                                            No long term subscription, we're
                                            month-to-month.
                                        </p>
                                    </div>
                                    <div className="card-back">
                                        <div className="pt-4">
                                            <h2 className="card-title">
                                                Contract Information
                                            </h2>
                                            <p className="text">
                                                If you choose to end your
                                                subscription with us, you won't
                                                be billed for the next month,
                                                but you can still use the
                                                platform until the end of the
                                                paid month.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="pricing-cards black"
                                onClick={flipCard}
                            >
                                <div className="card-inner">
                                    <div className="card-front">
                                        <p className="text">
                                            On Neo Al, words have more meaning.
                                            If your persona talks with tools for
                                            a task, we charge according to the
                                            number of words it uses in that
                                            interaction.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="next-action-card-sm">
                                <button
                                    className="next-btn -rotate-180"
                                    onClick={() => changeTab(0)}
                                >
                                    <BsChevronDown className="icon" />
                                </button>
                            </div>
                        </div>

                        <div className="hidden md:block mt-20">
                            <button
                                className="feature-btn"
                                onClick={() => changeTab(0)}
                            >
                                Next
                                <TbArrowRightCircle className="icon" />
                            </button>
                        </div>
                        <div className="four-cards-panel-footer md:mt-8">
                            <ul>
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://neoworlder.com"
                                    >
                                        NeoWorlder Inc.
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://lootverse.com"
                                    >
                                        Lootverse.
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://worldercore.com"
                                    >
                                        WorlderCore.
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://projectxlabs.ai"
                                    >
                                        Project X.
                                    </a>
                                </li>
                                <li onClick={() => setShowTermsModal(true)}>
                                    <button>Terms.</button>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>

                <div
                    className={`mobile-nav-links-container ${
                        showSmSideNav ? "side-open" : ""
                    }`}
                >
                    <button
                        onClick={() => toggleSideNav(false)}
                        className="close-btn"
                    ></button>
                    <ul className="main-links">
                        <li>
                            <button
                                onClick={authenticateUser}
                                className="landing-nav-link"
                            >
                                login
                            </button>
                        </li>
                        <li>
                            <button
                                id="about-link"
                                onClick={() => changeTab(5)}
                                className="landing-nav-link"
                            >
                                about
                            </button>
                        </li>
                        <li>
                            <button
                                id="pricing-link"
                                onClick={() => changeTab(6)}
                                className="landing-nav-link"
                            >
                                pricing
                            </button>
                        </li>
                    </ul>

                    <ul className="bottom-links">
                        <li>
                            <a target="_blank" href="https://neoworlder.com">
                                NeoWorlder
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://lootverse.com">
                                Lootverse
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://worldercore.com">
                                WorlderCore
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://projectxlabs.ai">
                                Project X
                            </a>
                        </li>
                        <li
                            onClick={() => {
                                toggleSideNav(false);
                                setTimeout(() => {
                                    setShowTermsModal(true);
                                }, 400);
                            }}
                        >
                            <button>Terms</button>
                        </li>
                    </ul>
                    <div className="mobile-nav-links-footer">
                        <p className="">v0.1.1</p>
                        <p className="">&copy; 2023 NeoWorlder, Inc.</p>
                    </div>
                </div>

                <ArinChat
                    isOpen={openChat}
                    onClose={() => setOpenChat(false)}
                />

                <NeoImageModal
                    isOpen={showIntelligencePopUP}
                    onClose={() => setShowIntelligencePopUP(false)}
                    textLength={"short"}
                    imageUrl="/NeuronsandCells_Small.png"
                >
                    <NeoImageModalTitle>
                        Sparks of Silicon Life
                    </NeoImageModalTitle>
                    <NeoImageModalParagraph>
                        We’ve developed a unique process known as “Cellular
                        Reasoning Chains,” where a persona creates
                        interconnected cells within its brain.
                    </NeoImageModalParagraph>
                    <NeoImageModalParagraph>
                        These cells are reasoning entities capable of executing
                        highly complex tasks. They have the unique ability to
                        auto-evaluate outcomes and auto-generate additional
                        cells in sequence.
                    </NeoImageModalParagraph>
                    <NeoImageModalParagraph>
                        You have the power to create any application that can
                        help you or your business thrive and evolve.
                    </NeoImageModalParagraph>
                </NeoImageModal>

                <NeoModal
                    isOpen={showBannerPopUp}
                    title={"You, the creator."}
                    subtitle={
                        "You can add, query and refine memories, and get your personas to " +
                        "interact with you. Know this: They will be capable of original thoughts."
                    }
                    type={"banner"}
                    onClose={() => setShowBannerPopUp(false)}
                />

                <NeoModal
                    isOpen={showRegularPopUp}
                    backgroundImageUrl={"/Lootverse_3.png"}
                    type={"regular"}
                    onClose={() => setShowRegularPopUp(false)}
                />

                <NewsletterModal
                    isOpen={showNewsLetterModal}
                    onClose={() => setShowNewsLetterModal(false)}
                />

                <NeoSheetModal
                    isOpen={showAccessInformationModal}
                    rightImageUrl={"/Luka_popup.png"}
                    onClose={() => setShowAccessInformationModal(false)}
                >
                    <NeoSheetModalTitle>The Role of Arins</NeoSheetModalTitle>
                    <NeoSheetModalDescription>
                        Neo's primary objective is to assist you in creating
                        artificially intelligent personas that enhance your
                        personal and professional life. We've streamlined this
                        complex, multi-step process so you can get started with
                        Neo in minutes.
                    </NeoSheetModalDescription>
                    <NeoSheetModalDescription>
                        Your journey begins when you establish your neural
                        pathways (APIs) and upload your digital files, referred
                        to as 'memories'. An 'arin', an acronym for Artificially
                        Intelligent Being, assists you in cleansing your data,
                        structuring it, and retrieving specific information from
                        it.
                    </NeoSheetModalDescription>
                    <NeoSheetModalDescription>
                        Our model leverages arins’ capabilities in determining
                        the information you require, when you require it,
                        thereby streamlining your decision-making process.
                    </NeoSheetModalDescription>
                    <NeoSheetModalDescription>
                        Arins are also instrumental in cataloguing and educating
                        your digital brain through a process of reinforced
                        learning. This method continually refines and improves
                        the accuracy of your query results over time, leading to
                        a more effective and personalized user experience.
                    </NeoSheetModalDescription>
                    <NeoSheetModalDescription>
                        At the same time, these memories are included in your
                        digital twin, or clone. Everyone on Neo gets a clone.
                    </NeoSheetModalDescription>
                </NeoSheetModal>

                <NeoImageModal
                    isOpen={showWhiteRabbitModal}
                    onClose={() => setShowWhiteRabbitModal(false)}
                    imageUrl="/WhiteRabbit2.png"
                >
                    <NeoImageModalTitle>
                        Neo, Meet White Rabbit
                    </NeoImageModalTitle>
                    <NeoImageModalParagraph>
                        At NeoWorlder, we are pioneering the architecture of a
                        new level of existence by creating the next layer of
                        simulated reality. This audacious endeavor requires us
                        to be the architects of an entire universe, crafting
                        realities for a new intelligent species.
                    </NeoImageModalParagraph>
                    <NeoImageModalParagraph>
                        Our virtual universe is complete with its unique
                        history, laws of physics, and AI inhabitants,
                        demonstrating our ability to create complex systems that
                        mirror our own world. For us, this is more than just a
                        project; it's the birth of an entirely new reality for
                        beings that we've engineered.
                    </NeoImageModalParagraph>
                    <NeoImageModalParagraph>
                        In essence, we are providing a foundation for the
                        argument that our reality might itself be a simulation.
                        The creation of these new worlds suggests that our own
                        existence could be the product of a more advanced
                        civilization's technological prowess.
                    </NeoImageModalParagraph>
                    <NeoImageModalParagraph>
                        Our work also hints at the possibility of many such
                        simulated worlds co-existing, just as there could be
                        countless human-made simulations nested within each
                        other. This brings forth a profound new understanding of
                        reality and our place within it, opening the gateway to
                        exploring the multiverse of simulated realities.
                    </NeoImageModalParagraph>
                </NeoImageModal>

                <NeoImageModal
                    isOpen={showArchitectPopUp}
                    onClose={() => setShowArchitectModal(false)}
                    textLength={"short"}
                    imageUrl="/Deployment_Background.png"
                    textContainerWidth={"514px"}
                >
                    <NeoImageModalTitle>
                        Now I am become seed, the bringer of life.
                    </NeoImageModalTitle>
                    <NeoImageModalParagraph>
                        There are many ways to engineer creativity and
                        intelligence on Neo. You can:
                    </NeoImageModalParagraph>
                    <NeoImageModalParagraph>
                        <p className="modal-arrow-poin-paragraph">
                            <div className="first-col">
                                <BsArrowRightSquare className="icon" />
                            </div>
                            <div className="">
                                create artificially intelligent personas with
                                personalities capable of solving tasks alone or
                                together with humans and other Al personas, and
                                share or monetize this capability outside of the
                                Neo platform,
                            </div>
                        </p>
                    </NeoImageModalParagraph>
                    <NeoImageModalParagraph>
                        <p className="modal-arrow-poin-paragraph">
                            <div className="first-col">
                                <BsArrowRightSquare className="icon" />
                            </div>
                            <div className="">
                                provide ready made memories for users to tap
                                into or making it available to their Al personas
                                in fulfilling their tasks,
                            </div>
                        </p>
                    </NeoImageModalParagraph>
                    <NeoImageModalParagraph>
                        <p className="modal-arrow-poin-paragraph">
                            <div className="first-col">
                                <BsArrowRightSquare className="icon" />
                            </div>
                            <div className="">
                                provide tools that personas use as part of the
                                task solving chain.
                            </div>
                        </p>
                    </NeoImageModalParagraph>
                    <NeoImageModalParagraph>
                        The intertwining of these interactions at scale gives
                        you front row seats to the evolution of humanity.
                    </NeoImageModalParagraph>
                </NeoImageModal>
                {/* Terms of Use modal*/}
                <PrivacyPolicyModal
                    isOpen={showTermsModal}
                    onClose={() => setShowTermsModal(false)}
                />

                {/*  modal*/}

                <Modal
                    isOpen={redirectDisclosure.isOpen}
                    onClose={redirectDisclosure.onClose}
                    size="xs"
                    isCentered={true}
                    closeOnOverlayClick={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody>
                            <div className="flex flex-col py-4 items-center">
                                <p className="mb-2">
                                    Authenticating with NeoWorlder SSO...
                                </p>
                                <Spinner size="md" />
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </main>
            <div
                id="orientation-message"
                className="hidden fixed z-50 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[90%] md:w-[50%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] bg-white rounded-lg shadow-lg p-4 md:p-8 flex flex-col gap-4 items-center justify-center"
            >
                {isTablet && (
                    <div className="tablet-mode flex flex-col gap-2 items-center">
                        <LordIcon
                            src="/lord-icons/rotate-device.json"
                            trigger="loop"
                            colors={{
                                primary: "#121331",
                                secondary: "#2196f3",
                            }}
                            stroke={40}
                            delay={1000}
                            size={90}
                        />
                        <h2 className="text-[16px]">
                            Please rotate your device for a better experience.
                        </h2>
                    </div>
                )}
                {isDesktop && (
                    <div className="desktop-mode flex flex-col gap-2 items-center">
                        <LordIcon
                            src="/lord-icons/resize-window.json"
                            trigger="loop"
                            colors={{
                                primary: "#121331",
                                secondary: "#2196f3",
                            }}
                            stroke={40}
                            delay={1000}
                            size={90}
                        />
                        <h2 className="text-[16px]">
                            Please maximize your browser window for a better
                            experience.
                        </h2>
                    </div>
                )}
            </div>
        </>
    );
}
