import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthUser } from "../../utils/auth.utils";
import {
    CollaborateState,
    setCollaborateViewMode,
    setEnableCollaborationInput,
    setIsCollaborateLoading,
    setLastDecisionMessageId,
} from "../../store/slices/collaborate.slice";
import "../../styles/CollaborateMessage.component.scss";
import { formatCollaborateMessage, formatCollaborationAppFileLink } from "../../utils/collaborate/parser";
import { Button, ButtonGroup, useToast } from "@chakra-ui/react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useHttpClient } from "../../utils/http.utils";
import { AutoInteractionMessage } from "../../models/collaboration.model";
import { getPersonaFullName } from "../../utils/personas.utils";

export function InputMessageTypeForm({
    message,
    onEdit,
}: {
    message: string;
    onEdit?: (msg: string) => void;
}) {
    const [editMode, setEditMode] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (editMode) {
            const input = inputRef.current as unknown as HTMLInputElement;
            input.value = message;
        }
    }, [editMode]);

    const onSave = () => {
        if (typeof onEdit === "function") {
            const input = inputRef.current as unknown as HTMLInputElement;
            onEdit(input.value);
        }
        setEditMode(false);
    };

    return (
        <div className="input-message-form-container my-2">
            {editMode ? (
                <div className="input-message-form">
                    <div className="input">
                        <input
                            ref={inputRef}
                            type="text"
                            placeholder="Enter suggestion"
                            name=""
                            id=""
                        />
                    </div>
                    <div className="actions">
                        <button className={"save-btn"} onClick={onSave}>
                            <AiOutlineCheck className="icon" />
                        </button>
                        <button
                            className={"cancel-btn"}
                            onClick={() => setEditMode(false)}
                        >
                            <AiOutlineClose className="icon" />
                        </button>
                    </div>
                </div>
            ) : (
                <div className="input-message-text">
                    <p className="text">{message}</p>
                    <button
                        className="edit-btn"
                        onClick={() => setEditMode(true)}
                    >
                        Edit
                    </button>
                </div>
            )}
        </div>
    );
}

export default function InteractionMessage({
    message,
}: {
    message: AutoInteractionMessage;
}) {
    const collaborate_state: CollaborateState = useSelector(
        (state) => (state as any).collaborate,
    );
    const searches = useSelector((state) => (state as any).searches);
    const authUser = getAuthUser();
    const [hasDecided, setHasDecided] = useState(false);
    const toast = useToast();

    const { initializeCollaborationAppApi, fetchCollaborationAppGroupApi, sendExecutionCommandApi } =
        useHttpClient();
    const dispatch = useDispatch();

    const handleDecision = async (decision: "yes" | "no") => {
        if (decision === "yes") {
            // Hide yes/no buttons
            setHasDecided(true);
            // await proceedAutoInteractionActionApi(collaborate_state.autoInteractionId, collaborate_state.collaborationAppId, decision);
            initializeCollaborationAppApi(
                collaborate_state.autoInteractionId,
                collaborate_state.collaborationAppId,
            ).catch((err) => {
                // Hide yes/no buttons
                setHasDecided(false);
            });
        } else {
            dispatch(setEnableCollaborationInput(true));
            // Hide yes/no buttons
            setHasDecided(true);
        }
    };

    const handleCollabDecision = async (decisionMessageType: AutoInteractionMessage["type"], decision: "yes" | "no") => {
        if (!collaborate_state.isInitialized) {
            toast({
                title: "You will have to first initialize the session",
                status: "warning",
            });
            return;
        }

        let responseType: "execute_next_cell" | "execute_previous_cell" | "execute_tool" | "cancel_execute_tool" | "" = "";

        if (
            collaborate_state.viewMode === "initialize" &&
            collaborate_state.selectedCollaborationApp?.id
        ) {
            if (decision === "yes") {
                switch (decisionMessageType){
                    case "execute_next_cell":
                        responseType = "execute_next_cell";
                        break;
                    case "execute_tool":
                        responseType = "execute_tool";
                        break;
                    default:
                }
            } else if (decision === "no") {
                switch (decisionMessageType){
                    case "execute_next_cell":
                        responseType = "execute_previous_cell";
                        break;
                    case "execute_tool":
                        responseType = "cancel_execute_tool";
                        break;
                    default:
                }
            }

            if (responseType !== "") {
                sendExecutionCommandApi(collaborate_state.selectedCollaborationApp.id, responseType);
                
                // Clear last decision message id
                dispatch(setLastDecisionMessageId(""));
    
                // Show loading spinner
                dispatch(setIsCollaborateLoading(true));
            }

        }
    };

    const shouldShowControlButtons = () => (message.type === "execute_next_cell" || message.type === "execute_tool") &&
        collaborate_state.lastDecisionMessageId === message.id;

    return (
        <div className="collaborate-message">
            {message.message_type === "ai" &&
                message.message_format === "normal" && (
                    <div className={"ai-message" + (shouldShowControlButtons()
                        ? " with-control-buttons"
                        : ""
                    )}>
                        <div className="character">
                            <p className="character-name">
                                {getPersonaFullName(
                                    collaborate_state.persona,
                                    message.persona
                                ) || searches.character.character}
                            </p>
                            <p className="character-message">
                                {formatCollaborateMessage(message.message)}
                            </p>
                            {message.message && message.file ? <br></br> : <></>}
                            {
                                message.file
                                    ? <p className="character-message character-message-file-link">
                                            {formatCollaborationAppFileLink(message.file)}
                                        </p>
                                    : <></>
                            }
                        </div>
                        {shouldShowControlButtons() && (
                            <div className="message-action">
                                <ButtonGroup>
                                    <Button
                                        onClick={() => handleCollabDecision(message.type, "yes")}
                                        size="sm"
                                        background="blue.50"
                                        color="blue.500"
                                    >
                                        Proceed
                                    </Button>
                                    <Button
                                        onClick={() => handleCollabDecision(message.type, "no")}
                                        size="sm"
                                        background="red.50"
                                        color="red.500"
                                    >
                                        Rerun Previous Cell
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )}
                    </div>
                )
            }
            {message.message_type === "user" &&
                message.message_format === "normal" && (
                    <div className="user-message-container">
                        <div className="user-message">
                            <p className="character-name">
                                {authUser.fullname || authUser.username}
                            </p>
                            <p className="character-message">
                                {formatCollaborateMessage(message.message)}
                            </p>
                        </div>
                    </div>
                )}
            {message.message_type === "ai" &&
                message.message_format === "decision" && (
                    <div className="ai-message">
                        <div className="character">
                            <p className="character-name">
                                {message.persona ||
                                    searches.character.character}
                            </p>
                            <p className="character-message">
                                {formatCollaborateMessage(message.message)}
                            </p>
                            {message.message && message.file ? <br></br> : <></>}
                            {
                                message.file
                                    ? <p className="character-message character-message-file-link">
                                            {formatCollaborationAppFileLink(message.file)}
                                        </p>
                                    : <></>
                            }
                        </div>
                        {!hasDecided && (
                            <div className="message-action">
                                <ButtonGroup>
                                    <Button
                                        onClick={() => handleDecision("yes")}
                                        size="sm"
                                        background="blue.50"
                                        color="blue.500"
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        onClick={() => handleDecision("no")}
                                        size="sm"
                                        background="red.50"
                                        color="red.500"
                                    >
                                        No
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )}
                    </div>
                )}
            {/*{message.message_type === "ai" && message.message_format === "input" &&*/}
            {/*    <div className="ai-message">*/}
            {/*        <div className="character">*/}
            {/*            <p className="character-name">{message.persona || searches.character.character}</p>*/}
            {/*            <p className="character-message">*/}
            {/*                {*/}
            {/*                    (message.message as string[]).map((item, index) => {*/}
            {/*                        return <InputMessageTypeForm*/}
            {/*                            message={item}*/}
            {/*                            onEdit={(msg) => updateInteractionGoal(index, msg)}*/}
            {/*                        />*/}
            {/*                    })*/}
            {/*                }*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
}
