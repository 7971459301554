import { AiFillWarning, AiOutlineSearch } from "react-icons/ai";
import { FiMinus, FiPlus, FiSettings } from "react-icons/fi";
import { MdOutlineMenuOpen } from "react-icons/md";
import { RiChatPrivateLine } from "react-icons/ri";
import { CiStickyNote } from "react-icons/ci";
import "../styles/SearchBox.component.scss";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { UploadDataState } from "../store/slices/memories.slice";
import {
    useDisclosure,
    Button,
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerBody,
    NumberInput,
    NumberInputField,
    DrawerContent,
    DrawerFooter,
    FormControl,
    Spinner,
    AccordionPanel,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Textarea,
    ButtonGroup,
    useToast,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { AI_MODEL, SearchConfig } from "../models/search.model";
import { useHttpClient } from "../utils/http.utils";
import {
    convertHeaderStringToList,
    removeDuplicatesByProperty,
} from "../utils/strings.utils";
import { GiBrain } from "react-icons/gi";
import Select from "react-select";
import { BsViewStacked, BsMic } from "react-icons/bs";
import { VscClearAll } from "react-icons/vsc";
import { isDOMElement } from "../utils/dom.utils";

interface SearchBoxProps {
    defaultInputValue?: string;
    loading?: boolean;
    loadingText?: string;
    onSearch: (value: string, params: SearchConfig) => void;
    onNotes?: (value: string, params: SearchConfig) => void;
    onClearInput?: () => void;
    onControlsFormToggle?: (value: boolean) => void;
}

export default function SearchBox(props: SearchBoxProps) {
    // Redux selectors
    const searches = useSelector((state) => (state as any).searches);
    const uploadedData = useSelector(
        (state) => (state as any).uploadedData as UploadDataState,
    );
    const chainedItems = useSelector((state) => (state as any).searches.chain);
    const [inputExpandMode, setInputExpandMode] = useState(false);
    // Refs
    const notesInput = useRef(null);
    // Disclosure settings
    const notesDisclosure = useDisclosure();
    const settingsDisclosure = useDisclosure();
    const toast = useToast();

    // Modal state controllers
    const { fetchMemoriesApi } = useHttpClient();
    const [sectorsOptions, setSectorsOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [defaultMemory, setDefaultMemory] = useState<{
        label: string;
        value: string;
    }>();
    const searchBoxRef = useRef(null);
    // Local state vars
    const [params, setParams] = useState<SearchConfig>({
        temp: 0.8,
        custom_prompt: "",
        prompt_used: "",
        relation: "",
        alpha: 1,
        is_structured: false,
        max_tokens: 500,
        top_p: 0.6,
        frequency_penalty: 0.11,
        presence_penalty: 0.24,
        top_k: 4,
        num_beam: 4,
        model: "O",
        data_upload_id: "",
    });
    const [isControlsFormVisible, setIsControlsFormVisible] = useState(false);

    // TODO FIND BETTER ALTERNATIVE FOR PASTE
    // useEffect(() => {
    //     if (searchBoxRef && searchBoxRef.current) {
    //         (searchBoxRef.current as any).addEventListener('paste', function (e) {
    //             e.preventDefault()
    //             const text = e.clipboardData.getData('text/plain');
    //             console.log(text)
    //             document.execCommand('insertText', false, text)
    //         });
    //     }
    // }, []);

    useEffect(() => {
        // Get sectors, remove duplicate sectors
        // TODO: ADD ALL SECTION AND REQUEST IMPLEMENTATIONS FOR SECTORS
        let sectorOptions = [
            { label: "All", value: "all" },
            ...uploadedData.memoriesTree.map((ns) => ({
                label: ns.sector_display as string,
                value: ns.namespace as string,
            })),
        ];

        // console.log(sectorOptions, "sectorOptions");//debug

        setSectorsOptions(sectorOptions);

        let persistedMemory;
        const memory = window.sessionStorage.getItem("selected_memory");
        if (memory) {
            persistedMemory = memory;
        }

        const ns = persistedMemory
            ? uploadedData.memoriesTree.find(
                  (sc) => sc.namespace === persistedMemory,
              )
            : uploadedData.memoriesTree[0];
        // Set default input for sectors
        setDefaultMemory({
            label: ns?.sector_display as string,
            value: ns?.namespace as string,
        });

        if (ns) {
            setParams({
                ...params,
                // check_list: ns.namespace as string,
                is_structured: !!ns.is_structured, // TODO: Update structure on mongodb object
                relation: ns.relation || "", // TODO: UPDATE RELATION
            });
        }
        // }, [uploadedData.namespace]);
    }, [uploadedData]);

    useEffect(() => {
        // set the default search value if passed
        if (searchBoxRef.current && props.defaultInputValue) {
            const split_text = convertHeaderStringToList(
                props.defaultInputValue || "",
            );

            let html_prompt = split_text.map((el) => {
                if (el.startsWith("[")) {
                    return `<div class="prompt"><p class="prompt-title">${el.slice(
                        1,
                        -1,
                    )}</p><input class="prompt-input" /></div>`;
                }
                return el;
            });
            // @ts-ignore
            searchBoxRef.current.innerHTML = html_prompt.join(" ");

            autoExpand(searchBoxRef.current);
        }
    }, [props.defaultInputValue]);

    /**
     * ======== GETTERS =======
     */
    function getChainProgress(): number {
        return (
            (chainedItems.length / Number(process.env.REACT_APP_MAX_CHAIN)) *
            100
        );
    }

    const setParamVal = (key: string, value: any) => {
        let _data: any = { ...params };
        _data[key] = value;
        setParams(_data);
    };

    const onNameSpaceSelect = (e: any) => {
        const value = e.value;

        if (window) {
            window.sessionStorage.setItem("selected_memory", value);
        }

        if (value !== "all") {
            // get namespace
            // const ns = uploadedData.namespace.find(
            //     (ns) => ns.namespace === value
            // );
            const ns = uploadedData.memoriesTree.find(
                (ns) => ns.namespace === value,
            );
            if (ns) {
                setParams({
                    ...params,
                    // check_list: ns.namespace as string,
                    is_structured: ns.is_structured as boolean,
                    relation: ns.relation as string,
                    data_upload_id: ns.id as string,
                });
            }
        } else {
            setParams({
                ...params,
                // check_list: "all",
                is_structured: false,
                relation: "",
            });
        }
    };

    const onInputChange = (event: any) => {
        // When the user presses enter
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            const inputEl = event.target;

            let formatted_prompt = "";

            // In the case of a prompt, loop through the template and extract the text
            for (const node of inputEl.childNodes) {
                if (isDOMElement(node)) {
                    const inputs = node.getElementsByClassName("prompt-input");
                    if (inputs.length > 1) {
                        formatted_prompt += inputs[0].value;
                    } else {
                        formatted_prompt += node.innerText;
                    }
                } else {
                    formatted_prompt += node.nodeValue;
                }
            }

            // Resize input box
            autoExpand(event.currentTarget);
            // Exit function if there are no memories

            if (uploadedData.memoriesTree.length === 0) {
                return;
            }
            // Get the value of the input if formatted prompt is empty, this done to handle the case where the user
            // presses enter without typing anything in the input box. As was the case with Firefox
            if (formatted_prompt.length === 0) {
                formatted_prompt = inputEl.textContent;
            }
            // Get input value
            if (typeof props.onSearch === "function") {
                props.onSearch(formatted_prompt.trim(), params);
            }
            // @ts-ignore
            searchBoxRef.current.innerHTML = "";
        }
    };

    const autoExpand = (element: any) => {
        element.style.height = "45px"; // Reset the height to get the scrollHeight correctly
        if (element.scrollHeight >= 90) {
            setInputExpandMode(true);
            element.style.height = "270px";
        } else {
            setInputExpandMode(false);
            element.style.height = "45px";
        }
        // if (element.scrollHeight > element.clientHeight) {
        //     element.style.height = element.scrollHeight < 401 ? element.scrollHeight + 'px' : '400px';
        // }
    };

    function onControlsFormToggle() {
        const newVal = !isControlsFormVisible;
        setIsControlsFormVisible(newVal);
        if (typeof props.onControlsFormToggle === "function") {
            props.onControlsFormToggle(newVal);
        }
    }

    function emitNotes() {
        // @ts-ignore
        const notesVal = notesInput.current.value;
        if (!notesVal) {
            toast({
                title: "Please input your notes",
                status: "warning",
            });
            return;
        }
        if (typeof props.onNotes === "function") {
            props.onNotes(notesVal, params);
        }
        notesDisclosure.onClose();
    }

    function clearInput() {
        // @ts-ignore
        searchBoxRef.current.innerHTML = "";

        setInputExpandMode(false);
        // @ts-ignore
        searchBoxRef.current.style.height = "45px";
        if (typeof props.onClearInput === "function") {
            props.onClearInput();
        }
    }

    return (
        <div className="search-container pt-3 bg-white pb-3 pl-4">
            {/*Chaining progress bar*/}
            <div className="chaining-progress-container">
                <div
                    className="progress-bar"
                    style={{ width: `${getChainProgress()}%` }}
                ></div>
            </div>
            {/* ========== MEMORIES CARD=============*/}
            {uploadedData.memoriesTree.length === 0 &&
                !uploadedData.loading &&
                !uploadedData.isError && (
                    <div className="error-container pb-5 mr-4">
                        <div className="memory-error py-2 px-4 flex items-center gap-2 rounded-lg bg-yellow-100">
                            <AiFillWarning className="text-yellow-600" />
                            <span className="font=semibold text-yellow-900">
                                You have no memories to query.
                            </span>
                            <NavLink to={"/store-memories"}>
                                <Button variant="link" colorScheme="blue">
                                    Upload memory now
                                </Button>
                            </NavLink>
                        </div>
                    </div>
                )}

            {uploadedData.isError && !uploadedData.loading && (
                <div className="error-container pb-5 mr-4">
                    <div className="memory-error py-2 px-4 flex items-center gap-2 rounded-lg bg-yellow-100">
                        <AiFillWarning className="text-yellow-600" />
                        <span className="font=semibold text-yellow-900">
                            Could not load your memories.
                        </span>
                        <Button
                            onClick={fetchMemoriesApi}
                            variant="link"
                            colorScheme="blue"
                        >
                            Try again
                        </Button>
                    </div>
                </div>
            )}

            {/*If re-fetching namespaces*/}
            {uploadedData.loading && (
                <div className="error-container pb-5 mr-4">
                    <div className="memory-error py-2 px-4 flex items-center gap-2 rounded-lg bg-gray-100">
                        <Spinner size="sm" />
                        <span className="font=semibold">Loading memories</span>
                    </div>
                </div>
            )}
            {/* In case of custom handling loading state*/}
            {props.loading && (
                <div className="error-container pb-5 mr-4">
                    <div className="memory-error py-2 px-4 flex items-center gap-2 rounded-lg bg-gray-100">
                        <Spinner size="sm" />
                        <span className="font=semibold">
                            {props.loadingText}
                        </span>
                    </div>
                </div>
            )}

            <div className="search-box bg-white">
                <div
                    className={`input-controls pr-1 ${
                        inputExpandMode ? "expand" : ""
                    }`}
                >
                    <div className="input-group">
                        <div className="search-icon-container">
                            <AiOutlineSearch className="search-icon" />
                        </div>
                        <div className="input-box flex flex-col justify-center w-full pl-2 flex-grow  relative">
                            <div className="relative">
                                <div
                                    ref={searchBoxRef}
                                    role="textbox"
                                    className="search-input-box"
                                    contentEditable={
                                        !(uploadedData.isError || props.loading)
                                    }
                                    onKeyDown={onInputChange}
                                    style={{ height: "45px" }}
                                    placeholder="Ask Anything"
                                    suppressContentEditableWarning
                                ></div>
                            </div>
                        </div>
                        {/*<div className="notes-action">*/}
                        {/*    */}
                        {/*</div>*/}
                    </div>
                    <div
                        className={`controls-toggle ${
                            inputExpandMode ? "expand" : ""
                        } flex items-center justify-center`}
                    >
                        <button onClick={clearInput} className="action-btn">
                            <VscClearAll className="icon" />
                        </button>
                        <button
                            className="action-btn"
                            onClick={notesDisclosure.onOpen}
                        >
                            <CiStickyNote className="icon" />
                        </button>
                        <button className="action-btn">
                            <BsMic className="icon" />
                        </button>
                        <button
                            className="action-btn"
                            onClick={onControlsFormToggle}
                        >
                            <BsViewStacked className="icon" />
                        </button>
                    </div>
                </div>

                {isControlsFormVisible && (
                    <div className="controls-form flex justify-between mr-4 pt-3 items-center">
                        {/*<div className="left-controls flex items-center">*/}
                        {/*<div className="select-box">*/}
                        {/*    /!*<p className="font-semibold text-xs mt-1 mb-1">Memory</p>*!/*/}
                        {/*    <Select*/}
                        {/*        defaultValue={defaultMemory}*/}
                        {/*        menuPlacement="top"*/}
                        {/*        className="arin-memory-select-container"*/}
                        {/*        classNamePrefix="arin-react-select"*/}
                        {/*        placeholder="Select Memory"*/}
                        {/*        onChange={onNameSpaceSelect}*/}
                        {/*        options={sectorsOptions}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/*</div>*/}

                        <div className="right-controls flex items-center">
                            <div className="tone-box pl-2">
                                <div className="tone-slot">
                                    <label className="w-full">
                                        <input
                                            type="radio"
                                            defaultChecked={true}
                                            onChange={(e) =>
                                                setParamVal(
                                                    "model",
                                                    e.target.value,
                                                )
                                            }
                                            value="O"
                                            name="product"
                                            className="tone-input-element"
                                        />

                                        <div className="tone-card flex gap-1.5 cursor-pointer">
                                            <div className="card-icon">
                                                <MdOutlineMenuOpen className="icon" />
                                            </div>
                                            <div className="card-content">
                                                <h4 className="title mb-0">
                                                    3rd Party Model
                                                </h4>
                                                <p className="subtitle text-xs mb-0 text-slate-500">
                                                    Non-Proprietary
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="tone-slot">
                                    <label className="w-full">
                                        <input
                                            type="radio"
                                            name="product"
                                            onChange={(e) =>
                                                setParamVal(
                                                    "model",
                                                    e.target.value,
                                                )
                                            }
                                            value="L"
                                            className="tone-input-element"
                                        />

                                        <div className="tone-card flex gap-1.5 cursor-pointer">
                                            <div className="card-icon">
                                                <RiChatPrivateLine className="icon" />
                                            </div>
                                            <div className="card-content">
                                                <h4 className="title m-0">
                                                    Private Model
                                                </h4>
                                                <p className="subtitle text-xs m-0 text-slate-500">
                                                    Self-Hosted
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div
                                onClick={settingsDisclosure.onOpen}
                                className="settings-card flex gap-1.5 cursor-pointer"
                            >
                                <div className="card-icon">
                                    <FiSettings className="icon" />
                                </div>
                                <div className="card-content">
                                    <h4 className="title m-0">
                                        Nucleus Settings
                                    </h4>
                                    <p className="subtitle text-xs m-0 text-slate-500">
                                        Modify Parameters
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/*  Modals*/}
            <Drawer
                isOpen={settingsDisclosure.isOpen}
                placement="right"
                size="sm"
                onClose={settingsDisclosure.onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />

                    <DrawerBody className="settings-drawer-body">
                        <div className="header flex gap-2 border-b pb-4 mb-6">
                            <div className="icon">
                                <img src="/n_settings.png" alt="" />
                            </div>
                            <div className="settings-text-head">
                                <h4 className="title text-lg">
                                    Nucleus Settings
                                </h4>
                                <p className="text-slate-500">
                                    Modify Parameters
                                </p>
                            </div>
                        </div>
                        <FormControl mb={5}>
                            <div className="py-2">
                                <span className="settings-title">
                                    Override Neural Pathway
                                </span>
                            </div>
                            <Select
                                options={[
                                    { label: "OpenAI GPT3.5", value: "gpt3.5" },
                                    { label: "OpenAI GPT4", value: "" },
                                ]}
                            />
                        </FormControl>

                        <FormControl mb={5}>
                            <Accordion allowMultiple>
                                <AccordionItem
                                    style={{ border: "none" }}
                                    px={0}
                                >
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                className="settings-accordion-button"
                                                px={0}
                                            >
                                                <Box
                                                    as="span"
                                                    flex="1"
                                                    textAlign="left"
                                                >
                                                    <span className="settings-title">
                                                        Maximum Response
                                                    </span>
                                                </Box>
                                                {isExpanded ? (
                                                    <FiMinus className="text-lg" />
                                                ) : (
                                                    <FiPlus className="text-lg" />
                                                )}
                                            </AccordionButton>
                                            <AccordionPanel px={0} pt={0}>
                                                <p className="text-sm text-slate-500">
                                                    A higher value allows for
                                                    longer responses, while a
                                                    lower value results in
                                                    shorter ones. (range 100 -
                                                    2048)
                                                </p>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            </Accordion>
                            <NumberInput
                                size="sm"
                                value={params.max_tokens}
                                onChange={(val) =>
                                    setParamVal("max_tokens", Number(val))
                                }
                            >
                                <NumberInputField placeholder="Enter Max tokens" />
                            </NumberInput>
                        </FormControl>

                        <FormControl mb={5}>
                            <Accordion allowMultiple>
                                <AccordionItem
                                    style={{ border: "none" }}
                                    px={0}
                                >
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                className="settings-accordion-button"
                                                px={0}
                                            >
                                                <Box
                                                    as="span"
                                                    flex="1"
                                                    textAlign="left"
                                                >
                                                    <span className="settings-title">
                                                        Idea Diversity
                                                    </span>
                                                </Box>
                                                {isExpanded ? (
                                                    <FiMinus className="text-lg" />
                                                ) : (
                                                    <FiPlus className="text-lg" />
                                                )}
                                            </AccordionButton>
                                            <AccordionPanel px={0} pt={0}>
                                                <p className="text-sm text-slate-500">
                                                    A higher value produces more
                                                    diverse and unexpected
                                                    responses, while a lower
                                                    value makes responses more
                                                    focused and consistent.
                                                    (range 0 - 1)
                                                </p>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            </Accordion>
                            <NumberInput
                                size="sm"
                                value={params.temp}
                                onChange={(val) =>
                                    setParamVal("temp", Number(val))
                                }
                            >
                                <NumberInputField placeholder="Enter Max tokens" />
                            </NumberInput>
                        </FormControl>

                        <FormControl mb={5}>
                            <Accordion allowMultiple>
                                <AccordionItem
                                    style={{ border: "none" }}
                                    px={0}
                                >
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                className="settings-accordion-button"
                                                px={0}
                                            >
                                                <Box
                                                    as="span"
                                                    flex="1"
                                                    textAlign="left"
                                                >
                                                    <span className="settings-title">
                                                        Response Length Control
                                                    </span>
                                                </Box>
                                                {isExpanded ? (
                                                    <FiMinus className="text-lg" />
                                                ) : (
                                                    <FiPlus className="text-lg" />
                                                )}
                                            </AccordionButton>
                                            <AccordionPanel px={0} pt={0}>
                                                <p className="text-sm text-slate-500">
                                                    A higher value results in
                                                    longer responses, while a
                                                    lower value produces shorter
                                                    ones. (range 0 - 1)
                                                </p>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            </Accordion>
                            <NumberInput
                                size="sm"
                                value={params.alpha}
                                onChange={(val) =>
                                    setParamVal("alpha", Number(val))
                                }
                            >
                                <NumberInputField placeholder="Enter Max tokens" />
                            </NumberInput>
                        </FormControl>

                        <FormControl mb={5}>
                            <Accordion allowMultiple>
                                <AccordionItem
                                    style={{ border: "none" }}
                                    px={0}
                                >
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                className="settings-accordion-button"
                                                px={0}
                                            >
                                                <Box
                                                    as="span"
                                                    flex="1"
                                                    textAlign="left"
                                                >
                                                    <span className="settings-title">
                                                        Idea Selection Range
                                                    </span>
                                                </Box>
                                                {isExpanded ? (
                                                    <FiMinus className="text-lg" />
                                                ) : (
                                                    <FiPlus className="text-lg" />
                                                )}
                                            </AccordionButton>
                                            <AccordionPanel px={0} pt={0}>
                                                <p className="text-sm text-slate-500">
                                                    A higher value considers of
                                                    a wider range of responses,
                                                    while a lower value narrows
                                                    that range. (range 0 - 1)
                                                </p>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            </Accordion>
                            <NumberInput
                                size="sm"
                                value={params.top_p}
                                onChange={(val) =>
                                    setParamVal("top_p", Number(val))
                                }
                            >
                                <NumberInputField placeholder="Enter Max tokens" />
                            </NumberInput>
                        </FormControl>

                        {params.model === AI_MODEL.PRIVATE && (
                            <FormControl mb={3}>
                                <Accordion allowMultiple>
                                    <AccordionItem
                                        style={{ border: "none" }}
                                        px={0}
                                    >
                                        {({ isExpanded }) => (
                                            <>
                                                <AccordionButton
                                                    className="settings-accordion-button"
                                                    px={0}
                                                >
                                                    <Box
                                                        as="span"
                                                        flex="1"
                                                        textAlign="left"
                                                    >
                                                        <span className="settings-title">
                                                            Idea Selection Range
                                                        </span>
                                                    </Box>
                                                    {isExpanded ? (
                                                        <FiMinus className="text-lg" />
                                                    ) : (
                                                        <FiPlus className="text-lg" />
                                                    )}
                                                </AccordionButton>
                                                <AccordionPanel px={0} pt={0}>
                                                    <p className="text-sm text-slate-500">
                                                        A higher value considers
                                                        of a wider range of
                                                        responses, while a lower
                                                        value narrows that
                                                        range. (range 0 - 1)
                                                    </p>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                </Accordion>
                                <NumberInput
                                    size="sm"
                                    value={params.top_k}
                                    onChange={(val) =>
                                        setParamVal("top_k", Number(val))
                                    }
                                >
                                    <NumberInputField placeholder="Enter Max tokens" />
                                </NumberInput>
                            </FormControl>
                        )}

                        {params.model === AI_MODEL.PUBLIC && (
                            <FormControl mb={3}>
                                <Accordion allowMultiple>
                                    <AccordionItem
                                        style={{ border: "none" }}
                                        px={0}
                                    >
                                        {({ isExpanded }) => (
                                            <>
                                                <AccordionButton
                                                    className="settings-accordion-button"
                                                    px={0}
                                                >
                                                    <Box
                                                        as="span"
                                                        flex="1"
                                                        textAlign="left"
                                                    >
                                                        <span className="settings-title">
                                                            Uncommon Word
                                                            Deterrent
                                                        </span>
                                                    </Box>
                                                    {isExpanded ? (
                                                        <FiMinus className="text-lg" />
                                                    ) : (
                                                        <FiPlus className="text-lg" />
                                                    )}
                                                </AccordionButton>
                                                <AccordionPanel px={0} pt={0}>
                                                    <p className="text-sm text-slate-500">
                                                        A higher value
                                                        discourages uncommon
                                                        words, while a lower
                                                        value allows more
                                                        unusual word choices.
                                                        (range 0 - 2)
                                                    </p>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                </Accordion>
                                <NumberInput
                                    value={params.frequency_penalty}
                                    onChange={(val) =>
                                        setParamVal(
                                            "frequency_penalty",
                                            Number(val),
                                        )
                                    }
                                >
                                    <NumberInputField placeholder="Enter Max tokens" />
                                </NumberInput>
                            </FormControl>
                        )}

                        {params.model === AI_MODEL.PUBLIC && (
                            <FormControl mb={3}>
                                <Accordion allowMultiple>
                                    <AccordionItem
                                        style={{ border: "none" }}
                                        px={0}
                                    >
                                        {({ isExpanded }) => (
                                            <>
                                                <AccordionButton
                                                    className="settings-accordion-button"
                                                    px={0}
                                                >
                                                    <Box
                                                        as="span"
                                                        flex="1"
                                                        textAlign="left"
                                                    >
                                                        <span className="settings-title">
                                                            New Idea Inclusion
                                                        </span>
                                                    </Box>
                                                    {isExpanded ? (
                                                        <FiMinus className="text-lg" />
                                                    ) : (
                                                        <FiPlus className="text-lg" />
                                                    )}
                                                </AccordionButton>
                                                <AccordionPanel px={0} pt={0}>
                                                    <p className="text-sm text-slate-500">
                                                        A higher value
                                                        encourages more new
                                                        ideas, while a lower
                                                        value focuses on
                                                        answering narrowly.
                                                        (range 0 - 2)
                                                    </p>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                </Accordion>
                                <NumberInput
                                    value={params.presence_penalty}
                                    onChange={(val) =>
                                        setParamVal(
                                            "presence_penalty",
                                            Number(val),
                                        )
                                    }
                                >
                                    <NumberInputField placeholder="Enter Max tokens" />
                                </NumberInput>
                            </FormControl>
                        )}

                        {params.model === AI_MODEL.PRIVATE && (
                            <FormControl mb={3}>
                                <Accordion allowMultiple>
                                    <AccordionItem
                                        style={{ border: "none" }}
                                        px={0}
                                    >
                                        {({ isExpanded }) => (
                                            <>
                                                <AccordionButton
                                                    className="settings-accordion-button"
                                                    px={0}
                                                >
                                                    <Box
                                                        as="span"
                                                        flex="1"
                                                        textAlign="left"
                                                    >
                                                        <span className="settings-title">
                                                            Idea Path
                                                        </span>
                                                    </Box>
                                                    {isExpanded ? (
                                                        <FiMinus className="text-lg" />
                                                    ) : (
                                                        <FiPlus className="text-lg" />
                                                    )}
                                                </AccordionButton>
                                                <AccordionPanel px={0} pt={0}>
                                                    <p className="text-sm text-slate-500">
                                                        This setting gives your
                                                        brain more 'idea paths'
                                                        to choose from when it's
                                                        generating text. A
                                                        higher setting means it
                                                        can explore more ideas,
                                                        which could make the
                                                        text better, but it also
                                                        means the computer has
                                                        to do more work.
                                                    </p>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                </Accordion>
                                <NumberInput
                                    value={params.num_beam}
                                    onChange={(val) =>
                                        setParamVal("num_beam", Number(val))
                                    }
                                >
                                    <NumberInputField placeholder="Enter Max tokens" />
                                </NumberInput>
                            </FormControl>
                        )}
                    </DrawerBody>

                    <DrawerFooter>
                        <Button
                            variant="outline"
                            mr={3}
                            onClick={settingsDisclosure.onClose}
                        >
                            Close
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            {/*    NOtest monodal*/}
            <Modal
                isOpen={notesDisclosure.isOpen}
                onClose={notesDisclosure.onClose}
                size="xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Accordion allowMultiple>
                            <AccordionItem style={{ border: "none" }} px={0}>
                                {({ isExpanded }) => (
                                    <>
                                        <AccordionButton
                                            className="settings-accordion-button"
                                            px={0}
                                        >
                                            <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                            >
                                                <p className="font-bold flex gap-1 text-lg">
                                                    <CiStickyNote className="text-2xl" />
                                                    &nbsp;Notes
                                                </p>
                                            </Box>
                                            {isExpanded ? (
                                                <FiMinus className="text-lg" />
                                            ) : (
                                                <FiPlus className="text-lg" />
                                            )}
                                        </AccordionButton>
                                        <AccordionPanel px={0} pt={0}>
                                            <p className="text-sm text-slate-500">
                                                I will revise your notes,
                                                ensuring grammatical accuracy
                                                and coherence. Subsequently, I
                                                will store them in a sector of
                                                your memory named "Thoughts"
                                                (description: Topics that I
                                                record periodically,
                                                encapsulating matters of
                                                importance to me) within a
                                                location termed "Notes"
                                                (description: Thoughts,
                                                observations and notes that I
                                                document from time to time).
                                            </p>
                                        </AccordionPanel>
                                    </>
                                )}
                            </AccordionItem>
                        </Accordion>

                        <FormControl mt={1}>
                            <Textarea
                                ref={notesInput}
                                placeholder="Enter notes"
                                rows={19}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup spacing="3">
                            <Button colorScheme="brand" onClick={emitNotes}>
                                Send
                            </Button>
                            <Button
                                colorScheme="brand"
                                variant="outline"
                                onClick={notesDisclosure.onClose}
                            >
                                Close
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
