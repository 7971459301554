import { useSelector } from "react-redux";
import { SearchState } from "../store/slices/searches.slice";
import { MemoriesModelQuery } from "../models/memories.model";

interface MemoryConfiguration {
    check_list: string;
    rlhf_switch: boolean;
    search_notes: boolean;
    sub_query: boolean;
    use_gpt4_db_query: boolean;
    segregated_destribution: string[];
    third_party_destribution: string[];
}
export function useMemoriesConfig(): MemoryConfiguration {
    const isAllMemoriesSelected: boolean = useSelector(
        (state: { searches: SearchState }) =>
            state.searches.isAllMemoriesSelected,
    );
    const segregatedMemories: MemoriesModelQuery[] = useSelector(
        (state: { searches: SearchState }) => state.searches.segregatedMemories,
    );
    const thirdPartyMemories: MemoriesModelQuery[] = useSelector(
        (state: { searches: SearchState }) => state.searches.thirdPartyMemories,
    );
    const rlhfSwitch: boolean = useSelector(
        (state: { searches: SearchState }) => state.searches.useRLHFSearch,
    );
    const searchNotes: boolean = useSelector(
        (state: { searches: SearchState }) => state.searches.shouldSearchNotes,
    );
    const isAllSegregatedMemoriesSelected = () =>
        segregatedMemories.every((memory) => memory.isSelected);

    let segregatedSectors: string[] = [],
        thirdPartySector: string[] = thirdPartyMemories
            .filter((memory) => memory.isSelected)
            .map((memory) => memory.sector);
    if (isAllSegregatedMemoriesSelected()) {
        segregatedSectors = ["All"];
    } else {
        segregatedSectors = segregatedMemories
            .filter((memory) => memory.isSelected)
            .map((memory) => memory.sector);
    }

    const memories_payload = {
        check_list: isAllMemoriesSelected ? "All" : "Segregated",
        rlhf_switch: rlhfSwitch,
        search_notes: searchNotes,
        sub_query: true,
        use_gpt4_db_query: false,
        segregated_destribution: isAllMemoriesSelected
            ? ["All"]
            : segregatedSectors,
        third_party_destribution: isAllMemoriesSelected
            ? ["All"]
            : thirdPartySector,
    };

    return memories_payload;
}
