import "../styles/StoreMemories.component.scss";
import ArinLayout from "../components/ArinLayout";
import React, { useEffect, useRef, useState } from "react";
import { setMetaTags } from "../utils/seo.utils";
import { Button, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, Checkbox, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Radio, RadioGroup, Select, Stack, Textarea, useDisclosure, useToast, TabPanels, TabPanel, Tab, TabList, Tabs } from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { UploadFileMeta } from "../models/upload.model";
import UploadDataForm from "../components/UploadDataForm";
import DataUploadReorder from "../components/DataUploadReorder";
import { useHttpClient } from "../utils/http.utils";
import { getAuthUser } from "../utils/auth.utils";
import StoreMemoriesPanel from "../components/sidenav/StoreMemoriesPanel";
import { useSelector } from "react-redux";
import { UploadDataState } from "../store/slices/memories.slice";
import { capitalizeWord, removeDuplicatesByProperty } from "../utils/strings.utils";
import InputMask from "react-input-mask";
import ReactSelect from "react-select";
import { formatDate } from "../utils/date.utils";
import { Field, Form, Formik } from "formik";
import { checkSomeKeysDefined } from "../utils/object.utils";
import dayjs from "dayjs";
import { useGenericModal } from "../components/modals/GenericModalContext";
import { AccountState } from "../store/slices/account.slice";

interface UploadForm {
    sector: string;
    sector_description: string;
    sector_display: string;
    location: string;
    location_display: string;
    location_description: string;
    memoryPlug: string;
    brain_type: string;
    isChanged: boolean;
    validity_date: string;
    gpt_correction: boolean;
    use_ocr: boolean;
    selectedFile?: {
        file: File;
        meta: UploadFileMeta;
    };
}

interface InfoToUpdate {
    namespace: string;
    description: string;
    sheet_name: string;
    is_structured: true;
}

// "info_to_update":

export default function StoreMemories() {
    const toast = useToast();
    const addSectorDisclosure = useDisclosure();
    const addLocationDisclosure = useDisclosure();
    const uploadDataRef = useRef(null);
    // Redux state
    const uploadedData = useSelector((state) => (state as any).uploadedData as UploadDataState);
    // local states
    const [uploadForm, setUploadForm] = useState<UploadForm>({
        sector: "",
        sector_display: "",
        sector_description: "",
        location: "",
        location_display: "",
        location_description: "",
        isChanged: false,
        memoryPlug: "",
        brain_type: "",
        validity_date: "",
        gpt_correction: false,
        use_ocr: false,
        selectedFile: undefined,
    });
    const [isStructuredData, setIsStructuredData] = useState(false);
    const { httpClient, fetchMemoriesApi, uploadMemoryFileApi, uploadMemoryURLApi, updateUploadedFileDescriptionApi } = useHttpClient();
    const { token } = getAuthUser();
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [linksUploadProgress, setLinksUploadProgress] = useState<number>(0);
    // This property contains the file name gotten from the public api
    const [uploadedFileName, setUploadedFileName] = useState("");
    // This property contains the file description gotten from the public api
    const [uploadedFileDescription, setUploadedFileDescription] = useState<string>("");
    // New description upload
    const [structuredSheetDescription, setStructuredSheetDescription] = useState<InfoToUpdate[]>([]);
    const [uploadedFileDescriptionStatus, setUploadedFileDescriptionStatus] = useState<boolean>(false);
    const [uploadedFileData, setUploadedFileData] = useState<any>();
    const [isLoadingRelationship, setIsLoadingRelationship] = useState(false);
    const relationShipFormRef = useRef(null);
    const [sectorOptions, setSectorOptions] = useState<{ sector: string; sector_display: string; description?: string }[]>([]);
    const [sectorLocationsOptions, setSectorLocationOptions] = useState<{ location: string; location_display: string; description?: string }[]>([]);
    const accountState: AccountState = useSelector((state: any) => state.account);

    const setFormData = (key: string, value: any) => {
        let _data: any = { ...uploadForm };
        _data[key] = value;
        setUploadForm(_data);
    };

    useEffect(() => {
        setMetaTags({ title: "Store Memories" });
    }, []);

    useEffect(() => {
        const sectors = uploadedData.memoriesTree.map((memory, index) => ({
            sector: memory.sector,
            sector_display: memory.sector_display,
            description: memory.sector_description,
        }));
        setSectorOptions(sectors);
    }, [uploadedData.memoriesTree]);

    function isFileUploading() {
        return (uploadProgress > 0 && uploadProgress < 100) || (linksUploadProgress > 0 && linksUploadProgress < 100);
    }

    function setUploadFile(file: any, meta: any) {
        setFormData("selectedFile", { file, meta });
        // Reset
        setUploadProgress(0);
        setIsStructuredData(false);
        setUploadedFileName("");
    }

    function onFileNameChange(fileName: string) {
        const formCopy = { ...uploadForm };
        if (formCopy.selectedFile) {
            formCopy.selectedFile.meta.name = fileName;
            setUploadForm(formCopy);
        }
    }

    /**
     * Form validators
     */

    function validateRequired(key, value) {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    }

    const addNewSector = (values) => {
        // @ts-ignore
        let { sector, description } = values;

        if (!sector) {
            toast({
                title: "Please add new sector",
                status: "warning",
                duration: 1500,
            });
            return;
        }

        if (!description) {
            toast({
                title: "Please add a description for the sector",
                status: "warning",
                duration: 1500,
            });
            return;
        }
        // value = capitalizeWord(value);
        setSectorOptions(
            removeDuplicatesByProperty(
                [
                    ...sectorOptions,
                    {
                        sector: sector.trim(),
                        sector_display: sector,
                        description,
                    },
                ],
                "sector",
            ),
        );

        setUploadForm({
            ...uploadForm,
            sector: sector.trim(),
            sector_description: description,
        });
        // Reset the locations because a new sector does not have any new locaitons
        setSectorLocationOptions([]);
        // dispatch(addSector(value));
        addSectorDisclosure.onClose();
    };
    const addNewLocation = (values) => {
        // @ts-ignore
        const { location, description } = values;
        if (!location) {
            toast({
                title: "Please add new location",
                status: "warning",
                duration: 1500,
            });
            return;
        }
        if (!description) {
            toast({
                title: "Please add a location description",
                status: "warning",
                duration: 1500,
            });
            return;
        }
        // value = capitalizeWord(value);
        setUploadForm({
            ...uploadForm,
            location: location.trim(),
            location_description: description,
        });
        setSectorLocationOptions(
            removeDuplicatesByProperty(
                [
                    ...sectorLocationsOptions,
                    {
                        location: location.trim(),
                        location_display: location,
                        description,
                    },
                ],
                "location",
            ),
        );
        // dispatch(addLocation({sector: uploadForm.sector, location: value}));
        addLocationDisclosure.onClose();
    };

    const validateForm = () => {
        if (!uploadForm.sector) {
            throw "Please add a sector";
        }
        if (!uploadForm.selectedFile) {
            throw "Please upload a file";
        }
        if (!uploadForm.location) {
            throw "Please add a location";
        }
    };

    function onSectorChange(e: any) {
        const selectedSector = e.target.value;
        if (!selectedSector) {
            return;
        }

        const sector = uploadedData.memoriesTree?.find((m) => m.sector === selectedSector);
        setUploadForm({
            ...uploadForm,
            sector: selectedSector,
            sector_display: sector?.sector_display || "",
            sector_description: sector?.sector_description || "",
            location: "",
            location_display: "",
            location_description: "",
        });
        // set sector location options
        const locations = sector?.locations.map((lc) => ({
            location: lc.location,
            location_display: lc.location_display,
            description: lc.location_description,
        })) as {
            location: string;
            location_display: string;
            description?: string;
        }[];
        setSectorLocationOptions(locations);
    }

    const onSelectLocation = (e: any) => {
        const value = e.target.value;
        if (!value) {
            return;
        }

        const location = sectorLocationsOptions.find((lc) => lc.location === value);
        setUploadForm({
            ...uploadForm,
            location: value,
            location_display: location?.location_display || "",
            location_description: location?.description || "",
        });
    };

    const resetForm = () => {
        // clear inputs
        // @ts-ignore
        uploadDataRef.current.clearFileInputValue();
        setIsStructuredData(false);
        setIsLoadingRelationship(false);
        setIsLoading(false);
        setUploadedFileData({});
        setUploadedFileDescription("");
        setUploadedFileDescriptionStatus(false);
        setUploadedFileName("");
        // Reset strucured data description
        setStructuredSheetDescription([]);
        // show toast
        toast({
            title: "Successfully uploaded data",
            status: "success",
            duration: 2500,
        });
        // TODO: REDUCE THIS TO ONE FUNCITON
        // Fetch namespaces
        fetchMemoriesApi();
        // get namespaces
        // getNameSpacesApi();

        setUploadForm({
            ...uploadForm,
            isChanged: false,
            validity_date: "",
            selectedFile: undefined,
        });
    };

    /**
     * This function sanitizes and re-formats the validity date
     */
    const formatValidityDate = (date: string) => {
        if (date) {
            return dayjs(date, {
                format: "MM/DD/YYYY",
            }).format("DD-MM-YYYY");
        } else {
            return dayjs().format("DD-MM-YYYY");
        }
    };

    /**
     * Uploads data to the server based on the specified upload type (memory file or links form).
     * If a file description is set, it updates the file description. If a memory file is uploaded,
     * it takes precedence over links form. If the file is structured, it gets relations and approves it.
     * Otherwise, it posts the data to the server and sets the uploaded file description, data, and file path.
     * If the links form is used, it validates the form and calls the API with the collected values.
     * Finally, it handles errors and shows a toast message accordingly.
     *
     * @async
     * @function
     * @returns {Promise<void>}
     */
    const uploadData = async () => {
        try {
            /**
             * If file description is set, update file description
             * **/
            if (uploadedFileDescription.length > 0) {
                updateFileDescription();
                return;
            }

            if (structuredSheetDescription.length > 0) {
                updateStructuredDescription();
                return;
            }
            /**
             * If the first file upload is selected, it takes precedence over the rest
             */
            if (uploadForm.selectedFile && uploadForm.selectedFile?.file && uploadForm.selectedFile?.meta) {
                await validateForm();
                const formData = new FormData();
                // Update the formData object
                formData.append("memoryFile", (uploadForm.selectedFile as any).file, uploadForm.selectedFile?.meta.name + "." + uploadForm.selectedFile?.meta.extensionName);
                // formData.append("fileName", uploadForm.selectedFile?.meta.name || '');
                // set the button loading to true
                setIsLoading(true);
                // upload file
                const { data } = await httpClient.post(`/search/memory?access_token=${token}`, formData, {
                    onUploadProgress: function (progressEvent) {
                        setUploadProgress(Math.round((progressEvent.loaded * 100) / (progressEvent as any).total));
                    },
                });
                // Extract the file name from path
                const fileName = data.data.path.replace(/^.*[\\\/]/, "");
                // set file path in state
                setUploadedFileName(fileName);
                /**
                 * If file is structured, get relations and approve....
                 */
                if (uploadForm.selectedFile?.meta.isStructured) {
                    setIsStructuredData(!!uploadForm.selectedFile?.meta.isStructured);
                    setIsLoadingRelationship(!!uploadForm.selectedFile?.meta.isStructured);
                    setIsLoading(false);
                } else {
                    /**
                     * In the case of unstructured data
                     */
                    const payload = {
                        sector: uploadForm.sector,
                        sector_description: uploadForm.sector_description,
                        location: uploadForm.location,
                        relation: "",
                        location_description: uploadForm.location_description,
                        filename: fileName,
                        filename_display: `${uploadForm.selectedFile.meta.name}.${uploadForm.selectedFile.meta.extensionName}`,
                        brain_type: uploadForm.brain_type,
                        validity_date: formatValidityDate(uploadForm.validity_date),
                        gpt_correction: uploadForm.gpt_correction,
                        use_ocr: uploadForm.use_ocr,
                    };
                    // post data to server
                    const data = await uploadMemoryFileApi(payload);

                    // Remove loading
                    setIsLoading(false);

                    if (!data.id && data.message) {
                        toast({
                            title: data.message,
                            status: "warning",
                        });
                    } else if (!data.id && !data.message) {
                        toast({
                            title: "An error occurred while uploading your data. Please contact your admin.",
                            status: "warning",
                            duration: 1900,
                        });
                    } else {
                        setUploadedFileDescription(data["filename_description"]);
                        setUploadedFileDescriptionStatus(true);

                        setStructuredSheetDescription(data["info_to_update"]);

                        setUploadedFileData(data);
                    }
                }
            } else {
                // In this care we are looking at the links form
                const uploadLinksForm = uploadDataRef.current as any;
                // Validate form
                if (uploadLinksForm.validateLinksForm()) {
                    // validate column names and sector
                    if (!uploadForm.sector) {
                        throw "Please add a sector";
                    }
                    if (!uploadForm.location) {
                        throw "Please add a location";
                    }
                    // collect values and call api
                    const { type, file, links, columnName } = uploadLinksForm.getLinksFormValues();
                    // construct payload
                    const payload = {
                        upload_type: type,
                        sector: uploadForm.sector,
                        sector_description: uploadForm.sector_description,
                        location: uploadForm.location,
                        location_description: uploadForm.location_description,
                        columnName: "",
                        typ: "list",
                        filename: "",
                        brain_type: uploadForm.brain_type,
                        filename_display: "",
                        validity_date: formatValidityDate(uploadForm.validity_date),
                        gpt_correction: false,
                        use_ocr: false,
                    };

                    // set is loading to true
                    setIsLoading(true);

                    if (file) {
                        // If there is a file, upload file
                        const formData = new FormData();
                        formData.append("memoryFile", file, file.name || "links_file");
                        // formData.append("fileName", uploadForm.selectedFile?.meta.name || '');
                        // upload file
                        const { data } = await httpClient.post(`/search/memory?access_token=${token}`, formData, {
                            onUploadProgress: function (progressEvent) {
                                // @ts-ignore
                                setLinksUploadProgress(Math.round((progressEvent.loaded * 100) / (progressEvent as any).total));
                            },
                        });

                        payload.columnName = columnName;
                        payload.typ = "file";
                        payload.filename = data.data.path.replace(/^.*[\\\/]/, "");
                        payload.filename_display = file.name;
                    } else {
                        // Incase there are links
                        payload.filename = links.map((link: any) => link.value).join(",");
                        payload.filename_display = links.map((link: any) => link.value).join(",");
                    }
                    // submit upload request
                    const data = await uploadMemoryURLApi(payload);

                    // Remove loading
                    setIsLoading(false);

                    if (!data.id && data.message) {
                        toast({
                            title: data.message,
                            status: "warning",
                        });
                    } else if (!data.id && !data.message) {
                        toast({
                            title: "An error occurred while uploading your data. Please contact your admin.",
                            status: "warning",
                            duration: 1900,
                        });
                    } else {
                        const fileDescription = data["filename_description"];
                        setUploadedFileDescription(fileDescription);
                        setUploadedFileDescriptionStatus(true);
                        setUploadedFileData(data);

                        const sheetDescription = data["info_to_update"];
                        setStructuredSheetDescription(sheetDescription);
                        // If there is no file description, reset form
                        if (!fileDescription && !sheetDescription) {
                            resetForm();
                        }

                        uploadLinksForm.resetLinksForm();
                        // show toast
                        toast({
                            title: "Successfully uploaded data",
                            status: "success",
                            duration: 2500,
                        });
                    }
                }
            }
        } catch (e: any) {
            console.log(e);
            setIsLoading(false);
            toast({
                title: "An error occurred while uploading your data. Please contact your admin.",
                status: "warning",
                duration: 1900,
            });
        }
    };

    const uploadStructuredData = () => {
        // Get relationships
        // @ts-ignore
        const relation = relationShipFormRef.current.getRelationships();
        // Submit upload
        setIsLoading(true);
        validateForm();
        // Construct payload
        const payload = {
            sector: uploadForm.sector,
            sector_description: uploadForm.sector_description,
            location: uploadForm.location,
            location_description: uploadForm.location_description,
            filename: uploadedFileName,
            filename_display: `${uploadForm.selectedFile?.meta.name}.${uploadForm.selectedFile?.meta.extensionName}`,
            brain_type: uploadForm.brain_type,
            relation: relation,
            validity_date: formatValidityDate(uploadForm.validity_date),
            gpt_correction: false, // For structured data, gpt correction is always false
            use_ocr: false, // For structured data, use ocr is always false
        };
        // upload to server
        uploadMemoryFileApi(payload)
            .then((data) => {
                // Remove loading
                setIsLoading(false);
                // Clear relationships form
                setIsLoadingRelationship(false);

                if (!data.id && data.message) {
                    toast({
                        title: data.message,
                        status: "warning",
                    });
                } else if (!data.id && !data.message) {
                    toast({
                        title: "An error occurred while uploading your data. Please contact your admin.",
                        status: "warning",
                        duration: 1900,
                    });
                } else {
                    const fileDescription = data["filename_description"];
                    setUploadedFileDescription(fileDescription);
                    setUploadedFileData(data);

                    setUploadedFileDescriptionStatus(true);
                    const sheetDescription = data["info_to_update"];

                    setStructuredSheetDescription(sheetDescription);
                    if (!fileDescription && !sheetDescription) {
                        resetForm();
                    }
                    setIsStructuredData(false);
                    /**
                     * Clear relationships form data
                     */
                    (relationShipFormRef.current as any).clearRelationShips();
                }
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: "An error occurred while uploading your data. Please contact your admin.",
                    status: "error",
                    duration: 2400,
                });
            })
            .finally(() => setIsLoading(false));
    };

    const updateFileDescription = () => {
        if (!uploadedFileDescription) {
            toast({
                title: "Please provide a file summary",
                status: "warning",
            });
            return;
        }

        setIsLoading(true);

        updateUploadedFileDescriptionApi({
            description: uploadedFileDescription,
            ...uploadedFileData,
        })
            .then(() => {
                resetForm();
            })
            .catch(() => {
                toast({
                    title: "We cannot update your file summary, Please try again",
                    status: "error",
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateStructuredDescription = () => {
        const infoToUpdate = structuredSheetDescription.map((info, index) => {
            let newDescription: HTMLTextAreaElement = document.getElementsByClassName(`text-description-${index}`)[0] as HTMLTextAreaElement;

            return {
                description: newDescription.value,
                is_structured: info.is_structured,
                sheet_name: info.sheet_name,
                namespace: info.namespace,
            };
        });

        setIsLoading(true);

        let updateDescriptionJob = Promise.all(
            infoToUpdate.map((info, index) =>
                updateUploadedFileDescriptionApi({
                    description: info.description,
                    ...uploadedFileData,
                }),
            ),
        );

        updateDescriptionJob
            .then(() => {
                resetForm();
            })
            .catch(() => {
                toast({
                    title: "We cannot update your file summary, Please try again",
                    status: "error",
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <ArinLayout>
            <div className="search-layout-wrapper store-memories">
                <div className="left-pane">
                    <div className="sidebar-sm">
                        <div className="side-nav-sm">
                            <h4 className="title">Memories</h4>
                            <p className="subtitle">Manage your memories.</p>
                        </div>
                        <div className="sidebar-body">
                            <div className={`folder-card cursor-pointer rounded-lg cursor-p py-1 mb-3`}>
                                <StoreMemoriesPanel />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-pane main grid-column upload-content-section position-relative">
                    <div className="column-contents">
                        <div className="main-pane-contents h-full">
                            <div className="side-nav-sm">
                                <h4 className="title">Create memory</h4>
                                <div className="subtitle">
                                    <span className="mr-2 inline-block">Choose neural pathways, sector, location to upload memory.</span>
                                    <Popover placement="left-end">
                                        <PopoverTrigger>
                                            <button className="text-blue-500 hover:underline">About our data handling policy?</button>
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <PopoverArrow />
                                            <PopoverCloseButton />
                                            <PopoverHeader>
                                                <strong>How we handle your data?</strong>
                                            </PopoverHeader>
                                            <PopoverBody>
                                                <p className="mb-3">We use your uploaded information solely to process and organize it into your personal database. Once the transfer is complete, we promptly delete any files in our possession.</p>
                                                <p className="mb-3">Your access credentials are encrypted and stored locally on the respective user's device, ensuring that we do not have direct access to them.</p>
                                                <p className="mb-3">
                                                    To enhance our service, we employ training, alignment, reinforcement learning through human feedback, and automated introspection, which you authorize us to perform. These measures aim to increase the accuracy and creativity of your queries, with the resulting improvements stored in NeoWorlder's database. If you terminate your account or have outstanding balances for 90 days, we will delete all of your stored, curated information.
                                                </p>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>
                            <div className="main-pane-body store-memories pt-1">
                                <div className="form">
                                    <div className="input-section">
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <GridItem w="100%">
                                                <FormControl mb={6}>
                                                    <FormLabel>Neural Pathway (Memory)</FormLabel>
                                                    <ReactSelect
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                        onChange={(e) => setFormData("memoryPlug", e?.label)}
                                                        options={[
                                                            {
                                                                label: "Pinecone DB",
                                                                value: "palm2",
                                                            },
                                                            // {label: 'Add Plug', value: 'add_plug'},
                                                        ]}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem w="100%">
                                                <FormControl mb={6}>
                                                    <FormLabel>Memory Type</FormLabel>
                                                    <ReactSelect
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                        onChange={(e) => setFormData("brain_type", e?.label)}
                                                        options={[
                                                            {
                                                                label: "Segregated",
                                                                value: "Segregated",
                                                            },
                                                            {
                                                                label: "General",
                                                                value: "General",
                                                            },
                                                        ]}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                        </Grid>
                                        <FormControl mb={3}>
                                            <FormLabel>Sector</FormLabel>
                                            <Select disabled={sectorOptions.length === 0} mb={1} value={uploadForm.sector} onChange={onSectorChange} placeholder="Select Sector">
                                                {sectorOptions.map((sector, index) => {
                                                    return (
                                                        <option key={index} value={sector.sector}>
                                                            {capitalizeWord(sector.sector_display)}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                            <Button mt={2} onClick={addSectorDisclosure.onOpen} variant="ghost" colorScheme="blue">
                                                <FiPlus />
                                                &nbsp;Add Sector
                                            </Button>
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel>Location</FormLabel>
                                            <Select disabled={sectorLocationsOptions.length === 0} mb={1} value={uploadForm.location} onChange={onSelectLocation} placeholder="Select Location">
                                                {sectorLocationsOptions.map((location, index) => {
                                                    return (
                                                        <option key={index} value={location.location}>
                                                            {capitalizeWord(location.location_display)}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                            <Button mt={2} isDisabled={!uploadForm.sector} onClick={addLocationDisclosure.onOpen} variant="ghost" colorScheme="blue">
                                                <FiPlus />
                                                &nbsp;Add Location
                                            </Button>
                                        </FormControl>

                                        <FormControl mb={3}>
                                            <FormLabel>Is this historical information that has since changed?</FormLabel>
                                            <RadioGroup value={uploadForm.isChanged ? "yes" : "no"} onChange={(value) => setFormData("isChanged", value === "yes")} defaultValue="no">
                                                <Stack>
                                                    <Radio value="yes">Yes</Radio>
                                                    <Radio value="no">No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>

                                        {uploadForm.isChanged && (
                                            <FormControl mb={3}>
                                                <FormLabel>When was the information updated?</FormLabel>
                                                <Input value={uploadForm.validity_date} onChange={(e) => setFormData("validity_date", e.target.value)} placeholder="Enter date (Ex. 05/23/2023)" as={InputMask} mask="99/99/9999" maskChar={null} />
                                            </FormControl>
                                        )}

                                        <FormControl mt={6}>
                                            <FormLabel>Table Extraction</FormLabel>
                                            <p className="text-slate-700 mb-2">OCR table extraction can accurately extract structured tables with an approximate success rate of 50%.</p>
                                            <Checkbox isChecked={uploadForm.use_ocr} onChange={(e) => setFormData("use_ocr", e.target.checked)}>
                                                I agree to use GTP-4 for this purpose
                                            </Checkbox>
                                        </FormControl>

                                        <FormControl mt={6}>
                                            <FormLabel>Use Advanced corrections</FormLabel>
                                            <p className="text-slate-700 mb-2">We will apply higher orders classifications, when searching.</p>
                                            <Checkbox isChecked={uploadForm.gpt_correction} onChange={(e) => setFormData("gpt_correction", e.target.checked)}>
                                                Yes use advanced corrections
                                            </Checkbox>
                                        </FormControl>

                                        <UploadDataForm ref={uploadDataRef} progress={uploadProgress} linksUploadProgress={linksUploadProgress} onChange={setUploadFile} onFileNameChange={onFileNameChange} />

                                        {/*    Summary of uploaded file*/}
                                        {uploadedFileDescription?.length > 0 && (
                                            <FormControl mt={6}>
                                                <Textarea placeholder="Summary" value={uploadedFileDescription} rows={4} onChange={(e) => setUploadedFileDescription(e.target.value as string)}></Textarea>
                                            </FormControl>
                                        )}
                                        {structuredSheetDescription.length > 0 && (
                                            <>
                                                <FormLabel mb={4}>Sheet Description</FormLabel>
                                                <Tabs>
                                                    <TabList>
                                                        {structuredSheetDescription.map((info, index) => {
                                                            return <Tab key={`tab-${index}`}>{info.sheet_name}</Tab>;
                                                        })}
                                                    </TabList>

                                                    <TabPanels>
                                                        {structuredSheetDescription.map((info, index) => {
                                                            return (
                                                                <TabPanel key={`tab-panel-${index}`}>
                                                                    <Textarea className={`text-description-${index}`} placeholder="Summary" rows={8} defaultValue={info.description}></Textarea>
                                                                </TabPanel>
                                                            );
                                                        })}
                                                    </TabPanels>
                                                </Tabs>
                                            </>
                                        )}
                                    </div>

                                    <DataUploadReorder
                                        ref={relationShipFormRef}
                                        showForm={isStructuredData}
                                        data={{
                                            fileName: uploadedFileName,
                                            loadRelations: isLoadingRelationship,
                                        }}
                                        onInitialize={(hasFinished) => setIsLoadingRelationship(!hasFinished)}
                                    />

                                    <div className="actions pt-6">
                                        {isStructuredData ? (
                                            <Button onClick={uploadStructuredData} loadingText="Saving data.." isLoading={isLoading} isDisabled={isLoadingRelationship} variant="solid" colorScheme="brand">
                                                Approve
                                            </Button>
                                        ) : (
                                            <Button onClick={uploadData} loadingText={uploadedFileDescription ? "Saving Data.." : "Processing.."} isLoading={isLoading} isDisabled={isFileUploading() || !uploadForm.sector || !uploadForm.location || !uploadForm.brain_type || !uploadForm.memoryPlug} variant="solid" colorScheme="brand">
                                                {uploadedFileDescription ? "Save Data" : "Upload Data"}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ==== RIGHT PANE ============*/}
                <div className="right-pane grid-column">
                    <div className="store-memories-map column-contents px-4 pt-4">
                        <div className="form-preview">
                            {checkSomeKeysDefined(uploadForm, ["memoryPlug", "brain_type", "sector", "location"]) && (
                                <div className="preview-container px-6 pt-4">
                                    <div className="col">
                                        {uploadForm.memoryPlug && (
                                            <div>
                                                <p className="text-slate-500 mb-1 text-sm">Nucleus</p>
                                                <h2 className="font-semibold mb-1">{capitalizeWord(uploadForm.memoryPlug)}</h2>
                                                {uploadForm.brain_type && (
                                                    <div>
                                                        <h2 className="font-semibold mb-3">{capitalizeWord(uploadForm.brain_type)}</h2>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <p className="text-slate-500 mb-1 text-sm">Cut off date</p>
                                        {uploadForm.isChanged ? <h2 className="font-semibold mb-3">{uploadForm.validity_date}</h2> : <h2 className="font-semibold mb-3">{formatDate(new Date().toISOString(), "MM/DDD/YYY")}</h2>}
                                    </div>
                                    <div className="col">
                                        {uploadForm.sector && (
                                            <div>
                                                <p className="text-slate-500 mb-1 text-sm">Sector</p>
                                                <h2 className="font-semibold mb-1">{capitalizeWord(uploadForm.sector)}</h2>
                                                <p title={uploadForm.sector_description} className="small-text text-slate-500 mb-4 text-sm">
                                                    {uploadForm.sector_description}
                                                </p>
                                            </div>
                                        )}
                                        {uploadForm.location && (
                                            <div>
                                                <p className="text-slate-500 mb-1 text-sm">Location</p>
                                                <h2 className="font-semibold mb-1">{capitalizeWord(uploadForm.location)}</h2>
                                                <p title={uploadForm.location_description} className="small-text text-slate-500 mb-3 text-sm">
                                                    {uploadForm.location_description}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* Add Sector  Modals*/}
            <Modal isOpen={addSectorDisclosure.isOpen} onClose={addSectorDisclosure.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <Formik initialValues={{ sector: "", description: "" }} onSubmit={addNewSector}>
                        {(props) => (
                            <Form>
                                <ModalHeader>Add Sector</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Field name="sector" validate={(e) => validateRequired("Sector", e)}>
                                        {({ field, form }) => (
                                            <FormControl mb={3} isInvalid={form.errors.sector && form.touched.sector}>
                                                <FormLabel>Sector</FormLabel>
                                                <Input {...field} placeholder="New Sector name" />
                                                <FormErrorMessage>{form.errors.sector}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="description" validate={(e) => validateRequired("Description", e)}>
                                        {({ field, form }) => (
                                            <FormControl mb={3} isInvalid={form.errors.description && form.touched.description}>
                                                <FormLabel>Description</FormLabel>
                                                <Textarea {...field} placeholder="Description"></Textarea>
                                                <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </ModalBody>

                                <ModalFooter>
                                    <Button variant="ghost" mr={3} onClick={addSectorDisclosure.onClose}>
                                        Close
                                    </Button>
                                    <Button loadingText="Saving.." color="white" style={{ backgroundColor: "#000" }} type="submit">
                                        Save
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
            {/*  Add Location  Modals*/}
            <Modal isOpen={addLocationDisclosure.isOpen} onClose={addLocationDisclosure.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <Formik initialValues={{ location: "", description: "" }} onSubmit={addNewLocation}>
                        {(props) => (
                            <Form>
                                <ModalHeader>Add Location</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Field name="location" validate={(e) => validateRequired("Location", e)}>
                                        {({ field, form }) => (
                                            <FormControl mb={3} isInvalid={form.errors.location && form.touched.location}>
                                                <FormLabel>Location</FormLabel>
                                                <Input {...field} placeholder="New Location name" />
                                                <FormErrorMessage>{form.errors.location}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="description" validate={(e) => validateRequired("Description", e)}>
                                        {({ field, form }) => (
                                            <FormControl mb={3} isInvalid={form.errors.description && form.touched.description}>
                                                <FormLabel>Description</FormLabel>
                                                <Textarea {...field} placeholder="Description"></Textarea>
                                                <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </ModalBody>

                                <ModalFooter>
                                    <Button variant="ghost" mr={3} onClick={addLocationDisclosure.onClose}>
                                        Close
                                    </Button>
                                    <Button loadingText="Saving.." colorScheme="brand" type="submit">
                                        Save
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </ArinLayout>
    );
}
