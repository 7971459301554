import { ResponsiveLine } from "@nivo/line";
import { useEffect, useMemo, useState } from "react";
import ReactSelect from "react-select";
import "../styles/UsageChart.component.scss";
import { Button } from "@chakra-ui/react";
import { PersonaModel, PersonaTransaction } from "../models/persona.model";
import { useHttpClient } from "../utils/http.utils";
import dayjs from "dayjs";
import { formatDate } from "../utils/date.utils";
import { config } from "../config";

// const arins = [
//     { value: "all", label: "All" },
//     { value: "kay", label: "Katy Adameve" },
//     { value: "sarah", label: "Sarah Kissingers" },
// ];

interface UsageChartProps {
    title: string;
    persona?: PersonaModel;
}

interface GraphOption {
    value: "words" | "T$";
    label: "Words" | "T$";
}

const graphOptions: GraphOption[] = [
    { value: "words", label: "Words" },
    { value: "T$", label: "T$" },
];

export default function UsageChart({ title, persona }: UsageChartProps) {
    const [segmentValue, setSegmentValue] = useState("daily");
    const [personaTransactions, setPersonaTransactions] = useState<PersonaTransaction[]>([]);
    const [selectedGraphOption, setSelectedGraphOption] = useState<GraphOption>(
        { value: "words", label: "Words" }
    );
    const {
        getPersonaTransactionApi
    } = useHttpClient();
    const { creditPerWord } = config;

    useEffect(() => {
        const fetchPersonaTransactions = async () => {
            setPersonaTransactions(await getPersonaTransactionApi(
                persona?.id!,
                {transaction_type: "decrease_word_credit_balance"}
            ));
        }

        if (persona?.id) {
            fetchPersonaTransactions();
        } else {
            setPersonaTransactions([]);
        }
    }, [persona]);

    const graphData = () => {
        const personaCreditPerWord = persona?.pricing_per_word || creditPerWord;

        const dayJsDayList = Array.from(
            { length: 7 },
            (_, i) => dayjs().subtract(i, "days").startOf("day")
        );

        const sevenDaysGraphData = dayJsDayList.map(dayJsDay => ({
            x: dayJsDay.format("MMM DD"),
            y: 0
        }));

        // Fill values in graphData
        personaTransactions.forEach(transaction => {
            const transactionDayStart = dayjs(transaction.created_at).startOf("day");
            const todayStart = dayjs().startOf("day");
            const diffDays = todayStart.diff(transactionDayStart, "days");

            let graphAmount = 0;
            switch (selectedGraphOption.value) {
                case "T$":
                    graphAmount = transaction.amount;
                    break;
                case "words": 
                default:
                    graphAmount = Number(transaction.amount / personaCreditPerWord);
            }

            if (diffDays < 7 && diffDays >= 0) {
                sevenDaysGraphData[diffDays].y += graphAmount;
            }
        });

        return [
            {
                id: "Usage",
                color: "hsl(205, 70%, 50%)",
                data: sevenDaysGraphData.reverse()
            },
        ];
    };

    const onGraphOptionChange = (value) => {
        setSelectedGraphOption(value)
    };

    const memoizedGraphData = useMemo(graphData, [persona, personaTransactions, selectedGraphOption]);

    return (
        <div className="chart-container">
            <div className="flex justify-between">
                <div className="header">
                    <div className="header-tile pl-[24px]">
                        <h1 className="title">{title}</h1>
                        {/* <Button colorScheme='blue' variant='ghost'>
                            Top up
                        </Button> */}
                        {/* <Button colorScheme='blue' variant='link'>
                            Download History
                        </Button> */}
                    </div>
                </div>
                <div className={"flex gap-2 items-center"}>
                    {/* <div className="w-32">
                        <ReactSelect
                            placeholder="Persona"
                            className="arin-react-select-container"
                            classNamePrefix="arin-react-select"
                            value={arins[0]}
                            options={arins}
                        />
                    </div> */}
                    <ReactSelect
                        placeholder="Persona"
                        className="arin-react-select-container"
                        classNamePrefix="arin-react-select"
                        value={selectedGraphOption}
                        options={graphOptions}
                        onChange={onGraphOptionChange}
                    />
                </div>
            </div>
            <div className="chart" style={{ height: "40vh", width: "100%" }}>
                <ResponsiveLine
                    data={memoizedGraphData}
                    margin={{ top: 50, right: 30, bottom: 60, left: 60 }}
                    xScale={{ type: "point" }}
                    yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: false,
                        reverse: false,
                    }}
                    curve="cardinal"
                    enableArea={true}
                    areaBaselineValue={60}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: selectedGraphOption.label,
                        legendOffset: -40,
                        legendPosition: "middle",
                    }}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 15,
                    }}
                    pointSize={10}
                    pointColor={{ theme: "background" }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: "serieColor" }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                />
            </div>
        </div>
    );
}
