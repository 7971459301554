import React, { useEffect, useState } from "react";
import Select from "react-select";

import {
    Box,
    Input,
    Tag,
    Flex,
    TagCloseButton,
    TagLabel,
    FormControl,
    Checkbox,
    Button,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    useToast,
} from "@chakra-ui/react";
import { HiLockClosed } from "react-icons/hi";
import { SegregatedSectorDisplayCard } from "../memories/MemoryDisplayCards";
import { useSelector } from "react-redux";
import { UploadDataState } from "../../store/slices/memories.slice";
import {
    MemoriesModel,
    MemoriesModelQuery,
    MemoryFile,
} from "../../models/memories.model";
import { SearchState } from "../../store/slices/searches.slice";
import { useHttpClient } from "../../utils/http.utils";
import {
    PersonaFormState,
    PersonaState,
} from "../../store/slices/persona.slice";
import { removeDuplicatesByProperty } from "../../utils/strings.utils";
import { to } from "react-spring";
import { getPersonaFullName } from "../../utils/personas.utils";
import { PersonaCollaborationAppGroupModel, PersonaModel } from "../../models/persona.model";

// const DATA = [
//     {
//         id: "1",
//         name: "PDF Making App",
//     },
//     {
//         id: "2",
//         name: "Email App",
//     },
//     {
//         id: "3",
//         name: "Google Translate",
//     },
//     {
//         id: " 4",
//         name: "Google SERP",
//     },
// ];

type PersonaSkillsOptions = PersonaCollaborationAppGroupModel & {
    value: string | undefined;
    label: string;
};

const selectStyles = {
    control: (provided: any) => ({
        ...provided,
        border: "none",
        boxShadow: "none", // Remove the box-shadow as well
        "&:hover": {
            border: "none",
        },
    }),
};
// TODO: move to models folder
type PersonaMemoriesProps = {
    moveToNext?: () => void;
    moveToPrevious?: () => void;
    persona?: PersonaModel;
};

const PersonaMemories = ({
    moveToNext,
    moveToPrevious,
    persona
}: PersonaMemoriesProps) => {
    const toast = useToast();
    /**
     * Redux selectors
     */
    // Select memories from redux slice
    const memoriesState = useSelector((state: any) => state.uploadedData);
    // selected persona
    const personaState: PersonaState = useSelector(
        (state: any) => state.persona,
    );
    const personaFormState: PersonaFormState =
        personaState.personaAttributesForm;
    //  http
    const {
        addPersonaMemoryApi,
        deletePersonaMemoryApi,
        listPersonaMemoryApi,
        fetchCollaborationAppGroupForSkillsApi,
        updatePersonaApi,
        updatePersonaCollaborationAppGroupApi,
        fetchPersonaCollaborationAppGroupApi
    } = useHttpClient();
    // Local state
    const [selectSkills, setSelectSkills] = useState<any[]>([]);
    const [skillsData, setSkillsData] = useState<PersonaSkillsOptions[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const searchBoxRef = React.useRef<HTMLInputElement>(null);

    // const getSkillsOptions = (): PersonaSkillsOptions[] => {
    //     return skillsData.map((item) => ({
    //         value: item.id,
    //         label: item.collaboration_app_group_title,
    //         ...item
    //     }));
    // };

    const [searchThirdPartyMemoriesList, setSearchThirdPartyMemoriesList] =
        useState<MemoriesModel[]>([]);

    const [searchSegregatedPartyMemoriesList, setSearchSegregatedMemoriesList] =
        useState<MemoriesModel[]>([]);

    const onSelectSkills = (values) => {
        if (persona?.id) {
            updatePersonaCollaborationAppGroupApi(persona?.id!, (values as PersonaSkillsOptions[]).map(value => ({
                persona: value.persona,
                collaboration_app_group: value.collaboration_app_group,
                collaboration_app_group_title: value.collaboration_app_group_title
            })));
            setSelectSkills(values);
        }
    };

    const [selectedSegregatedMemories, setSelectedSegregatedMemories] =
        useState<MemoriesModel[]>([]);

    useEffect(() => {
        // if (personaState.selectedPersonaMemories.length === 0) {
            listPersonaMemoryApi(personaFormState.id as string);
        // }
        setSelectedSegregatedMemories(personaState.selectedPersonaMemories);
    }, [persona]);

    useEffect(() => {
        const fetchSkills = async () => {
            const data = await fetchCollaborationAppGroupForSkillsApi();

            setSkillsData(data.map((collaborationAppGroup): PersonaSkillsOptions => ({
                collaboration_app_group: collaborationAppGroup.id,
                collaboration_app_group_title: collaborationAppGroup.title,
                persona: persona?.id!,
                value: collaborationAppGroup.id,
                label: collaborationAppGroup.title
            })));
        }

        const fetchSelectedSkills = async () => {
            const data: PersonaCollaborationAppGroupModel[] = await fetchPersonaCollaborationAppGroupApi(persona?.id!);

            setSelectSkills(data.map((personaCollaborationAppGroup): PersonaSkillsOptions => ({
                collaboration_app_group: personaCollaborationAppGroup.collaboration_app_group,
                collaboration_app_group_title: personaCollaborationAppGroup.collaboration_app_group_title,
                persona: personaCollaborationAppGroup.persona,
                value: personaCollaborationAppGroup.collaboration_app_group,
                label: personaCollaborationAppGroup.collaboration_app_group_title
            })));
        }

        if (persona?.id) {
            fetchSkills();
            fetchSelectedSkills();
        } else {
            setSelectSkills([]);
            setSkillsData([]);
        }
    }, [persona])

    useEffect(() => {
        setSelectedSegregatedMemories(personaState.selectedPersonaMemories);
    }, [personaState.selectedPersonaMemories]);

    const selectSegregatedMemory = (item) => {
        // Do not add item if it already exists
        if (
            selectedSegregatedMemories.find(
                (memory) => memory.sector === item.sector,
            )
        ) {
            toast({
                title: `${getPersonaFullName(
                    personaFormState,
                )} already has the ${item.sector_display} memory`,
                status: "warning",
            });
            return;
        }
        setSelectedSegregatedMemories((prev) => [...prev, item]);
        // clear auto complete
        (searchBoxRef.current as any).value = "";
        setSearchSegregatedMemoriesList([]);
        /**
         * Save persona memory
         */
        addPersonaMemoryApi(
            personaFormState.id as string,
            item.data_sector,
        ).catch((err) => {
            // remove persona from card
            setSelectedSegregatedMemories((prev) =>
                prev.filter((p) => p.sector !== item.sector),
            );
            // show message
            toast({
                title: `Cannot add ${item.sector_display} memory to your persona`,
                status: "error",
            });
        });
    };

    const handleRemoveMemoryChip = (memory: MemoriesModel) => {
        setSelectedSegregatedMemories((prev) =>
            prev.filter((item) => item.sector !== memory.sector),
        );
        /**
         * Remove from persona memory
         */
        deletePersonaMemoryApi(personaFormState.id as string, memory.id).catch(
            (err) => {
                // remove persona from card
                setSelectedSegregatedMemories((prev) => [...prev, memory]);
                // show message
                toast({
                    title: `Cannot remove ${memory.sector_display} memory to your persona`,
                    status: "error",
                });
            },
        );
    };

    const searchSegregatedMemory = (e: any) => {
        const query: string = e.target.value;
        if (query.length > 0) {
            const memories = removeDuplicatesByProperty(
                memoriesState.data,
                "sector_display",
            ).filter((memory) =>
                memory.sector_display
                    .toLowerCase()
                    .includes(query.toLowerCase()),
            );
            setSearchSegregatedMemoriesList(memories);
        } else {
            setSearchSegregatedMemoriesList([]);
        }
    };

    /**
     *
     * @param e Value from input
     */
    const searchThirdPartyMemory = (e: any) => {
        const query: string = e.target.value;
        if (query.length > 0) {
            const memories = memoriesState.data.filter((memory) =>
                memory.sector_display
                    .toLowerCase()
                    .includes(query.toLowerCase()),
            );
            setSearchSegregatedMemoriesList(memories);
        } else {
            setSearchSegregatedMemoriesList([]);
        }
    };

    const updatePersonaFunc = async (data: PersonaModel) => {
        if (persona?.id) {
            setIsLoading(true);
            await updatePersonaApi(data);
            setIsLoading(false);
        }
    };

    return (
        <div className="mt-[4px]">
            <h1 className="mb-[7px] leading-[1] font-medium text-[16px]">
                Memories
            </h1>
            <p className="text-[13px] text-gray-500">
                Select and add the memories you want "Persona Name" to have
                access to.
            </p>
            <Box className="App">
                {/* TODO: Make this a reusable component */}
                <Box
                    mt={5}
                    border="1px solid #dddddd"
                    w="500px"
                    h="120px"
                    mb="6"
                    className="rounded"
                >
                    <Flex flexWrap="wrap" p="2">
                        {selectedSegregatedMemories.map((item, index) => {
                            return (
                                <Tag
                                    colorScheme="blue"
                                    key={"memories-" + index}
                                    m={1}
                                >
                                    <TagLabel>
                                        {item?.sector_display ||
                                            item?.name_display}
                                    </TagLabel>
                                    <TagCloseButton
                                        onClick={() =>
                                            handleRemoveMemoryChip(item)
                                        }
                                    />
                                </Tag>
                            );
                        })}
                    </Flex>
                </Box>

                <Tabs className="" w="500px">
                    <TabList>
                        <Tab>
                            <HiLockClosed />
                            &nbsp;<span className="text-sm">Segregated</span>
                        </Tab>
                        <Tab>
                            &nbsp;<span className="text-sm">Third Party</span>
                        </Tab>
                    </TabList>
                </Tabs>
                {/* TODO: Make this a reusable component */}
                <Box
                    border="none"
                    w="500px"
                    h="42px"
                    position="relative"
                    mb="10"
                    mt="5"
                    zIndex={"100"}
                >
                    <Input
                        type="text"
                        ref={searchBoxRef}
                        onChange={searchSegregatedMemory}
                        placeholder="Search memories"
                        border="1px solid #ddd"
                        _placeholder={{ fontSize: "15px" }}
                    />
                    <div className="absolute bg-white rounded-[8px] top-[100%] left-0 right-0 z-20 shadow-lg">
                        {searchSegregatedPartyMemoriesList.map((item) => (
                            <SegregatedSectorDisplayCard
                                title={item.sector_display}
                                description={item.sector_description}
                                hideCheckBox={true}
                                onClick={() => selectSegregatedMemory(item)}
                            />
                        ))}
                    </div>
                </Box>

                <div className="z-1 mt-8">
                    <h1 className="mb-[7px] leading-[1] font-medium text-[16px]">
                        Skills
                    </h1>
                    <p className="text-[13px] text-gray-500">
                        Select and add Skills you would like "Persona Name" to
                        have. Skills are created in the apply section it gives
                        the ability to your Ai entity to think, handle multiple
                        tools, created chained reasoning cells and more.
                    </p>

                    <div className="mt-4 h-[120px] w-[500px] border-[1px] rounded-[4px] border-slate-400">
                        <FormControl mb={3}>
                            <Select
                                isMulti
                                isClearable={false}
                                name="colors"
                                options={skillsData}
                                value={selectSkills || []}
                                onChange={onSelectSkills}
                                className="arin-react-select-container "
                                classNamePrefix="arin-react-select"
                                styles={selectStyles}
                            />
                        </FormControl>
                    </div>
                    <div className="mt-5  w-[500px] ">
                        <Checkbox
                            colorScheme="red"
                            isChecked={persona?.can_create_collab === "yes"}
                            isDisabled={isLoading}
                            onChange={() => updatePersonaFunc({
                                id: persona!.id,
                                can_create_collab: persona?.can_create_collab === "yes" ? "no" : "yes"
                            })}
                        >
                            <span className="text-[15px]">
                                Allow Persona to create its own cells?
                            </span>
                        </Checkbox>
                        &nbsp;
                        <p className="text-[13px] text-gray-500 text-justify  ml-6 mt-2">
                            If this is ticked the entity may decide how to solve
                            the problem by itself requiring large compute. it
                            may be unpredictable. Be sure if you tick this box.{" "}
                        </p>
                        {/* <div className=" flex justify-end">
                            <Button
                                mt={4}
                                type="submit"
                                style={{
                                    color: "#fff",
                                    background: "#000",
                                    width: "150px",
                                }}
                                onClick={moveToNext}
                            >
                                Commit
                            </Button>
                        </div> */}
                    </div>
                </div>
            </Box>
        </div>
    );
};

export default PersonaMemories;
