export function isObjectEmpty(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value !== null && value !== undefined && value !== "") {
                return false;
            }
        }
    }
    return true;
}

export function checkSomeKeysDefined(obj, keys) {
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (obj.hasOwnProperty(key) && !!obj[key]) {
            return true;
        }
    }
    return false;
}
