import { Textarea, Button } from "@chakra-ui/react";
import { FormikProps } from "formik";
import React, { useEffect, useRef } from "react";
import { PersonaState } from "../../store/slices/persona.slice";
import { useHttpClient } from "../../utils/http.utils";
import { useSelector } from "react-redux";
import BotChat from "./BotChat";
import UserChat from "./UserChat";
import downloadFile from "../../utils/collaborate/downloadFIle";
import { AuthUserProfile } from "../../models/user.model";
import { BsDownload, BsFillCheckCircleFill } from "react-icons/bs";
import { extractJSON } from "../../utils/strings.utils";
import hotToast from "react-hot-toast";

interface PersonaPersonalityWritingSamplingProps {
    markAsCompleted?: (completedStatus: boolean) => void;
}

const PersonaWritingSampling = ({
    markAsCompleted,
}: PersonaPersonalityWritingSamplingProps) => {
    const { personalityWritingSamplingApi } = useHttpClient();
    const [wordCount, setWordCount] = React.useState<number>(0);
    const [inputValue, setInputValue] = React.useState<string>("");
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [fileTobeDownloaded, setFileTobeDownloaded] = React.useState<any>();
    const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<
        Array<{ content: string; type: "bot" | "user"; loading?: boolean }>
    >([]);

    const personaState: PersonaState = useSelector(
        (state: any) => state.persona,
    );

    const authUser: AuthUserProfile = useSelector(
        (state: any) => state.auth.user,
    );

    const {
        id: personaId,
        first_name: personaName,
        writing_sampling_status,
    } = personaState.personaAttributesForm;

    useEffect(() => {
        if (writing_sampling_status === "completed") {
            setIsCompleted(true);
            if (markAsCompleted) {
                markAsCompleted(true);
            }
        } else {
            setIsCompleted(false);
            if (markAsCompleted) {
                markAsCompleted(false);
            }
            loadData();
        }
    }, [personaState.personaAttributesForm]);

    const loadData = async () => {
        setIsSubmitting(true);
        try {
            const response = await personalityWritingSamplingApi(personaId);
            const jsonData = extractJSON(response);
            if (typeof jsonData === "object" && jsonData !== null) {
                setIsCompleted(true);
                setFileTobeDownloaded(jsonData);
                // Mark tab as completed
                if (markAsCompleted) {
                    markAsCompleted(true);
                }

                // If there was an initial empty bot message, remove it.
                setMessages((prev) => {
                    const updatedMessages = [...prev];
                    if (
                        updatedMessages.length > 0 &&
                        updatedMessages[updatedMessages.length - 1].content ===
                            ""
                    ) {
                        updatedMessages.pop();
                    }
                    return updatedMessages;
                });
                // construct final message
            } else {
                setMessages([
                    { content: response, type: "bot", loading: false },
                ]);
            }
        } catch (error) {
            setMessages([
                {
                    content: "An error occurred!",
                    type: "bot",
                    loading: false,
                },
            ]);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFIleDownload = () => {
        downloadFile(`Persona-${personaName}`, fileTobeDownloaded);
    };

    const requestWritingSampling = async (userQuery: string = "") => {
        try {
            // Add a bot message with empty content (will show loading animation)
            setMessages((prev) => [
                ...prev,
                { content: "", type: "bot", loading: true },
            ]);

            const response = await personalityWritingSamplingApi(
                personaId,
                userQuery,
            );

            // const stringifyResponse = JSON.stringify(response);

            const jsonData = extractJSON(response);

            if (typeof jsonData === "object" && jsonData !== null) {
                setIsCompleted(true);
                setFileTobeDownloaded(jsonData);
                // Mark tab as completed
                if (markAsCompleted) {
                    markAsCompleted(true);
                }
                // Remove the last bot chat
                setMessages((prev) => {
                    const updatedMessages = [...prev];
                    updatedMessages.pop();
                    return updatedMessages;
                });

                // add json message to the messages array
                if (jsonData.characteristics?.backstory?.personality) {
                    setMessages([
                        {
                            content:
                                "Personality\n" +
                                jsonData.characteristics?.backstory
                                    ?.personality,
                            type: "bot",
                            loading: false,
                        },
                    ]);
                }
            } else {
                setMessages((prev) => {
                    const updatedMessages = [...prev];
                    updatedMessages[updatedMessages.length - 1].content =
                        response;
                    updatedMessages[updatedMessages.length - 1].loading = false; // Remove loading state
                    return updatedMessages;
                });
            }
        } catch (error) {
            setMessages((prev) => {
                const updatedMessages = [...prev];
                updatedMessages[updatedMessages.length - 1].content =
                    "An error occurred!";
                updatedMessages[updatedMessages.length - 1].loading = false; // Remove loading state
                return updatedMessages;
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputValue = e.target.value;
        setInputValue(inputValue);
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const pastedText = e.clipboardData?.getData("text") || "";

        // Calculate combined word count (current + pasted)
        const currentWords = inputValue.trim().split(/\s+/).filter(Boolean);
        const pastedWords = pastedText.trim().split(/\s+/).filter(Boolean);
        const combinedWords = [...currentWords, ...pastedWords];

        // If combined word count is more than 1000, truncate it
        const truncatedWords = combinedWords.slice(0, 1000);

        // Update the input value with the truncated text
        setInputValue(truncatedWords.join(" "));
        setWordCount(truncatedWords.length);

        e.preventDefault(); // Prevent default paste behavior
    };

    const handleKeyDown = async (e: any) => {
        // Calculate word count
        const words = inputValue.trim().split(/\s+/).filter(Boolean);
        setWordCount(words.length);

        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();

            let inputValue = e.target.value;

            if (words.length > 1000) {
                hotToast.error("You have exceeded the word 1000");
                return;
            }
            /**
             * For the first user's message, we need to check if the user has written at least 200 words.
             */
            const userMessageExist = messages.some(msg => msg.type === "user" && msg.content);
            if (!userMessageExist && words.length < 200) {
                hotToast.error("You need to write at least 200 words");
                return;
            }

            // Add user message to the messages array
            setMessages((prev) => [
                ...prev,
                { content: inputValue, type: "user" },
            ]);

            // Clear the inputValue immediately after adding the message to the list
            setInputValue("");
            setWordCount(0);

            await requestWritingSampling(inputValue);
        }
    };

    return (
        <div className="">
            <p className="text-[14px] mb-8 text-slate-500">
                Your General Al Entity will analyze specific handwriting styles
                for this particular persona.
            </p>
            {/* AI Message on the Left */}
            <div className="input-group my-4">
                {isCompleted && (
                    <>
                        <div className="h-[200px] flex flex-col gap-[6px] items-center justify-center">
                            <BsFillCheckCircleFill className="text-green-500 text-5xl" />
                            <p className="text-[15px] text-center mt-4 text-gray-500">
                                You have completed the writing sampling test
                            </p>
                        </div>
                        {fileTobeDownloaded && (
                            <div className="px-4 py-3">
                                <p className="mb-4 text-[14px] text-slate-500">
                                    Your entity's personality trait has been
                                    baked in the digital DNA. You can no longer
                                    change that.
                                </p>

                                <Button
                                    onClick={handleFIleDownload}
                                    colorScheme="brand"
                                    size={"lg"}
                                >
                                    Communicate
                                    <BsDownload className="ml-2" size="20px" />
                                </Button>
                            </div>
                        )}
                    </>
                )}
                {!isCompleted && (
                    <>
                        {messages.map((message, index) =>
                            message.type === "bot" ? (
                                <BotChat
                                    key={index}
                                    loading={message.loading}
                                    message={message.content}
                                />
                            ) : (
                                <UserChat
                                    key={index}
                                    message={message.content}
                                    userName={
                                        (authUser.fullname as string) ||
                                        (authUser.username as string) ||
                                        (authUser.email as string)
                                    }
                                />
                            ),
                        )}
                        <Textarea
                            placeholder="Type your reply here.."
                            resize="none"
                            rows={2}
                            value={inputValue}
                            onChange={onInputChange}
                            onKeyDown={handleKeyDown}
                            onPaste={handlePaste}
                        />
                        <p
                            className={`text-[14px] mt-2 ${
                                (wordCount > 1000) ? "text-red-500" : ""
                            }`}
                        >
                            Word count: {wordCount}/1000
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default PersonaWritingSampling;