const hideMiddleChars = (str: string) => {
    if (str.length <= 6) {
        return str;
    }

    const start = str.substring(0, 2);
    const end = str.substring(str.length - 4);

    return `${start}-...${end}`;
};

export default hideMiddleChars;
