import ArinLayout from "../components/ArinLayout";
import SearchBox from "../components/SearchBox";
import "../styles/ThreadView.component.scss";
import { AiOutlineArrowLeft } from "react-icons/ai";
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Stack,
    Tab,
    TabList,
    Tabs,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SearchModel, ThreadModel } from "../models/search.model";
import { setMetaTags } from "../utils/seo.utils";
import { useHttpClient } from "../utils/http.utils";
import ChainingCard from "../components/ChainingCard";
import { getAuthUser } from "../utils/auth.utils";
import {
    addThreadQuery,
    setThreadLoadingStatus,
} from "../store/slices/threads.slice";
import { scrollToBottom } from "../utils/dom.utils";
import ResponseCard from "../components/ResponseCard";
import { SlLink } from "react-icons/sl";
import { MdCopyAll, MdOutlineBookmarkBorder } from "react-icons/md";
import PromptLibrary from "../components/PromptLibrary";
import SaveResponseForm from "../components/SaveResponseForm";
import MemoriesPanel from "../components/memories/MemoriesPanel";
import { useMemoriesConfig } from "../utils/memories-config.utils";
import useSearches from "../utils/search/searches.utils";

export default function ThreadView() {
    const searches: any = useSelector((state) => (state as any).searches);
    const threads: any = useSelector((state) => (state as any).threads);

    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<string>();
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [isControlsFormVisible, setIsControlsFormVisible] = useState(false);
    const chainedItems: SearchModel[] = useSelector(
        (state) => (state as any).searches.chain,
    );
    const folderChainedItems: SearchModel[] = useSelector(
        (state) => (state as any).searches.folderChain,
    );
    const params = useParams();
    const containerRef = useRef(null);
    const authUser = getAuthUser();
    const navigate = useNavigate();
    const [response, setResponse] = useState<SearchModel>();
    const { fetchThreadReplyApi, fetchSearchByIdApi, fetchNotesInThreadApi } =
        useHttpClient();
    const toast = useToast();
    const dispatch = useDispatch();
    const noDatabaseDis = useDisclosure();
    const [tabIndex, setTabIndex] = useState(0);
    const memoriesConfig = useMemoriesConfig();
    const { getFolderChainHistory, getFindChainHistory } = useSearches();

    useEffect(() => {
        /**
         *  Get specific respons from state,
         *  */
        const response = searches.replies.find(
            (search: SearchModel) => search.id === Number(params.id),
        );

        if (response) {
            /**
             * Get and set thread history
             */
            setIsLoading(false);
            setResponse({
                ...response,
                isFinishedTyping: true,
                isLoading: false,
            });
            setMetaTags({ title: `Thread: ${response?.question}` });
            // load history
            // loadHistory(response?.id.toString());
        } else {
            // fetch repose from api
            fetchSearchByIdApi(params.id as string)
                .then(({ data }: { data: SearchModel }) => {
                    setMetaTags({ title: `Thread: ${response?.question}` });
                    setResponse({
                        ...data,
                        isFinishedTyping: true,
                        isLoading: false,
                    });

                    if (data) {
                        // loadHistory((data.id as number).toString())
                    }
                })
                .catch((err) => {
                    console.error(err);
                    /**
                     * Show error and navigate back to home page...
                     */
                    toast({
                        title: "Cannot load thread",
                        description:
                            "The response you are trying to fetch was not found",
                    });
                    navigate(-1);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, []);

    // const loadHistory = (responseId: string) => {
    //     /**
    //      * Generate chain IDs
    //      * 1.  Get all chained items ids, then add the id of the current reply
    //      */
    //     const threadMode = searchParams.get("mode") || "search";
    //
    //     let ids: any[] = []
    //
    //     switch (threadMode) {
    //         case "search":
    //         default:
    //             ids = [...chainedItems.map(chain => chain.id), responseId];
    //             break;
    //             ids = [...folderChainedItems.map(chain => chain.id), responseId];
    //         case "folder":
    //             break;
    //     }
    //
    //     if (ids.length > 0) {
    //         setIsLoadingHistory(true);
    //         fetchThreadHistoryApi(ids as string[])
    //             .finally(() => {
    //                 setIsLoadingHistory(false);
    //             })
    //     }
    // }

    const onSearch = async (query: string, params: any) => {
        // Clean search query, remove white spaces and trailing new line characters if exist
        const search_query = query.trim();
        if (!search_query) {
            return;
        }
        /**
         * User's can only ask question at the time, in case the user is trying to ask a question, while another
         * is pending, reject
         */
        if (threads.isSearchLoading) {
            toast({
                title: "Please wait for first query to complete",
                position: "top",
                status: "info",
                duration: 2300,
            });
            return;
        }
        // set loading status...
        dispatch(setThreadLoadingStatus(true));

        // set state to typing and add search to state
        dispatch(
            addThreadQuery({
                isFinishedTyping: false,
                isLoading: true,
                isExpanded: false,
                question: search_query,
                ...(response?.persona_id && {
                    persona_id: response?.persona_id,
                }),
                character: response?.character,
                character_message: response?.character_message,
                history: threads.history,
                search_id: response?.id,
                reply: "",
            }),
        );

        // Scroll to bottom
        scrollToBottom(containerRef);
        /**
         * Get thread reply
         */
        /**
         *  Send search Query
         */
        // if (params.check_list === "all") {
        //     const {data} =  await getAgentNamespace();
        //
        //     if (data["message"] === "No database matched") {
        //         // TODO: SHOW CHOOSE OTHER MEMORY MODAL
        //         dispatch(setThreadLoadingStatus(false));
        //         dispatch(removeLastThreadQuery())
        //         noDatabaseDis.onOpen();
        //         return;
        //     } else {
        //         params.check_list = data["message"];
        //     }
        // }

        const payload = {
            search: search_query,
            search_id: response?.id,
            ...(response?.persona_id && { persona_id: response?.persona_id }),
            character: response?.character,
            character_message: response?.character_message,
            history: threads.history,
            // check_list: "['all']",
            ...params,
            relation: "", // TODO: REQUEST FORMAT FOR AHTESHAM
            ...memoriesConfig,
        };

        const threadMode = searchParams.get("mode") || "search";

        switch (threadMode) {
            case "search":
            default:
                payload.history = getFindChainHistory();
                break;
            case "folder":
                payload.history = getFolderChainHistory();
                break;
        }

        fetchThreadReplyApi(payload);
    };

    function onNotes(notes: string, params: any) {
        // Clean search query, remove white spaces and trailing new line characters if exist
        const search_query = notes.trim();
        if (!search_query) {
            return;
        }
        /**
         * User's can only ask question at the time, in case the user is trying to ask a question, while another
         * is pending, reject
         */
        if (threads.isSearchLoading) {
            toast({
                title: "Please wait for first query to complete",
                position: "top",
                status: "info",
                duration: 2300,
            });
            return;
        }

        // Scroll to bottom
        scrollToBottom(containerRef);

        fetchNotesInThreadApi({
            notes: notes,
            ...(response?.persona_id && { persona_id: response?.persona_id }),
            character: searches.character.character,
            character_message: searches.character.character_message,
            ...params,
        });
    }

    function onResponseSave() {
        console.log(response);
        setIsSaveModalOpen(true);
    }

    /**
     *
     * Copy the reply to clipboard
     */
    const copyThreadToClipBoard = () => {
        const newLIne = "\n";
        let data =
            response?.question + newLIne + response?.reply + newLIne + newLIne;
        //append threads
        threads.replies.forEach((thread: ThreadModel) => {
            console.log(threads.replies);
            if (thread.search_id === response?.id) {
                data +=
                    thread?.question +
                    newLIne +
                    thread?.reply +
                    newLIne +
                    newLIne;
            }
        });
        // copy clipboard
        navigator.clipboard
            .writeText(data)
            .then(() => {
                toast({
                    title: "Thread Copied",
                    duration: 1000,
                    status: "info",
                });
            })
            .catch(() => {
                toast({
                    title: "Can not copy to clipboard",
                    duration: 1000,
                    status: "error",
                });
            });
    };

    const clearSaveModalOpenState = () => {
        setIsSaveModalOpen(false);
    };

    function onPromptSelect(prompt: string) {
        setSelectedPrompt(prompt);
    }

    return (
        <ArinLayout>
            <div className="search-layout-wrapper">
                <div
                    id="left-panel"
                    className="left-panel grid-column pl-2 pb-3"
                >
                    <div className="switch-header">
                        <Tabs
                            variant="solid-rounded"
                            size="sm"
                            colorScheme="brand"
                            onChange={(index) => setTabIndex(index)}
                        >
                            <TabList>
                                <Tab>Memories</Tab>
                                <Tab>Prompt</Tab>
                            </TabList>
                        </Tabs>
                    </div>
                    <div className="switch-body">
                        {tabIndex === 0 && (
                            <div className="h-full">
                                <MemoriesPanel />
                            </div>
                        )}
                        {tabIndex === 1 && (
                            <div className="h-full">
                                <PromptLibrary onSelect={onPromptSelect} />
                            </div>
                        )}
                        {/*<TransitionGroup>*/}
                        {/*    <CSSTransition*/}
                        {/*        nodeRef={promptContainerRef}*/}
                        {/*        in={tabIndex === 0}*/}
                        {/*        timeout={200}*/}
                        {/*        classNames="left-switch-node"*/}
                        {/*    >*/}
                        {/*        */}
                        {/*    </CSSTransition>*/}

                        {/*    <CSSTransition nodeRef={memoriesContainerRef} in={tabIndex === 0} timeout={200} classNames="left-switch-node">*/}
                        {/*       */}
                        {/*    </CSSTransition>*/}
                        {/*</TransitionGroup>*/}
                    </div>
                </div>
                <div className="main grid-column">
                    <div className="thread-toolbar">
                        <div className="flex gap-2 items-center">
                            <Button
                                variant="ghost"
                                onClick={() => navigate(-1)}
                                leftIcon={<AiOutlineArrowLeft />}
                            >
                                Back
                            </Button>
                            <div className="twin-avatars">
                                <img
                                    src={"/user_avatar.png"}
                                    alt="avatar"
                                    className="profile-image"
                                />
                                <img
                                    src={
                                        authUser.profile_picture ||
                                        "/user_avatar.png"
                                    }
                                    alt="avatar"
                                    className="profile-image"
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <p
                                title={response?.question}
                                className="question-title font-semibold truncate w-full mb-0"
                            >
                                {response?.question}
                            </p>
                            <p className="text-slate-500 text-xs font-medium">
                                Thread with {response?.character} &{" "}
                                {authUser.fullname || authUser.username}
                            </p>
                        </div>
                        <div className="flex gap-1 items-center">
                            <button
                                title="Copy Thread"
                                className="icon-buttons"
                                onClick={onResponseSave}
                            >
                                <MdOutlineBookmarkBorder className="icon" />
                            </button>
                            <button
                                title="Copy Thread"
                                className="icon-buttons"
                                onClick={copyThreadToClipBoard}
                            >
                                <MdCopyAll className="icon" />
                            </button>
                            <Button
                                colorScheme={"blue"}
                                size="sm"
                                variant="outline"
                            >
                                <SlLink className="rotate" />
                                &nbsp;&nbsp;Chain
                            </Button>
                        </div>
                    </div>
                    <div
                        className={`search-view with-toolbar thread-search-view ${
                            !isControlsFormVisible && "expand"
                        }`}
                    >
                        <div ref={containerRef} className="scroll-view">
                            <div className="thread-card">
                                {response && (
                                    <ResponseCard
                                        data={response as SearchModel}
                                        mode="search"
                                        hideSaveAction={true}
                                        hideChainAction={true}
                                        hideThreadAction={true}
                                    />
                                )}
                                {isLoading && (
                                    <div className="character-card mt-1 card px-8 py-6 mb-4">
                                        <Stack>
                                            <Skeleton
                                                height="20px"
                                                width="200px"
                                            />
                                            <Skeleton height="25px" />
                                            <Skeleton height="25px" />
                                            <Skeleton height="25px" />
                                        </Stack>
                                    </div>
                                )}
                            </div>
                            <div className="responses-thread">
                                {threads.replies
                                    .filter(
                                        (thread: ThreadModel) =>
                                            thread.search_id === response?.id,
                                    )
                                    .map((item: ThreadModel, index: number) => {
                                        return (
                                            <ResponseCard
                                                key={index}
                                                mode="thread"
                                                data={item}
                                                hideSaveAction={true}
                                                hideThreadAction={true}
                                                hideChainAction={true}
                                                onSaveResponse={onResponseSave}
                                                scrollToBottomHandler={() =>
                                                    scrollToBottom(containerRef)
                                                }
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`search-box-form ${
                            !isControlsFormVisible && "shrink"
                        }`}
                    >
                        <SearchBox
                            defaultInputValue={selectedPrompt}
                            onControlsFormToggle={(val) =>
                                setIsControlsFormVisible(val)
                            }
                            loading={isLoadingHistory}
                            loadingText="Loading History.."
                            onSearch={onSearch}
                            onNotes={onNotes}
                        />
                    </div>
                </div>
                <div className="chaining-col grid-column">
                    <ChainingCard />
                </div>
            </div>
            {/* Save modals */}
            <SaveResponseForm
                isOpen={isSaveModalOpen}
                data={response as SearchModel}
                onClose={clearSaveModalOpenState}
            />

            <Modal
                size="sm"
                isCentered={true}
                isOpen={noDatabaseDis.isOpen}
                onClose={noDatabaseDis.onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <span className="text-lg">No Database Matched</span>
                    </ModalHeader>
                    <ModalBody>
                        <p className="text-sm">
                            Your agent could not find any database for your
                            search query. Please select a memory from the search
                            box below
                        </p>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={noDatabaseDis.onClose}
                            colorScheme="brand"
                            variant="outline"
                            size="sm"
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </ArinLayout>
    );
}
