import { Button } from "@chakra-ui/react";
import { PersonaModel } from "../../models/persona.model";
import { useRef } from "react";
import { useHttpClient } from "../../utils/http.utils";

interface PersonaProfilePhotoProps {
    persona: PersonaModel;
}

export default function PersonaProfilePhoto({
    persona,
}: PersonaProfilePhotoProps) {
    const profilePicRef = useRef(null);
    const imageRef = useRef(null);
    const { uploadPersonaProfilePhotoApi } = useHttpClient();

    const togglePicker = () => {
        (profilePicRef.current as unknown as HTMLInputElement).click();
    };

    const onPhotoSelected = async (e) => {
        const file = e.target.files[0];
        // create object url from file
        (imageRef.current as unknown as HTMLImageElement).src =
            URL.createObjectURL(file);
        // Upload the file to server and show progress on frontend.
        await uploadPersonaProfilePhotoApi(file, persona.id as string);
    };

    return (
        <div className="profile-photo flex flex-col gap-[15px] items-center">
            <input
                style={{ display: "none" }}
                type="file"
                ref={profilePicRef}
                accept="image/*"
                onChange={onPhotoSelected}
            />
            <img
                ref={imageRef}
                className="image-progress-clip h-[95px] w-[95px] rounded-full object-cover"
                src="/user_avatar.png"
                alt=""
            />
            <Button
                onClick={togglePicker}
                variant="link"
                size={"xs"}
                colorScheme="blue"
            >
                {persona.profile_photo
                    ? "Change Photo"
                    : "Upload Profile Photo"}
            </Button>
        </div>
    );
}
