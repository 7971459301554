export default function LoadingDots({ size = 4 }) {
    return (
        <div className="pending-cursor">
            {/* <p className="text-slate-500 text-sm">{loadingText}</p> */}
            <div className="loading-dots">
                <span
                    style={{ height: `${size}px`, width: `${size}px` }}
                    className="dot"
                ></span>
                <span
                    style={{ height: `${size}px`, width: `${size}px` }}
                    className="dot"
                ></span>
                <span
                    style={{ height: `${size}px`, width: `${size}px` }}
                    className="dot"
                ></span>
            </div>
        </div>
    );
}
