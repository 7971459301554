import "../styles/DataUploadReorder.component.scss";
import React, {
    useCallback,
    useEffect,
    forwardRef,
    useState,
    useImperativeHandle,
    useRef,
} from "react";
import { convertHeaderStringToList } from "../utils/strings.utils";
import {
    Button,
    Spinner,
    TabList,
    TabPanels,
    Tabs,
    Tab,
    TabPanel,
    useToast,
} from "@chakra-ui/react";
import { useHttpClient } from "../utils/http.utils";
import { AiFillWarning } from "react-icons/ai";
import { IoRefresh } from "react-icons/io5";
import { UploadFileRelation } from "../models/upload.model";
import { setBatch } from "react-redux/es/utils/batch";

interface Box {
    id: string;
    order: number;
    isCol: boolean;
    name: string;
}

interface DataUploadProps {
    showForm: boolean;
    data: {
        fileName: string;
        loadRelations: boolean;
    };
    // This function is fired when relations have been loaded
    onInitialize?: (hasFinished: boolean) => void;
}

interface ReorderFormData {
    file_name: string;
    sheet_name: string;
    boxes: Box[]; // These are the different editable boxes
    headers: string[]; // This is the column names
}

const DataUploadReorder = forwardRef(function DataUploadReorder(
    props: DataUploadProps,
    ref: any,
) {
    const [dragId, setDragId] = useState();
    // const [boxes, setBoxes] = useState<Box[]>([]);
    const [reorderFormData, setReorderFormData] = useState<ReorderFormData[]>(
        [],
    );
    const [loading, setIsLoading] = useState<boolean>(true);
    const [isLoadingRelationshipError, setIsIsLoadingRelationshipError] =
        useState<boolean>(false);
    const [
        loadingRelationshipErrorMessage,
        setLoadingRelationshipErrorMessage,
    ] = useState<string>(
        "Unable to load column relationships. Please try again later.",
    );
    const { getStructuredDataRelationshipsApi } = useHttpClient();
    const createChips = (relation: UploadFileRelation[]) => {
        let reorderFormDataTemp: ReorderFormData[] = [];

        relation.forEach((data, index) => {
            const splitText = convertHeaderStringToList(
                data.relation as string,
            );
            let headers: string[] = [];
            const boxes = splitText.map((el, box_index) => {
                let box = {
                    id: `box_${index}_${box_index}`,
                    order: box_index + 1,
                    isCol: false,
                    name: el,
                };
                if (el.indexOf("[") > -1) {
                    box.name = el.slice(1, -1);
                    headers.push(el.slice(1, -1));
                    box.isCol = true;
                }
                return box;
            });

            reorderFormDataTemp.push({
                file_name: data.file_name as string,
                sheet_name: data.sheet_name as string,
                boxes: boxes,
                headers: headers,
            });
        });
        // populate form data
        setReorderFormData(reorderFormDataTemp);
        // Remove relations loader from screen
        setIsLoading(false);
    };

    const fetchRelationships = async () => {
        try {
            const { data } = await getStructuredDataRelationshipsApi(
                props.data.fileName,
            );

            if (data.success === false) {
                setIsLoading(false);
                setIsIsLoadingRelationshipError(true);
                if (data.error) {
                    setLoadingRelationshipErrorMessage(data.error);
                }
                return;
            }

            createChips(data.message);

            // reset values
            setIsLoading(false);
            setIsIsLoadingRelationshipError(false);
            // emit finish initialization
            if (typeof props.onInitialize === "function") {
                props.onInitialize(true);
            }
        } catch (e) {
            console.error(e);
            setIsLoading(false);
            setIsIsLoadingRelationshipError(true);
            setLoadingRelationshipErrorMessage(
                "Unable to load column relationships. Please try again later.",
            );
        }
    };

    useEffect(() => {
        if (
            props.showForm &&
            props.data.loadRelations &&
            props.data.fileName &&
            reorderFormData.length === 0
        ) {
            /// Fetch relationship
            fetchRelationships();
            // setRelationShipTexts(html_text)
        }
        return () => {
            setReorderFormData([]);
        };
    }, [props.showForm]);

    const refreshRelationships = () => {
        fetchRelationships();
        setIsIsLoadingRelationshipError(false);
        setIsLoading(true);
    };

    const handleDrag = (ev: any) => {
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = (ev: any, locIndex: number) => {
        const formDataCopy = [...reorderFormData];

        const newFormState = formDataCopy.map((formData, index) => {
            if (locIndex === index) {
                const dragBox = formData.boxes.find((box) => box.id === dragId);
                const dropBox = formData.boxes.find(
                    (box) => box.id === ev.currentTarget.id,
                );

                const dragBoxOrder = (dragBox as any).order;
                const dropBoxOrder = (dropBox as any).order;

                formData.boxes.map((box) => {
                    if (box.id === dragId) {
                        box.order = dropBoxOrder;
                    }
                    if (box.id === ev.currentTarget.id) {
                        box.order = dragBoxOrder;
                    }
                    return box;
                });
            }
            return formData;
        });

        setReorderFormData(newFormState);
    };

    useImperativeHandle(ref, () => ({
        getRelationships(): UploadFileRelation[] {
            let container = document.getElementsByClassName(
                    "drag-container",
                ) as HTMLCollectionOf<HTMLDivElement>,
                fileRelations: UploadFileRelation[] = [];

            // Container and reorderForm have the same length and their indexes correspond
            for (let index = 0; index < container.length; index++) {
                let data: string[] = [],
                    fileRelation: UploadFileRelation = {
                        file_name: reorderFormData[index].file_name,
                        sheet_name: reorderFormData[index].sheet_name,
                        relation: "",
                    };

                for (let i = 0; i < container[index].children.length; i++) {
                    let child = container[index].children[i] as HTMLElement;

                    if (child.classList.contains("column")) {
                        if (child.innerText) {
                            data.push(`[${child.innerText}]`);
                        }
                    } else {
                        if (child.innerText) {
                            data.push(child.innerText);
                        }
                    }
                }

                fileRelation.relation = data.join(" ");

                fileRelations.push(fileRelation);
            }

            return fileRelations;
        },

        clearRelationShips() {
            setReorderFormData([]);
        },
    }));

    // @ts-ignore
    return (
        <div className="reorder-form pt-6">
            {props.showForm && (
                <div>
                    {loading && !isLoadingRelationshipError && (
                        <div className="py-4 flex gap-2 items-center">
                            <Spinner color="blue" />
                            <p className="text-slate-900 text-blue-900 font-medium">
                                Loading column relationships..
                            </p>
                        </div>
                    )}
                    {!loading &&
                        !isLoadingRelationshipError &&
                        reorderFormData.length > 0 && (
                            <div className="pt-4">
                                <p className="font-semibold text-xl mb-2">
                                    Structured Data
                                </p>
                                <p className="text-slate-500 mb-3">
                                    The file you uploaded contains structured
                                    data that require your input to identify the
                                    relationships between them.
                                </p>
                                <Tabs>
                                    <TabList>
                                        {reorderFormData.map((data, index) => {
                                            return (
                                                <Tab
                                                    key={`tab-header-${index}`}
                                                >
                                                    {data.sheet_name}
                                                </Tab>
                                            );
                                        })}
                                    </TabList>

                                    <TabPanels>
                                        {reorderFormData.map((data, index) => (
                                            <TabPanel
                                                key={`tab-panel-${index}`}
                                            >
                                                <p className="font-semibold mb-2">
                                                    Here are the header fields
                                                    we have extracted:
                                                </p>
                                                <div className="columns mb-6">
                                                    {data.headers.map(
                                                        (header, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="chip mr-2"
                                                                >
                                                                    {header}
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                </div>
                                                <p className="font-semibold mb-2">
                                                    Drag the chips and edit the
                                                    text to accurately describe
                                                    the relationship between the
                                                    fields.
                                                </p>
                                                <div className="drag-container p-4">
                                                    {data.boxes
                                                        .sort(
                                                            (a, b) =>
                                                                a.order -
                                                                b.order,
                                                        )
                                                        .map(
                                                            (
                                                                box,
                                                                box_index,
                                                            ) => (
                                                                <span
                                                                    key={`box-span-${box_index}`}
                                                                    draggable={
                                                                        true
                                                                    }
                                                                    id={box.id}
                                                                    onDragOver={(
                                                                        ev,
                                                                    ) =>
                                                                        ev.preventDefault()
                                                                    }
                                                                    onDragStart={
                                                                        handleDrag
                                                                    }
                                                                    onDrop={(
                                                                        e,
                                                                    ) =>
                                                                        handleDrop(
                                                                            e,
                                                                            index,
                                                                        )
                                                                    }
                                                                    role={
                                                                        "textbox"
                                                                    }
                                                                    contentEditable={
                                                                        true
                                                                    }
                                                                    className={`drag-span ${
                                                                        box.isCol
                                                                            ? "column"
                                                                            : ""
                                                                    }`}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: box.name,
                                                                    }}
                                                                />
                                                            ),
                                                        )}
                                                </div>
                                            </TabPanel>
                                        ))}
                                    </TabPanels>
                                </Tabs>
                            </div>
                        )}
                    {!loading && isLoadingRelationshipError && (
                        <div className="py-4 flex gap-2 items-center">
                            <AiFillWarning color="red" fontSize={20} />
                            <p className="text-slate-900 text-red-600 font-medium">
                                {loadingRelationshipErrorMessage}
                            </p>
                            <Button
                                onClick={refreshRelationships}
                                variant="ghost"
                            >
                                <IoRefresh className="icons" />
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});

export default DataUploadReorder;
