import { PersonaModel } from "../../models/persona.model";
import { ProfileAvatar } from "../Avatar";
import "../../styles/personas/PersonaListCard.component.scss";

interface PersonaListCardProps {
    data: PersonaModel;
    isActive?: boolean;
    onClick?: (persona: PersonaModel) => void;
}

export default function PersonaListCard(props: PersonaListCardProps) {
    const handleClick = (data: PersonaModel) => {
        if (props.onClick) {
            props.onClick(props.data);
        }
    };
    return (
        <div
            className={`persona-list-card ${
                props.isActive ? "active" : ""
            } mb-4 px-4 py-2 rounded-lg`}
            onClick={() => handleClick(props.data)}
        >
            <div className="left flex gap-3 items-center">
                <ProfileAvatar src={props.data.profile_photo} size="30px" />
                <h4 className="clone-text font-semibold">
                    {props.data.first_name + " " + props.data.last_name}
                </h4>
            </div>
        </div>
    );
}
