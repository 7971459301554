import {
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Grid,
    GridItem,
    Input,
    useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { useHttpClient } from "../../../utils/http.utils";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { OrganizationModel } from "../../../models/organization.model";
import { AccountState } from "../../../store/slices/account.slice";

const countryOptions = [{ value: "us", label: "United States" }];
const taxIDOptions = [{ value: "ein", label: "EIN" }];

export default function SubscriptionOrganizationView() {
    const { accountData: loggedInUser, activeOrganization }: AccountState = useSelector((state: any) => state.account);
    const formikRef = useRef(null);
    const toast = useToast();

    const {
        updateOrganizationApi,
        createOrganizationApi,
    } = useHttpClient();

    useEffect(() => {
        if (activeOrganization?.id) {
            (formikRef.current as any).setValues({
                name: activeOrganization.name,
                billing_email: activeOrganization.billing_email,
                country: activeOrganization?.address?.country,
                address_line_1: activeOrganization?.address?.address_line_1,
                address_line_2: activeOrganization?.address?.address_line_2,
                city: activeOrganization?.address?.city,
                state: activeOrganization?.address?.state,
                tax_id_type: activeOrganization?.business_tax?.type,
                tax_id: activeOrganization?.business_tax?.tax_id,
            });
        }
    }, []);

    // validate required fields
    function validateRequired(key, value) {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    }

    const saveOrganization = (values, actions) => {
        // create a new organization if none exists, otherwise update organization
        let promise;
        if (!activeOrganization?.id) {
            promise = createOrganizationApi({
                name: values.name,
                billing_email: values.billing_email,
                address: {
                    address_line_1: values.address_line_1,
                    address_line_2: values.address_line_2,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                },
                business_tax: {
                    type: values.tax_id_type,
                    tax_id: values.tax_id,
                },
            });
        } else {
            promise = updateOrganizationApi({
                id: activeOrganization.id,
                name: values.name,
                billing_email: values.billing_email,
                address: {
                    address_line_1: values.address_line_1,
                    address_line_2: values.address_line_2,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                },
                business_tax: {
                    type: values.tax_id_type,
                    tax_id: values.tax_id,
                },
            });
        }

        promise
            .then(() => {
                toast({
                    title: "Organization saved successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    };

    return (
        <div className="accounts-subview account-organization w-full h-full">
            <div className="side-nav-sm">
                <h4 className="title">Organization</h4>
                <p className="subtitle">
                    Changes to these preferences will apply to future invoices
                    only.
                </p>
            </div>

            <div className="body px-6 pt-6">
                <div className="form-body">
                    <Formik
                        initialValues={{
                            name: activeOrganization?.name || "",
                            billing_email: activeOrganization?.billing_email || "",
                            country: activeOrganization?.address?.country || "us",
                            address_line_1: activeOrganization?.address?.address_line_1 || "",
                            address_line_2: activeOrganization?.address?.address_line_2 || "",
                            city: activeOrganization?.address?.city || "",
                            state: activeOrganization?.address?.state || "",
                            tax_id_type: activeOrganization?.business_tax?.type || "",
                            tax_id: activeOrganization?.business_tax?.tax_id || "",
                        }}
                        innerRef={formikRef}
                        onSubmit={saveOrganization}
                    >
                        {(props) => (
                            <Form>
                                <Field
                                    name="name"
                                    validate={(e) =>
                                        validateRequired("Name", e)
                                    }
                                >
                                    {({ field, form }) => (
                                        <FormControl
                                            mb={6}
                                            isInvalid={
                                                form.errors.name &&
                                                form.touched.name
                                            }
                                        >
                                            <FormLabel>Company Name</FormLabel>
                                            <Input
                                                {...field}
                                                size="sm"
                                                placeholder="Company Name"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.name}
                                            </FormErrorMessage>

                                            <FormHelperText>
                                                If specified, this name will
                                                appear on invoices instead of
                                                your organization name
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field
                                    name="billing_email"
                                    validate={(e) =>
                                        validateRequired("Billing Email", e)
                                    }
                                >
                                    {({ field, form }) => (
                                        <FormControl
                                            mb={6}
                                            isInvalid={
                                                form.errors.billing_email &&
                                                form.touched.billing_email
                                            }
                                        >
                                            <FormLabel>Billing email</FormLabel>
                                            <Input
                                                {...field}
                                                size="sm"
                                                placeholder="Billing email"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.billing_email}
                                            </FormErrorMessage>
                                            <FormHelperText>
                                                Invoices and other billing
                                                notifications will be sent here
                                                (in addition to being sent to
                                                the owners of your
                                                organization.)
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Field>

                                <h4 className="label font-semibold mb-1">
                                    Primary business address
                                </h4>
                                <p className="text-slate-500 text-sm mb-2">
                                    This is the physical address of the company
                                    purchasing NeoWorlder Inc services and is
                                    used to calculate any applicable sales
                                    taxes.
                                </p>

                                <Field name="country">
                                    {({ field, form }) => (
                                        <FormControl mb={4}>
                                            <Select
                                                placeholder="Select country"
                                                value={countryOptions.find(
                                                    (option) =>
                                                        field.value
                                                            ? field.value.includes(
                                                                  option.value,
                                                              )
                                                            : "",
                                                )}
                                                onChange={(selectedOption) => {
                                                    form.setFieldValue(
                                                        "country",
                                                        (selectedOption as any)
                                                            ?.value,
                                                    );
                                                }}
                                                onBlur={field.onBlur}
                                                options={countryOptions}
                                                className="arin-react-select-container"
                                                classNamePrefix="arin-react-select"
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="address_line_1">
                                    {({ field, form }) => (
                                        <FormControl mb={4}>
                                            <Input
                                                {...field}
                                                size="sm"
                                                placeholder="Address line 1"
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="address_line_2">
                                    {({ field, form }) => (
                                        <FormControl mb={4}>
                                            <Input
                                                {...field}
                                                size="sm"
                                                placeholder="Address line 2"
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                    <GridItem w="100%">
                                        <Field name="city">
                                            {({ field, form }) => (
                                                <FormControl mb={4}>
                                                    <Input
                                                        {...field}
                                                        size="sm"
                                                        placeholder="City"
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem w="100%">
                                        <Field name="state">
                                            {({ field, form }) => (
                                                <FormControl mb={4}>
                                                    <Input
                                                        {...field}
                                                        size="sm"
                                                        placeholder="State"
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>
                                </Grid>

                                <h4 className="label font-semibold mt-6 mb-1">
                                    Business Tax ID
                                </h4>
                                <p className="text-slate-500 text-sm mb-2">
                                    If you are a business tax registrant, please
                                    enter your business tax ID here.
                                </p>
                                <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                                    <GridItem w="100%">
                                        <Field name="taxt_id_type">
                                            {({ field, form }) => (
                                                <FormControl>
                                                    <Select
                                                        placeholder="Select Type"
                                                        options={taxIDOptions}
                                                        value={taxIDOptions.find(
                                                            (option) =>
                                                                field.value
                                                                    ? field.value.includes(
                                                                          option.value,
                                                                      )
                                                                    : "",
                                                        )}
                                                        onChange={(
                                                            selectedOption,
                                                        ) => {
                                                            form.setFieldValue(
                                                                "tax_id_type",
                                                                (
                                                                    selectedOption as any
                                                                )?.value,
                                                            );
                                                        }}
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem w="100%" colSpan={2}>
                                        <Field name="tax_id">
                                            {({ field, form }) => (
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        size="sm"
                                                        placeholder="Tax ID"
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>
                                </Grid>
                                <div className="border-t py-4 mt-6 flex justify-end pl-6">
                                    <Button
                                        isLoading={props.isSubmitting}
                                        loadingText="Saving.."
                                        colorScheme="brand"
                                        variant="solid"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
