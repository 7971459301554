import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { authenticateUser, setAuthUserSession } from "../utils/auth.utils";
import { useDispatch } from "react-redux";
import { setAuthState, setAuthUser } from "../store/slices/auth.slice";
import { useHttpClient } from "../utils/http.utils";

export default function OAuthCallback() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchUserCredentialsApi } = useHttpClient();
  const [validationError, setValidationError] = useState<string>();
  const code = searchParams.get("code");
  const accessToken = searchParams.get("access_token");
  const organizationId = searchParams.get("org");

  useEffect(() => {
    if (code) {
      authenticateUser(code as string)
        .then((token) => {
          // @ts-ignore
          // eslint-disable-next-line no-restricted-globals
          location.href = `${process.env.REACT_APP_NEO_UI_URL}/oauth_callback?access_token=${token}`;
        })
        .catch((err) => {
          // TODO: SHOW ERROR MESSAGE SO THEY CAN GO BACK TO LOGIN
          console.error(err);
        });
    }

    if (accessToken) {
      setAuthUserSession(accessToken as string).then((user) => {
        loadUserCredentials(user);
      });
    }
    // if acess code of access token is not specified
    if (!code && !accessToken) {
      setValidationError("We cannot sign you in at the moment, the authorization link seems too be broken. Please login again.");
    }
  }, []);

  async function loadUserCredentials(user: any) {
    dispatch(setAuthUser(user));
    dispatch(setAuthState(true));
    // Load user data from db
    await fetchUserCredentialsApi(user.email);
    navigate("/ask-anything" + "?org=" + organizationId);
  }
  return (
    <div className="bg-gradient-to-br from-gray-50 via-gray-100 to-gray-300 flex h-screen w-screen justify-center items-center">
      <img
        src="/neo_ai_logo_black.png"
        alt=""
        className="h-[30px] mb-[16px] absolute top-[100px] right-[50%] translate-x-[50%]"
      />
      <div className="content text-center">
        <h1 className="text-3xl font-semibold">
          {validationError ? "Oh Uh! Something is not right.." : "Hold on, while we authenticate you.."}
        </h1>
        {validationError ? (
          <div className="w-[400px] mx-auto mt-6 min-h-[50px] rounded-[8px] border border-red-400 bg-red-50 px-4 py-3 text-red-900">
            {validationError}
          </div>
        ) : (
          <div className="mt-4">
            <Spinner size="xl" />
          </div>
        )}
      </div>
    </div>
  );
}
