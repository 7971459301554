import { Input, Textarea, Button, useToast } from "@chakra-ui/react";
import { FormikProps } from "formik";
import React, { useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { PersonaState } from "../../store/slices/persona.slice";

import UserChat from "./UserChat";
import BotChat from "./BotChat";
import { useHttpClient } from "../../utils/http.utils";
import isValidJSON from "../../utils/collaborate/isValidJson";
import downloadFile from "../../utils/collaborate/downloadFIle";
import { AuthUserProfile } from "../../models/user.model";
import { BsDownload, BsFillCheckCircleFill } from "react-icons/bs";
import { countWords, extractJSON } from "../../utils/strings.utils";

interface PersonaPersonalitySpecificKnowledgeProps {
    markAsCompleted?: (completedState: boolean) => void;
}

const PersonaSpecificKnowledge = ({
    markAsCompleted,
}: PersonaPersonalitySpecificKnowledgeProps) => {
    const { personalitySpecificKnowledgeApi } = useHttpClient();
    const authUser: AuthUserProfile = useSelector(
        (state: any) => state.auth.user,
    );
    const toast = useToast();

    const [inputValue, setInputValue] = React.useState<string>("");
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [fileToBeDownloaded, setFileToBeDownloaded] = React.useState<any>();
    const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<
        Array<{ content: string; type: "bot" | "user"; loading?: boolean }>
    >([]);
    const [wordCount, setWordCount] = React.useState<number>(0);

    const personaState: PersonaState = useSelector(
        (state: any) => state.persona,
    );
    const {
        id: personaId,
        first_name: personaName,
        character_knowledge_status,
    } = personaState.personaAttributesForm;

    useEffect(() => {
        if (character_knowledge_status === "completed") {
            setIsCompleted(true);
            if (markAsCompleted) {
                markAsCompleted(true);
            }
            return;
        } else {
            setIsCompleted(false);
            if (markAsCompleted) {
                markAsCompleted(false);
            }
            loadData();
        }
    }, [personaState.personaAttributesForm]);

    const loadData = async () => {
        setIsSubmitting(true);
        try {
            const response = await personalitySpecificKnowledgeApi(personaId);

            const jsonData = extractJSON(response);
            
            if (typeof jsonData === "object" && jsonData !== null) {
                setIsCompleted(true);
                setFileToBeDownloaded(jsonData);
                // Mark tab as completed
                if (markAsCompleted) {
                    markAsCompleted(true);
                }

                // If there was an initial empty bot message, remove it.
                setMessages((prev) => {
                    const updatedMessages = [...prev];
                    if (
                        updatedMessages.length > 0 &&
                        updatedMessages[updatedMessages.length - 1].content ===
                            ""
                    ) {
                        updatedMessages.pop();
                    }
                    return updatedMessages;
                });
                // add json message to the messages array
                if (jsonData.characteristics?.backstory?.personality) {
                    setMessages([
                        {
                            content:
                                "Personality\n" +
                                jsonData.characteristics?.backstory
                                    ?.personality,
                            type: "bot",
                            loading: false,
                        },
                    ]);
                }
            } else {
                setMessages([
                    { content: response, type: "bot", loading: false },
                ]);
            }
        } catch (error) {
            setMessages([
                {
                    content: "An error occurred!",
                    type: "bot",
                    loading: false,
                },
            ]);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFIleDownload = () => {
        downloadFile(`Persona-${personaName}`, fileToBeDownloaded);
    };

    const requestSpecificKnowledge = async (userQuery: string = "") => {
        try {
            // Add a bot message with empty content (will show loading animation)
            setMessages((prev) => [
                ...prev,
                { content: "", type: "bot", loading: true },
            ]);

            const response = await personalitySpecificKnowledgeApi(
                personaId,
                userQuery,
            );

            // const stringifyResponse = JSON.stringify(response);

            // const isJson = isValidJSON(stringifyResponse);
            const jsonData = extractJSON(response);

            if (typeof jsonData === "object" && jsonData !== null) {
                setIsCompleted(true);
                setFileToBeDownloaded(jsonData);
                // Mark tab as completed
                if (markAsCompleted) {
                    markAsCompleted(true);
                }
                // Remove the last bot chat
                setMessages((prev) => {
                    const updatedMessages = [...prev];
                    updatedMessages.pop();
                    return updatedMessages;
                });
            } else {
                setMessages((prev) => {
                    const updatedMessages = [...prev];
                    updatedMessages[updatedMessages.length - 1].content =
                        response;
                    updatedMessages[updatedMessages.length - 1].loading = false; // Remove loading state
                    return updatedMessages;
                });
            }
        } catch (error) {
            console.log(
                "~ file: PersonaSpecificKnowledge.tsx:141 ~ requestSpecificKnowledge ~ error:",
                error,
            );
            setMessages((prev) => {
                const updatedMessages = [...prev];
                updatedMessages[updatedMessages.length - 1].content =
                    "An error occurred!";
                updatedMessages[updatedMessages.length - 1].loading = false; // Remove loading state
                return updatedMessages;
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();

            // Reject if words are greater than 100
            if (countWords(inputValue) > 100) {
                toast({
                    title: "Please do not exceed 100 words.",
                    status: "warning",
                });
                return;
            }

            setWordCount(0);

            // Add user message to the messages array
            setMessages((prev) => [
                ...prev,
                { content: inputValue, type: "user" },
            ]);

            // Clear the inputValue
            setInputValue("");
            setWordCount(0);

            await requestSpecificKnowledge(inputValue);
        }
    };

    const formikRef = useRef<FormikProps<{ personalitySpecificKnowledge: string }>>(null);

    const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputValue = e.target.value;

        // Calculate word count
        const words = inputValue.trim().split(/\s+/).filter(Boolean);

        // If word count is 1000 or less, update the state, otherwise do nothing (preventing further input)
        if (words.length <= 1000) {
            setWordCount(words.length);
            setInputValue(inputValue);
        }
    };

    return (
        <div className="">
            <p className="text-[14px] mb-8 text-slate-500">
                You are required to describe the specific knowledge for this
                entity. A series of questions and answer will help you determine
                this.
            </p>

            <div className="input-group my-4">
                {isCompleted ? (
                    <>
                        <div className="h-[200px] flex flex-col gap-[6px] items-center justify-center">
                            <BsFillCheckCircleFill className="text-green-500 text-5xl" />
                            <p className="text-[15px] text-center mt-4 text-gray-500">
                                You have completed the specific knowledge test
                            </p>
                        </div>
                        {fileToBeDownloaded && (
                            <div className="px-4 py-3">
                                <p className="mb-4 text-[14px] text-slate-500">
                                    Your entity's personality trait has been
                                    baked in the digital DNA. You can no longer
                                    change that.
                                </p>

                                <Button
                                    onClick={handleFIleDownload}
                                    colorScheme="brand"
                                    size={"lg"}
                                >
                                    Communicate
                                    <BsDownload className="ml-2" size="20px" />
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {messages.map((message, index) =>
                            message.type === "bot" ? (
                                <BotChat
                                    key={`bot-${index}`}
                                    loading={message.loading}
                                    message={message.content}
                                />
                            ) : (
                                <UserChat
                                    key={index}
                                    message={message.content}
                                    userName={
                                        (authUser.fullname as string) ||
                                        (authUser.username as string) ||
                                        (authUser.email as string)
                                    }
                                />
                            ),
                        )}
                        <Textarea
                            placeholder="Type your reply here.."
                            resize="none"
                            rows={2}
                            value={inputValue}
                            onChange={onInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <p
                            className={`text-[14px] mt-2 ${
                                wordCount > 100 ? "text-red-500" : ""
                            }`}
                        >
                            Word count: {wordCount} / 100
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default PersonaSpecificKnowledge;
