import ArinLayout from "../components/ArinLayout";
import ApprovedResponseCard from "../components/ApprovedResponseCard";
import "../styles/TrainingQueue.component.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SearchModel } from "../models/search.model";
import { useHttpClient } from "../utils/http.utils";
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Tag,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { setMetaTags } from "../utils/seo.utils";
import SkeletonLoader from "../components/SkeletonLoader";
import { MdContentCopy, MdDeleteOutline } from "react-icons/md";
import ActionCard from "../components/ActionCard";
import { formatDate } from "../utils/date.utils";
import { getAuthUser } from "../utils/auth.utils";
import { DeleteConfirmation } from "../components/utils/ConfirmationModals";

export default function RefinementsView() {
    const { approveSearchForTrainingApi, fetchSearchesApi } = useHttpClient();
    // const dataCursor = useGetUserQueriesQuery('');
    const approvedTrainingDisclosure = useDisclosure();
    const [selectedQuery, setSelectedQuery] = useState<SearchModel>();
    const [isLoading, setIsLoading] = useState(true);
    const userQueries: SearchModel[] = useSelector(
        (state) => (state as any).userQueries.queries,
    );
    const [unApprovedQueries, setUnApprovedQueries] = useState<SearchModel[]>(
        [],
    );
    const [approvedQueries, setApprovedQueries] = useState<SearchModel[]>([]);
    const toast = useToast();
    const authUser = getAuthUser();

    useEffect(() => {
        // Set meta tags
        setMetaTags({ title: "Refinements" });
    }, []);

    useEffect(() => {
        const approved = userQueries.filter((q) => q.is_approved);
        setApprovedQueries(approved);

        const un_approved = userQueries.filter((q) => {
            return !q.is_approved && (q.is_edited || q.Sentiment === "1");
        });
        setUnApprovedQueries(un_approved);
    }, [userQueries]);

    /**
     *  Fetch user's queries
     * */
    const fetchUserQueries = useCallback(() => {
        fetchSearchesApi().finally(() => {
            setIsLoading(false);
        });
    }, [fetchSearchesApi]);

    useEffect(() => {
        if (userQueries.length === 0) {
            fetchUserQueries();
        } else {
            setIsLoading(false);
        }
    }, [userQueries.length, fetchUserQueries]);

    /**
     * When user clicks on approved queries
     */
    const showApprovedQueryDetails = (query: SearchModel) => {
        setSelectedQuery(query);
        approvedTrainingDisclosure.onOpen();
    };

    /**
     * When the user removes search from training
     */
    const onRemoveFromTraining = async (search: SearchModel) => {
        const deleteAction = await DeleteConfirmation({
            title: "Remove from training",
            message:
                "Are you sure you want to remove this search from training?",
        });

        if (deleteAction) {
            changeApprovedStatus(search, "2");
        }
    };

    /**
     * Approve card for training
     */
    const onApproveForTraining = (search: SearchModel) => {
        changeApprovedStatus(search, "1");
    };
    /**
     *
     * @param search {SearchModel} The search query
     * @param approval {string} Change value
     */
    const changeApprovedStatus = (search: SearchModel, approval: string) => {
        approveSearchForTrainingApi(search, approval);
    };

    /**
     *
     * Copy the reply to clipboard
     */
    const copyToClipBoard = (data: any) => {
        navigator.clipboard
            .writeText(data)
            .then(() => {
                toast({
                    title: "Copied",
                    duration: 1000,
                    status: "info",
                });
            })
            .catch(() => {
                toast({
                    title: "Can not copy to clipboard",
                    duration: 1000,
                    status: "error",
                });
            });
    };

    return (
        <div id="view">
            <ArinLayout>
                <div className="side-pane-2-view">
                    <div className="left-pane">
                        <div className="sidebar-sm">
                            <div className="side-nav-sm">
                                <h4 className="title">Response Refinements</h4>
                                <p className="subtitle">
                                    Train your brain to be better.
                                </p>
                            </div>
                            <div className="sidebar-body">
                                {approvedQueries.map((row: any, index: any) => {
                                    return (
                                        <ActionCard
                                            key={`action_card_${index}`}
                                            title={row.question}
                                            allowHover={true}
                                            textOverflowLines={4}
                                            subtitle={formatDate(
                                                row.date_added,
                                            )}
                                            text={row.reply}
                                            onClick={() =>
                                                showApprovedQueryDetails(row)
                                            }
                                            actionEl={
                                                <span
                                                    style={{ color: "#1e1e1e" }}
                                                >
                                                    <MdDeleteOutline className="icon" />
                                                </span>
                                            }
                                            onActionClick={() =>
                                                onRemoveFromTraining(row)
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="main-pane upload-content-section position-relative">
                        <div className="main-pane-contents no-bg">
                            <div className="answers">
                                {/*show skeleton loading*/}
                                {isLoading && <SkeletonLoader />}
                                {unApprovedQueries.map(
                                    (row: any, index: any) => {
                                        return (
                                            <ApprovedResponseCard
                                                key={index}
                                                data={row}
                                                onApproveForTraining={
                                                    onApproveForTraining
                                                }
                                            />
                                        );
                                    },
                                )}
                                {!isLoading &&
                                    unApprovedQueries.length === 0 &&
                                    userQueries.length > 0 && (
                                        <div className="empty-state">
                                            <div className="empty-content text-center w-100">
                                                <img
                                                    src="/all_caught_up.png"
                                                    alt=""
                                                    className="icon"
                                                />
                                                <h2 className="font-bold text-2xl mb-2">
                                                    You're all caught up
                                                </h2>
                                                <p className="text-slate-500">
                                                    All your queries are now in
                                                    training. More will show up
                                                    here when you search
                                                </p>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            {/* Show empty step  */}
                            {!isLoading && userQueries.length === 0 && (
                                <div className="empty-state">
                                    <div className="empty-content">
                                        <img
                                            src="/no_documents.png"
                                            className="icon"
                                        />
                                        <h1 className="title text-2xl font-bold">
                                            You have no queries
                                        </h1>
                                        <p className="text-lg text-slate-500">
                                            All your search queries will appear
                                            here. You can approve queries for
                                            training here
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/*    show training query */}
                <Modal
                    size="2xl"
                    isOpen={approvedTrainingDisclosure.isOpen}
                    onClose={approvedTrainingDisclosure.onClose}
                >
                    <ModalOverlay />
                    <ModalContent className="view-approved-query-modal">
                        <div
                            style={{ marginLeft: 0 }}
                            className="toolbar pl-6 mb-3 pt-2 flex gap-2 pr-4 justify-between items-center"
                        >
                            <div className="flex gap-3 items-center">
                                <div className="profile flex gap-2 items-center ">
                                    <div className="flex items-center gap-2">
                                        <div
                                            className="twin-avatars"
                                            style={{ gap: "" }}
                                        >
                                            <img
                                                src={"/user_avatar.png"}
                                                alt="avatar"
                                                className="profile-image"
                                            />
                                            <img
                                                src={
                                                    authUser.profile_picture ||
                                                    "/user_avatar.png"
                                                }
                                                alt="avatar"
                                                className="profile-image"
                                            />
                                        </div>
                                    </div>
                                    <p className="arin-name text">
                                        {selectedQuery?.character} &{" "}
                                        {authUser.fullname || authUser.username}
                                    </p>
                                </div>
                            </div>
                            <div className="actions flex items-center">
                                <div className="tags">
                                    {selectedQuery?.is_approved && (
                                        <Tag size="sm" colorScheme="blue">
                                            In Queue
                                        </Tag>
                                    )}
                                    {selectedQuery?.is_training && (
                                        <Tag size="sm" colorScheme="green">
                                            In Training
                                        </Tag>
                                    )}
                                </div>
                                <Button
                                    onClick={() =>
                                        copyToClipBoard(selectedQuery?.reply)
                                    }
                                    colorScheme="gray"
                                    variant="ghost"
                                >
                                    <MdContentCopy className="icon text-2xl" />
                                </Button>
                            </div>
                        </div>

                        <ModalBody>
                            <div className="question py-3 px-4 mb-4 bg-gray-100 rounded-md">
                                {selectedQuery?.question}
                            </div>
                            <div className="reply mt-1">
                                {selectedQuery?.reply}
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                colorScheme="grey"
                                variant="outline"
                                size="sm"
                                mr={3}
                                onClick={approvedTrainingDisclosure.onClose}
                            >
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ArinLayout>
        </div>
    );
}
