import io from "socket.io-client";

const URL = process.env.REACT_APP_SOCKET_API_URL as string;

export const socket = io(URL, {
    autoConnect: false,
    reconnection: true,
    transports: ["websocket"],
});
// migrate to vs
// export const socket = io(URL);
