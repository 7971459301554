import React, { useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useHttpClient } from "./utils/http.utils";
import { useDispatch, useSelector } from "react-redux";
import { getAuthUser, isAuthenticated } from "./utils/auth.utils";
import { setAuthState, setAuthUser } from "./store/slices/auth.slice";
import { AccountState } from "./store/slices/account.slice";
import { addAutoInteractionMessage, addBrainActivityMessage, CollaborateState, setIsCollaborateLoading, setSocketConnected } from "./store/slices/collaborate.slice";
import { socket } from "./utils/socket.utils";
import { AutoInteractionMessage } from "./models/collaboration.model";
import { PersonaState } from "./store/slices/persona.slice";
import hotToast from "react-hot-toast";
import { useGenericModal } from "./components/modals/GenericModalContext";
import { ROUTES } from "./routes";
import ErrorBoundary from "./views/ErrorBoundary";



function App() {
    const {
        fetchFoldersApi,
        fetchPersonaApi,
        fetchUserCredentialsApi,
        fetchMemoriesApi,
        listPaymentMethodApi,
        loadSetOrganizationAndMembersApi,
        listUserSubscriptionApi
    } = useHttpClient();
    const dispatch = useDispatch();
    let [searchParams] = useSearchParams();
    const organizationId = searchParams.get("org");

    const foldersState = useSelector((state) => (state as any).folders.folders);
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const personaState: PersonaState = useSelector((state) => (state as any).persona);
    const collaborateState: CollaborateState = useSelector((state) => (state as any).collaborate);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const memoriesTree = useSelector((state) => (state as any).uploadedData.memoriesTree);
    const authUser = getAuthUser();
    const location = useLocation();
    const { openPaywallModal, closePaywallModal } = useGenericModal();

    useEffect(() => {
        setTimeout(() => {
            const loadingEl = document.getElementById("loading-el");
            loadingEl?.remove();
        }, 2500);
    }, []);

    useEffect(() => {
        /**
         *  LOADING APP DATA
         *  1. Namespaces (Memories)
         *  2. Folders
         *  3. Character
         *  4. Payment methods
         *  5. Organization
         *  6. Organization Member
         *
         *  This one works once the page loads
         *  This load the authenticated user data to the state
         *
         *  The reason why this is loaded at the start of the application is because this data is used across the
         *  application. so for a better user experience it's great to preload this data
         *
         */
        if (isAuthenticated()) {
            dispatch(setAuthUser(authUser));
            dispatch(setAuthState(true));

            /**
             * Fetch this data when the user's api keys have successfully loaded
             */
            if (accountState.accountExists) {
                if (memoriesTree.length === 0) {
                    fetchMemoriesApi();
                }
                // if (namespaces.length === 0) {
                //     fetchNameSpacesApi();
                // }
                if (foldersState.length === 0) {
                    fetchFoldersApi();
                }
                if (personaState.personas.length === 0) {
                    fetchPersonaApi(null, null);
                }
                if (paymentMethodState.data.length === 0) {
                    listPaymentMethodApi().catch((err) => {
                        hotToast.error("Cannot fetch your payment methods at this time, Please try again later");
                    });
                }
                // load organizations
                if (accountState.organizations.length === 0 && accountState.accountData) {
                    loadSetOrganizationAndMembersApi(accountState.accountData.id, organizationId as string)
                        .then(({organizations, activeOrganization}) => {
                            // Check if the react router path is equal to /find-anything
                            const restrictedPaths = [ROUTES.ASK_ANYTHING, ROUTES.STORE_MEMORIES, ROUTES.APPLY, ROUTES.PERSONAS];
                            const hasAccess = accountState.accountData?.backend_subscription_status === "active" || 
                                activeOrganization?.user?.backend_subscription_status === "active";
                            if (restrictedPaths.some(path => location.pathname.startsWith(path))) {
                                // check for the user's subscription
                                if (!hasAccess) {
                                    openPaywallModal();
                                } else {
                                    closePaywallModal();
                                }
                            } else {
                                closePaywallModal();
                            }
                        })
                }
                // load user subscriptions
                if (!accountState.subscription && accountState.accountData?.stripe_customer_id) {
                    listUserSubscriptionApi();
                }
            } else {
                if (!accountState.accountExists) {
                    fetchUserCredentialsApi().catch((err) => {
                        hotToast.error("Cannot fetch your account data at this time, Please try again later");
                    });
                }
            }
        }
    }, [accountState.accountExists]);

    /**
     * Socket listener hook, this hook runs every time the collaborate state changes
     */
    useEffect(() => {
        if (collaborateState.isInitialized && (collaborateState.socketRoom || collaborateState.selectedCollaborationApp?.id)) {
            // connect to sockets
            socket.connect();
        }

        function onConnect() {
            console.log("connected to the socket"); //debug

            dispatch(setSocketConnected(true));
        }

        function onDisconnect() {
            console.log("disconnected to the socket"); //debug

            dispatch(setSocketConnected(false));
        }

        function autoInteractionUpdateHandler({ data }: { data: AutoInteractionMessage }) {
            // if message is activity message
            if (data.message_type === "ai" && (data.thought || data.action || data.observation || data.content_log || data.evaluation || data.tool_result)) {
                dispatch(addBrainActivityMessage(data));

                if (data.message || data.file) {
                    dispatch(addAutoInteractionMessage(data));
                }
            } else if (data.message_type === "ai" && (data.message || data.file)) {
                dispatch(addAutoInteractionMessage(data));
            } else {
                dispatch(addAutoInteractionMessage(data));
            }
            /**
             * Show loading animation when user sends a message
             */
            if (data.message_type === "user") {
                dispatch(setIsCollaborateLoading(true));
            } else {
                dispatch(setIsCollaborateLoading(false));
            }

            // // if message is activity messaeg
            // if (data.message_type === "ai" && (data.thought || data.action || data.observation || data.content_log)) {
            //     dispatch(addBrainActivityMessage(data));
            // } else if (data.message_type === "ai" && data.message) {
            //     dispatch(addAutoInteractionMessage(data));
            // } else {
            //     dispatch(addAutoInteractionMessage(data));
            // }
            // /**
            //  * Show loading animation when user sends a message
            //  */
            // if (data.message_type === "user") {
            //     dispatch(setIsCollaborateLoading(true));
            // } else {
            //     dispatch(setIsCollaborateLoading(false));
            // }
        }

        /**
         * When the socket connects join the channel
         */

        socket.on<"connect">("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("connect_error", console.log);

        if (collaborateState.socketRoom) {
            socket.on(collaborateState.socketRoom, ({ data }: { data: AutoInteractionMessage }) => {
                // if message is an activity message
                if (data.message_type === "ai" && (data.thought || data.action || data.observation || data.content_log || data.evaluation || data.tool_result)) {
                    dispatch(addBrainActivityMessage(data));

                    if (data.message) {
                        dispatch(addAutoInteractionMessage(data));
                    }
                } else if (data.message_type === "ai" && data.message) {
                    dispatch(addAutoInteractionMessage(data));
                } else {
                    dispatch(addAutoInteractionMessage(data));
                }
                /**
                 * Show loading animation when user sends a message
                 */
                if (data.message_type === "user") {
                    dispatch(addAutoInteractionMessage(data));
                    dispatch(setIsCollaborateLoading(true));
                } else {
                    dispatch(setIsCollaborateLoading(false));
                }
            });
        }

        /**
         *
         * Connect to specific interaction room
         */
        if (collaborateState.autoInteractionId) {
            console.log(`listening to auto-interaction-update-${collaborateState.autoInteractionId}`); //debug

            socket.on(`auto-interaction-update-${collaborateState.autoInteractionId}`, autoInteractionUpdateHandler);
        }

        return () => {
            socket.off("connect", onConnect);
            socket.off("disconnect", onDisconnect);
            socket.on(`auto-interaction-update-${collaborateState.autoInteractionId}`, autoInteractionUpdateHandler);
        };
    }, [collaborateState.isInitialized, collaborateState.socketRoom, collaborateState.autoInteractionId]);

    useEffect(() => {
        if (collaborateState.selectedCollaborationApp?.id) {
            const socketHandler = ({ data }: { data: AutoInteractionMessage }) => {
                console.log(data);
                // if message is activity message
                if (data.message_type === "ai" && (data.thought || data.action || data.observation || data.content_log || data.message || data.evaluation || data.tool_result)) {
                    dispatch(addBrainActivityMessage(data));

                    if (data.message) {
                        dispatch(addAutoInteractionMessage(data));
                    }
                } else {
                    dispatch(addAutoInteractionMessage(data));
                }
                /**
                 * Show loading animation when user sends a message
                 */
                if (data.message_type === "user") {
                    dispatch(addAutoInteractionMessage(data));
                    dispatch(setIsCollaborateLoading(true));
                } else {
                    dispatch(setIsCollaborateLoading(false));
                }
            };

            socket.on(`collaboration-app-${collaborateState.selectedCollaborationApp?.id}`, socketHandler);

            return () => {
                socket.off(`collaboration-app-${collaborateState.selectedCollaborationApp?.id}`, socketHandler);
            };
        }
    }, [collaborateState.selectedCollaborationApp]);

    return (
        <div id="App">
            <ErrorBoundary>
                <Outlet />
            </ErrorBoundary>
        </div>
    );
}

export default App;
