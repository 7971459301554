import {
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    Skeleton,
    Stack,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import "../styles/ResponseCard.component.scss";
import { MdContentCopy, MdOutlineBookmarkBorder } from "react-icons/md";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { AiOutlineEdit } from "react-icons/ai";
import React, { useEffect, useRef, useState } from "react";
import { SearchModel, ThreadModel } from "../models/search.model";
import { useDispatch, useSelector } from "react-redux";
import {
    addQueryToChain,
    addQueryToFolderChain,
    updateSearchQuery,
} from "../store/slices/searches.slice";
import { formatDate } from "../utils/date.utils";
import { useHttpClient } from "../utils/http.utils";
import { SlLink } from "react-icons/sl";
import { TbLayoutNavbarExpand } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { BsDownload, BsFillLightningChargeFill } from "react-icons/bs";
import {
    capitalizeWord,
    convertHalfCharactersToBold,
    truncateFileName,
} from "../utils/strings.utils";
import { updateThreadQuery } from "../store/slices/threads.slice";
import { updateActiveFolderQuery } from "../store/slices/folder.slice";
import { IoDocumentAttachOutline } from "react-icons/io5";

declare type RESPONSE_MODE = "search" | "thread" | "folder";

interface ResponseCardProps {
    data: SearchModel | ThreadModel;
    onSaveResponse?: (search: SearchModel | ThreadModel) => void;
    mode?: RESPONSE_MODE;
    hideSaveAction?: boolean;
    hideChainAction?: boolean;
    hideThreadAction?: boolean;
    scrollToBottomHandler?: () => void; // Passed by parent, used to scroll to bottom of view in chat mode
}

interface ArinCharacterProps {
    name: string;
    loading?: boolean;
    message: string;
}

export function ArinCharacter(props: ArinCharacterProps) {
    return (
        <div className="character-card card px-8 py-6 mb-4">
            {!props.loading ? (
                <>
                    <p className="mb-1 text-slate-500 font-semibold text-xs">
                        About
                    </p>
                    <p title={props.message} className="about-character">
                        {props.message}
                    </p>
                </>
            ) : (
                <Stack>
                    <Skeleton height="20px" width="200px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                </Stack>
            )}
        </div>
    );
}

function SearchLoader() {
    const [loadingText, setLoadingText] = useState<string>(
        "Understanding query and planning retrieval process",
    );

    useEffect(() => {
        let loadingIndex = 0,
            messages = [
                "Understanding query and planning retrieval process",
                "Deep searching through memories",
                "Found what I was looking for",
                "Formulating a proper response",
            ],
            interval = setInterval(() => {
                if (loadingIndex < messages.length) {
                    setLoadingText(messages[loadingIndex]);
                    loadingIndex++;
                }
            }, 4500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="pending-cursor">
            <p className="text-slate-500 text-sm">{loadingText}</p>
            <div className="loading-dots">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
            </div>
        </div>
    );
}

export default function ResponseCard(props: ResponseCardProps) {
    // REDUX state selectors
    const threads: any = useSelector((state) => (state as any).threads);
    // default values
    const responseMode: RESPONSE_MODE = props.mode || "search";
    // state variables
    const [displayResponse, setDisplayResponse] = useState("");
    const {
        fetchHistoryApi,
        editSearchResponseApi,
        editThreadResponseApi,
        changeResponseSentimentApi,
        changeThreadSentimentApi,
        downloadFileApi,
    } = useHttpClient();
    const [suggestedAnswer, setSuggestedAnswer] = useState<string>();
    const [isEditing, setIsEditing] = useState(false);
    const [isBoltMode, setIsBoltMode] = useState(false);
    const [isSavingEdit, setIsSavingEdit] = useState(false);
    const [threadCount, setThreadCount] = useState<number>(0);
    const suggestEditInputRef = useRef(null);
    const dispatch = useDispatch();
    const toast = useToast();
    const [isExpandEvent, setIsExpandEvent] = useState(false);
    const chainedItems = useSelector((state) => (state as any).searches.chain);
    const folderChainedItems = useSelector(
        (state) => (state as any).searches.folderChain,
    );

    function scrollToBottomOfParent() {
        // scroll to bottom of page after answering
        if (typeof props.scrollToBottomHandler === "function") {
            props.scrollToBottomHandler();
        }
    }

    useEffect(() => {
        if (props.data?.reply?.length === 0) {
            return;
        }
        // The index of the type-writer cursor, 0 means it will start typing form the beginning
        let cursorIndex: number = 0;
        // In case of expanded results, start typing from extended reply
        if (isExpandEvent) {
            // Get index of reply delimiter
            cursorIndex = props.data.reply?.indexOf("|") || 0;
        }

        var stringResponse = props.data.reply || "";

        if (props.data.Suggestion) {
            stringResponse = props.data.Suggestion;
        }

        if (props.data.isFinishedTyping) {
            setDisplayResponse(stringResponse);
            return;
        }

        const typewriterIntervalRef = setInterval(() => {
            setDisplayResponse(stringResponse.slice(0, cursorIndex));
            cursorIndex++;
            // While typing, stay at the bottom of the parent
            scrollToBottomOfParent();
            // When typing is complete
            if (cursorIndex > stringResponse.length) {
                clearInterval(typewriterIntervalRef);
                // update state of answer in db
                if (stringResponse.length > 0) {
                    switch (responseMode) {
                        case "search":
                        default:
                            dispatch(
                                updateSearchQuery({
                                    ...props.data,
                                    isFinishedTyping: true,
                                    /**
                                     * @deprecated
                                     * Expand has been changed to threads
                                     */
                                    isExpanded: isExpandEvent, // This is set to true in the case the user clicked on expand instead...
                                    isLoading: false,
                                }),
                            );
                            break;
                        case "thread":
                            dispatch(
                                updateThreadQuery({
                                    ...props.data,
                                    isFinishedTyping: true,
                                    /**
                                     * @deprecated
                                     * Expand has been changed to threads
                                     */
                                    isExpanded: isExpandEvent, // This is set to true in the case the user clicked on expand instead...
                                    isLoading: false,
                                }),
                            );
                            break;
                        case "folder":
                            dispatch(
                                updateActiveFolderQuery({
                                    ...props.data,
                                    isFinishedTyping: true,
                                    /**
                                     * @deprecated
                                     * Expand has been changed to threads
                                     */
                                    isExpanded: isExpandEvent, // This is set to true in the case the user clicked on expand instead...
                                    isLoading: false,
                                }),
                            );
                            break;
                    }
                    scrollToBottomOfParent();
                }
                //
            }
        }, 20);

        return () => clearInterval(typewriterIntervalRef);
    }, [props.data.reply, props.data.Suggestion]);

    useEffect(() => {
        let count = 0;
        threads.replies.forEach((item: any) => {
            if ("id" in props.data && item.search_id === props.data.id) {
                count += 1;
            }
        });
        setThreadCount(count);
    }, [threads]);

    function isChained(id: number): boolean {
        switch (responseMode) {
            case "search":
            default:
                return (
                    chainedItems.findIndex((item: any) => item.id === id) > -1
                );
            case "folder":
                return (
                    folderChainedItems.findIndex(
                        (item: any) => item.id === id,
                    ) > -1
                );
        }
    }

    function addToChain(item: SearchModel) {
        switch (responseMode) {
            case "search":
                dispatch(addQueryToChain(item));
                // I am doing this because the dispatch is async and might finish later before uploading the chainedItems list
                const ids = [...chainedItems, item].map((item: any) => item.id);
                // Fetch history
                fetchHistoryApi(ids);
                break;
            case "folder":
                dispatch(addQueryToFolderChain(item));
                // I am doing this because the dispatch is async and might finish later before uploading the chainedItems list
                const folder_ids = [...folderChainedItems, item].map(
                    (item: any) => item.id,
                );
                // Fetch history
                fetchHistoryApi(folder_ids, "folder");
                break;
        }
    }

    /**
     * Emit a response to the parent container in the case the user
     * tries to save the response.
     */
    const emitSaveResponse = () => {
        if (typeof props.onSaveResponse === "function") {
            props.onSaveResponse(props.data);
        }
    };

    /**
     *
     * Copy the reply to clipboard
     */
    const copyToClipBoard = (data: any) => {
        navigator.clipboard
            .writeText(data)
            .then(() => {
                toast({
                    title: "Copied",
                    duration: 1000,
                    status: "info",
                });
            })
            .catch(() => {
                toast({
                    title: "Can not copy to clipboard",
                    duration: 1000,
                    status: "error",
                });
            });
    };

    /**
     * Expand search results
     */
    // const expandSearch = () => {
    //     // set current search card to loading
    //     setIsExpandEvent(true);
    //     dispatch(updateSearchQuery({...props.data, isFinishedTyping: false, isLoading: true}));
    //     // Fetch expanded results from endpoint
    //     httpClient.post(`/search/Expand/${props.data.id}?access_token=${token}`)
    //         .then(({data}) => {
    //             // Update search card in state...
    //             dispatch(updateSearchQuery({...props.data, ...data, isFinishedTyping: false, isLoading: false}));
    //         })
    //         .catch(error => {
    //             console.error(error);
    //             toast({
    //                 title: 'Unable to expand this search',
    //                 description: 'We are unable to fetch at this time, Please try again later',
    //                 duration: 1200
    //             });
    //             // Reset status variables...
    //             setIsExpandEvent(false);
    //         });
    // }

    const changeSentiment = (sentiment: string) => {
        let promise: Promise<any>;
        if (responseMode === "thread") {
            promise = changeThreadSentimentApi(props.data, sentiment);
        } else {
            promise = changeResponseSentimentApi(props.data, sentiment);
        }
        promise.catch((error) => {
            console.error(error);
            // Reset status variables...
            setIsExpandEvent(false);
        });
    };

    /**
     * ====================================================================================
     *          SUGGESTING EDIT FUNCTIONS
     * ====================================================================================
     *
     */
    const suggestResponseEdit = () => {
        if (!suggestedAnswer) {
            toast({
                title: "You cannot suggest an empty edit",
                status: "warning",
                duration: 45000,
            });
            return;
        }
        setIsSavingEdit(true);
        let promise: Promise<any>;
        if (responseMode === "thread") {
            promise = editThreadResponseApi(props.data, suggestedAnswer);
        } else {
            promise = editSearchResponseApi(props.data, suggestedAnswer);
        }
        // set promise
        promise.finally(() => {
            // Close editor
            setIsEditing(false);
            setIsSavingEdit(false);
        });
    };

    const setEditMode = () => {
        if (props.data.Suggestion) {
            setSuggestedAnswer(props.data.Suggestion);
        } else {
            setSuggestedAnswer(props.data.reply);
        }

        setIsEditing(true);
        setTimeout(() => {
            // @ts-ignore
            suggestEditInputRef.current.focus();
        });
    };

    const clearEditMode = () => {
        setSuggestedAnswer("");
        setIsEditing(false);
    };

    return (
        <div className="card px-6 pb-6 pt-6">
            <div className="card-question px-4 py-2 mb-4">
                {/*<img*/}
                {/*    src={profile_picture || "/user_avatar.png"}*/}
                {/*    alt="avatar"*/}
                {/*    className="profile-image"*/}
                {/*/>*/}
                <p className="name">{props.data?.question}</p>
            </div>
            {responseMode === "folder" && (
                <div className="character-info">
                    <img
                        src={"/user_avatar.png"}
                        alt="avatar"
                        className="profile-image"
                    />
                    <div className="character-name">{props.data.character}</div>
                </div>
            )}
            {!isEditing && (
                <div className="card-meta mb-3 flex justify-between items-end">
                    {/*<p className="arin-name">{props.data?.character}</p>*/}
                </div>
            )}
            {isEditing ? (
                <div className="edit-box">
                    <FormControl>
                        <FormLabel>
                            <strong>Edit Answer</strong>
                        </FormLabel>
                        <Textarea
                            ref={suggestEditInputRef}
                            className="text-box"
                            value={suggestedAnswer}
                            placeholder="Edit Answer"
                            onChange={(e) => setSuggestedAnswer(e.target.value)}
                        />
                    </FormControl>
                </div>
            ) : (
                <div className="card-response border-l-2 pl-4">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: isBoltMode
                                ? convertHalfCharactersToBold(displayResponse)
                                : displayResponse,
                        }}
                    ></span>
                    {props.data.isFinishedTyping &&
                        props.data.struct_files?.map((fileData, index) => {
                            return (
                                <div
                                    key={`files-${index}`}
                                    className="preview-container"
                                >
                                    <div className="preview-section">
                                        <div className="preview-table-container">
                                            <table className="preview-table">
                                                <tr>
                                                    {fileData.preview.header_list.map(
                                                        (header, hIndex) => {
                                                            return (
                                                                <th
                                                                    key={`h-${hIndex}`}
                                                                >
                                                                    {header}
                                                                </th>
                                                            );
                                                        },
                                                    )}
                                                </tr>
                                                {fileData.preview.data_list.map(
                                                    (row, rIndex) => {
                                                        return (
                                                            <tr
                                                                key={`row-${rIndex}`}
                                                            >
                                                                {row.map(
                                                                    (
                                                                        cell,
                                                                        cIndex,
                                                                    ) => (
                                                                        <td
                                                                            key={`c-${cIndex}`}
                                                                        >
                                                                            {
                                                                                cell
                                                                            }
                                                                        </td>
                                                                    ),
                                                                )}
                                                            </tr>
                                                        );
                                                    },
                                                )}
                                            </table>
                                        </div>
                                        {fileData.filename && (
                                            <div className="preview-file">
                                                <div className="leading">
                                                    <IoDocumentAttachOutline className="icon" />
                                                </div>
                                                <div className="body">
                                                    <p className="filename">
                                                        {truncateFileName(
                                                            fileData.filename,
                                                            100,
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="action">
                                                    <Button
                                                        onClick={() =>
                                                            downloadFileApi(
                                                                fileData.filename,
                                                            )
                                                        }
                                                        variant="ghost"
                                                    >
                                                        <BsDownload className="icon" />
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    {!props.data.isFinishedTyping && props.data.isLoading && (
                        <SearchLoader />
                    )}
                </div>
            )}
            {/*{props.data.isLoading && <Cursor/>}*/}
            {props.data?.isFinishedTyping && !isEditing && (
                <div className="card-footer mt-6">
                    <div className="date">
                        {/*<p className="card-date text-slate-400">{capitalizeWord(getMemoryFromNamespaceString(props.data.check_list as string).sector || '')}</p>*/}
                        {props.data.segregated_destribution && (
                            <p className="card-date text-slate-400">
                                {capitalizeWord(
                                    props.data.segregated_destribution[0],
                                )}
                            </p>
                        )}
                        <p className="card-date text-slate-400">
                            {formatDate(props.data.date_added as string)}
                        </p>
                    </div>
                    <div className="card-footer-actions">
                        {props.data.Sentiment === "1" && (
                            <p className="sentiment-message gap-1.5 pr-4 text-slate-500">
                                <FiThumbsUp className="icon positive" />
                                <span>You rated "Amazing"</span>
                            </p>
                        )}
                        {props.data.Sentiment === "0" && (
                            <p className="sentiment-message flex items-center gap-1.5 pr-4 text-slate-500">
                                <FiThumbsDown className="icon negative" />
                                <span>You rated "Could be better"</span>
                            </p>
                        )}
                        {props.data?.isFinishedTyping && !isEditing && (
                            <>
                                <Button
                                    mr={1}
                                    className="icon-buttons"
                                    onClick={() => setIsBoltMode(!isBoltMode)}
                                    variant={isBoltMode ? "solid" : "ghost"}
                                    colorScheme={isBoltMode ? "yellow" : "gray"}
                                >
                                    <BsFillLightningChargeFill className="icon" />
                                </Button>
                                <Button
                                    mr={1}
                                    className="icon-buttons"
                                    colorScheme="gray"
                                    onClick={() =>
                                        copyToClipBoard(props.data.reply)
                                    }
                                    variant="ghost"
                                >
                                    <MdContentCopy className="icon" />
                                </Button>
                                {!props.hideSaveAction &&
                                    props.data.type !== "notes" && (
                                        <Button
                                            mr={1}
                                            className="icon-buttons"
                                            onClick={emitSaveResponse}
                                            variant="ghost"
                                        >
                                            <MdOutlineBookmarkBorder className="icon save-icon" />
                                        </Button>
                                    )}
                            </>
                        )}
                        {!props.data.Sentiment &&
                            props.data.type !== "notes" && (
                                <>
                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        onClick={() => changeSentiment("1")}
                                        colorScheme="blue"
                                        size="sm"
                                        // variant={props.data.Sentiment === '1' ? 'solid' : 'outline'}
                                        variant="ghost"
                                    >
                                        <FiThumbsUp className="icon positive" />
                                    </Button>
                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        onClick={() => changeSentiment("0")}
                                        colorScheme="red"
                                        size="sm"
                                        // variant={props.data.Sentiment === '0' ? 'solid' : 'outline'}
                                        variant="ghost"
                                    >
                                        <FiThumbsDown className="icon negative" />
                                    </Button>
                                </>
                            )}
                        {props.data.type !== "notes" && (
                            <Button
                                mr={2}
                                onClick={setEditMode}
                                colorScheme="gray"
                                size="sm"
                                variant="outline"
                            >
                                <AiOutlineEdit className="icon" />
                                &nbsp;Edit
                            </Button>
                        )}
                        {props.data.type !== "notes" && (
                            <>
                                {!props.hideChainAction && (
                                    <Button
                                        mr={2}
                                        onClick={() => addToChain(props.data)}
                                        colorScheme={
                                            isChained(
                                                (props.data as SearchModel)
                                                    .id as number,
                                            )
                                                ? "blue"
                                                : "gray"
                                        }
                                        size="sm"
                                        variant="outline"
                                    >
                                        <SlLink className="icon rotate" />
                                        &nbsp;&nbsp;Chain
                                    </Button>
                                )}
                                {!props.hideThreadAction && (
                                    <NavLink
                                        to={`/ask-anything/thread/${
                                            (props.data as SearchModel).id
                                        }${
                                            responseMode === "folder"
                                                ? "?mode=folder"
                                                : ""
                                        }`}
                                    >
                                        <Button
                                            title={
                                                threadCount > 0
                                                    ? `Thread (${threadCount})`
                                                    : "Start thread"
                                            }
                                            colorScheme={
                                                threadCount > 0
                                                    ? "brand"
                                                    : "gray"
                                            }
                                            size="sm"
                                            variant={
                                                threadCount > 0
                                                    ? "solid"
                                                    : "outline"
                                            }
                                        >
                                            <TbLayoutNavbarExpand className="icon" />
                                            &nbsp;
                                            {threadCount > 0
                                                ? `Thread (${threadCount})`
                                                : "Start thread"}
                                        </Button>
                                    </NavLink>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
            {/*    if  isEditing*/}
            {isEditing && (
                <div className="card-footer mt-3">
                    <p className="card-date text-slate-400">
                        {formatDate(props.data.date_added as string)}
                    </p>
                    <div className="card-footer-actions">
                        <ButtonGroup>
                            <Button
                                isDisabled={isSavingEdit}
                                onClick={clearEditMode}
                                colorScheme="gray"
                                size="sm"
                                variant="outline"
                            >
                                Cancel
                            </Button>
                            <Button
                                isLoading={isSavingEdit}
                                loadingText="Saving.."
                                onClick={suggestResponseEdit}
                                colorScheme="green"
                                size="sm"
                                variant="solid"
                            >
                                Save
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            )}
        </div>
    );
}
