import { Button, FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import "../../styles/BrainView.component.scss";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { AccountState, setAccountApiKeys, setUserAccountExists } from "../../store/slices/account.slice";
import { setMetaTags } from "../../utils/seo.utils";
import { useHttpClient } from "../../utils/http.utils";
import { getAuthUser } from "../../utils/auth.utils";
import "../../styles/NeuralPathways.component.scss";
import { FiPlus } from "react-icons/fi";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import debounce from "lodash.debounce";
import { transformUnderscoresToSpacesAndCapitalize } from "../../utils/strings.utils";
import { LordIcon } from "../../components/icons/LordIcon";

interface UserTool {
    user?: string;
    tool_name: string;
    tool_id: string;
    configuration: any;
    show_password_config: any;
    should_show_arguments: boolean;
}

interface ToolOption {
    label: string;
    value: string;
    id: string;
    name: string;
    arguments_object: any;
}

interface Tool {
    id: string;
    name: string;
    arguments_object?: any;
}

export default function NeuralPathways() {
    const accountState: AccountState = useSelector((state: any) => state.account);

    const { createUserApi, updateUserApi, listToolsApi, listUserToolsApi, updateUserToolsApi } = useHttpClient();
    const toast = useToast();
    const authUser = getAuthUser();
    const dispatch = useDispatch();
    const addToolDisclosure = useDisclosure();
    const [isToolsLoading, setIsToolsLoading] = useState(false);
    const [isUpdatingTools, setIsUpdatingTools] = useState(false);
    const [hasToolConfigChanged, setHasToolConfigChanged] = useState(false);
    const [toolOptions, setToolOptions] = useState<ToolOption[]>([]);
    const [selectedTools, setSelectedTools] = useState<ToolOption[]>([]);
    const [userTools, setUserTools] = useState<UserTool[]>([]);
    const [userToolIdsSet, setUserToolIdsSet] = useState<Set<string>>(new Set<string>());
    const [originalUserToolsStringified, setOriginalUserToolsStringified] = useState<string>("");
    const [toolsMap, setToolsMap] = useState<Map<string, Tool>>(new Map<string, Tool>());

    useEffect(() => {
        setMetaTags({ title: "Neural Pathways" });
    }, []);

    useEffect(() => {
        const setAllTools = async () => {
            const responseData = await listToolsApi();

            setToolsMap(
                new Map(
                    responseData?.map((tool) => [
                        tool.id,
                        {
                            id: tool.id,
                            name: tool.name,
                            arguments_object: tool.arguments_object,
                        },
                    ]),
                ),
            );
        };

        const setDisplayedUserTools = async () => {
            const responseData = await listUserToolsApi();

            if (responseData) {
                const userToolsData = responseData.map((userTool) => ({
                    user: userTool.user,
                    tool_id: userTool.tool_id,
                    tool_name: userTool.tool_name,
                    configuration: userTool.configuration,
                    should_show_arguments: false,
                    show_password_config: Object.keys(userTool.configuration).reduce<any>((passwordArguments, currentKey) => {
                        passwordArguments[currentKey] = false;
    
                        return passwordArguments;
                    }, {}),
                }));
    
                setOriginalUserToolsStringified(extractPureUserToolConfigStringified(userToolsData));
                setUserTools(userToolsData);
            }
        };

        setAllTools();
        setDisplayedUserTools();
    }, []);

    useEffect(() => {
        setUserToolIdsSet(new Set(userTools.map((userTool) => userTool.tool_id)));
    }, [userTools]);

    useEffect(() => {
        if (extractPureUserToolConfigStringified(userTools) === originalUserToolsStringified) {
            setHasToolConfigChanged(false);
        } else {
            setHasToolConfigChanged(true);
        }
    }, [userTools, originalUserToolsStringified]);

    function onSaveForm(values, actions) {
        let promise: Promise<any>;

        if (accountState.accountExists) {
            promise = updateUserApi({ email: authUser.email, ...values });
        } else {
            promise = createUserApi({ email: authUser.email, ...values });
        }

        promise
            .then(() => {
                dispatch(
                    setAccountApiKeys({
                        openAi: {
                            apiKey: values.openAiKey,
                        },
                        pinecone: {
                            env: values.pineconeEnv,
                            index: values.pineconeIndex,
                            key: values.pineconeKey,
                        },
                    }),
                );
                // update account exists status
                if (!accountState.accountExists) {
                    dispatch(setUserAccountExists(true));
                }
                toast({
                    title: "Saved",
                    status: "success",
                });
            })
            .catch(() => {
                toast({
                    title: "We can not save your info at this time. Please try again later",
                    status: "error",
                });
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    const extractPureUserToolConfigStringified = (userToolLIst: UserTool[]) => {
        return JSON.stringify(
            userToolLIst.map((userTool) => ({
                user: userTool.user,
                tool_id: userTool.tool_id,
                tool_name: userTool.tool_name,
                configuration: userTool.configuration,
            })),
        );
    };

    const closeAddToolModal = () => {
        addToolDisclosure.onClose();
    };

    const handleToolSearchInputChange = (value) => {
        debouncedSearch(value);
    };

    const onSelectTools = (val) => {
        setSelectedTools(val);
    };

    const onAddTool = () => {
        setUserTools([
            ...userTools,
            ...selectedTools
                .filter((selectedTool) => !userToolIdsSet.has(selectedTool.id))
                .map((selectedTool) => ({
                    tool_id: selectedTool.id,
                    tool_name: selectedTool.name,
                    configuration: Object.keys(selectedTool.arguments_object).reduce<any>((argumentsObject, currentKey) => {
                        if (selectedTool.arguments_object[currentKey].parameter_type === "custom") {
                            argumentsObject[currentKey] = "";
                        }

                        return argumentsObject;
                    }, {}),
                    show_password_config: Object.keys(selectedTool.arguments_object).reduce<any>((argumentsObject, currentKey) => {
                        if (selectedTool.arguments_object[currentKey].parameter_type === "custom") {
                            argumentsObject[currentKey] = false;
                        }

                        return argumentsObject;
                    }, {}),
                    should_show_arguments: false,
                })),
        ]);

        closeAddToolModal();
        setSelectedTools([]);
    };

    const changeToolValue = (value: string, tool_id: string, argument_key: string) => {
        const currentUserTools = [...userTools];

        const userTool = currentUserTools.find((tool) => tool.tool_id === tool_id);

        if (userTool) {
            userTool.configuration[argument_key] = value;
        }

        setUserTools(currentUserTools);
    };

    const changeShowPasswordArgumentValue = (tool_id: string, argument_key: string) => {
        const currentUserTools = [...userTools];

        const userTool = currentUserTools.find((tool) => tool.tool_id === tool_id);

        if (userTool) {
            userTool.show_password_config[argument_key] = !userTool.show_password_config[argument_key];
        }

        setUserTools(currentUserTools);
    };

    const setShouldShowArguments = (tool_id: string) => {
        const currentUserTools = [...userTools];

        const userTool = currentUserTools.find((tool) => tool.tool_id === tool_id);

        if (userTool) {
            userTool.should_show_arguments = !userTool.should_show_arguments;
        }

        setUserTools(currentUserTools);
    };

    const debouncedSearch = debounce(async (value) => {
        if (!value) {
            setToolOptions([]);
            return;
        }

        setIsToolsLoading(true); // Begin loading

        try {
            const responseData = await listToolsApi({
                search_criteria: {
                    name: {
                        contains: value,
                    },
                },
            });

            const fetchedToolOptions = responseData.map((tool) => ({
                label: tool.name as string,
                value: tool.id as string,
                id: tool.id as string,
                name: tool.name as string,
                arguments_object: tool.arguments_object,
            }));

            setToolOptions(
                fetchedToolOptions.filter((tool) => {
                    // Only tools with custom arguments
                    return !userToolIdsSet.has(tool.id) && Object.keys(tool.arguments_object).find((key) => tool.arguments_object[key].parameter_type === "custom");
                }),
            );
        } catch (error) {
            console.error("Failed to fetch tools:", error);
        }

        setIsToolsLoading(false); // End loading
    }, 300); // 300ms delay

    const updateUserTools = async () => {
        setIsToolsLoading(true);

        const updateResponse = await updateUserToolsApi(
            userTools.map((userTool) => ({
                tool_id: userTool.tool_id,
                tool_name: userTool.tool_name,
                configuration: userTool.configuration,
            })),
        );

        if (updateResponse) {
            setOriginalUserToolsStringified(extractPureUserToolConfigStringified(userTools));
        }

        setIsToolsLoading(false);
    };

    return (
        <div className="neural-path-ways h-full">
            <div className="left-col">
                <div className="side-nav-sm">
                    <h4 className="title">Neural Pathways</h4>
                </div>
                <div className="form-container px-6 mt-6">
                    <Formik
                        initialValues={{
                            openAiKey: accountState.apiKeys.openAi.apiKey,
                            pineconeKey: accountState.apiKeys.pinecone.key,
                            pineconeEnv: accountState.apiKeys.pinecone.env,
                            pineconeIndex: accountState.apiKeys.pinecone.index,
                        }}
                        onSubmit={onSaveForm}
                    >
                        {({ handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="section mb-6">
                                    <p className="font-semibold">Open AI</p>
                                    <p className="text-slate-500 mb-3">Configure your Open AI brain</p>
                                    <div className="mt-1">
                                        <Field name="openAiKey">
                                            {({ field, form }) => (
                                                <FormControl isInvalid={!!form.errors.openAiKey && form.errors.openAiKey}>
                                                    <p className="font-medium text-sm mb-2">API Key</p>
                                                    <Input {...field} placeholder="API Key" />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <div className="section">
                                    <p className="font-semibold">Pinecone</p>
                                    <p className="text-slate-500 mb-3">Configure pinecone</p>
                                    <div className="mt-1">
                                        <Field name="pineconeKey">
                                            {({ field, form }) => (
                                                <FormControl mb={6} isInvalid={!!form.errors.pineconeKey && form.errors.pineconeKey}>
                                                    <p className="font-medium text-sm mb-2">API Key</p>
                                                    <Input {...field} placeholder="API Key" />
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="pineconeEnv">
                                            {({ field, form }) => (
                                                <FormControl mb={6} isInvalid={!!form.errors.pineconeEnv && form.errors.pineconeEnv}>
                                                    <p className="font-medium text-sm mb-2">Environment</p>
                                                    <Input {...field} placeholder="Environment" />
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="pineconeIndex">
                                            {({ field, form }) => (
                                                <FormControl mb={6} isInvalid={!!form.errors.pineconeIndex && form.errors.pineconeIndex}>
                                                    <p className="font-medium text-sm mb-2">Index</p>
                                                    <Input {...field} placeholder="Index" />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <div className="actions pt-6">
                                    <Button type="submit" isLoading={isSubmitting} loadingText="Saving" colorScheme="brand">
                                        Save
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
            <div className="right-col flex flex-col">
                <div className="side-nav-sm-container flex flex-row items-center">
                    <div className="side-nav-sm flex-1">
                        <h4 className="title">Tools</h4>
                        <p className="subtitle">Where your neural pathways go</p>
                    </div>
                    <div className="pr-2">
                        <Button onClick={() => addToolDisclosure.onOpen()} size="sm" colorScheme="brand">
                            <FiPlus />
                        </Button>
                    </div>
                </div>
                {userTools.length > 0 && (
                    <>
                        <div className="right-col-content flex-1">
                            <form onSubmit={(e) => e.preventDefault()}>
                                {userTools.map((userTool, index) => (
                                    <div className="px-4" key={index}>
                                        <div className="flex flex-row">
                                            <h4 className="flex-1 label font-semibold mb-1 p-2">{userTool.tool_name}</h4>
                                            <div className="actions flex items-center justify-center pt-2">
                                                <button onClick={() => setShouldShowArguments(userTool.tool_id)} type="button">
                                                    <LordIcon
                                                        src={userTool.should_show_arguments ? "/lord-icons/chevron-down.json" : "/lord-icons/chevron-right.json"}
                                                        // trigger="hover"
                                                        colors={{
                                                            primary: "#121331",
                                                            secondary: "#333",
                                                        }}
                                                        stroke={40}
                                                        size={16}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                        <div className={"argument-values pr-2 pl-4" + (userTool.should_show_arguments ? " show-arguments" : "")}>
                                            {Object.keys(userTool.configuration).map((key, index) => (
                                                <FormControl key={index} mb={4}>
                                                    <InputGroup size="sm">
                                                        <Input value={userTool.configuration[key]} onChange={(event) => changeToolValue(event.target.value, userTool.tool_id, key)} type={userTool.show_password_config[key] ? "text" : "password"} placeholder={transformUnderscoresToSpacesAndCapitalize(key)} />
                                                        <InputRightElement>
                                                            <IconButton aria-label={userTool.show_password_config[key] ? "Mask password" : "Show password"} icon={userTool.show_password_config[key] ? <ViewOffIcon /> : <ViewIcon />} onClick={() => changeShowPasswordArgumentValue(userTool.tool_id, key)} variant="ghost" size="sm" />
                                                        </InputRightElement>
                                                    </InputGroup>
                                                </FormControl>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </form>
                        </div>
                        <div className="right-col-footer flex flex-row-reverse">
                            <div className="actions p-2">
                                <Button isLoading={isUpdatingTools} isDisabled={!hasToolConfigChanged} loadingText="Updating..." colorScheme="brand" onClick={updateUserTools}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {/*    Modals*/}
            <Modal isOpen={addToolDisclosure.isOpen} onClose={closeAddToolModal} closeOnOverlayClick={false} size="lg" isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Tools</ModalHeader>
                    <ModalCloseButton />
                    <Formik initialValues={{ tool: "" }} onSubmit={onAddTool}>
                        {(props) => (
                            <Form>
                                <ModalBody>
                                    <div className="form-group">
                                        <FormControl mb={3}>
                                            <Select defaultValue={selectedTools} isMulti isClearable={false} name="colors" options={toolOptions} isLoading={isToolsLoading} onChange={onSelectTools} onInputChange={handleToolSearchInputChange} placeholder={"Type to Add"} className="arin-react-select-container" classNamePrefix="arin-react-select" loadingMessage={() => "Loading..."} noOptionsMessage={() => (isToolsLoading ? "Loading..." : "No options")} />
                                        </FormControl>
                                    </div>
                                </ModalBody>

                                <ModalFooter>
                                    <Button variant="ghost" mr={3} onClick={closeAddToolModal}>
                                        Close
                                    </Button>
                                    <Button
                                        // isLoading={props.isSubmitting}
                                        loadingText="Adding Tools ..."
                                        type="submit"
                                        colorScheme="brand"
                                    >
                                        Add
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </div>
    );
}
